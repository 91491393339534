<template>
    <div class="d-flex flex-column gap-4">
        <Skeleton width="100%" height="80px" borderRadius="10px" />
        <Skeleton width="100%" height="80px" borderRadius="10px" />
    </div>
</template>


<script lang="ts" setup>
</script>


<style lang="scss" scoped>

</style>