/**
 * @fileoverview gRPC-Web generated client stub for dataApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: anvisaDrug.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.dataApi = require('./anvisaDrug_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataApi.AnvisaDrugProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataApi.AnvisaDrugProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.DrugSearchItemsRequest,
 *   !proto.dataApi.DrugSearchItemsResponse>}
 */
const methodDescriptor_AnvisaDrugProtoService_DrugSearchItems = new grpc.web.MethodDescriptor(
  '/dataApi.AnvisaDrugProtoService/DrugSearchItems',
  grpc.web.MethodType.UNARY,
  proto.dataApi.DrugSearchItemsRequest,
  proto.dataApi.DrugSearchItemsResponse,
  /**
   * @param {!proto.dataApi.DrugSearchItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.DrugSearchItemsResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.DrugSearchItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.DrugSearchItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.DrugSearchItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.AnvisaDrugProtoServiceClient.prototype.drugSearchItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.AnvisaDrugProtoService/DrugSearchItems',
      request,
      metadata || {},
      methodDescriptor_AnvisaDrugProtoService_DrugSearchItems,
      callback);
};


/**
 * @param {!proto.dataApi.DrugSearchItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.DrugSearchItemsResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.AnvisaDrugProtoServicePromiseClient.prototype.drugSearchItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.AnvisaDrugProtoService/DrugSearchItems',
      request,
      metadata || {},
      methodDescriptor_AnvisaDrugProtoService_DrugSearchItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.ListLaboratoriesRequest,
 *   !proto.dataApi.ListLaboratoriesResponse>}
 */
const methodDescriptor_AnvisaDrugProtoService_ListLaboratories = new grpc.web.MethodDescriptor(
  '/dataApi.AnvisaDrugProtoService/ListLaboratories',
  grpc.web.MethodType.UNARY,
  proto.dataApi.ListLaboratoriesRequest,
  proto.dataApi.ListLaboratoriesResponse,
  /**
   * @param {!proto.dataApi.ListLaboratoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.ListLaboratoriesResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.ListLaboratoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.ListLaboratoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.ListLaboratoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.AnvisaDrugProtoServiceClient.prototype.listLaboratories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.AnvisaDrugProtoService/ListLaboratories',
      request,
      metadata || {},
      methodDescriptor_AnvisaDrugProtoService_ListLaboratories,
      callback);
};


/**
 * @param {!proto.dataApi.ListLaboratoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.ListLaboratoriesResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.AnvisaDrugProtoServicePromiseClient.prototype.listLaboratories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.AnvisaDrugProtoService/ListLaboratories',
      request,
      metadata || {},
      methodDescriptor_AnvisaDrugProtoService_ListLaboratories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.ListAprasentationsRequest,
 *   !proto.dataApi.ListAprasentationsResponse>}
 */
const methodDescriptor_AnvisaDrugProtoService_ListAprasentations = new grpc.web.MethodDescriptor(
  '/dataApi.AnvisaDrugProtoService/ListAprasentations',
  grpc.web.MethodType.UNARY,
  proto.dataApi.ListAprasentationsRequest,
  proto.dataApi.ListAprasentationsResponse,
  /**
   * @param {!proto.dataApi.ListAprasentationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.ListAprasentationsResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.ListAprasentationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.ListAprasentationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.ListAprasentationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.AnvisaDrugProtoServiceClient.prototype.listAprasentations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.AnvisaDrugProtoService/ListAprasentations',
      request,
      metadata || {},
      methodDescriptor_AnvisaDrugProtoService_ListAprasentations,
      callback);
};


/**
 * @param {!proto.dataApi.ListAprasentationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.ListAprasentationsResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.AnvisaDrugProtoServicePromiseClient.prototype.listAprasentations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.AnvisaDrugProtoService/ListAprasentations',
      request,
      metadata || {},
      methodDescriptor_AnvisaDrugProtoService_ListAprasentations);
};


module.exports = proto.dataApi;

