<template>
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.833328 15.9166C0.833328 17.4749 2.02499 18.6666 3.58333 18.6666H16.4167C17.975 18.6666 19.1667 17.4749 19.1667 15.9166V8.58325H0.833328V15.9166ZM16.4167 2.16659H14.5833V1.24992C14.5833 0.699919 14.2167 0.333252 13.6667 0.333252C13.1167 0.333252 12.75 0.699919 12.75 1.24992V2.16659H7.24999V1.24992C7.24999 0.699919 6.88333 0.333252 6.33333 0.333252C5.78333 0.333252 5.41666 0.699919 5.41666 1.24992V2.16659H3.58333C2.02499 2.16659 0.833328 3.35825 0.833328 4.91658V6.74992H19.1667V4.91658C19.1667 3.35825 17.975 2.16659 16.4167 2.16659Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>