// source: template.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.templateApi.AddDiagnosisTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.AddDiagnosisTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.AddDrugTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.AddDrugTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.AddExamTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.AddExamTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.AddForwardingTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.AddForwardingTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.AddMedicalCertificateTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.AddMedicalCertificateTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.CidProto', null, global);
goog.exportSymbol('proto.templateApi.DeleteDiagnosisTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.DeleteDiagnosisTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.DeleteDrugTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.DeleteDrugTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.DeleteExamTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.DeleteExamTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.DeleteForwardingTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.DeleteForwardingTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.DeleteMedicalCertificateTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.DeleteMedicalCertificateTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.DiagnosisProto', null, global);
goog.exportSymbol('proto.templateApi.DrugProto', null, global);
goog.exportSymbol('proto.templateApi.ExamProto', null, global);
goog.exportSymbol('proto.templateApi.ForwardingProto', null, global);
goog.exportSymbol('proto.templateApi.ListCidProto', null, global);
goog.exportSymbol('proto.templateApi.ListDTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosesProto', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosisCidProto', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosisCompleteTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosisTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosisTemplatesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListDiagnosisTemplatesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListDrugTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListDrugTemplatesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListDrugTemplatesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListDrugsProto', null, global);
goog.exportSymbol('proto.templateApi.ListDrugsRequest', null, global);
goog.exportSymbol('proto.templateApi.ListDrugsResponse', null, global);
goog.exportSymbol('proto.templateApi.ListExamTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListExamTemplatesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListExamTemplatesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListExamsProto', null, global);
goog.exportSymbol('proto.templateApi.ListExamsRequest', null, global);
goog.exportSymbol('proto.templateApi.ListExamsResponse', null, global);
goog.exportSymbol('proto.templateApi.ListFTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListForwardingTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListForwardingTemplatesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListForwardingTemplatesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListForwardingsProto', null, global);
goog.exportSymbol('proto.templateApi.ListForwardingsRequest', null, global);
goog.exportSymbol('proto.templateApi.ListForwardingsResponse', null, global);
goog.exportSymbol('proto.templateApi.ListMCCidProto', null, global);
goog.exportSymbol('proto.templateApi.ListMCTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListMedicalCertificateTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.ListMedicalCertificateTemplatesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListMedicalCertificateTemplatesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListMedicalCertificatesProto', null, global);
goog.exportSymbol('proto.templateApi.ListMedicalCertificatesRequest', null, global);
goog.exportSymbol('proto.templateApi.ListMedicalCertificatesResponse', null, global);
goog.exportSymbol('proto.templateApi.ListTemplateProto', null, global);
goog.exportSymbol('proto.templateApi.MedicalCertificateCidProto', null, global);
goog.exportSymbol('proto.templateApi.MedicalCertificateProto', null, global);
goog.exportSymbol('proto.templateApi.UpdateDiagnosisTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.UpdateDiagnosisTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.UpdateDrugProto', null, global);
goog.exportSymbol('proto.templateApi.UpdateDrugTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.UpdateDrugTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.UpdateExamProto', null, global);
goog.exportSymbol('proto.templateApi.UpdateExamTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.UpdateExamTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.UpdateForwardingTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.UpdateForwardingTemplateResponse', null, global);
goog.exportSymbol('proto.templateApi.UpdateMedicalCertificateTemplateRequest', null, global);
goog.exportSymbol('proto.templateApi.UpdateMedicalCertificateTemplateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDiagnosesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosesRequest.displayName = 'proto.templateApi.ListDiagnosesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDiagnosesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosesResponse.displayName = 'proto.templateApi.ListDiagnosesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListDiagnosisCompleteTemplateProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListDiagnosisCompleteTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosisCompleteTemplateProto.displayName = 'proto.templateApi.ListDiagnosisCompleteTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListDiagnosesProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListDiagnosesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosesProto.displayName = 'proto.templateApi.ListDiagnosesProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosisCidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDiagnosisCidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosisCidProto.displayName = 'proto.templateApi.ListDiagnosisCidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosisTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDiagnosisTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosisTemplatesRequest.displayName = 'proto.templateApi.ListDiagnosisTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosisTemplatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListDiagnosisTemplatesResponse.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListDiagnosisTemplatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosisTemplatesResponse.displayName = 'proto.templateApi.ListDiagnosisTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDiagnosisTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDiagnosisTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDiagnosisTemplateProto.displayName = 'proto.templateApi.ListDiagnosisTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteDiagnosisTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteDiagnosisTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteDiagnosisTemplateRequest.displayName = 'proto.templateApi.DeleteDiagnosisTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteDiagnosisTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteDiagnosisTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteDiagnosisTemplateResponse.displayName = 'proto.templateApi.DeleteDiagnosisTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateDiagnosisTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.UpdateDiagnosisTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.UpdateDiagnosisTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateDiagnosisTemplateRequest.displayName = 'proto.templateApi.UpdateDiagnosisTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateDiagnosisTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateDiagnosisTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateDiagnosisTemplateResponse.displayName = 'proto.templateApi.UpdateDiagnosisTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddDiagnosisTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.AddDiagnosisTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.AddDiagnosisTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddDiagnosisTemplateRequest.displayName = 'proto.templateApi.AddDiagnosisTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DiagnosisProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.DiagnosisProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.DiagnosisProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DiagnosisProto.displayName = 'proto.templateApi.DiagnosisProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddDiagnosisTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.AddDiagnosisTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddDiagnosisTemplateResponse.displayName = 'proto.templateApi.AddDiagnosisTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMedicalCertificatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListMedicalCertificatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMedicalCertificatesRequest.displayName = 'proto.templateApi.ListMedicalCertificatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMedicalCertificatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListMedicalCertificatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMedicalCertificatesResponse.displayName = 'proto.templateApi.ListMedicalCertificatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMCTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListMCTemplateProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListMCTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMCTemplateProto.displayName = 'proto.templateApi.ListMCTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMedicalCertificatesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListMedicalCertificatesProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListMedicalCertificatesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMedicalCertificatesProto.displayName = 'proto.templateApi.ListMedicalCertificatesProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMCCidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListMCCidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMCCidProto.displayName = 'proto.templateApi.ListMCCidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListMedicalCertificateTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMedicalCertificateTemplatesRequest.displayName = 'proto.templateApi.ListMedicalCertificateTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListMedicalCertificateTemplatesResponse.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListMedicalCertificateTemplatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMedicalCertificateTemplatesResponse.displayName = 'proto.templateApi.ListMedicalCertificateTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListMedicalCertificateTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListMedicalCertificateTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListMedicalCertificateTemplateProto.displayName = 'proto.templateApi.ListMedicalCertificateTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteMedicalCertificateTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteMedicalCertificateTemplateRequest.displayName = 'proto.templateApi.DeleteMedicalCertificateTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteMedicalCertificateTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteMedicalCertificateTemplateResponse.displayName = 'proto.templateApi.DeleteMedicalCertificateTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.UpdateMedicalCertificateTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.UpdateMedicalCertificateTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateMedicalCertificateTemplateRequest.displayName = 'proto.templateApi.UpdateMedicalCertificateTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateMedicalCertificateTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateMedicalCertificateTemplateResponse.displayName = 'proto.templateApi.UpdateMedicalCertificateTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddMedicalCertificateTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.AddMedicalCertificateTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.AddMedicalCertificateTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddMedicalCertificateTemplateRequest.displayName = 'proto.templateApi.AddMedicalCertificateTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.MedicalCertificateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.MedicalCertificateProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.MedicalCertificateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.MedicalCertificateProto.displayName = 'proto.templateApi.MedicalCertificateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.MedicalCertificateCidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.MedicalCertificateCidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.MedicalCertificateCidProto.displayName = 'proto.templateApi.MedicalCertificateCidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddMedicalCertificateTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.AddMedicalCertificateTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddMedicalCertificateTemplateResponse.displayName = 'proto.templateApi.AddMedicalCertificateTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListForwardingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListForwardingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListForwardingsRequest.displayName = 'proto.templateApi.ListForwardingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListForwardingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListForwardingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListForwardingsResponse.displayName = 'proto.templateApi.ListForwardingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListFTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListFTemplateProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListFTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListFTemplateProto.displayName = 'proto.templateApi.ListFTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListForwardingsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListForwardingsProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListForwardingsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListForwardingsProto.displayName = 'proto.templateApi.ListForwardingsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListCidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListCidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListCidProto.displayName = 'proto.templateApi.ListCidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListForwardingTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListForwardingTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListForwardingTemplatesRequest.displayName = 'proto.templateApi.ListForwardingTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListForwardingTemplatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListForwardingTemplatesResponse.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListForwardingTemplatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListForwardingTemplatesResponse.displayName = 'proto.templateApi.ListForwardingTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListForwardingTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListForwardingTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListForwardingTemplateProto.displayName = 'proto.templateApi.ListForwardingTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteForwardingTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteForwardingTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteForwardingTemplateRequest.displayName = 'proto.templateApi.DeleteForwardingTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteForwardingTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteForwardingTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteForwardingTemplateResponse.displayName = 'proto.templateApi.DeleteForwardingTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateForwardingTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.UpdateForwardingTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.UpdateForwardingTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateForwardingTemplateRequest.displayName = 'proto.templateApi.UpdateForwardingTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateForwardingTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateForwardingTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateForwardingTemplateResponse.displayName = 'proto.templateApi.UpdateForwardingTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddForwardingTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.AddForwardingTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.AddForwardingTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddForwardingTemplateRequest.displayName = 'proto.templateApi.AddForwardingTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ForwardingProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ForwardingProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ForwardingProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ForwardingProto.displayName = 'proto.templateApi.ForwardingProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.CidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.CidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.CidProto.displayName = 'proto.templateApi.CidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddForwardingTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.AddForwardingTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddForwardingTemplateResponse.displayName = 'proto.templateApi.AddForwardingTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDrugsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDrugsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDrugsRequest.displayName = 'proto.templateApi.ListDrugsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDrugsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDrugsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDrugsResponse.displayName = 'proto.templateApi.ListDrugsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListDTemplateProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListDTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDTemplateProto.displayName = 'proto.templateApi.ListDTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDrugsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDrugsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDrugsProto.displayName = 'proto.templateApi.ListDrugsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDrugTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDrugTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDrugTemplatesRequest.displayName = 'proto.templateApi.ListDrugTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDrugTemplatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListDrugTemplatesResponse.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListDrugTemplatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDrugTemplatesResponse.displayName = 'proto.templateApi.ListDrugTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListDrugTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListDrugTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListDrugTemplateProto.displayName = 'proto.templateApi.ListDrugTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteDrugTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteDrugTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteDrugTemplateRequest.displayName = 'proto.templateApi.DeleteDrugTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteDrugTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteDrugTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteDrugTemplateResponse.displayName = 'proto.templateApi.DeleteDrugTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateDrugTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.UpdateDrugTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.UpdateDrugTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateDrugTemplateRequest.displayName = 'proto.templateApi.UpdateDrugTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateDrugTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateDrugTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateDrugTemplateResponse.displayName = 'proto.templateApi.UpdateDrugTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateDrugProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateDrugProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateDrugProto.displayName = 'proto.templateApi.UpdateDrugProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DrugProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DrugProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DrugProto.displayName = 'proto.templateApi.DrugProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddDrugTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.AddDrugTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.AddDrugTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddDrugTemplateRequest.displayName = 'proto.templateApi.AddDrugTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddDrugTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.AddDrugTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddDrugTemplateResponse.displayName = 'proto.templateApi.AddDrugTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteExamTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteExamTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteExamTemplateRequest.displayName = 'proto.templateApi.DeleteExamTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.DeleteExamTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.DeleteExamTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.DeleteExamTemplateResponse.displayName = 'proto.templateApi.DeleteExamTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateExamTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.UpdateExamTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.UpdateExamTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateExamTemplateRequest.displayName = 'proto.templateApi.UpdateExamTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateExamTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateExamTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateExamTemplateResponse.displayName = 'proto.templateApi.UpdateExamTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.UpdateExamProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.UpdateExamProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.UpdateExamProto.displayName = 'proto.templateApi.UpdateExamProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListExamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListExamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListExamsRequest.displayName = 'proto.templateApi.ListExamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListExamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListExamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListExamsResponse.displayName = 'proto.templateApi.ListExamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListTemplateProto.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListTemplateProto.displayName = 'proto.templateApi.ListTemplateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListExamsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListExamsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListExamsProto.displayName = 'proto.templateApi.ListExamsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListExamTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListExamTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListExamTemplatesRequest.displayName = 'proto.templateApi.ListExamTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListExamTemplatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.ListExamTemplatesResponse.repeatedFields_, null);
};
goog.inherits(proto.templateApi.ListExamTemplatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListExamTemplatesResponse.displayName = 'proto.templateApi.ListExamTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddExamTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.templateApi.AddExamTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.templateApi.AddExamTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddExamTemplateRequest.displayName = 'proto.templateApi.AddExamTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.AddExamTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.AddExamTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.AddExamTemplateResponse.displayName = 'proto.templateApi.AddExamTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ExamProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ExamProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ExamProto.displayName = 'proto.templateApi.ExamProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.templateApi.ListExamTemplateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.templateApi.ListExamTemplateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.templateApi.ListExamTemplateProto.displayName = 'proto.templateApi.ListExamTemplateProto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosesRequest}
 */
proto.templateApi.ListDiagnosesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosesRequest;
  return proto.templateApi.ListDiagnosesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosesRequest}
 */
proto.templateApi.ListDiagnosesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string diagnosis_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListDiagnosesRequest.prototype.getDiagnosisTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosesRequest} returns this
 */
proto.templateApi.ListDiagnosesRequest.prototype.setDiagnosisTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    template: (f = msg.getTemplate()) && proto.templateApi.ListDiagnosisCompleteTemplateProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosesResponse}
 */
proto.templateApi.ListDiagnosesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosesResponse;
  return proto.templateApi.ListDiagnosesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosesResponse}
 */
proto.templateApi.ListDiagnosesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListDiagnosisCompleteTemplateProto;
      reader.readMessage(value,proto.templateApi.ListDiagnosisCompleteTemplateProto.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.templateApi.ListDiagnosisCompleteTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListDiagnosesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListDiagnosesResponse} returns this
 */
proto.templateApi.ListDiagnosesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListDiagnosesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosesResponse} returns this
 */
proto.templateApi.ListDiagnosesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListDiagnosesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosesResponse} returns this
 */
proto.templateApi.ListDiagnosesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListDiagnosisCompleteTemplateProto template = 4;
 * @return {?proto.templateApi.ListDiagnosisCompleteTemplateProto}
 */
proto.templateApi.ListDiagnosesResponse.prototype.getTemplate = function() {
  return /** @type{?proto.templateApi.ListDiagnosisCompleteTemplateProto} */ (
    jspb.Message.getWrapperField(this, proto.templateApi.ListDiagnosisCompleteTemplateProto, 4));
};


/**
 * @param {?proto.templateApi.ListDiagnosisCompleteTemplateProto|undefined} value
 * @return {!proto.templateApi.ListDiagnosesResponse} returns this
*/
proto.templateApi.ListDiagnosesResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templateApi.ListDiagnosesResponse} returns this
 */
proto.templateApi.ListDiagnosesResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templateApi.ListDiagnosesResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosisCompleteTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosisCompleteTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.templateApi.ListDiagnosesProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosisCompleteTemplateProto;
  return proto.templateApi.ListDiagnosisCompleteTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosisCompleteTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.templateApi.ListDiagnosesProto;
      reader.readMessage(value,proto.templateApi.ListDiagnosesProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosisCompleteTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosisCompleteTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListDiagnosesProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string diagnosis_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.getDiagnosisTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.setDiagnosisTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDiagnosesProto diagnoses = 4;
 * @return {!Array<!proto.templateApi.ListDiagnosesProto>}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.templateApi.ListDiagnosesProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListDiagnosesProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListDiagnosesProto>} value
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto} returns this
*/
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListDiagnosesProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListDiagnosesProto}
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListDiagnosesProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListDiagnosisCompleteTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisCompleteTemplateProto.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListDiagnosesProto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.templateApi.ListDiagnosisCidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosesProto}
 */
proto.templateApi.ListDiagnosesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosesProto;
  return proto.templateApi.ListDiagnosesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosesProto}
 */
proto.templateApi.ListDiagnosesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 4:
      var value = new proto.templateApi.ListDiagnosisCidProto;
      reader.readMessage(value,proto.templateApi.ListDiagnosisCidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListDiagnosisCidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string diagnosis_id = 1;
 * @return {string}
 */
proto.templateApi.ListDiagnosesProto.prototype.getDiagnosisId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosesProto} returns this
 */
proto.templateApi.ListDiagnosesProto.prototype.setDiagnosisId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.templateApi.ListDiagnosesProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosesProto} returns this
 */
proto.templateApi.ListDiagnosesProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string orientation = 3;
 * @return {string}
 */
proto.templateApi.ListDiagnosesProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosesProto} returns this
 */
proto.templateApi.ListDiagnosesProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDiagnosisCidProto cids = 4;
 * @return {!Array<!proto.templateApi.ListDiagnosisCidProto>}
 */
proto.templateApi.ListDiagnosesProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.templateApi.ListDiagnosisCidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListDiagnosisCidProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListDiagnosisCidProto>} value
 * @return {!proto.templateApi.ListDiagnosesProto} returns this
*/
proto.templateApi.ListDiagnosesProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListDiagnosisCidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListDiagnosisCidProto}
 */
proto.templateApi.ListDiagnosesProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListDiagnosisCidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListDiagnosesProto} returns this
 */
proto.templateApi.ListDiagnosesProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosisCidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosisCidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosisCidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisCidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisCidId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosisCidProto}
 */
proto.templateApi.ListDiagnosisCidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosisCidProto;
  return proto.templateApi.ListDiagnosisCidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosisCidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosisCidProto}
 */
proto.templateApi.ListDiagnosisCidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisCidId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosisCidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosisCidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosisCidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisCidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisCidId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string diagnosis_cid_id = 1;
 * @return {string}
 */
proto.templateApi.ListDiagnosisCidProto.prototype.getDiagnosisCidId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisCidProto} returns this
 */
proto.templateApi.ListDiagnosisCidProto.prototype.setDiagnosisCidId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.templateApi.ListDiagnosisCidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisCidProto} returns this
 */
proto.templateApi.ListDiagnosisCidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListDiagnosisCidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisCidProto} returns this
 */
proto.templateApi.ListDiagnosisCidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosisTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosisTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosisTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosisTemplatesRequest}
 */
proto.templateApi.ListDiagnosisTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosisTemplatesRequest;
  return proto.templateApi.ListDiagnosisTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosisTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosisTemplatesRequest}
 */
proto.templateApi.ListDiagnosisTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosisTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosisTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosisTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.templateApi.ListDiagnosisTemplatesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisTemplatesRequest} returns this
 */
proto.templateApi.ListDiagnosisTemplatesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListDiagnosisTemplatesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosisTemplatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosisTemplatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisTemplatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.templateApi.ListDiagnosisTemplateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosisTemplatesResponse;
  return proto.templateApi.ListDiagnosisTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosisTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListDiagnosisTemplateProto;
      reader.readMessage(value,proto.templateApi.ListDiagnosisTemplateProto.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosisTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosisTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisTemplatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListDiagnosisTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse} returns this
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse} returns this
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse} returns this
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDiagnosisTemplateProto templates = 4;
 * @return {!Array<!proto.templateApi.ListDiagnosisTemplateProto>}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.templateApi.ListDiagnosisTemplateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListDiagnosisTemplateProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListDiagnosisTemplateProto>} value
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse} returns this
*/
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListDiagnosisTemplateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListDiagnosisTemplateProto}
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListDiagnosisTemplateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListDiagnosisTemplatesResponse} returns this
 */
proto.templateApi.ListDiagnosisTemplatesResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDiagnosisTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDiagnosisTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDiagnosisTemplateProto}
 */
proto.templateApi.ListDiagnosisTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDiagnosisTemplateProto;
  return proto.templateApi.ListDiagnosisTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDiagnosisTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDiagnosisTemplateProto}
 */
proto.templateApi.ListDiagnosisTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDiagnosisTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDiagnosisTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDiagnosisTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string diagnosis_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.getDiagnosisTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.setDiagnosisTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDiagnosisTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 total = 4;
 * @return {number}
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.ListDiagnosisTemplateProto} returns this
 */
proto.templateApi.ListDiagnosisTemplateProto.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteDiagnosisTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteDiagnosisTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteDiagnosisTemplateRequest}
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteDiagnosisTemplateRequest;
  return proto.templateApi.DeleteDiagnosisTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteDiagnosisTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteDiagnosisTemplateRequest}
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteDiagnosisTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteDiagnosisTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string diagnosis_template_id = 1;
 * @return {string}
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.prototype.getDiagnosisTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteDiagnosisTemplateRequest} returns this
 */
proto.templateApi.DeleteDiagnosisTemplateRequest.prototype.setDiagnosisTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteDiagnosisTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteDiagnosisTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteDiagnosisTemplateResponse}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteDiagnosisTemplateResponse;
  return proto.templateApi.DeleteDiagnosisTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteDiagnosisTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteDiagnosisTemplateResponse}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteDiagnosisTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteDiagnosisTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.DeleteDiagnosisTemplateResponse} returns this
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteDiagnosisTemplateResponse} returns this
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteDiagnosisTemplateResponse} returns this
 */
proto.templateApi.DeleteDiagnosisTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateDiagnosisTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateDiagnosisTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    diagnosisTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.templateApi.DiagnosisProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateDiagnosisTemplateRequest;
  return proto.templateApi.UpdateDiagnosisTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateDiagnosisTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiagnosisTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.templateApi.DiagnosisProto;
      reader.readMessage(value,proto.templateApi.DiagnosisProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateDiagnosisTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateDiagnosisTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiagnosisTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.DiagnosisProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string diagnosis_template_id = 1;
 * @return {string}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.getDiagnosisTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.setDiagnosisTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DiagnosisProto diagnoses = 4;
 * @return {!Array<!proto.templateApi.DiagnosisProto>}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.templateApi.DiagnosisProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.DiagnosisProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.DiagnosisProto>} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest} returns this
*/
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.DiagnosisProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.DiagnosisProto}
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.DiagnosisProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.UpdateDiagnosisTemplateRequest} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateRequest.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateDiagnosisTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateDiagnosisTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateDiagnosisTemplateResponse}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateDiagnosisTemplateResponse;
  return proto.templateApi.UpdateDiagnosisTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateDiagnosisTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateDiagnosisTemplateResponse}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateDiagnosisTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateDiagnosisTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateResponse} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateResponse} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDiagnosisTemplateResponse} returns this
 */
proto.templateApi.UpdateDiagnosisTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.AddDiagnosisTemplateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddDiagnosisTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddDiagnosisTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDiagnosisTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.templateApi.DiagnosisProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddDiagnosisTemplateRequest}
 */
proto.templateApi.AddDiagnosisTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddDiagnosisTemplateRequest;
  return proto.templateApi.AddDiagnosisTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddDiagnosisTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddDiagnosisTemplateRequest}
 */
proto.templateApi.AddDiagnosisTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.templateApi.DiagnosisProto;
      reader.readMessage(value,proto.templateApi.DiagnosisProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddDiagnosisTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddDiagnosisTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDiagnosisTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.DiagnosisProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_name = 1;
 * @return {string}
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDiagnosisTemplateRequest} returns this
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDiagnosisTemplateRequest} returns this
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DiagnosisProto diagnoses = 3;
 * @return {!Array<!proto.templateApi.DiagnosisProto>}
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.templateApi.DiagnosisProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.DiagnosisProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.DiagnosisProto>} value
 * @return {!proto.templateApi.AddDiagnosisTemplateRequest} returns this
*/
proto.templateApi.AddDiagnosisTemplateRequest.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.DiagnosisProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.DiagnosisProto}
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.DiagnosisProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.AddDiagnosisTemplateRequest} returns this
 */
proto.templateApi.AddDiagnosisTemplateRequest.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.DiagnosisProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DiagnosisProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DiagnosisProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DiagnosisProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DiagnosisProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.templateApi.CidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DiagnosisProto}
 */
proto.templateApi.DiagnosisProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DiagnosisProto;
  return proto.templateApi.DiagnosisProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DiagnosisProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DiagnosisProto}
 */
proto.templateApi.DiagnosisProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 3:
      var value = new proto.templateApi.CidProto;
      reader.readMessage(value,proto.templateApi.CidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DiagnosisProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DiagnosisProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DiagnosisProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DiagnosisProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.CidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.templateApi.DiagnosisProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DiagnosisProto} returns this
 */
proto.templateApi.DiagnosisProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orientation = 2;
 * @return {string}
 */
proto.templateApi.DiagnosisProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DiagnosisProto} returns this
 */
proto.templateApi.DiagnosisProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CidProto cids = 3;
 * @return {!Array<!proto.templateApi.CidProto>}
 */
proto.templateApi.DiagnosisProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.templateApi.CidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.CidProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.CidProto>} value
 * @return {!proto.templateApi.DiagnosisProto} returns this
*/
proto.templateApi.DiagnosisProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.CidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.CidProto}
 */
proto.templateApi.DiagnosisProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.CidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.DiagnosisProto} returns this
 */
proto.templateApi.DiagnosisProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddDiagnosisTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddDiagnosisTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDiagnosisTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddDiagnosisTemplateResponse}
 */
proto.templateApi.AddDiagnosisTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddDiagnosisTemplateResponse;
  return proto.templateApi.AddDiagnosisTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddDiagnosisTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddDiagnosisTemplateResponse}
 */
proto.templateApi.AddDiagnosisTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddDiagnosisTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddDiagnosisTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDiagnosisTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.AddDiagnosisTemplateResponse} returns this
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDiagnosisTemplateResponse} returns this
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDiagnosisTemplateResponse} returns this
 */
proto.templateApi.AddDiagnosisTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMedicalCertificatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMedicalCertificatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMedicalCertificatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMedicalCertificatesRequest}
 */
proto.templateApi.ListMedicalCertificatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMedicalCertificatesRequest;
  return proto.templateApi.ListMedicalCertificatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMedicalCertificatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMedicalCertificatesRequest}
 */
proto.templateApi.ListMedicalCertificatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMedicalCertificatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMedicalCertificatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMedicalCertificatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string medical_certificate_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificatesRequest.prototype.getMedicalCertificateTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificatesRequest} returns this
 */
proto.templateApi.ListMedicalCertificatesRequest.prototype.setMedicalCertificateTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMedicalCertificatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMedicalCertificatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    template: (f = msg.getTemplate()) && proto.templateApi.ListMCTemplateProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMedicalCertificatesResponse}
 */
proto.templateApi.ListMedicalCertificatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMedicalCertificatesResponse;
  return proto.templateApi.ListMedicalCertificatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMedicalCertificatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMedicalCertificatesResponse}
 */
proto.templateApi.ListMedicalCertificatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListMCTemplateProto;
      reader.readMessage(value,proto.templateApi.ListMCTemplateProto.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMedicalCertificatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMedicalCertificatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.templateApi.ListMCTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListMedicalCertificatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListMCTemplateProto template = 4;
 * @return {?proto.templateApi.ListMCTemplateProto}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.getTemplate = function() {
  return /** @type{?proto.templateApi.ListMCTemplateProto} */ (
    jspb.Message.getWrapperField(this, proto.templateApi.ListMCTemplateProto, 4));
};


/**
 * @param {?proto.templateApi.ListMCTemplateProto|undefined} value
 * @return {!proto.templateApi.ListMedicalCertificatesResponse} returns this
*/
proto.templateApi.ListMedicalCertificatesResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templateApi.ListMedicalCertificatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templateApi.ListMedicalCertificatesResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListMCTemplateProto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMCTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMCTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMCTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMCTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    medicalCertificatesList: jspb.Message.toObjectList(msg.getMedicalCertificatesList(),
    proto.templateApi.ListMedicalCertificatesProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMCTemplateProto}
 */
proto.templateApi.ListMCTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMCTemplateProto;
  return proto.templateApi.ListMCTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMCTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMCTemplateProto}
 */
proto.templateApi.ListMCTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.templateApi.ListMedicalCertificatesProto;
      reader.readMessage(value,proto.templateApi.ListMedicalCertificatesProto.deserializeBinaryFromReader);
      msg.addMedicalCertificates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMCTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMCTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMCTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMCTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMedicalCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListMedicalCertificatesProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string medical_certificate_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListMCTemplateProto.prototype.getMedicalCertificateTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMCTemplateProto} returns this
 */
proto.templateApi.ListMCTemplateProto.prototype.setMedicalCertificateTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListMCTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMCTemplateProto} returns this
 */
proto.templateApi.ListMCTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListMCTemplateProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMCTemplateProto} returns this
 */
proto.templateApi.ListMCTemplateProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListMedicalCertificatesProto medical_certificates = 4;
 * @return {!Array<!proto.templateApi.ListMedicalCertificatesProto>}
 */
proto.templateApi.ListMCTemplateProto.prototype.getMedicalCertificatesList = function() {
  return /** @type{!Array<!proto.templateApi.ListMedicalCertificatesProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListMedicalCertificatesProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListMedicalCertificatesProto>} value
 * @return {!proto.templateApi.ListMCTemplateProto} returns this
*/
proto.templateApi.ListMCTemplateProto.prototype.setMedicalCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListMedicalCertificatesProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListMedicalCertificatesProto}
 */
proto.templateApi.ListMCTemplateProto.prototype.addMedicalCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListMedicalCertificatesProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListMCTemplateProto} returns this
 */
proto.templateApi.ListMCTemplateProto.prototype.clearMedicalCertificatesList = function() {
  return this.setMedicalCertificatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListMedicalCertificatesProto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMedicalCertificatesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMedicalCertificatesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificatesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.templateApi.ListMCCidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMedicalCertificatesProto}
 */
proto.templateApi.ListMedicalCertificatesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMedicalCertificatesProto;
  return proto.templateApi.ListMedicalCertificatesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMedicalCertificatesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMedicalCertificatesProto}
 */
proto.templateApi.ListMedicalCertificatesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 4:
      var value = new proto.templateApi.ListMCCidProto;
      reader.readMessage(value,proto.templateApi.ListMCCidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMedicalCertificatesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMedicalCertificatesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificatesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListMCCidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string medical_certificate_id = 1;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.getMedicalCertificateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificatesProto} returns this
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.setMedicalCertificateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificatesProto} returns this
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string orientation = 3;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificatesProto} returns this
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListMCCidProto cids = 4;
 * @return {!Array<!proto.templateApi.ListMCCidProto>}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.templateApi.ListMCCidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListMCCidProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListMCCidProto>} value
 * @return {!proto.templateApi.ListMedicalCertificatesProto} returns this
*/
proto.templateApi.ListMedicalCertificatesProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListMCCidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListMCCidProto}
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListMCCidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListMedicalCertificatesProto} returns this
 */
proto.templateApi.ListMedicalCertificatesProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMCCidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMCCidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMCCidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMCCidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateCidId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMCCidProto}
 */
proto.templateApi.ListMCCidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMCCidProto;
  return proto.templateApi.ListMCCidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMCCidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMCCidProto}
 */
proto.templateApi.ListMCCidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateCidId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMCCidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMCCidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMCCidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMCCidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateCidId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string medical_certificate_cid_id = 1;
 * @return {string}
 */
proto.templateApi.ListMCCidProto.prototype.getMedicalCertificateCidId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMCCidProto} returns this
 */
proto.templateApi.ListMCCidProto.prototype.setMedicalCertificateCidId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.templateApi.ListMCCidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMCCidProto} returns this
 */
proto.templateApi.ListMCCidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListMCCidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMCCidProto} returns this
 */
proto.templateApi.ListMCCidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMedicalCertificateTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesRequest}
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMedicalCertificateTemplatesRequest;
  return proto.templateApi.ListMedicalCertificateTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesRequest}
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMedicalCertificateTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesRequest} returns this
 */
proto.templateApi.ListMedicalCertificateTemplatesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMedicalCertificateTemplatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.templateApi.ListMedicalCertificateTemplateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMedicalCertificateTemplatesResponse;
  return proto.templateApi.ListMedicalCertificateTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListMedicalCertificateTemplateProto;
      reader.readMessage(value,proto.templateApi.ListMedicalCertificateTemplateProto.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMedicalCertificateTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListMedicalCertificateTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListMedicalCertificateTemplateProto templates = 4;
 * @return {!Array<!proto.templateApi.ListMedicalCertificateTemplateProto>}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.templateApi.ListMedicalCertificateTemplateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListMedicalCertificateTemplateProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListMedicalCertificateTemplateProto>} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse} returns this
*/
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListMedicalCertificateTemplateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto}
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListMedicalCertificateTemplateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListMedicalCertificateTemplatesResponse} returns this
 */
proto.templateApi.ListMedicalCertificateTemplatesResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListMedicalCertificateTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListMedicalCertificateTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificateTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListMedicalCertificateTemplateProto;
  return proto.templateApi.ListMedicalCertificateTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListMedicalCertificateTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListMedicalCertificateTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListMedicalCertificateTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListMedicalCertificateTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string medical_certificate_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.getMedicalCertificateTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto} returns this
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.setMedicalCertificateTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto} returns this
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto} returns this
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 total = 4;
 * @return {number}
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.ListMedicalCertificateTemplateProto} returns this
 */
proto.templateApi.ListMedicalCertificateTemplateProto.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteMedicalCertificateTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateRequest}
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteMedicalCertificateTemplateRequest;
  return proto.templateApi.DeleteMedicalCertificateTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateRequest}
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteMedicalCertificateTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string medical_certificate_template_id = 1;
 * @return {string}
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.prototype.getMedicalCertificateTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.DeleteMedicalCertificateTemplateRequest.prototype.setMedicalCertificateTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteMedicalCertificateTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateResponse}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteMedicalCertificateTemplateResponse;
  return proto.templateApi.DeleteMedicalCertificateTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateResponse}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteMedicalCertificateTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.DeleteMedicalCertificateTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateMedicalCertificateTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    medicalCertificateTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    medicalCertificateList: jspb.Message.toObjectList(msg.getMedicalCertificateList(),
    proto.templateApi.MedicalCertificateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateMedicalCertificateTemplateRequest;
  return proto.templateApi.UpdateMedicalCertificateTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMedicalCertificateTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.templateApi.MedicalCertificateProto;
      reader.readMessage(value,proto.templateApi.MedicalCertificateProto.deserializeBinaryFromReader);
      msg.addMedicalCertificate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateMedicalCertificateTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedicalCertificateTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMedicalCertificateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.MedicalCertificateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string medical_certificate_template_id = 1;
 * @return {string}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.getMedicalCertificateTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.setMedicalCertificateTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated MedicalCertificateProto medical_certificate = 4;
 * @return {!Array<!proto.templateApi.MedicalCertificateProto>}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.getMedicalCertificateList = function() {
  return /** @type{!Array<!proto.templateApi.MedicalCertificateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.MedicalCertificateProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.MedicalCertificateProto>} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} returns this
*/
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.setMedicalCertificateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.MedicalCertificateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.MedicalCertificateProto}
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.addMedicalCertificate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.MedicalCertificateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateRequest.prototype.clearMedicalCertificateList = function() {
  return this.setMedicalCertificateList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateMedicalCertificateTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateResponse}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateMedicalCertificateTemplateResponse;
  return proto.templateApi.UpdateMedicalCertificateTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateResponse}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateMedicalCertificateTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.UpdateMedicalCertificateTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddMedicalCertificateTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddMedicalCertificateTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    medicalCertificateList: jspb.Message.toObjectList(msg.getMedicalCertificateList(),
    proto.templateApi.MedicalCertificateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddMedicalCertificateTemplateRequest}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddMedicalCertificateTemplateRequest;
  return proto.templateApi.AddMedicalCertificateTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddMedicalCertificateTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddMedicalCertificateTemplateRequest}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.templateApi.MedicalCertificateProto;
      reader.readMessage(value,proto.templateApi.MedicalCertificateProto.deserializeBinaryFromReader);
      msg.addMedicalCertificate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddMedicalCertificateTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddMedicalCertificateTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMedicalCertificateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.MedicalCertificateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_name = 1;
 * @return {string}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MedicalCertificateProto medical_certificate = 3;
 * @return {!Array<!proto.templateApi.MedicalCertificateProto>}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.getMedicalCertificateList = function() {
  return /** @type{!Array<!proto.templateApi.MedicalCertificateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.MedicalCertificateProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.MedicalCertificateProto>} value
 * @return {!proto.templateApi.AddMedicalCertificateTemplateRequest} returns this
*/
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.setMedicalCertificateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.MedicalCertificateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.MedicalCertificateProto}
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.addMedicalCertificate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.MedicalCertificateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.AddMedicalCertificateTemplateRequest} returns this
 */
proto.templateApi.AddMedicalCertificateTemplateRequest.prototype.clearMedicalCertificateList = function() {
  return this.setMedicalCertificateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.MedicalCertificateProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.MedicalCertificateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.MedicalCertificateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.MedicalCertificateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.MedicalCertificateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.templateApi.MedicalCertificateCidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.MedicalCertificateProto}
 */
proto.templateApi.MedicalCertificateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.MedicalCertificateProto;
  return proto.templateApi.MedicalCertificateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.MedicalCertificateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.MedicalCertificateProto}
 */
proto.templateApi.MedicalCertificateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 3:
      var value = new proto.templateApi.MedicalCertificateCidProto;
      reader.readMessage(value,proto.templateApi.MedicalCertificateCidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.MedicalCertificateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.MedicalCertificateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.MedicalCertificateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.MedicalCertificateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.MedicalCertificateCidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.templateApi.MedicalCertificateProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.MedicalCertificateProto} returns this
 */
proto.templateApi.MedicalCertificateProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orientation = 2;
 * @return {string}
 */
proto.templateApi.MedicalCertificateProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.MedicalCertificateProto} returns this
 */
proto.templateApi.MedicalCertificateProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MedicalCertificateCidProto cids = 3;
 * @return {!Array<!proto.templateApi.MedicalCertificateCidProto>}
 */
proto.templateApi.MedicalCertificateProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.templateApi.MedicalCertificateCidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.MedicalCertificateCidProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.MedicalCertificateCidProto>} value
 * @return {!proto.templateApi.MedicalCertificateProto} returns this
*/
proto.templateApi.MedicalCertificateProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.MedicalCertificateCidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.MedicalCertificateCidProto}
 */
proto.templateApi.MedicalCertificateProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.MedicalCertificateCidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.MedicalCertificateProto} returns this
 */
proto.templateApi.MedicalCertificateProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.MedicalCertificateCidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.MedicalCertificateCidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.MedicalCertificateCidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.MedicalCertificateCidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.MedicalCertificateCidProto}
 */
proto.templateApi.MedicalCertificateCidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.MedicalCertificateCidProto;
  return proto.templateApi.MedicalCertificateCidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.MedicalCertificateCidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.MedicalCertificateCidProto}
 */
proto.templateApi.MedicalCertificateCidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.MedicalCertificateCidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.MedicalCertificateCidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.MedicalCertificateCidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.MedicalCertificateCidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.templateApi.MedicalCertificateCidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.MedicalCertificateCidProto} returns this
 */
proto.templateApi.MedicalCertificateCidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.templateApi.MedicalCertificateCidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.MedicalCertificateCidProto} returns this
 */
proto.templateApi.MedicalCertificateCidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddMedicalCertificateTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddMedicalCertificateTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddMedicalCertificateTemplateResponse}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddMedicalCertificateTemplateResponse;
  return proto.templateApi.AddMedicalCertificateTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddMedicalCertificateTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddMedicalCertificateTemplateResponse}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddMedicalCertificateTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddMedicalCertificateTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.AddMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddMedicalCertificateTemplateResponse} returns this
 */
proto.templateApi.AddMedicalCertificateTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListForwardingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListForwardingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListForwardingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListForwardingsRequest}
 */
proto.templateApi.ListForwardingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListForwardingsRequest;
  return proto.templateApi.ListForwardingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListForwardingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListForwardingsRequest}
 */
proto.templateApi.ListForwardingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListForwardingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListForwardingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListForwardingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string forwarding_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListForwardingsRequest.prototype.getForwardingTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingsRequest} returns this
 */
proto.templateApi.ListForwardingsRequest.prototype.setForwardingTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListForwardingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListForwardingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListForwardingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    template: (f = msg.getTemplate()) && proto.templateApi.ListFTemplateProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListForwardingsResponse}
 */
proto.templateApi.ListForwardingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListForwardingsResponse;
  return proto.templateApi.ListForwardingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListForwardingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListForwardingsResponse}
 */
proto.templateApi.ListForwardingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListFTemplateProto;
      reader.readMessage(value,proto.templateApi.ListFTemplateProto.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListForwardingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListForwardingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListForwardingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.templateApi.ListFTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListForwardingsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListForwardingsResponse} returns this
 */
proto.templateApi.ListForwardingsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListForwardingsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingsResponse} returns this
 */
proto.templateApi.ListForwardingsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListForwardingsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingsResponse} returns this
 */
proto.templateApi.ListForwardingsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListFTemplateProto template = 4;
 * @return {?proto.templateApi.ListFTemplateProto}
 */
proto.templateApi.ListForwardingsResponse.prototype.getTemplate = function() {
  return /** @type{?proto.templateApi.ListFTemplateProto} */ (
    jspb.Message.getWrapperField(this, proto.templateApi.ListFTemplateProto, 4));
};


/**
 * @param {?proto.templateApi.ListFTemplateProto|undefined} value
 * @return {!proto.templateApi.ListForwardingsResponse} returns this
*/
proto.templateApi.ListForwardingsResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templateApi.ListForwardingsResponse} returns this
 */
proto.templateApi.ListForwardingsResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templateApi.ListForwardingsResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListFTemplateProto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListFTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListFTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListFTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListFTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    forwardingsList: jspb.Message.toObjectList(msg.getForwardingsList(),
    proto.templateApi.ListForwardingsProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListFTemplateProto}
 */
proto.templateApi.ListFTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListFTemplateProto;
  return proto.templateApi.ListFTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListFTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListFTemplateProto}
 */
proto.templateApi.ListFTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.templateApi.ListForwardingsProto;
      reader.readMessage(value,proto.templateApi.ListForwardingsProto.deserializeBinaryFromReader);
      msg.addForwardings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListFTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListFTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListFTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListFTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForwardingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListForwardingsProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string forwarding_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListFTemplateProto.prototype.getForwardingTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListFTemplateProto} returns this
 */
proto.templateApi.ListFTemplateProto.prototype.setForwardingTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListFTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListFTemplateProto} returns this
 */
proto.templateApi.ListFTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListFTemplateProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListFTemplateProto} returns this
 */
proto.templateApi.ListFTemplateProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListForwardingsProto forwardings = 4;
 * @return {!Array<!proto.templateApi.ListForwardingsProto>}
 */
proto.templateApi.ListFTemplateProto.prototype.getForwardingsList = function() {
  return /** @type{!Array<!proto.templateApi.ListForwardingsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListForwardingsProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListForwardingsProto>} value
 * @return {!proto.templateApi.ListFTemplateProto} returns this
*/
proto.templateApi.ListFTemplateProto.prototype.setForwardingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListForwardingsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListForwardingsProto}
 */
proto.templateApi.ListFTemplateProto.prototype.addForwardings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListForwardingsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListFTemplateProto} returns this
 */
proto.templateApi.ListFTemplateProto.prototype.clearForwardingsList = function() {
  return this.setForwardingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListForwardingsProto.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListForwardingsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListForwardingsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListForwardingsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profession: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.templateApi.ListCidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListForwardingsProto}
 */
proto.templateApi.ListForwardingsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListForwardingsProto;
  return proto.templateApi.ListForwardingsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListForwardingsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListForwardingsProto}
 */
proto.templateApi.ListForwardingsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfession(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 4:
      var value = new proto.templateApi.ListCidProto;
      reader.readMessage(value,proto.templateApi.ListCidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListForwardingsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListForwardingsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListForwardingsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfession();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListCidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string forwarding_id = 1;
 * @return {string}
 */
proto.templateApi.ListForwardingsProto.prototype.getForwardingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingsProto} returns this
 */
proto.templateApi.ListForwardingsProto.prototype.setForwardingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profession = 2;
 * @return {string}
 */
proto.templateApi.ListForwardingsProto.prototype.getProfession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingsProto} returns this
 */
proto.templateApi.ListForwardingsProto.prototype.setProfession = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string orientation = 3;
 * @return {string}
 */
proto.templateApi.ListForwardingsProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingsProto} returns this
 */
proto.templateApi.ListForwardingsProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListCidProto cids = 4;
 * @return {!Array<!proto.templateApi.ListCidProto>}
 */
proto.templateApi.ListForwardingsProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.templateApi.ListCidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListCidProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListCidProto>} value
 * @return {!proto.templateApi.ListForwardingsProto} returns this
*/
proto.templateApi.ListForwardingsProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListCidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListCidProto}
 */
proto.templateApi.ListForwardingsProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListCidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListForwardingsProto} returns this
 */
proto.templateApi.ListForwardingsProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListCidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListCidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListCidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListCidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingCidId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListCidProto}
 */
proto.templateApi.ListCidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListCidProto;
  return proto.templateApi.ListCidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListCidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListCidProto}
 */
proto.templateApi.ListCidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingCidId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListCidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListCidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListCidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListCidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingCidId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string forwarding_cid_id = 1;
 * @return {string}
 */
proto.templateApi.ListCidProto.prototype.getForwardingCidId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListCidProto} returns this
 */
proto.templateApi.ListCidProto.prototype.setForwardingCidId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.templateApi.ListCidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListCidProto} returns this
 */
proto.templateApi.ListCidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListCidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListCidProto} returns this
 */
proto.templateApi.ListCidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListForwardingTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListForwardingTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListForwardingTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListForwardingTemplatesRequest}
 */
proto.templateApi.ListForwardingTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListForwardingTemplatesRequest;
  return proto.templateApi.ListForwardingTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListForwardingTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListForwardingTemplatesRequest}
 */
proto.templateApi.ListForwardingTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListForwardingTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListForwardingTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListForwardingTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.templateApi.ListForwardingTemplatesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingTemplatesRequest} returns this
 */
proto.templateApi.ListForwardingTemplatesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListForwardingTemplatesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListForwardingTemplatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListForwardingTemplatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingTemplatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.templateApi.ListForwardingTemplateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListForwardingTemplatesResponse}
 */
proto.templateApi.ListForwardingTemplatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListForwardingTemplatesResponse;
  return proto.templateApi.ListForwardingTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListForwardingTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListForwardingTemplatesResponse}
 */
proto.templateApi.ListForwardingTemplatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListForwardingTemplateProto;
      reader.readMessage(value,proto.templateApi.ListForwardingTemplateProto.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListForwardingTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListForwardingTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingTemplatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListForwardingTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListForwardingTemplatesResponse} returns this
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingTemplatesResponse} returns this
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingTemplatesResponse} returns this
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListForwardingTemplateProto templates = 4;
 * @return {!Array<!proto.templateApi.ListForwardingTemplateProto>}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.templateApi.ListForwardingTemplateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListForwardingTemplateProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListForwardingTemplateProto>} value
 * @return {!proto.templateApi.ListForwardingTemplatesResponse} returns this
*/
proto.templateApi.ListForwardingTemplatesResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListForwardingTemplateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListForwardingTemplateProto}
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListForwardingTemplateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListForwardingTemplatesResponse} returns this
 */
proto.templateApi.ListForwardingTemplatesResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListForwardingTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListForwardingTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListForwardingTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListForwardingTemplateProto}
 */
proto.templateApi.ListForwardingTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListForwardingTemplateProto;
  return proto.templateApi.ListForwardingTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListForwardingTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListForwardingTemplateProto}
 */
proto.templateApi.ListForwardingTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListForwardingTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListForwardingTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListForwardingTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListForwardingTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string forwarding_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListForwardingTemplateProto.prototype.getForwardingTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingTemplateProto} returns this
 */
proto.templateApi.ListForwardingTemplateProto.prototype.setForwardingTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListForwardingTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingTemplateProto} returns this
 */
proto.templateApi.ListForwardingTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.ListForwardingTemplateProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListForwardingTemplateProto} returns this
 */
proto.templateApi.ListForwardingTemplateProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 total = 4;
 * @return {number}
 */
proto.templateApi.ListForwardingTemplateProto.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.ListForwardingTemplateProto} returns this
 */
proto.templateApi.ListForwardingTemplateProto.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteForwardingTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteForwardingTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteForwardingTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteForwardingTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteForwardingTemplateRequest}
 */
proto.templateApi.DeleteForwardingTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteForwardingTemplateRequest;
  return proto.templateApi.DeleteForwardingTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteForwardingTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteForwardingTemplateRequest}
 */
proto.templateApi.DeleteForwardingTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteForwardingTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteForwardingTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteForwardingTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteForwardingTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string forwarding_template_id = 1;
 * @return {string}
 */
proto.templateApi.DeleteForwardingTemplateRequest.prototype.getForwardingTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteForwardingTemplateRequest} returns this
 */
proto.templateApi.DeleteForwardingTemplateRequest.prototype.setForwardingTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteForwardingTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteForwardingTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteForwardingTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteForwardingTemplateResponse}
 */
proto.templateApi.DeleteForwardingTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteForwardingTemplateResponse;
  return proto.templateApi.DeleteForwardingTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteForwardingTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteForwardingTemplateResponse}
 */
proto.templateApi.DeleteForwardingTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteForwardingTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteForwardingTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteForwardingTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.DeleteForwardingTemplateResponse} returns this
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteForwardingTemplateResponse} returns this
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteForwardingTemplateResponse} returns this
 */
proto.templateApi.DeleteForwardingTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.UpdateForwardingTemplateRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateForwardingTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateForwardingTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateForwardingTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forwardingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    forwardingList: jspb.Message.toObjectList(msg.getForwardingList(),
    proto.templateApi.ForwardingProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest}
 */
proto.templateApi.UpdateForwardingTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateForwardingTemplateRequest;
  return proto.templateApi.UpdateForwardingTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateForwardingTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest}
 */
proto.templateApi.UpdateForwardingTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForwardingTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.templateApi.ForwardingProto;
      reader.readMessage(value,proto.templateApi.ForwardingProto.deserializeBinaryFromReader);
      msg.addForwarding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateForwardingTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateForwardingTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateForwardingTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForwardingTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForwardingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ForwardingProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string forwarding_template_id = 1;
 * @return {string}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.getForwardingTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest} returns this
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.setForwardingTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest} returns this
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest} returns this
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ForwardingProto forwarding = 4;
 * @return {!Array<!proto.templateApi.ForwardingProto>}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.getForwardingList = function() {
  return /** @type{!Array<!proto.templateApi.ForwardingProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ForwardingProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ForwardingProto>} value
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest} returns this
*/
proto.templateApi.UpdateForwardingTemplateRequest.prototype.setForwardingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ForwardingProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ForwardingProto}
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.addForwarding = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ForwardingProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.UpdateForwardingTemplateRequest} returns this
 */
proto.templateApi.UpdateForwardingTemplateRequest.prototype.clearForwardingList = function() {
  return this.setForwardingList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateForwardingTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateForwardingTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateForwardingTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateForwardingTemplateResponse}
 */
proto.templateApi.UpdateForwardingTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateForwardingTemplateResponse;
  return proto.templateApi.UpdateForwardingTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateForwardingTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateForwardingTemplateResponse}
 */
proto.templateApi.UpdateForwardingTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateForwardingTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateForwardingTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateForwardingTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.UpdateForwardingTemplateResponse} returns this
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateForwardingTemplateResponse} returns this
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateForwardingTemplateResponse} returns this
 */
proto.templateApi.UpdateForwardingTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.AddForwardingTemplateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddForwardingTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddForwardingTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddForwardingTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    forwardingList: jspb.Message.toObjectList(msg.getForwardingList(),
    proto.templateApi.ForwardingProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddForwardingTemplateRequest}
 */
proto.templateApi.AddForwardingTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddForwardingTemplateRequest;
  return proto.templateApi.AddForwardingTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddForwardingTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddForwardingTemplateRequest}
 */
proto.templateApi.AddForwardingTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.templateApi.ForwardingProto;
      reader.readMessage(value,proto.templateApi.ForwardingProto.deserializeBinaryFromReader);
      msg.addForwarding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddForwardingTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddForwardingTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddForwardingTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getForwardingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.ForwardingProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_name = 1;
 * @return {string}
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddForwardingTemplateRequest} returns this
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddForwardingTemplateRequest} returns this
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ForwardingProto forwarding = 3;
 * @return {!Array<!proto.templateApi.ForwardingProto>}
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.getForwardingList = function() {
  return /** @type{!Array<!proto.templateApi.ForwardingProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ForwardingProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.ForwardingProto>} value
 * @return {!proto.templateApi.AddForwardingTemplateRequest} returns this
*/
proto.templateApi.AddForwardingTemplateRequest.prototype.setForwardingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.ForwardingProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ForwardingProto}
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.addForwarding = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.ForwardingProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.AddForwardingTemplateRequest} returns this
 */
proto.templateApi.AddForwardingTemplateRequest.prototype.clearForwardingList = function() {
  return this.setForwardingList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ForwardingProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ForwardingProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ForwardingProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ForwardingProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ForwardingProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    profession: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.templateApi.CidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ForwardingProto}
 */
proto.templateApi.ForwardingProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ForwardingProto;
  return proto.templateApi.ForwardingProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ForwardingProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ForwardingProto}
 */
proto.templateApi.ForwardingProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 3:
      var value = new proto.templateApi.CidProto;
      reader.readMessage(value,proto.templateApi.CidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ForwardingProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ForwardingProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ForwardingProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ForwardingProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfession();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.CidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string profession = 1;
 * @return {string}
 */
proto.templateApi.ForwardingProto.prototype.getProfession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ForwardingProto} returns this
 */
proto.templateApi.ForwardingProto.prototype.setProfession = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orientation = 2;
 * @return {string}
 */
proto.templateApi.ForwardingProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ForwardingProto} returns this
 */
proto.templateApi.ForwardingProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CidProto cids = 3;
 * @return {!Array<!proto.templateApi.CidProto>}
 */
proto.templateApi.ForwardingProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.templateApi.CidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.CidProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.CidProto>} value
 * @return {!proto.templateApi.ForwardingProto} returns this
*/
proto.templateApi.ForwardingProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.CidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.CidProto}
 */
proto.templateApi.ForwardingProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.CidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ForwardingProto} returns this
 */
proto.templateApi.ForwardingProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.CidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.CidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.CidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.CidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.CidProto}
 */
proto.templateApi.CidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.CidProto;
  return proto.templateApi.CidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.CidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.CidProto}
 */
proto.templateApi.CidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.CidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.CidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.CidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.CidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.templateApi.CidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.CidProto} returns this
 */
proto.templateApi.CidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.templateApi.CidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.CidProto} returns this
 */
proto.templateApi.CidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddForwardingTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddForwardingTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddForwardingTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddForwardingTemplateResponse}
 */
proto.templateApi.AddForwardingTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddForwardingTemplateResponse;
  return proto.templateApi.AddForwardingTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddForwardingTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddForwardingTemplateResponse}
 */
proto.templateApi.AddForwardingTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddForwardingTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddForwardingTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddForwardingTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.AddForwardingTemplateResponse} returns this
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddForwardingTemplateResponse} returns this
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddForwardingTemplateResponse} returns this
 */
proto.templateApi.AddForwardingTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDrugsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDrugsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDrugsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDrugsRequest}
 */
proto.templateApi.ListDrugsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDrugsRequest;
  return proto.templateApi.ListDrugsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDrugsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDrugsRequest}
 */
proto.templateApi.ListDrugsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDrugsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDrugsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDrugsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string drug_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListDrugsRequest.prototype.getDrugTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsRequest} returns this
 */
proto.templateApi.ListDrugsRequest.prototype.setDrugTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDrugsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDrugsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDrugsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    template: (f = msg.getTemplate()) && proto.templateApi.ListDTemplateProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDrugsResponse}
 */
proto.templateApi.ListDrugsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDrugsResponse;
  return proto.templateApi.ListDrugsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDrugsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDrugsResponse}
 */
proto.templateApi.ListDrugsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListDTemplateProto;
      reader.readMessage(value,proto.templateApi.ListDTemplateProto.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDrugsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDrugsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDrugsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.templateApi.ListDTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListDrugsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListDrugsResponse} returns this
 */
proto.templateApi.ListDrugsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListDrugsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsResponse} returns this
 */
proto.templateApi.ListDrugsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListDrugsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsResponse} returns this
 */
proto.templateApi.ListDrugsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListDTemplateProto template = 4;
 * @return {?proto.templateApi.ListDTemplateProto}
 */
proto.templateApi.ListDrugsResponse.prototype.getTemplate = function() {
  return /** @type{?proto.templateApi.ListDTemplateProto} */ (
    jspb.Message.getWrapperField(this, proto.templateApi.ListDTemplateProto, 4));
};


/**
 * @param {?proto.templateApi.ListDTemplateProto|undefined} value
 * @return {!proto.templateApi.ListDrugsResponse} returns this
*/
proto.templateApi.ListDrugsResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templateApi.ListDrugsResponse} returns this
 */
proto.templateApi.ListDrugsResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templateApi.ListDrugsResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListDTemplateProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.templateApi.ListDrugsProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDTemplateProto}
 */
proto.templateApi.ListDTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDTemplateProto;
  return proto.templateApi.ListDTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDTemplateProto}
 */
proto.templateApi.ListDTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = new proto.templateApi.ListDrugsProto;
      reader.readMessage(value,proto.templateApi.ListDrugsProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.ListDrugsProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string drug_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListDTemplateProto.prototype.getDrugTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDTemplateProto} returns this
 */
proto.templateApi.ListDTemplateProto.prototype.setDrugTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListDTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDTemplateProto} returns this
 */
proto.templateApi.ListDTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ListDrugsProto drugs = 3;
 * @return {!Array<!proto.templateApi.ListDrugsProto>}
 */
proto.templateApi.ListDTemplateProto.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.templateApi.ListDrugsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListDrugsProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.ListDrugsProto>} value
 * @return {!proto.templateApi.ListDTemplateProto} returns this
*/
proto.templateApi.ListDTemplateProto.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.ListDrugsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListDrugsProto}
 */
proto.templateApi.ListDTemplateProto.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.ListDrugsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListDTemplateProto} returns this
 */
proto.templateApi.ListDTemplateProto.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDrugsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDrugsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDrugsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apresentationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 4, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 6, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 7, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 10, 0),
    posology: jspb.Message.getFieldWithDefault(msg, 11, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 12, ""),
    category: jspb.Message.getFieldWithDefault(msg, 13, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 14, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDrugsProto}
 */
proto.templateApi.ListDrugsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDrugsProto;
  return proto.templateApi.ListDrugsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDrugsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDrugsProto}
 */
proto.templateApi.ListDrugsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosology(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDrugsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDrugsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDrugsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPosology();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string drug_id = 1;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getDrugId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setDrugId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string apresentation_id = 2;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string active_principle = 4;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string concentration = 5;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string pharmaceutical_form = 6;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string amount = 7;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string producer = 8;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 number_copies = 10;
 * @return {number}
 */
proto.templateApi.ListDrugsProto.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string posology = 11;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getPosology = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setPosology = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string indicated_amount = 12;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string category = 13;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string therapeutic_class = 14;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string recommendation = 15;
 * @return {string}
 */
proto.templateApi.ListDrugsProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugsProto} returns this
 */
proto.templateApi.ListDrugsProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDrugTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDrugTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDrugTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDrugTemplatesRequest}
 */
proto.templateApi.ListDrugTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDrugTemplatesRequest;
  return proto.templateApi.ListDrugTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDrugTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDrugTemplatesRequest}
 */
proto.templateApi.ListDrugTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDrugTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDrugTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDrugTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.templateApi.ListDrugTemplatesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugTemplatesRequest} returns this
 */
proto.templateApi.ListDrugTemplatesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListDrugTemplatesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDrugTemplatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDrugTemplatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugTemplatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.templateApi.ListDrugTemplateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDrugTemplatesResponse}
 */
proto.templateApi.ListDrugTemplatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDrugTemplatesResponse;
  return proto.templateApi.ListDrugTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDrugTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDrugTemplatesResponse}
 */
proto.templateApi.ListDrugTemplatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListDrugTemplateProto;
      reader.readMessage(value,proto.templateApi.ListDrugTemplateProto.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDrugTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDrugTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugTemplatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListDrugTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListDrugTemplatesResponse} returns this
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugTemplatesResponse} returns this
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugTemplatesResponse} returns this
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListDrugTemplateProto templates = 4;
 * @return {!Array<!proto.templateApi.ListDrugTemplateProto>}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.templateApi.ListDrugTemplateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListDrugTemplateProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListDrugTemplateProto>} value
 * @return {!proto.templateApi.ListDrugTemplatesResponse} returns this
*/
proto.templateApi.ListDrugTemplatesResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListDrugTemplateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListDrugTemplateProto}
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListDrugTemplateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListDrugTemplatesResponse} returns this
 */
proto.templateApi.ListDrugTemplatesResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListDrugTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListDrugTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListDrugTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListDrugTemplateProto}
 */
proto.templateApi.ListDrugTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListDrugTemplateProto;
  return proto.templateApi.ListDrugTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListDrugTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListDrugTemplateProto}
 */
proto.templateApi.ListDrugTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListDrugTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListDrugTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListDrugTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListDrugTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string drug_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListDrugTemplateProto.prototype.getDrugTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugTemplateProto} returns this
 */
proto.templateApi.ListDrugTemplateProto.prototype.setDrugTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListDrugTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListDrugTemplateProto} returns this
 */
proto.templateApi.ListDrugTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.templateApi.ListDrugTemplateProto.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.ListDrugTemplateProto} returns this
 */
proto.templateApi.ListDrugTemplateProto.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteDrugTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteDrugTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteDrugTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDrugTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteDrugTemplateRequest}
 */
proto.templateApi.DeleteDrugTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteDrugTemplateRequest;
  return proto.templateApi.DeleteDrugTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteDrugTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteDrugTemplateRequest}
 */
proto.templateApi.DeleteDrugTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteDrugTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteDrugTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteDrugTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDrugTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string drug_template_id = 1;
 * @return {string}
 */
proto.templateApi.DeleteDrugTemplateRequest.prototype.getDrugTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteDrugTemplateRequest} returns this
 */
proto.templateApi.DeleteDrugTemplateRequest.prototype.setDrugTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteDrugTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteDrugTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDrugTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteDrugTemplateResponse}
 */
proto.templateApi.DeleteDrugTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteDrugTemplateResponse;
  return proto.templateApi.DeleteDrugTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteDrugTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteDrugTemplateResponse}
 */
proto.templateApi.DeleteDrugTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteDrugTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteDrugTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteDrugTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.DeleteDrugTemplateResponse} returns this
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteDrugTemplateResponse} returns this
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteDrugTemplateResponse} returns this
 */
proto.templateApi.DeleteDrugTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.UpdateDrugTemplateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateDrugTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateDrugTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDrugTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    drugList: jspb.Message.toObjectList(msg.getDrugList(),
    proto.templateApi.UpdateDrugProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateDrugTemplateRequest}
 */
proto.templateApi.UpdateDrugTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateDrugTemplateRequest;
  return proto.templateApi.UpdateDrugTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateDrugTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateDrugTemplateRequest}
 */
proto.templateApi.UpdateDrugTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = new proto.templateApi.UpdateDrugProto;
      reader.readMessage(value,proto.templateApi.UpdateDrugProto.deserializeBinaryFromReader);
      msg.addDrug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateDrugTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateDrugTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDrugTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDrugList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.templateApi.UpdateDrugProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string drug_template_id = 1;
 * @return {string}
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.getDrugTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugTemplateRequest} returns this
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.setDrugTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugTemplateRequest} returns this
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UpdateDrugProto drug = 3;
 * @return {!Array<!proto.templateApi.UpdateDrugProto>}
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.getDrugList = function() {
  return /** @type{!Array<!proto.templateApi.UpdateDrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.UpdateDrugProto, 3));
};


/**
 * @param {!Array<!proto.templateApi.UpdateDrugProto>} value
 * @return {!proto.templateApi.UpdateDrugTemplateRequest} returns this
*/
proto.templateApi.UpdateDrugTemplateRequest.prototype.setDrugList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templateApi.UpdateDrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.UpdateDrugProto}
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.addDrug = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templateApi.UpdateDrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.UpdateDrugTemplateRequest} returns this
 */
proto.templateApi.UpdateDrugTemplateRequest.prototype.clearDrugList = function() {
  return this.setDrugList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateDrugTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateDrugTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDrugTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateDrugTemplateResponse}
 */
proto.templateApi.UpdateDrugTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateDrugTemplateResponse;
  return proto.templateApi.UpdateDrugTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateDrugTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateDrugTemplateResponse}
 */
proto.templateApi.UpdateDrugTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateDrugTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateDrugTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDrugTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.UpdateDrugTemplateResponse} returns this
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugTemplateResponse} returns this
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugTemplateResponse} returns this
 */
proto.templateApi.UpdateDrugTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateDrugProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateDrugProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateDrugProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDrugProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 9, 0),
    posology: jspb.Message.getFieldWithDefault(msg, 10, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    category: jspb.Message.getFieldWithDefault(msg, 12, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateDrugProto}
 */
proto.templateApi.UpdateDrugProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateDrugProto;
  return proto.templateApi.UpdateDrugProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateDrugProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateDrugProto}
 */
proto.templateApi.UpdateDrugProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosology(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateDrugProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateDrugProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateDrugProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateDrugProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPosology();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string concentration = 4;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pharmaceutical_form = 5;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amount = 6;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string producer = 7;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 number_copies = 9;
 * @return {number}
 */
proto.templateApi.UpdateDrugProto.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string posology = 10;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getPosology = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setPosology = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string indicated_amount = 11;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string category = 12;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string therapeutic_class = 13;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string recommendation = 14;
 * @return {string}
 */
proto.templateApi.UpdateDrugProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateDrugProto} returns this
 */
proto.templateApi.UpdateDrugProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DrugProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DrugProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DrugProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DrugProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 9, 0),
    posology: jspb.Message.getFieldWithDefault(msg, 10, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    category: jspb.Message.getFieldWithDefault(msg, 12, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DrugProto}
 */
proto.templateApi.DrugProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DrugProto;
  return proto.templateApi.DrugProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DrugProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DrugProto}
 */
proto.templateApi.DrugProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosology(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DrugProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DrugProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DrugProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DrugProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPosology();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string concentration = 4;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pharmaceutical_form = 5;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amount = 6;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string producer = 7;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 number_copies = 9;
 * @return {number}
 */
proto.templateApi.DrugProto.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string posology = 10;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getPosology = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setPosology = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string indicated_amount = 11;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string category = 12;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string therapeutic_class = 13;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string recommendation = 14;
 * @return {string}
 */
proto.templateApi.DrugProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DrugProto} returns this
 */
proto.templateApi.DrugProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.AddDrugTemplateRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddDrugTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddDrugTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddDrugTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDrugTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugList: jspb.Message.toObjectList(msg.getDrugList(),
    proto.templateApi.DrugProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddDrugTemplateRequest}
 */
proto.templateApi.AddDrugTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddDrugTemplateRequest;
  return proto.templateApi.AddDrugTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddDrugTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddDrugTemplateRequest}
 */
proto.templateApi.AddDrugTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 2:
      var value = new proto.templateApi.DrugProto;
      reader.readMessage(value,proto.templateApi.DrugProto.deserializeBinaryFromReader);
      msg.addDrug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddDrugTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddDrugTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddDrugTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDrugTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.templateApi.DrugProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_name = 1;
 * @return {string}
 */
proto.templateApi.AddDrugTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDrugTemplateRequest} returns this
 */
proto.templateApi.AddDrugTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DrugProto drug = 2;
 * @return {!Array<!proto.templateApi.DrugProto>}
 */
proto.templateApi.AddDrugTemplateRequest.prototype.getDrugList = function() {
  return /** @type{!Array<!proto.templateApi.DrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.DrugProto, 2));
};


/**
 * @param {!Array<!proto.templateApi.DrugProto>} value
 * @return {!proto.templateApi.AddDrugTemplateRequest} returns this
*/
proto.templateApi.AddDrugTemplateRequest.prototype.setDrugList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.templateApi.DrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.DrugProto}
 */
proto.templateApi.AddDrugTemplateRequest.prototype.addDrug = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.templateApi.DrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.AddDrugTemplateRequest} returns this
 */
proto.templateApi.AddDrugTemplateRequest.prototype.clearDrugList = function() {
  return this.setDrugList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddDrugTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddDrugTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddDrugTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDrugTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddDrugTemplateResponse}
 */
proto.templateApi.AddDrugTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddDrugTemplateResponse;
  return proto.templateApi.AddDrugTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddDrugTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddDrugTemplateResponse}
 */
proto.templateApi.AddDrugTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddDrugTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddDrugTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddDrugTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddDrugTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.AddDrugTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.AddDrugTemplateResponse} returns this
 */
proto.templateApi.AddDrugTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.AddDrugTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDrugTemplateResponse} returns this
 */
proto.templateApi.AddDrugTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.AddDrugTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddDrugTemplateResponse} returns this
 */
proto.templateApi.AddDrugTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteExamTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteExamTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteExamTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteExamTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteExamTemplateRequest}
 */
proto.templateApi.DeleteExamTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteExamTemplateRequest;
  return proto.templateApi.DeleteExamTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteExamTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteExamTemplateRequest}
 */
proto.templateApi.DeleteExamTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteExamTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteExamTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteExamTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteExamTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string exam_template_id = 1;
 * @return {string}
 */
proto.templateApi.DeleteExamTemplateRequest.prototype.getExamTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteExamTemplateRequest} returns this
 */
proto.templateApi.DeleteExamTemplateRequest.prototype.setExamTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.DeleteExamTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.DeleteExamTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteExamTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.DeleteExamTemplateResponse}
 */
proto.templateApi.DeleteExamTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.DeleteExamTemplateResponse;
  return proto.templateApi.DeleteExamTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.DeleteExamTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.DeleteExamTemplateResponse}
 */
proto.templateApi.DeleteExamTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.DeleteExamTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.DeleteExamTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.DeleteExamTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.DeleteExamTemplateResponse} returns this
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteExamTemplateResponse} returns this
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.DeleteExamTemplateResponse} returns this
 */
proto.templateApi.DeleteExamTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.UpdateExamTemplateRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateExamTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateExamTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateExamTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    examList: jspb.Message.toObjectList(msg.getExamList(),
    proto.templateApi.UpdateExamProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateExamTemplateRequest}
 */
proto.templateApi.UpdateExamTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateExamTemplateRequest;
  return proto.templateApi.UpdateExamTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateExamTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateExamTemplateRequest}
 */
proto.templateApi.UpdateExamTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 5:
      var value = new proto.templateApi.UpdateExamProto;
      reader.readMessage(value,proto.templateApi.UpdateExamProto.deserializeBinaryFromReader);
      msg.addExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateExamTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateExamTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateExamTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.templateApi.UpdateExamProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_template_id = 1;
 * @return {string}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.getExamTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamTemplateRequest} returns this
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.setExamTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamTemplateRequest} returns this
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model_name = 3;
 * @return {string}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamTemplateRequest} returns this
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string orientation = 4;
 * @return {string}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamTemplateRequest} returns this
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated UpdateExamProto exam = 5;
 * @return {!Array<!proto.templateApi.UpdateExamProto>}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.getExamList = function() {
  return /** @type{!Array<!proto.templateApi.UpdateExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.UpdateExamProto, 5));
};


/**
 * @param {!Array<!proto.templateApi.UpdateExamProto>} value
 * @return {!proto.templateApi.UpdateExamTemplateRequest} returns this
*/
proto.templateApi.UpdateExamTemplateRequest.prototype.setExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.templateApi.UpdateExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.UpdateExamProto}
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.addExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.templateApi.UpdateExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.UpdateExamTemplateRequest} returns this
 */
proto.templateApi.UpdateExamTemplateRequest.prototype.clearExamList = function() {
  return this.setExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateExamTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateExamTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateExamTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateExamTemplateResponse}
 */
proto.templateApi.UpdateExamTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateExamTemplateResponse;
  return proto.templateApi.UpdateExamTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateExamTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateExamTemplateResponse}
 */
proto.templateApi.UpdateExamTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateExamTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateExamTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateExamTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.UpdateExamTemplateResponse} returns this
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamTemplateResponse} returns this
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamTemplateResponse} returns this
 */
proto.templateApi.UpdateExamTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.UpdateExamProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.UpdateExamProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.UpdateExamProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateExamProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    justification: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.UpdateExamProto}
 */
proto.templateApi.UpdateExamProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.UpdateExamProto;
  return proto.templateApi.UpdateExamProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.UpdateExamProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.UpdateExamProto}
 */
proto.templateApi.UpdateExamProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.UpdateExamProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.UpdateExamProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.UpdateExamProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.UpdateExamProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJustification();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.templateApi.UpdateExamProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamProto} returns this
 */
proto.templateApi.UpdateExamProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code_tuss = 2;
 * @return {string}
 */
proto.templateApi.UpdateExamProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamProto} returns this
 */
proto.templateApi.UpdateExamProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string desc_group = 3;
 * @return {string}
 */
proto.templateApi.UpdateExamProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamProto} returns this
 */
proto.templateApi.UpdateExamProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_subgroup = 4;
 * @return {string}
 */
proto.templateApi.UpdateExamProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamProto} returns this
 */
proto.templateApi.UpdateExamProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orientation = 5;
 * @return {string}
 */
proto.templateApi.UpdateExamProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamProto} returns this
 */
proto.templateApi.UpdateExamProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string justification = 6;
 * @return {string}
 */
proto.templateApi.UpdateExamProto.prototype.getJustification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.UpdateExamProto} returns this
 */
proto.templateApi.UpdateExamProto.prototype.setJustification = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListExamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListExamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListExamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examTemplateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListExamsRequest}
 */
proto.templateApi.ListExamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListExamsRequest;
  return proto.templateApi.ListExamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListExamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListExamsRequest}
 */
proto.templateApi.ListExamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListExamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListExamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListExamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string exam_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListExamsRequest.prototype.getExamTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsRequest} returns this
 */
proto.templateApi.ListExamsRequest.prototype.setExamTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListExamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListExamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListExamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    template: (f = msg.getTemplate()) && proto.templateApi.ListTemplateProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListExamsResponse}
 */
proto.templateApi.ListExamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListExamsResponse;
  return proto.templateApi.ListExamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListExamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListExamsResponse}
 */
proto.templateApi.ListExamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListTemplateProto;
      reader.readMessage(value,proto.templateApi.ListTemplateProto.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListExamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListExamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListExamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.templateApi.ListTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListExamsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListExamsResponse} returns this
 */
proto.templateApi.ListExamsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListExamsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsResponse} returns this
 */
proto.templateApi.ListExamsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListExamsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsResponse} returns this
 */
proto.templateApi.ListExamsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListTemplateProto template = 4;
 * @return {?proto.templateApi.ListTemplateProto}
 */
proto.templateApi.ListExamsResponse.prototype.getTemplate = function() {
  return /** @type{?proto.templateApi.ListTemplateProto} */ (
    jspb.Message.getWrapperField(this, proto.templateApi.ListTemplateProto, 4));
};


/**
 * @param {?proto.templateApi.ListTemplateProto|undefined} value
 * @return {!proto.templateApi.ListExamsResponse} returns this
*/
proto.templateApi.ListExamsResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.templateApi.ListExamsResponse} returns this
 */
proto.templateApi.ListExamsResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.templateApi.ListExamsResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListTemplateProto.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    examsList: jspb.Message.toObjectList(msg.getExamsList(),
    proto.templateApi.ListExamsProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListTemplateProto}
 */
proto.templateApi.ListTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListTemplateProto;
  return proto.templateApi.ListTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListTemplateProto}
 */
proto.templateApi.ListTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.templateApi.ListExamsProto;
      reader.readMessage(value,proto.templateApi.ListExamsProto.deserializeBinaryFromReader);
      msg.addExams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.templateApi.ListExamsProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListTemplateProto.prototype.getExamTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListTemplateProto} returns this
 */
proto.templateApi.ListTemplateProto.prototype.setExamTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListTemplateProto} returns this
 */
proto.templateApi.ListTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string orientation = 3;
 * @return {string}
 */
proto.templateApi.ListTemplateProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListTemplateProto} returns this
 */
proto.templateApi.ListTemplateProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.templateApi.ListTemplateProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListTemplateProto} returns this
 */
proto.templateApi.ListTemplateProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ListExamsProto exams = 5;
 * @return {!Array<!proto.templateApi.ListExamsProto>}
 */
proto.templateApi.ListTemplateProto.prototype.getExamsList = function() {
  return /** @type{!Array<!proto.templateApi.ListExamsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListExamsProto, 5));
};


/**
 * @param {!Array<!proto.templateApi.ListExamsProto>} value
 * @return {!proto.templateApi.ListTemplateProto} returns this
*/
proto.templateApi.ListTemplateProto.prototype.setExamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.templateApi.ListExamsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListExamsProto}
 */
proto.templateApi.ListTemplateProto.prototype.addExams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.templateApi.ListExamsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListTemplateProto} returns this
 */
proto.templateApi.ListTemplateProto.prototype.clearExamsList = function() {
  return this.setExamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListExamsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListExamsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListExamsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    justification: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListExamsProto}
 */
proto.templateApi.ListExamsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListExamsProto;
  return proto.templateApi.ListExamsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListExamsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListExamsProto}
 */
proto.templateApi.ListExamsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListExamsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListExamsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListExamsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJustification();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code_tuss = 3;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_group = 4;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string desc_subgroup = 5;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string orientation = 6;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string justification = 7;
 * @return {string}
 */
proto.templateApi.ListExamsProto.prototype.getJustification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamsProto} returns this
 */
proto.templateApi.ListExamsProto.prototype.setJustification = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListExamTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListExamTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListExamTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    word: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListExamTemplatesRequest}
 */
proto.templateApi.ListExamTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListExamTemplatesRequest;
  return proto.templateApi.ListExamTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListExamTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListExamTemplatesRequest}
 */
proto.templateApi.ListExamTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListExamTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListExamTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListExamTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.templateApi.ListExamTemplatesRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplatesRequest} returns this
 */
proto.templateApi.ListExamTemplatesRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string word = 2;
 * @return {string}
 */
proto.templateApi.ListExamTemplatesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplatesRequest} returns this
 */
proto.templateApi.ListExamTemplatesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.ListExamTemplatesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListExamTemplatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListExamTemplatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamTemplatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.templateApi.ListExamTemplateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListExamTemplatesResponse}
 */
proto.templateApi.ListExamTemplatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListExamTemplatesResponse;
  return proto.templateApi.ListExamTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListExamTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListExamTemplatesResponse}
 */
proto.templateApi.ListExamTemplatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.templateApi.ListExamTemplateProto;
      reader.readMessage(value,proto.templateApi.ListExamTemplateProto.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListExamTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListExamTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamTemplatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ListExamTemplateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.ListExamTemplatesResponse} returns this
 */
proto.templateApi.ListExamTemplatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplatesResponse} returns this
 */
proto.templateApi.ListExamTemplatesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplatesResponse} returns this
 */
proto.templateApi.ListExamTemplatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ListExamTemplateProto templates = 4;
 * @return {!Array<!proto.templateApi.ListExamTemplateProto>}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.templateApi.ListExamTemplateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ListExamTemplateProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ListExamTemplateProto>} value
 * @return {!proto.templateApi.ListExamTemplatesResponse} returns this
*/
proto.templateApi.ListExamTemplatesResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ListExamTemplateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ListExamTemplateProto}
 */
proto.templateApi.ListExamTemplatesResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ListExamTemplateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.ListExamTemplatesResponse} returns this
 */
proto.templateApi.ListExamTemplatesResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.templateApi.AddExamTemplateRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddExamTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddExamTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddExamTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddExamTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examList: jspb.Message.toObjectList(msg.getExamList(),
    proto.templateApi.ExamProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddExamTemplateRequest}
 */
proto.templateApi.AddExamTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddExamTemplateRequest;
  return proto.templateApi.AddExamTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddExamTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddExamTemplateRequest}
 */
proto.templateApi.AddExamTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 4:
      var value = new proto.templateApi.ExamProto;
      reader.readMessage(value,proto.templateApi.ExamProto.deserializeBinaryFromReader);
      msg.addExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddExamTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddExamTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddExamTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddExamTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.templateApi.ExamProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.templateApi.AddExamTemplateRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddExamTemplateRequest} returns this
 */
proto.templateApi.AddExamTemplateRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.AddExamTemplateRequest.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddExamTemplateRequest} returns this
 */
proto.templateApi.AddExamTemplateRequest.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string orientation = 3;
 * @return {string}
 */
proto.templateApi.AddExamTemplateRequest.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddExamTemplateRequest} returns this
 */
proto.templateApi.AddExamTemplateRequest.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ExamProto exam = 4;
 * @return {!Array<!proto.templateApi.ExamProto>}
 */
proto.templateApi.AddExamTemplateRequest.prototype.getExamList = function() {
  return /** @type{!Array<!proto.templateApi.ExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.templateApi.ExamProto, 4));
};


/**
 * @param {!Array<!proto.templateApi.ExamProto>} value
 * @return {!proto.templateApi.AddExamTemplateRequest} returns this
*/
proto.templateApi.AddExamTemplateRequest.prototype.setExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.templateApi.ExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templateApi.ExamProto}
 */
proto.templateApi.AddExamTemplateRequest.prototype.addExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.templateApi.ExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.templateApi.AddExamTemplateRequest} returns this
 */
proto.templateApi.AddExamTemplateRequest.prototype.clearExamList = function() {
  return this.setExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.AddExamTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.AddExamTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.AddExamTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddExamTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.AddExamTemplateResponse}
 */
proto.templateApi.AddExamTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.AddExamTemplateResponse;
  return proto.templateApi.AddExamTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.AddExamTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.AddExamTemplateResponse}
 */
proto.templateApi.AddExamTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.AddExamTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.AddExamTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.AddExamTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.AddExamTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.templateApi.AddExamTemplateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.templateApi.AddExamTemplateResponse} returns this
 */
proto.templateApi.AddExamTemplateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.templateApi.AddExamTemplateResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddExamTemplateResponse} returns this
 */
proto.templateApi.AddExamTemplateResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.templateApi.AddExamTemplateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.AddExamTemplateResponse} returns this
 */
proto.templateApi.AddExamTemplateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ExamProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ExamProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ExamProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ExamProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    justification: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ExamProto}
 */
proto.templateApi.ExamProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ExamProto;
  return proto.templateApi.ExamProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ExamProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ExamProto}
 */
proto.templateApi.ExamProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ExamProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ExamProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ExamProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ExamProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJustification();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.templateApi.ExamProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ExamProto} returns this
 */
proto.templateApi.ExamProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code_tuss = 2;
 * @return {string}
 */
proto.templateApi.ExamProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ExamProto} returns this
 */
proto.templateApi.ExamProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string desc_group = 3;
 * @return {string}
 */
proto.templateApi.ExamProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ExamProto} returns this
 */
proto.templateApi.ExamProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_subgroup = 4;
 * @return {string}
 */
proto.templateApi.ExamProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ExamProto} returns this
 */
proto.templateApi.ExamProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orientation = 5;
 * @return {string}
 */
proto.templateApi.ExamProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ExamProto} returns this
 */
proto.templateApi.ExamProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string justification = 6;
 * @return {string}
 */
proto.templateApi.ExamProto.prototype.getJustification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ExamProto} returns this
 */
proto.templateApi.ExamProto.prototype.setJustification = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.templateApi.ListExamTemplateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.templateApi.ListExamTemplateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.templateApi.ListExamTemplateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamTemplateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.templateApi.ListExamTemplateProto}
 */
proto.templateApi.ListExamTemplateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.templateApi.ListExamTemplateProto;
  return proto.templateApi.ListExamTemplateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.templateApi.ListExamTemplateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.templateApi.ListExamTemplateProto}
 */
proto.templateApi.ListExamTemplateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.templateApi.ListExamTemplateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.templateApi.ListExamTemplateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.templateApi.ListExamTemplateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.templateApi.ListExamTemplateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string exam_template_id = 1;
 * @return {string}
 */
proto.templateApi.ListExamTemplateProto.prototype.getExamTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplateProto} returns this
 */
proto.templateApi.ListExamTemplateProto.prototype.setExamTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.templateApi.ListExamTemplateProto.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplateProto} returns this
 */
proto.templateApi.ListExamTemplateProto.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string orientation = 3;
 * @return {string}
 */
proto.templateApi.ListExamTemplateProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.templateApi.ListExamTemplateProto} returns this
 */
proto.templateApi.ListExamTemplateProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 total = 4;
 * @return {number}
 */
proto.templateApi.ListExamTemplateProto.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.templateApi.ListExamTemplateProto} returns this
 */
proto.templateApi.ListExamTemplateProto.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


goog.object.extend(exports, proto.templateApi);
