import { PatientProfileProtoServicePromiseClient } from '../grpc/generated/patientProfile_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new PatientProfileProtoServicePromiseClient(apiUrl);


async function listPatientHealth(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listPatientHealth(request, metadata, {});

    return response.toObject();
}

export default {
    listPatientHealth,
}