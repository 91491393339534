
import { ref } from "vue";
import CardPersonalized from "./CardPersonalized/CardPersonalized.vue";
import Close from '@/assets/IconsComponent/Close.vue'

export default {
    props: ["label", "list", "visible"],
    emits: ["update:visible", "openCard"],
    components: { CardPersonalized, Close },
    setup(props, { emit }) {

        const close = () => {
            emit("update:visible", false);
        }

        const clickCard = (item: any) => {
            emit("openCard", item);
        }

        return {
            close,
            clickCard,
        }
    },
};
