<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8992 18.5992L16.4983 15.2259C17.8184 13.5799 18.4577 11.4907 18.2847 9.38789C18.1118 7.28506 17.1397 5.32842 15.5684 3.92029C13.9971 2.51217 11.946 1.75959 9.83684 1.8173C7.72769 1.87501 5.72082 2.73863 4.22887 4.23058C2.73692 5.72253 1.8733 7.7294 1.81559 9.83855C1.75788 11.9477 2.51046 13.9988 3.91858 15.5701C5.32671 17.1414 7.28335 18.1135 9.38618 18.2864C11.489 18.4594 13.5782 17.8201 15.2242 16.5L18.5975 19.8734C18.6827 19.9593 18.7841 20.0275 18.8958 20.074C19.0075 20.1206 19.1273 20.1445 19.2483 20.1445C19.3693 20.1445 19.4891 20.1206 19.6009 20.074C19.7126 20.0275 19.8139 19.9593 19.8992 19.8734C20.0644 19.7024 20.1567 19.474 20.1567 19.2363C20.1567 18.9986 20.0644 18.7701 19.8992 18.5992ZM10.0817 16.5C8.81256 16.5 7.57196 16.1237 6.51675 15.4186C5.46153 14.7136 4.63909 13.7114 4.15343 12.5389C3.66777 11.3664 3.5407 10.0763 3.78829 8.83154C4.03587 7.58683 4.647 6.44349 5.54439 5.5461C6.44178 4.64871 7.58512 4.03758 8.82983 3.79C10.0745 3.54241 11.3647 3.66948 12.5372 4.15514C13.7097 4.6408 14.7119 5.46324 15.4169 6.51846C16.122 7.57367 16.4983 8.81427 16.4983 10.0834C16.4983 11.7852 15.8223 13.4173 14.6189 14.6206C13.4156 15.824 11.7835 16.5 10.0817 16.5Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>