<template>
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3333 23.4999H14.2667C14.3833 23.2666 14.3833 23.0332 14.3833 22.7999C17.8833 21.3999 20.2167 18.0166 20.2167 14.1666C20.2167 11.8332 19.2833 9.61656 17.7667 7.86656L18.4667 7.16656C18.5833 7.0499 18.7 6.93323 18.7 6.6999L19.5167 4.2499C19.6333 3.78323 19.5167 3.31656 19.2833 3.08323L16.8333 0.633231C16.4833 0.28323 16.0167 0.166564 15.6667 0.399897L13.3333 1.0999C13.2167 1.0999 12.9833 1.21656 12.8667 1.33323L5.39999 8.7999C4.93333 9.26656 4.93333 9.96657 5.39999 10.4332L3.76666 12.0666C3.29999 12.5332 3.29999 13.2332 3.76666 13.6999L6.21666 16.1499C6.68333 16.6166 7.38333 16.6166 7.84999 16.1499L9.48333 14.5166C9.94999 14.9832 10.65 14.9832 11.1167 14.5166L16.25 9.4999C17.4167 10.7832 18 12.4166 18 14.1666C18 16.8499 16.3667 19.2999 13.9167 20.4666C12.8667 18.8332 10.7667 18.2499 9.13333 19.2999C8.66666 19.6499 8.19999 19.9999 7.96666 20.5832C7.49999 20.3499 7.03333 20.2332 6.68333 19.8832C7.14999 19.7666 7.49999 19.2999 7.49999 18.8332C7.49999 18.1332 7.03333 17.6666 6.33333 17.6666H1.66666C0.966659 17.6666 0.499992 18.1332 0.499992 18.8332C0.499992 19.5332 0.966659 19.9999 1.66666 19.9999H3.18333C4.34999 21.2832 5.86666 22.3332 7.49999 22.9166C7.49999 23.1499 7.61666 23.3832 7.61666 23.4999H1.66666C0.966659 23.4999 0.499992 23.9666 0.499992 24.6666C0.499992 25.3666 0.966659 25.8332 1.66666 25.8332H20.3333C21.0333 25.8332 21.5 25.3666 21.5 24.6666C21.5 24.0832 21.0333 23.4999 20.3333 23.4999ZM7.03333 13.6999L6.21666 12.8832L7.03333 12.0666L7.84999 12.8832L7.03333 13.6999ZM11 23.4999C10.3 23.4999 9.83333 23.0332 9.83333 22.3332C9.83333 21.6332 10.3 21.1666 11 21.1666C11.7 21.1666 12.1667 21.6332 12.1667 22.3332C12.1667 23.0332 11.7 23.4999 11 23.4999Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>