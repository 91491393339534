<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6669 6.16652H5.00022C4.30022 6.16652 3.83356 6.63319 3.83356 7.33319V10.2499H12.5836C13.2836 10.2499 13.7502 10.7165 13.7502 11.4165V20.1665H16.6669C17.3669 20.1665 17.8336 19.6999 17.8336 18.9999V7.33319C17.8336 6.63319 17.3669 6.16652 16.6669 6.16652ZM10.8336 11.9999H1.50022C0.800224 11.9999 0.333557 12.4665 0.333557 13.1665V22.4999C0.333557 23.1999 0.800224 23.6665 1.50022 23.6665H10.8336C11.5336 23.6665 12.0002 23.1999 12.0002 22.4999V13.1665C12.0002 12.4665 11.5336 11.9999 10.8336 11.9999ZM22.5002 0.333191H8.50022C7.80022 0.333191 7.33356 0.799858 7.33356 1.49986V4.41652H18.4169C19.1169 4.41652 19.5836 4.88319 19.5836 5.58319V16.6665H22.5002C23.2002 16.6665 23.6669 16.1999 23.6669 15.4999V1.49986C23.6669 0.799858 23.2002 0.333191 22.5002 0.333191Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>