import { CidProtoServicePromiseClient } from '../grpc/generated/cid_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new CidProtoServicePromiseClient(apiUrl);

async function cid10Get(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.cid10Get(request, metadata, {});

    return response.toObject();
}



export default {
    cid10Get,
}