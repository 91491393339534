
import { ref } from "vue";
import Close from '@/assets/IconsComponent/Close.vue'
import Answers from "./Answers.vue";
import Write from "./Write.vue";
export default {
    props: ["label", "list", "visible"],
    emits: ["update:visible"],
    components: { Close, Answers, Write },
    setup(props, { emit }) {

        const close = () => {
            emit("update:visible", false);
        }

        return {
            close,
        }
    },
};
