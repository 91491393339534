// import { setElSeg } from "@fullcalendar/core";
import healthPb from "../grpc/generated/health_pb.js";
import healthService from "../services/healthService"

async function ListViewMore (appointmentid) {
    try {
        const req = new healthPb.ListViewMoreRequest();
        req.setAppointmentId(appointmentid)        
        const res = await healthService.listViewMore(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListPdfs (appointmentid) {
    try {
        const req = new healthPb.ListPdfsRequest();
        req.setAppointmentId(appointmentid)        
        const res = await healthService.listPdfs(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListMedicalCare (appointment_id) {
    try {
        const req = new healthPb.ListMedicalCareRequest();
        req.setAppointmentId(appointment_id);

        const res = await healthService.listMedicalCare(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}
async function PauseToExam (obj) {
    try {
        const req = new healthPb.PauseToExamRequest();
        req.setAppointmentId(obj.appointment_id);
        req.setWaitTime(obj.time);

        const res = await healthService.pauseToExam(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}
async function PausePatientCare (appointment_id) {
    try {
        const req = new healthPb.PausePatientCareRequest();
        req.setAppointmentId(appointment_id)
        const res = await healthService.pausePatientCare(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}
async function ListAppointment (appointment_id) {
    try {
        const req = new healthPb.ListAppointmentRequest();
        req.setAppointmentId(appointment_id)
        const res = await healthService.listAppointment(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListAppointmentHistory () {
    try {
        const req = new healthPb.EmptyRequest();
        
        const res = await healthService.listAppointmentHistory(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListDrugAllergies () {
    try {
        const req = new healthPb.EmptyRequest();
        
        const res = await healthService.listDrugAllergies(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateDrugAllergy (obj) {
    try {
        const req = new healthPb.CreateDrugAllergyRequest();
        // req.setHealthrecordid(obj.healthrecordid ?? "");
        req.setAppointmentId(obj.appointmentid);
        req.setTherapeuticClass(obj.therapeuticClass);
        req.setProductName(obj.name);
        req.setActivePrinciple(obj.activePrinciple);
        req.setRegulatoryCategory(obj.regulatoryCategory);
        req.setSymptoms(obj.symptoms);
        req.setComments(obj.comments);
        const res = await healthService.createDrugAllergy(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function SearchDrugAllergies (obj) {
    try {
        const req = new healthPb.SearchDrugAllergiesRequest();
        // req.setAppointmentid(obj.appointmentid)
        req.setWord(obj.search)
        
        const res = await healthService.searchDrugAllergies(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DeleteDrugAllergy (obj) {
    try {
        const req = new healthPb.DeleteDrugAllergyRequest();
        req.setDrugAllergyId(obj.drugallergyid)
        
        const res = await healthService.deleteDrugAllergy(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function UpdateDrugAllergy (obj) {
    try {
        const req = new healthPb.UpdateDrugAllergyRequest();
        req.setDrugAllergyId(obj.drugAllergyId)
        req.setAppointmentId(obj.appointmentId)
        req.setTherapeuticClass(obj.therapeuticClass)
        req.setProductName(obj.name)
        req.setActivePrinciple(obj.activePrinciple)
        req.setRegulatoryCategory(obj.regulatoryCategory)
        req.setSymptoms(obj.symptoms)
        req.setComments(obj.comments)
        
        const res = await healthService.updateDrugAllergy(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}


async function ListDrugs () {
    try {
        const req = new healthPb.EmptyRequest();
        // req.setAppointmentid(appointmentid)        
        const res = await healthService.listDrugs(req);

        console.log(res);
        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function SearchListDrugs (obj) {
    try {
        const req = new healthPb.SearchListDrugsRequest();
        req.setWord(obj.word); 
        // req.setAppointmentid(obj.appointmentid)

        const res = await healthService.searchListDrugs(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateDrugInUse (obj) {
    try {
        const req = new healthPb.CreateDrugInUseRequest();
        // req.setHealthrecordid(obj.healthrecordid)
        req.setAppointmentId(obj.appointmentid)
        
        obj?.drugs.forEach(drug => {
            let drugService = new proto.healthApi.CreateDrugInUseProto();
            drugService.setApresentationId(drug.apresentationId);
            drugService.setProductName(drug.productName);
            drugService.setActivePrinciple(drug.activePrinciple);
            drugService.setConcentration(drug.concentration);
            drugService.setPharmaceuticalForm(drug.pharmaceuticalForm);
            drugService.setAmount(drug.amount);
            drugService.setProducer(drug.producer);
            drugService.setType(drug.type);
            drugService.setNumberCopies(drug.numberCopies);
            drugService.setPosology(drug.posology);
            drugService.setCategory(drug.category);
            drugService.setTherapeuticClass(drug.therapeuticClass);
            drugService.setRecommendation(drug.recommendation);
            drugService.setInitialDate(drug.initialDate);
            drugService.setFinalDate(drug.finalDate);

            if(["Embalagem", "Embalagens"].includes(drug.typeAmount)){
                drugService.setIndicatedAmount(`${drug.indicatedAmount} ${drug.typeAmount}`);
            } else {
                drugService.setIndicatedAmount(`${drug.typeAmount}`);
            }

            // console.log(drug);
            req.addDrugs(drugService);
        })
        
        const res = await healthService.createDrugInUse(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DeleteDrugInUse (drugid) {
    try {
        const req = new healthPb.DeleteDrugInUseRequest();
        req.setPrescriptionDrugId(drugid)
        
        const res = await healthService.deleteDrugInUse(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function StartPatientCare (obj) {
    try {
        const req = new healthPb.StartPatientCareRequest();
        // req.setHealthrecordid(obj.healthrecordid)
        req.setAppointmentId(obj.appointmentid)
        
        const res = await healthService.startPatientCare(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function MedicalRecord (obj) {
    try {
        const req = new healthPb.MedicalRecordRequest();
        // req.setHealthrecordid(obj.healthrecordid)
        req.setAppointmentId(obj.appointmentid)

        let serviceSorting = new proto.healthApi.SortingProto();
        

        console.log(obj);

        // sorting
        if(obj?.sorting?.description){
            serviceSorting.setDescription(obj.sorting.description);
        }        
        if(obj?.sorting?.temperature?.value){
            const serviceTemperature = new proto.healthApi.TemperatureProto();
            serviceTemperature.setValue(obj.sorting.temperature.value)

            serviceSorting.setTemperature(serviceTemperature)
        }
        if(obj?.sorting?.blood_pressure?.blood_pressure_d && obj?.sorting?.blood_pressure?.blood_pressure_s){
            const serviceBloodPressure = new proto.healthApi.BloodPressureProto();
            serviceBloodPressure.setBloodPressureS(obj.sorting.blood_pressure.blood_pressure_s)
            serviceBloodPressure.setBloodPressureD(obj.sorting.blood_pressure.blood_pressure_d)
            serviceSorting.setBloodPressure(serviceBloodPressure)
        }
        if(obj?.sorting?.heart_rate?.rhythm){
            const serviceHeartRate = new proto.healthApi.HeartRateProto();
            serviceHeartRate.setRhythm(obj.sorting.heart_rate.rhythm)
            serviceSorting.setHeartRate(serviceHeartRate)
        }
        req.setSorting(serviceSorting);

        // anamnesis
        if(obj?.anamnesis){
            const serviceAnamnesis = new proto.healthApi.AnamnesisProto();
            serviceAnamnesis.setDescription(obj?.anamnesis);
            req.setAnamnesis(serviceAnamnesis);
        }
       
        // diagnostics
        obj?.diagnosis.forEach(diagnosis => {
            const serviceDiagnosis = new proto.healthApi.DiagnosisProto();
            serviceDiagnosis.setTitle(diagnosis.title || '');
            serviceDiagnosis.setOrientation(diagnosis.orientation || '');

            diagnosis?.cids?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code || '');
                serviceCid.setDescription(cid.description || '');

                serviceDiagnosis.addCids(serviceCid);
            });

            diagnosis?.cidsList?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code || '');
                serviceCid.setDescription(cid.description || '');

                serviceDiagnosis.addCids(serviceCid);
            });

            req.addDiagnoses(serviceDiagnosis);
        });

        const serviceLaboratoryExam = new proto.healthApi.ExamRequestProto();
        const serviceImageExam = new proto.healthApi.ExamRequestProto();
        const serviceOtherExam = new proto.healthApi.ExamRequestProto();

        // laboratoryExam
        if(obj.laboratoryExam.length){
            obj.laboratoryExam.forEach(exam => {
                const serviceExamReq = new proto.healthApi.ExamReqProto();
                serviceExamReq.setCodeTuss(exam.codeTuss);
                serviceExamReq.setDescription(exam.description);
                serviceExamReq.setDescGroup(exam.descGroup);
                serviceExamReq.setDescSubgroup(exam.descSubgroup);
                serviceLaboratoryExam.addExam(serviceExamReq);
            });
            req.setLaboratoryExam(serviceLaboratoryExam);
        }
        
        // imagingExam
        if(obj.imagingExam.length){
            obj.imagingExam.forEach(exam => {
                const serviceExamReq = new proto.healthApi.ExamReqProto();
                serviceExamReq.setCodeTuss(exam.codeTuss);
                serviceExamReq.setDescription(exam.description);
                serviceExamReq.setDescGroup(exam.descGroup);
                serviceExamReq.setDescSubgroup(exam.descSubgroup);
                serviceImageExam.addExam(serviceExamReq);
            });
            req.setImagingExam(serviceImageExam);
        }
        
        // otherExam
        if(obj.otherExam.length){
            obj.otherExam.forEach(exam => {
                const serviceExamReq = new proto.healthApi.ExamReqProto();
                serviceExamReq.setCodeTuss(exam.codeTuss);
                serviceExamReq.setDescription(exam.description);
                serviceExamReq.setDescGroup(exam.descGroup);
                serviceExamReq.setDescSubgroup(exam.descSubgroup);
                serviceOtherExam.addExam(serviceExamReq);
            });
            req.setOtherExam(serviceOtherExam);
        }

        //drugs
        obj.drugs.forEach(drug => {
            console.log("objDrug ->>>", drug);
            console.log(String(drug.indicatedAmount));
            const serviceDrug = new proto.healthApi.PrescriptionDrugProto(); // n tem add
            serviceDrug.setApresentationId(drug.apresentationId);
            serviceDrug.setProductName(drug.productName);
            serviceDrug.setActivePrinciple(drug.activePrinciple);
            serviceDrug.setConcentration(drug.concentration);
            serviceDrug.setPharmaceuticalForm(drug.pharmaceuticalForm);
            serviceDrug.setAmount(drug.amount);
            serviceDrug.setProducer(drug.producer);
            serviceDrug.setType(drug.type);
            serviceDrug.setNumberCopies(drug.numberCopies);
            serviceDrug.setPosology(drug.posology);
            serviceDrug.setIndicatedAmount(String(drug.indicatedAmount));
            serviceDrug.setCategory(drug.category);
            serviceDrug.setTherapeuticClass(drug.therapeuticClass);
            serviceDrug.setRecommendation(drug.recommendation);

            req.addDrugs(serviceDrug);
        });       

        //forwardings
        obj.forwardings.forEach(forwarding => {
            const serviceForwarding = new proto.healthApi.ForwardingProto();
            serviceForwarding.setProfession(forwarding.profission?.title || forwarding?.profession);
            serviceForwarding.setOrientation(forwarding.orientation);

            // addCids ( proto.healthApi.ForwardingCidProto )
            forwarding.cids?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceForwarding.addCids(serviceCid);
            });
            forwarding.cidsList?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceForwarding.addCids(serviceCid);
            });

            // addPartnerships
            forwarding.partnerships?.forEach(partnership => {
                const servicePartnership = new proto.healthApi.PartnershipProto();
                servicePartnership.setName(partnership.name);
                servicePartnership.setPictureUrl(partnership.pictureUrl);
                servicePartnership.setProfessionalId(partnership.professionalId);

                serviceForwarding.addPartnerships(servicePartnership);
            });
            req.addForwardings(serviceForwarding);            
        });

        // attest
        obj.medical_certificates.forEach(attest => {
            const serviceAttest = new proto.healthApi.MedicalCertificateProto();
            serviceAttest.setTitle(attest.title);
            serviceAttest.setOrientation(attest.orientation); // attes?.orientations removed

            // cids
            attest.cids?.forEach(cid => {
                const serviceCid = new proto.healthApi.MedicalCertificateCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceAttest.addCids(serviceCid);
            });
            attest.cidsList?.forEach(cid => {
                const serviceCid = new proto.healthApi.MedicalCertificateCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceAttest.addCids(serviceCid);
            });
        
            req.addMedicalCertificates(serviceAttest);
        });

        const res = await healthService.medicalRecord(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}
async function StopPatientCare (obj) {
    try {
        const req = new healthPb.StopPatientCareRequest();
        // req.setHealthrecordid(obj.healthrecordid)
        req.setAppointmentId(obj.appointmentid)

        let serviceSorting = new proto.healthApi.SortingProto();
        const serviceLaboratoryExam = new proto.healthApi.ExamRequestProto();
        const serviceImageExam = new proto.healthApi.ExamRequestProto();
        

        // sorting
        if(obj?.sorting?.description){
            serviceSorting.setDescription(obj.sorting.description);
        }        
        if(obj?.sorting?.temperature?.value){
            const serviceTemperature = new proto.healthApi.TemperatureProto();
            serviceTemperature.setValue(obj.sorting.temperature.value)

            serviceSorting.setTemperature(serviceTemperature)
        }
        if(obj?.sorting?.blood_pressure?.blood_pressure_d && obj?.sorting?.blood_pressure?.blood_pressure_s){
            const serviceBloodPressure = new proto.healthApi.BloodPressureProto();
            serviceBloodPressure.setBloodPressureS(obj.sorting.blood_pressure.blood_pressure_s)
            serviceBloodPressure.setBloodPressureD(obj.sorting.blood_pressure.blood_pressure_d)
            serviceSorting.setBloodPressure(serviceBloodPressure)
        }
        if(obj?.sorting?.heart_rate?.rhythm){
            const serviceHeartRate = new proto.healthApi.HeartRateProto();
            serviceHeartRate.setRhythm(obj.sorting.heart_rate.rhythm)
            serviceSorting.setHeartRate(serviceHeartRate)
        }
        req.setSorting(serviceSorting);

        // anamnesis
        if(obj?.anamnesis){
            const serviceAnamnesis = new proto.healthApi.AnamnesisProto();
            serviceAnamnesis.setDescription(obj?.anamnesis);
            req.setAnamnesis(serviceAnamnesis);
        }
       
        // diagnostics
        obj?.diagnosis.forEach(diagnosis => {
            const serviceDiagnosis = new proto.healthApi.DiagnosisProto();
            serviceDiagnosis.setTitle(diagnosis.title);
            serviceDiagnosis.setOrientation(diagnosis.orientation);

            diagnosis.cids?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceDiagnosis.addCids(serviceCid);
            });

            diagnosis.cidsList?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceDiagnosis.addCids(serviceCid);
            });

            req.addDiagnoses(serviceDiagnosis);
        });

        // laboratoryExam
        obj.laboratoryExam.forEach(exam => {
            const serviceExamReq = new proto.healthApi.ExamReqProto();
            serviceExamReq.setCodeTuss(exam.codeTuss);
            serviceExamReq.setDescription(exam.description);
            serviceExamReq.setDescGroup(exam.descGroup);
            serviceExamReq.setDescSubgroup(exam.descSubgroup);
            serviceLaboratoryExam.addExam(serviceExamReq);
        });
        if(obj.laboratoryExam.length > 0){
            req.setLaboratoryExam(serviceLaboratoryExam);
        }
        
        // imagingExam
        obj.imagingExam.forEach(exam => {
            const serviceExamReq = new proto.healthApi.ExamReqProto();
            serviceExamReq.setCodeTuss(exam.codeTuss);
            serviceExamReq.setDescription(exam.description);
            serviceExamReq.setDescGroup(exam.descGroup);
            serviceExamReq.setDescSubgroup(exam.descSubgroup);
            serviceImageExam.addExam(serviceExamReq);
        });
        if(obj.imagingExam.length > 0){
            req.setImagingExam(serviceImageExam);
        }
        
        // otherExam
        if(obj.otherExam.length > 0){
        }
        const serviceOtherExam = new proto.healthApi.ExamRequestProto();

        obj.otherExam.forEach(exam => {
            if(!exam){
                console.log("não tem exam");
            }else {
                console.log(exam);
            }

            const serviceExamReq = new proto.healthApi.ExamReqProto();
            serviceExamReq.setCodeTuss(exam.codeTuss);
            serviceExamReq.setDescription(exam.description);
            serviceExamReq.setDescGroup(exam.descGroup);
            serviceExamReq.setDescSubgroup(exam.descSubgroup);
            serviceExamReq.setOrientation(exam.orientation);
            serviceExamReq.setJustification(exam.justification);
            serviceOtherExam.addExam(serviceExamReq);
        });
        
        req.setOtherExam(serviceOtherExam);

        //drugs
        obj.drugs.forEach(drug => {
            const serviceDrug = new proto.healthApi.PrescriptionDrugProto(); // n tem add
            serviceDrug.setApresentationId(drug.apresentationId);
            serviceDrug.setProductName(drug.productName);
            serviceDrug.setActivePrinciple(drug.activePrinciple);
            serviceDrug.setConcentration(drug.concentration);
            serviceDrug.setPharmaceuticalForm(drug.pharmaceuticalForm);
            serviceDrug.setAmount(drug.amount);
            serviceDrug.setProducer(drug.producer);
            serviceDrug.setType(drug.type);
            serviceDrug.setNumberCopies(drug.numberCopies);
            serviceDrug.setPosology(drug.posology);
            serviceDrug.setIndicatedAmount(String(drug.indicatedAmount));
            serviceDrug.setCategory(drug.category);
            serviceDrug.setTherapeuticClass(drug.therapeuticClass);
            serviceDrug.setRecommendation(drug.recommendation);

            req.addDrugs(serviceDrug);
        });       

        //forwardings
        obj.forwardings.forEach(forwarding => {
            const serviceForwarding = new proto.healthApi.ForwardingProto();
            serviceForwarding.setProfession(forwarding.profission?.title || forwarding?.profession);
            serviceForwarding.setOrientation(forwarding.orientation);

            // addCids ( proto.healthApi.ForwardingCidProto )
            forwarding.cids?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceForwarding.addCids(serviceCid);
            });
            forwarding.cidsList?.forEach(cid => {
                const serviceCid = new proto.healthApi.ForwardingCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceForwarding.addCids(serviceCid);
            });

            // addPartnerships
            forwarding.partnerships?.forEach(partnership => {
                const servicePartnership = new proto.healthApi.PartnershipProto();
                servicePartnership.setName(partnership.name);
                servicePartnership.setPictureUrl(partnership.pictureUrl);
                servicePartnership.setProfessionalId(partnership.professionalId);

                serviceForwarding.addPartnerships(servicePartnership);
            });
            req.addForwardings(serviceForwarding);            
        });

        // attest
        obj.medical_certificates.forEach(attest => {
            const serviceAttest = new proto.healthApi.MedicalCertificateProto();
            serviceAttest.setTitle(attest.title);
            serviceAttest.setOrientation(attest.orientation);

            // cids
            attest.cids?.forEach(cid => {
                const serviceCid = new proto.healthApi.MedicalCertificateCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceAttest.addCids(serviceCid);
            });
            attest.cidsList?.forEach(cid => {
                const serviceCid = new proto.healthApi.MedicalCertificateCidProto();
                serviceCid.setCode(cid.code);
                serviceCid.setDescription(cid.description);

                serviceAttest.addCids(serviceCid);
            });
        
            req.addMedicalCertificates(serviceAttest);
        });

        const res = await healthService.stopPatientCare(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListLifestyles () {
    try {
        const req = new healthPb.EmptyRequest();
        // req.setAppointmentid(appointmentid)
        
        const res = await healthService.listLifestyles(req);

        console.log('ListLifestyles', res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function SearchListLifestyles (obj) {
    try {
        const req = new healthPb.SearchListLifestylesRequest();
        req.setAppointmentid(obj.appointmentid)
        req.setWord(obj.word)
        
        const res = await healthService.searchListLifestyles(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateDrink (obj) {
    try {
        const req = new healthPb.CreateDrinkRequest();
        // req.setHealthrecordid(obj.healthRecordId)
        req.setAppointmentId(obj.appointmentId)
        req.setDrinkDesc(obj.drinkDesc)
        req.setFrequency(obj.frequency)

        if(obj.frequency === 'Regularmente'){
            req.setMeasure(obj.measure)
            req.setAmount(obj.amount)
            req.setWhen(obj.when)
        }
        
        const res = await healthService.createDrink(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateCigarette (obj) {
    try {
        const req = new healthPb.CreateCigaretteRequest();
        // req.setHealthrecordid(obj.healthRecordId)
        req.setAppointmentid(obj.appointmentId)
        req.setType(obj.type)
        req.setFrequency(obj.frequency)

        if(obj.frequency === 'Regularmente'){
            req.setMeasure(obj.measure)
            req.setAmount(obj.amount)
            req.setWhen(obj.when)
        }
        
        const res = await healthService.createCigarette(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateExercise (obj) {
    try {
        const req = new healthPb.CreateExerciseRequest();
        // req.setHealthrecordid(obj.healthRecordId)
        req.setAppointmentid(obj.appointmentId)
        req.setTypeexercise(obj.typeExercise)
        req.setFrequency(obj.frequency)

        if(obj.frequency === 'Regularmente'){
            req.setDuration(obj.duration)
            req.setAmount(obj.amount)
            req.setWhen(obj.when)
        }
        
        const res = await healthService.createExercise(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DeleteExercise (exerciseid) {
    try {
        const req = new healthPb.DeleteExerciseRequest();
        req.setExerciseid(exerciseid)
        
        const res = await healthService.deleteExercise(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function UpdateExercise (obj) {
    try {
        const req = new healthPb.UpdateExerciseRequest();
        req.setExerciseid(obj.exerciseid)
        req.setAppointmentid(obj.appointmentId)
        req.setTypeexercise(obj.typeExercise)
        req.setFrequency(obj.frequency)

        if(obj.frequency === 'Regularmente'){
            req.setWhen(obj.when)
            req.setAmount(obj.amount)
            req.setDuration(obj.duration)
        }
        
        const res = await healthService.updateExercise(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function UpdateDrink (obj) {
    try {
        const req = new healthPb.UpdateDrinkRequest();
        req.setDrinkid(obj.drinkid)
        req.setAppointmentid(obj.appointmentId)
        req.setDrinkdesc(obj.drinkDesc)
        req.setFrequency(obj.frequency)

        if(obj.frequency === 'Regularmente'){
            req.setWhen(obj.when)
            req.setAmount(obj.amount)
            req.setMeasure(obj.measure)
        }
        
        const res = await healthService.updateDrink(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function UpdateCigarette (obj) {
    try {
        const req = new healthPb.UpdateCigaretteRequest();
        req.setSmokerid(obj.smokerid)
        req.setAppointmentid(obj.appointmentId)
        req.setType(obj.type)
        req.setFrequency(obj.frequency)

        if(obj.frequency === 'Regularmente'){
            req.setMeasure(obj.measure)
            req.setAmount(obj.amount)
            req.setWhen(obj.when)
        }
        
        const res = await healthService.updateCigarette(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DeleteDrink (drinkid) {
    try {
        const req = new healthPb.DeleteDrinkRequest();
        req.setDrinkid(drinkid)
        
        const res = await healthService.deleteDrink(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DeleteCigarette (smokerid) {
    try {
        const req = new healthPb.DeleteCigaretteRequest();
        req.setSmokerid(smokerid)
        
        const res = await healthService.deleteCigarette(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListExamHistory () {
    try {
        const req = new healthPb.EmptyRequest();
        
        const res = await healthService.listExamHistory(req);

        // console.log("ListExamHistory", res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateRequestResult (formData) {
    try {
        console.log('CreateRequestResult', formData)
        const req = new healthPb.CreateRequestResultRequest();
        // req.setHealthrecordid(formData.healthRecordId);
        req.setAppointmentid(formData.appointmentId);
        req.setPrescdate(formData.prescDate);
        req.setProfname(formData.profName);
        req.setOrientation(formData.orientation);
        req.setUrlexam(formData.urlExam);
        // console.log('formData.urlExam:', formData.urlExam);
        // exam: [];    req.addExam
        

        formData?.exam.forEach(exam => {
            let boolOnSiteCollection = false;

            // console.log('exam.onSiteCollection', exam.onSiteCollection)

            if(exam.onSiteCollection == undefined){
                boolOnSiteCollection = true;
            }

            if(exam.onSiteCollection?.name === 'sim'){
                boolOnSiteCollection = true;
            }

            // console.log('loop exam:', exam);
            const exameService = new proto.healthApi.ExamProto(); // addExam
            exameService.setCodetuss(exam.tuss ?? exam.codeTuss);
            exameService.setDescription(exam.description);
            exameService.setDescgroup(exam.descGroup);
            exameService.setDescsubgroup(exam.descSubgroup);
            exameService.setNumberpage(exam.numberPage ?? '');
            exameService.setUrlexam(formData.urlExam);
            
            // ExamResult: {}     exameService.setExamresult
            const examResultService = new proto.healthApi.ExamResultProto() 
            examResultService.setExamname(exam.examReport ?? exam.result.examName)
            examResultService.setCollectiondate(exam.collectionDate ?? exam.result.collectionDate)
            examResultService.setOnsitecollection(boolOnSiteCollection)
            examResultService.setMaterial(exam.material ?? exam.result.material)
            examResultService.setMethod(exam.method ?? exam.result.method)
            examResultService.setEquipment(exam.equipment ?? exam.result.equipment)
            examResultService.setLaboratory(exam.laboratory ?? exam.result.laboratory)
            examResultService.setUnit(exam.unit ?? exam.result.unit)
            examResultService.setObservation('')
            examResultService.setReleasedate(exam.releaseDate ?? exam.result.releaseDate)
            examResultService.setProfname(exam.profName ?? exam.result.profName)
            examResultService.setRegistry(exam.registry ?? exam.result.registry)
            examResultService.setAuthentication('')
            examResultService.setExamreport(exam.examReport ?? exam.result.examName)
            examResultService.setUrlexam('')
            // result: []    examResultService.addResult

            // console.log('exam.result', exam.result)

            if (Array.isArray(exam.result)) {
                exam.result.forEach(result => {
                    const resultService = new proto.healthApi.ResultProto()
                    resultService.setResultname(result?.resultName)
                    resultService.setName1(result?.name1)
                    resultService.setRes1(result?.res1)
                    resultService.setUnit1(result?.unit1)
                    resultService.setRes1sec(result?.res1Sec)
                    resultService.setName2(result?.name2)
                    resultService.setRes2(result?.res2)
                    resultService.setUnit2(result?.unit2)
                    resultService.setRes2sec(result?.res2Sec)
                    resultService.setUnit2sec(result?.unit2Sec)
                    resultService.setName3(result?.name3)
                    resultService.setRes3(result?.res3)
                    resultService.setUnit3(result?.unit3)
                    resultService.setRes3sec(result?.res3Sec)
                    resultService.setUnit3sec(result?.unit3Sec)
                    resultService.setName4(result?.name4)
                    resultService.setRes4(result?.res4)
                    resultService.setUnit4(result?.unit4)
                    resultService.setRes4sec(result?.res4Sec)
                    resultService.setUnit4sec(result?.unit4Sec)
                    resultService.setName5(result?.name5)
                    resultService.setRes5(result?.res5)
                    resultService.setUnit5(result?.unit5)
                    resultService.setRes5sec(result?.res5Sec)
                    resultService.setUnit5sec(result?.unit5Sec)
                    resultService.setName6(result?.name6)
                    resultService.setRes6(result?.res6)
                    resultService.setUnit6(result?.unit6)
                    resultService.setRes6sec(result?.res6Sec)
                    resultService.setUnit6sec(result?.unit6Sec)
                    resultService.setName7(result?.name7)
                    resultService.setRes7(result?.res7)
                    resultService.setUnit7(result?.unit7)
                    resultService.setRes7sec(result?.res7Sec)
                    resultService.setUnit7sec(result?.unit7Sec)
                    resultService.setObservation(result.observation)
                    examResultService.addResult(resultService) //ResultProto
                })
            }else{
                if (Array.isArray(exam.result.result)) {
                    exam.result.result.forEach(result => {
                        const resultService = new proto.healthApi.ResultProto()
                        resultService.setResultname(result?.resultName)
                        resultService.setName1(result?.name1)
                        resultService.setRes1(result?.res1)
                        resultService.setUnit1(result?.unit1)
                        resultService.setRes1sec(result?.res1Sec)
                        resultService.setName2(result?.name2)
                        resultService.setRes2(result?.res2)
                        resultService.setUnit2(result?.unit2)
                        resultService.setRes2sec(result?.res2Sec)
                        resultService.setUnit2sec(result?.unit2Sec)
                        resultService.setName3(result?.name3)
                        resultService.setRes3(result?.res3)
                        resultService.setUnit3(result?.unit3)
                        resultService.setRes3sec(result?.res3Sec)
                        resultService.setUnit3sec(result?.unit3Sec)
                        resultService.setName4(result?.name4)
                        resultService.setRes4(result?.res4)
                        resultService.setUnit4(result?.unit4)
                        resultService.setRes4sec(result?.res4Sec)
                        resultService.setUnit4sec(result?.unit4Sec)
                        resultService.setName5(result?.name5)
                        resultService.setRes5(result?.res5)
                        resultService.setUnit5(result?.unit5)
                        resultService.setRes5sec(result?.res5Sec)
                        resultService.setUnit5sec(result?.unit5Sec)
                        resultService.setName6(result?.name6)
                        resultService.setRes6(result?.res6)
                        resultService.setUnit6(result?.unit6)
                        resultService.setRes6sec(result?.res6Sec)
                        resultService.setUnit6sec(result?.unit6Sec)
                        resultService.setName7(result?.name7)
                        resultService.setRes7(result?.res7)
                        resultService.setUnit7(result?.unit7)
                        resultService.setRes7sec(result?.res7Sec)
                        resultService.setUnit7sec(result?.unit7Sec)
                        resultService.setObservation(result.observation)
                        examResultService.addResult(resultService) //ResultProto
                    })
                }
            }


            //ignore imageService
            // const imageService = new proto.healthApi.ImageProto()
            // examResultService.addImage('') //ImageProto
            //ignore imageService


            exameService.setExamresult(examResultService);
            req.addExam(exameService) // Add ao Exam: [];
        })

        const res = await healthService.createRequestResult(req);

        // console.log('res', res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListExam (examId) {
    try {
        const req = new healthPb.ListExamRequest();
        req.setExamid(examId)
        
        const res = await healthService.listExam(req);

        // console.log("ListExam", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListAnnotations () {
    try {
        const req = new healthPb.EmptyRequest();
        // req.setHealthrecordid(obj.healthrecordid)
        // req.setAppointmentid(obj.appointmentid)
        
        const res = await healthService.listAnnotations(req);

        // console.log("ListAnnotations", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DeleteAnnotation (obj) {
    try {
        const req = new healthPb.DeleteAnnotationRequest(obj);
        console.log(obj)
        req.setAnnotationid(obj.annotationid)
        
        const res = await healthService.deleteAnnotation(req);

        console.log("Nota deletada", res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function CreateAnnotation (obj) {
    try {
        const req = new healthPb.CreateAnnotationRequest(obj);
        console.log(obj)
        req.setAppointmentid(obj?.appointmentId)
        req.setTitle(obj.title.value || obj.title)
        req.setDescription(obj.description.value)
        
        const res = await healthService.createAnnotation(req);

        console.log("Nota adicionada", res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function UpdateAnnotation (obj) {
    try {
        const req = new healthPb.UpdateAnnotationRequest(obj);
        console.log(obj)
        req.setAnnotationid(obj.id)
        req.setTitle(obj.title.value)
        req.setDescription(obj.description.value)
        
        const res = await healthService.updateAnnotation(req);

        console.log("Nota adicionada", res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}


async function DeleteExam (examId) {
    try {
        const req = new healthPb.DeleteExamRequest();
        req.setExamid(examId)
        
        const res = await healthService.deleteExam(req);

        // console.log("ListExamHistory", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}


async function ListRequisition (examRequestId) {
    try {
        const req = new healthPb.ListRequisitionRequest();
        req.setExamrequestid(examRequestId)
        
        const res = await healthService.listRequisition(req);

        // console.log("ListExamHistory", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function UpdateResultExam (obj) {
    try {
        console.log('UpdateResultExam', {...obj});
        const req = new healthPb.UpdateResultExamRequest();
        req.setExamresultid(obj.examResultId);
        req.setExamname(obj.examName);
        req.setCollectiondate(obj.collectionDate);
        req.setOnsitecollection(obj.onSiteCollection);
        req.setMaterial(obj.material);
        req.setMethod(obj.method);
        req.setEquipment(obj.equipment);
        req.setLaboratory(obj.laboratory);
        req.setUnit(obj.unit);
        req.setObservation(obj.observation);
        req.setReleasedate(obj.releaseDate);
        req.setProfname(obj.profName);
        req.setRegistry(obj.registry);

        obj.result.forEach(exam => {
            const resultService = new proto.healthApi.UpdateResultProto();

            resultService.setResultid(exam.resultId);
            resultService.setExamresultid(obj.examResultId);
            resultService.setResultname(exam.resultName);

            // exam result 1
            resultService.setName1(exam.name1);
            resultService.setRes1(exam.res1);
            resultService.setUnit1(exam.unit1);
            resultService.setRes1sec(exam.res1Sec);
            resultService.setUnit1sec(exam.unit1Sec);
            
            // exam result 2
            resultService.setName2(exam.name2);
            resultService.setRes2(exam.res2);
            resultService.setUnit2(exam.unit2);
            resultService.setRes2sec(exam.res2Sec);
            resultService.setUnit2sec(exam.unit2Sec);
            
            // exam result 3
            resultService.setName3(exam.name3);
            resultService.setRes3(exam.res3);
            resultService.setUnit3(exam.unit3);
            resultService.setRes3sec(exam.res3Sec);
            resultService.setUnit3sec(exam.unit3Sec);
            
            // exam result 4
            resultService.setName4(exam.name4);
            resultService.setRes4(exam.res4);
            resultService.setUnit4(exam.unit4);
            resultService.setRes4sec(exam.res4Sec);
            resultService.setUnit4sec(exam.unit4Sec);
            
            // exam result 5
            resultService.setName5(exam.name5);
            resultService.setRes5(exam.res5);
            resultService.setUnit5(exam.unit5);
            resultService.setRes5sec(exam.res5Sec);
            resultService.setUnit5sec(exam.unit5Sec);
            
            // exam result 6
            resultService.setName6(exam.name6);
            resultService.setRes6(exam.res6);
            resultService.setUnit6(exam.unit6);
            resultService.setRes6sec(exam.res6Sec);
            resultService.setUnit6sec(exam.unit6Sec);
            
            // exam result 7
            resultService.setName7(exam.name7);
            resultService.setRes7(exam.res7);
            resultService.setUnit7(exam.unit7);
            resultService.setRes7sec(exam.res7Sec);
            resultService.setUnit7sec(exam.unit7Sec);

            resultService.setObservation(exam.observation);

            req.addResult(resultService);
        })
        
        const res = await healthService.updateResultExam(req);

        console.log("UpdateResultExam", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    ListViewMore,
    ListPdfs,
    ListMedicalCare,
    MedicalRecord,
    PauseToExam,
    PausePatientCare,
    ListAppointment,
    ListAppointmentHistory,
    ListDrugAllergies,
    CreateDrugAllergy,
    SearchDrugAllergies,
    DeleteDrugAllergy,
    UpdateDrugAllergy,
    ListDrugs,
    SearchListDrugs,
    CreateDrugInUse,
    DeleteDrugInUse,
    StartPatientCare,
    StopPatientCare,
    ListLifestyles,
    SearchListLifestyles,
    CreateExercise,
    DeleteExercise,
    UpdateExercise,
    CreateCigarette,
    DeleteCigarette,
    CreateDrink,
    DeleteDrink,
    UpdateDrink,
    UpdateCigarette,
    ListExamHistory,
    CreateRequestResult,
    ListExam,
    DeleteExam,
    ListRequisition,
    ListAnnotations,
    DeleteAnnotation,
    CreateAnnotation,
    UpdateAnnotation,
    UpdateResultExam,
}