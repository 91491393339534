import cbhpmService from "@/modules/core/services/cbhpmService"
import cbhpmPb from "@/modules/core/grpc/generated/cbhpm_pb.js";

interface SearchItems  {
    type: string;
    group: string;
    word: string;
}

const SearchItems = async (obj: SearchItems) => {
    try {
        const req = new cbhpmPb.CbhpmSearchItemsRequest();
        req.setType(obj.type)
        req.setGroup(obj.group)
        req.setWord(obj.word)

        const res = await cbhpmService.searchItems(req);
        
        return res
    } catch (error) {
        console.log(error);
        return error;
    }
}

const SearchExam = async (obj: any) => {
    try {
        const req = new cbhpmPb.SearchExamRequest();
        req.setGroup(obj.group)
        req.setWord(obj.word)

        const res = await cbhpmService.searchExam(req);
        
        return res
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    SearchItems,
    SearchExam,
}