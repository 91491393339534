<template>
    <div class="loader-content-health">
        <div class="historicals--loader">
            <Skeleton width="100%" height="290px" />
        </div>
        <div class="bottom--loader">
            <Skeleton class="shadow-loader" width="100%" height="64px" borderRadius="16px" v-for="x in 9" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.loader-content-health {
    position: sticky;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 26px;
    .historicals--loader{
        border-radius: 20px;
        overflow: hidden;
        height: 290px;
        position: relative;
    }
    .bottom--loader{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .shadow-loader{
            box-shadow: 0px 1px 3px rgb(61 71 102 / 8%);
        }
    }
}
</style>