<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9998 9.99976C19.9998 8.02195 19.4133 6.08855 18.3145 4.44406C17.2156 2.79957 15.6539 1.51784 13.8266 0.760965C11.9993 0.00408835 9.98866 -0.193945 8.04885 0.191907C6.10904 0.577759 4.32722 1.53017 2.92869 2.92869C1.53016 4.32722 0.577757 6.10905 0.191904 8.04886C-0.193947 9.98867 0.00408502 11.9993 0.760961 13.8266C1.51784 15.6539 2.79956 17.2156 4.44405 18.3145C6.08855 19.4133 8.02194 19.9998 9.99976 19.9998C11.313 19.9998 12.6133 19.7411 13.8266 19.2386C15.0398 18.736 16.1422 17.9994 17.0708 17.0708C17.9994 16.1422 18.736 15.0399 19.2386 13.8266C19.7411 12.6133 19.9998 11.313 19.9998 9.99976ZM1.99976 9.99976C1.99976 8.41751 2.46895 6.87079 3.348 5.5552C4.22705 4.2396 5.47648 3.21422 6.93829 2.60872C8.4001 2.00322 10.0086 1.8448 11.5605 2.15348C13.1123 2.46216 14.5378 3.22409 15.6566 4.34291C16.7754 5.46173 17.5374 6.88719 17.846 8.43904C18.1547 9.99089 17.9963 11.5994 17.3908 13.0612C16.7853 14.523 15.7599 15.7725 14.4443 16.6515C13.1287 17.5306 11.582 17.9998 9.99976 17.9998C7.87802 17.9998 5.84319 17.1569 4.3429 15.6566C2.84261 14.1563 1.99976 12.1215 1.99976 9.99976ZM10.9998 13.9998L10.9998 5.99976C10.9998 5.73454 10.8944 5.48019 10.7069 5.29265C10.5193 5.10512 10.265 4.99976 9.99976 4.99976C9.73454 4.99976 9.48019 5.10512 9.29265 5.29265C9.10511 5.48019 8.99976 5.73454 8.99976 5.99976L8.99976 13.9998C8.99976 14.265 9.10511 14.5193 9.29265 14.7069C9.48019 14.8944 9.73454 14.9998 9.99976 14.9998C10.265 14.9998 10.5193 14.8944 10.7069 14.7069C10.8944 14.5193 10.9998 14.265 10.9998 13.9998Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>