<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.6 6.1001L15.9 0.400098C15.5 9.76622e-05 14.9 9.76622e-05 14.5 0.400098L0.399976 14.5001C-2.44081e-05 14.9001 -2.44081e-05 15.5001 0.399976 15.9001L6.09998 21.6001C6.49998 22.0001 7.09998 22.0001 7.49998 21.6001L9.59998 19.5001L6.09998 16.0001C5.69998 15.6001 5.69998 15.0001 6.09998 14.6001C6.49998 14.2001 7.09998 14.2001 7.49998 14.6001L11 18.1001L12.4 16.7001L10.3 14.6001C9.89998 14.2001 9.89998 13.6001 10.3 13.2001C10.7 12.8001 11.3 12.8001 11.7 13.2001L13.8 15.3001L15.2 13.9001L11.7 10.4001C11.3 10.0001 11.3 9.4001 11.7 9.0001C12.1 8.6001 12.7 8.6001 13.1 9.0001L16.6 12.5001L18 11.1001L15.9 9.0001C15.5 8.6001 15.5 8.0001 15.9 7.6001C16.3 7.2001 16.9 7.2001 17.3 7.6001L19.4 9.7001L21.5 7.6001C22 7.1001 22 6.4001 21.6 6.1001Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>