import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb561bce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M6 13H1C0.4 13 0 13.4 0 14C0 14.6 0.4 15 1 15H5V19C5 19.6 5.4 20 6 20C6.6 20 7 19.6 7 19V14C7 13.4 6.6 13 6 13ZM6 0C5.4 0 5 0.4 5 1V5H1C0.4 5 0 5.4 0 6C0 6.6 0.4 7 1 7H6C6.6 7 7 6.6 7 6V1C7 0.4 6.6 0 6 0ZM14 7H19C19.6 7 20 6.6 20 6C20 5.4 19.6 5 19 5H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V6C13 6.6 13.4 7 14 7ZM19 13H14C13.4 13 13 13.4 13 14V19C13 19.6 13.4 20 14 20C14.6 20 15 19.6 15 19V15H19C19.6 15 20 14.6 20 14C20 13.4 19.6 13 19 13Z",
      fill: $setup.fill
    }, null, 8, _hoisted_2)
  ]))
}