/**
 * @fileoverview gRPC-Web generated client stub for templateApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: template.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.templateApi = require('./template_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.templateApi.TemplateProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.templateApi.TemplateProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.AddExamTemplateRequest,
 *   !proto.templateApi.AddExamTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_AddExamTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/AddExamTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.AddExamTemplateRequest,
  proto.templateApi.AddExamTemplateResponse,
  /**
   * @param {!proto.templateApi.AddExamTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.AddExamTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.AddExamTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.AddExamTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.AddExamTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.addExamTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddExamTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddExamTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.AddExamTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.AddExamTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.addExamTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddExamTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddExamTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListExamTemplatesRequest,
 *   !proto.templateApi.ListExamTemplatesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListExamTemplates = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListExamTemplates',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListExamTemplatesRequest,
  proto.templateApi.ListExamTemplatesResponse,
  /**
   * @param {!proto.templateApi.ListExamTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListExamTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListExamTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListExamTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListExamTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listExamTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListExamTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListExamTemplates,
      callback);
};


/**
 * @param {!proto.templateApi.ListExamTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListExamTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listExamTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListExamTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListExamTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListExamsRequest,
 *   !proto.templateApi.ListExamsResponse>}
 */
const methodDescriptor_TemplateProtoService_ListExams = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListExams',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListExamsRequest,
  proto.templateApi.ListExamsResponse,
  /**
   * @param {!proto.templateApi.ListExamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListExamsResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListExamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListExamsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListExamsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listExams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListExams',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListExams,
      callback);
};


/**
 * @param {!proto.templateApi.ListExamsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListExamsResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listExams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListExams',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListExams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.UpdateExamTemplateRequest,
 *   !proto.templateApi.UpdateExamTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_UpdateExamTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/UpdateExamTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.UpdateExamTemplateRequest,
  proto.templateApi.UpdateExamTemplateResponse,
  /**
   * @param {!proto.templateApi.UpdateExamTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.UpdateExamTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.UpdateExamTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.UpdateExamTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.UpdateExamTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.updateExamTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateExamTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateExamTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.UpdateExamTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.UpdateExamTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.updateExamTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateExamTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateExamTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.DeleteExamTemplateRequest,
 *   !proto.templateApi.DeleteExamTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_DeleteExamTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/DeleteExamTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.DeleteExamTemplateRequest,
  proto.templateApi.DeleteExamTemplateResponse,
  /**
   * @param {!proto.templateApi.DeleteExamTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.DeleteExamTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.DeleteExamTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.DeleteExamTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.DeleteExamTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.deleteExamTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteExamTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteExamTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.DeleteExamTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.DeleteExamTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.deleteExamTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteExamTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteExamTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.AddDrugTemplateRequest,
 *   !proto.templateApi.AddDrugTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_AddDrugTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/AddDrugTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.AddDrugTemplateRequest,
  proto.templateApi.AddDrugTemplateResponse,
  /**
   * @param {!proto.templateApi.AddDrugTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.AddDrugTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.AddDrugTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.AddDrugTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.AddDrugTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.addDrugTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddDrugTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddDrugTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.AddDrugTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.AddDrugTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.addDrugTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddDrugTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddDrugTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.UpdateDrugTemplateRequest,
 *   !proto.templateApi.UpdateDrugTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_UpdateDrugTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/UpdateDrugTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.UpdateDrugTemplateRequest,
  proto.templateApi.UpdateDrugTemplateResponse,
  /**
   * @param {!proto.templateApi.UpdateDrugTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.UpdateDrugTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.UpdateDrugTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.UpdateDrugTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.UpdateDrugTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.updateDrugTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateDrugTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateDrugTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.UpdateDrugTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.UpdateDrugTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.updateDrugTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateDrugTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateDrugTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.DeleteDrugTemplateRequest,
 *   !proto.templateApi.DeleteDrugTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_DeleteDrugTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/DeleteDrugTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.DeleteDrugTemplateRequest,
  proto.templateApi.DeleteDrugTemplateResponse,
  /**
   * @param {!proto.templateApi.DeleteDrugTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.DeleteDrugTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.DeleteDrugTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.DeleteDrugTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.DeleteDrugTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.deleteDrugTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteDrugTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteDrugTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.DeleteDrugTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.DeleteDrugTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.deleteDrugTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteDrugTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteDrugTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListDrugTemplatesRequest,
 *   !proto.templateApi.ListDrugTemplatesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListDrugTemplates = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListDrugTemplates',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListDrugTemplatesRequest,
  proto.templateApi.ListDrugTemplatesResponse,
  /**
   * @param {!proto.templateApi.ListDrugTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListDrugTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListDrugTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListDrugTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListDrugTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listDrugTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDrugTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDrugTemplates,
      callback);
};


/**
 * @param {!proto.templateApi.ListDrugTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListDrugTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listDrugTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDrugTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDrugTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListDrugsRequest,
 *   !proto.templateApi.ListDrugsResponse>}
 */
const methodDescriptor_TemplateProtoService_ListDrugs = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListDrugs',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListDrugsRequest,
  proto.templateApi.ListDrugsResponse,
  /**
   * @param {!proto.templateApi.ListDrugsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListDrugsResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListDrugsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListDrugsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListDrugsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listDrugs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDrugs',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDrugs,
      callback);
};


/**
 * @param {!proto.templateApi.ListDrugsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListDrugsResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listDrugs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDrugs',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDrugs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.AddForwardingTemplateRequest,
 *   !proto.templateApi.AddForwardingTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_AddForwardingTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/AddForwardingTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.AddForwardingTemplateRequest,
  proto.templateApi.AddForwardingTemplateResponse,
  /**
   * @param {!proto.templateApi.AddForwardingTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.AddForwardingTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.AddForwardingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.AddForwardingTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.AddForwardingTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.addForwardingTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddForwardingTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddForwardingTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.AddForwardingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.AddForwardingTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.addForwardingTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddForwardingTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddForwardingTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.UpdateForwardingTemplateRequest,
 *   !proto.templateApi.UpdateForwardingTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_UpdateForwardingTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/UpdateForwardingTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.UpdateForwardingTemplateRequest,
  proto.templateApi.UpdateForwardingTemplateResponse,
  /**
   * @param {!proto.templateApi.UpdateForwardingTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.UpdateForwardingTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.UpdateForwardingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.UpdateForwardingTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.UpdateForwardingTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.updateForwardingTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateForwardingTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateForwardingTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.UpdateForwardingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.UpdateForwardingTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.updateForwardingTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateForwardingTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateForwardingTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.DeleteForwardingTemplateRequest,
 *   !proto.templateApi.DeleteForwardingTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_DeleteForwardingTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/DeleteForwardingTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.DeleteForwardingTemplateRequest,
  proto.templateApi.DeleteForwardingTemplateResponse,
  /**
   * @param {!proto.templateApi.DeleteForwardingTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.DeleteForwardingTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.DeleteForwardingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.DeleteForwardingTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.DeleteForwardingTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.deleteForwardingTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteForwardingTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteForwardingTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.DeleteForwardingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.DeleteForwardingTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.deleteForwardingTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteForwardingTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteForwardingTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListForwardingTemplatesRequest,
 *   !proto.templateApi.ListForwardingTemplatesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListForwardingTemplates = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListForwardingTemplates',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListForwardingTemplatesRequest,
  proto.templateApi.ListForwardingTemplatesResponse,
  /**
   * @param {!proto.templateApi.ListForwardingTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListForwardingTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListForwardingTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListForwardingTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListForwardingTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listForwardingTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListForwardingTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListForwardingTemplates,
      callback);
};


/**
 * @param {!proto.templateApi.ListForwardingTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListForwardingTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listForwardingTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListForwardingTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListForwardingTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListForwardingsRequest,
 *   !proto.templateApi.ListForwardingsResponse>}
 */
const methodDescriptor_TemplateProtoService_ListForwardings = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListForwardings',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListForwardingsRequest,
  proto.templateApi.ListForwardingsResponse,
  /**
   * @param {!proto.templateApi.ListForwardingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListForwardingsResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListForwardingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListForwardingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListForwardingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listForwardings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListForwardings',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListForwardings,
      callback);
};


/**
 * @param {!proto.templateApi.ListForwardingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListForwardingsResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listForwardings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListForwardings',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListForwardings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.AddMedicalCertificateTemplateRequest,
 *   !proto.templateApi.AddMedicalCertificateTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_AddMedicalCertificateTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/AddMedicalCertificateTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.AddMedicalCertificateTemplateRequest,
  proto.templateApi.AddMedicalCertificateTemplateResponse,
  /**
   * @param {!proto.templateApi.AddMedicalCertificateTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.AddMedicalCertificateTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.AddMedicalCertificateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.AddMedicalCertificateTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.AddMedicalCertificateTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.addMedicalCertificateTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddMedicalCertificateTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddMedicalCertificateTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.AddMedicalCertificateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.AddMedicalCertificateTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.addMedicalCertificateTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddMedicalCertificateTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddMedicalCertificateTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.UpdateMedicalCertificateTemplateRequest,
 *   !proto.templateApi.UpdateMedicalCertificateTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_UpdateMedicalCertificateTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/UpdateMedicalCertificateTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.UpdateMedicalCertificateTemplateRequest,
  proto.templateApi.UpdateMedicalCertificateTemplateResponse,
  /**
   * @param {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.UpdateMedicalCertificateTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.UpdateMedicalCertificateTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.UpdateMedicalCertificateTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.updateMedicalCertificateTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateMedicalCertificateTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateMedicalCertificateTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.UpdateMedicalCertificateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.UpdateMedicalCertificateTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.updateMedicalCertificateTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateMedicalCertificateTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateMedicalCertificateTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.DeleteMedicalCertificateTemplateRequest,
 *   !proto.templateApi.DeleteMedicalCertificateTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_DeleteMedicalCertificateTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/DeleteMedicalCertificateTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.DeleteMedicalCertificateTemplateRequest,
  proto.templateApi.DeleteMedicalCertificateTemplateResponse,
  /**
   * @param {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.DeleteMedicalCertificateTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.DeleteMedicalCertificateTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.DeleteMedicalCertificateTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.deleteMedicalCertificateTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteMedicalCertificateTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteMedicalCertificateTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.DeleteMedicalCertificateTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.DeleteMedicalCertificateTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.deleteMedicalCertificateTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteMedicalCertificateTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteMedicalCertificateTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListMedicalCertificateTemplatesRequest,
 *   !proto.templateApi.ListMedicalCertificateTemplatesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListMedicalCertificateTemplates = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListMedicalCertificateTemplates',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListMedicalCertificateTemplatesRequest,
  proto.templateApi.ListMedicalCertificateTemplatesResponse,
  /**
   * @param {!proto.templateApi.ListMedicalCertificateTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListMedicalCertificateTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListMedicalCertificateTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListMedicalCertificateTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listMedicalCertificateTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListMedicalCertificateTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListMedicalCertificateTemplates,
      callback);
};


/**
 * @param {!proto.templateApi.ListMedicalCertificateTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListMedicalCertificateTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listMedicalCertificateTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListMedicalCertificateTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListMedicalCertificateTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListMedicalCertificatesRequest,
 *   !proto.templateApi.ListMedicalCertificatesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListMedicalCertificates = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListMedicalCertificates',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListMedicalCertificatesRequest,
  proto.templateApi.ListMedicalCertificatesResponse,
  /**
   * @param {!proto.templateApi.ListMedicalCertificatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListMedicalCertificatesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListMedicalCertificatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListMedicalCertificatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListMedicalCertificatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listMedicalCertificates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListMedicalCertificates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListMedicalCertificates,
      callback);
};


/**
 * @param {!proto.templateApi.ListMedicalCertificatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListMedicalCertificatesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listMedicalCertificates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListMedicalCertificates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListMedicalCertificates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.AddDiagnosisTemplateRequest,
 *   !proto.templateApi.AddDiagnosisTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_AddDiagnosisTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/AddDiagnosisTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.AddDiagnosisTemplateRequest,
  proto.templateApi.AddDiagnosisTemplateResponse,
  /**
   * @param {!proto.templateApi.AddDiagnosisTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.AddDiagnosisTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.AddDiagnosisTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.AddDiagnosisTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.AddDiagnosisTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.addDiagnosisTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddDiagnosisTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddDiagnosisTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.AddDiagnosisTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.AddDiagnosisTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.addDiagnosisTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/AddDiagnosisTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_AddDiagnosisTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.UpdateDiagnosisTemplateRequest,
 *   !proto.templateApi.UpdateDiagnosisTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_UpdateDiagnosisTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/UpdateDiagnosisTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.UpdateDiagnosisTemplateRequest,
  proto.templateApi.UpdateDiagnosisTemplateResponse,
  /**
   * @param {!proto.templateApi.UpdateDiagnosisTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.UpdateDiagnosisTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.UpdateDiagnosisTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.UpdateDiagnosisTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.UpdateDiagnosisTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.updateDiagnosisTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateDiagnosisTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateDiagnosisTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.UpdateDiagnosisTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.UpdateDiagnosisTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.updateDiagnosisTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/UpdateDiagnosisTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_UpdateDiagnosisTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.DeleteDiagnosisTemplateRequest,
 *   !proto.templateApi.DeleteDiagnosisTemplateResponse>}
 */
const methodDescriptor_TemplateProtoService_DeleteDiagnosisTemplate = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/DeleteDiagnosisTemplate',
  grpc.web.MethodType.UNARY,
  proto.templateApi.DeleteDiagnosisTemplateRequest,
  proto.templateApi.DeleteDiagnosisTemplateResponse,
  /**
   * @param {!proto.templateApi.DeleteDiagnosisTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.DeleteDiagnosisTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.DeleteDiagnosisTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.DeleteDiagnosisTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.DeleteDiagnosisTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.deleteDiagnosisTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteDiagnosisTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteDiagnosisTemplate,
      callback);
};


/**
 * @param {!proto.templateApi.DeleteDiagnosisTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.DeleteDiagnosisTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.deleteDiagnosisTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/DeleteDiagnosisTemplate',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_DeleteDiagnosisTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListDiagnosisTemplatesRequest,
 *   !proto.templateApi.ListDiagnosisTemplatesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListDiagnosisTemplates = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListDiagnosisTemplates',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListDiagnosisTemplatesRequest,
  proto.templateApi.ListDiagnosisTemplatesResponse,
  /**
   * @param {!proto.templateApi.ListDiagnosisTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListDiagnosisTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListDiagnosisTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListDiagnosisTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListDiagnosisTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listDiagnosisTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDiagnosisTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDiagnosisTemplates,
      callback);
};


/**
 * @param {!proto.templateApi.ListDiagnosisTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListDiagnosisTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listDiagnosisTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDiagnosisTemplates',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDiagnosisTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.templateApi.ListDiagnosesRequest,
 *   !proto.templateApi.ListDiagnosesResponse>}
 */
const methodDescriptor_TemplateProtoService_ListDiagnoses = new grpc.web.MethodDescriptor(
  '/templateApi.TemplateProtoService/ListDiagnoses',
  grpc.web.MethodType.UNARY,
  proto.templateApi.ListDiagnosesRequest,
  proto.templateApi.ListDiagnosesResponse,
  /**
   * @param {!proto.templateApi.ListDiagnosesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.templateApi.ListDiagnosesResponse.deserializeBinary
);


/**
 * @param {!proto.templateApi.ListDiagnosesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.templateApi.ListDiagnosesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.templateApi.ListDiagnosesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.templateApi.TemplateProtoServiceClient.prototype.listDiagnoses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDiagnoses',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDiagnoses,
      callback);
};


/**
 * @param {!proto.templateApi.ListDiagnosesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.templateApi.ListDiagnosesResponse>}
 *     Promise that resolves to the response
 */
proto.templateApi.TemplateProtoServicePromiseClient.prototype.listDiagnoses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/templateApi.TemplateProtoService/ListDiagnoses',
      request,
      metadata || {},
      methodDescriptor_TemplateProtoService_ListDiagnoses);
};


module.exports = proto.templateApi;

