import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90e5ee9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filter = _resolveComponent("Filter")!
  const _component_Search = _resolveComponent("Search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Filter),
    _createVNode(_component_Search, {
      modelValue: $props.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.search) = $event))
    }, null, 8, ["modelValue"])
  ]))
}