<template>
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.1669 5.33335H22.5002C23.2002 5.33335 23.6669 4.86669 23.6669 4.16669C23.6669 3.46669 23.2002 3.00002 22.5002 3.00002H13.1669C12.4669 3.00002 12.0002 3.46669 12.0002 4.16669C12.0002 4.86669 12.4669 5.33335 13.1669 5.33335ZM22.5002 7.66669H13.1669C12.4669 7.66669 12.0002 8.13335 12.0002 8.83335C12.0002 9.53335 12.4669 10 13.1669 10H22.5002C23.2002 10 23.6669 9.53335 23.6669 8.83335C23.6669 8.13335 23.2002 7.66669 22.5002 7.66669ZM1.50022 10H8.50022C9.20022 10 9.66689 9.53335 9.66689 8.83335V1.83335C9.66689 1.13335 9.20022 0.666687 8.50022 0.666687H1.50022C0.800224 0.666687 0.333557 1.13335 0.333557 1.83335V8.83335C0.333557 9.53335 0.800224 10 1.50022 10ZM22.5002 12.3334H1.50022C0.800224 12.3334 0.333557 12.8 0.333557 13.5C0.333557 14.2 0.800224 14.6667 1.50022 14.6667H22.5002C23.2002 14.6667 23.6669 14.2 23.6669 13.5C23.6669 12.8 23.2002 12.3334 22.5002 12.3334ZM13.1669 17H1.50022C0.800224 17 0.333557 17.4667 0.333557 18.1667C0.333557 18.8667 0.800224 19.3334 1.50022 19.3334H13.1669C13.8669 19.3334 14.3336 18.8667 14.3336 18.1667C14.3336 17.4667 13.8669 17 13.1669 17Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>