import { ProfessionProtoServicePromiseClient } from '../grpc/generated/profession_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new ProfessionProtoServicePromiseClient(apiUrl);

async function getProfessions(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.getProfessions(request, metadata, {});

    return response.toObject();
}



export default {
    getProfessions,
}