export const mapeamento = [
    { 
        name: 'Modelo 1', // ERITROGRAMA, LEUCOCITOS, PLAQUETAS
        codes: [
            '40304361',
        ]
    },
    { 
        name: 'Modelo 2', // RESULTADO
        codes: [
            '40301630',
            '40301583',
            '40302423',
            '40316572',
            '40316270',
            '40302733',
            '40316491',
            '40302830',
            '40301150',
            '40301400',
            '40302040',
            '40316360',
            '40302580',
            '40302318',
        ]
    },
    { 
        name: 'Modelo 3', // RESULTADO, Tempo de jejum
        codes: [
            '40302547',
            '40302695',
        ]
    },
]

export function validateCode(code: string) {
    for (let item of mapeamento) {
        if (item.codes.includes(code)) {
            return item;
        }
    }
    return null;
}