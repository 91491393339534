<template>
    <li><span>P. arterial</span> 120 / 80</li>
    <li><span>F. cardíaca</span> 81 bpm</li>
    <li><span>Sono</span> 7:22 h</li>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["list"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

li{
    display: flex;
    gap: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    span{
        color: #BDBDBD;
    }
}

@media (min-width: 1920px) {
    li{
        font-size: 14px;
    }
    span{
        font-size: 14px;
    }
}

</style>