<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 14.2V5.8C19.2 5.4 20 4.3 20 3C20 1.3 18.7 0 17 0C15.7 0 14.6 0.8 14.2 2H5.8C5.4 0.8 4.3 0 3 0C1.3 0 0 1.3 0 3C0 4.3 0.8 5.4 2 5.8V14.2C0.8 14.6 0 15.7 0 17C0 18.7 1.3 20 3 20C4.3 20 5.4 19.2 5.8 18H14.2C14.6 19.2 15.7 20 17 20C18.7 20 20 18.7 20 17C20 15.7 19.2 14.6 18 14.2ZM14.2 16H5.8C5.5 15.2 4.8 14.5 4 14.2V5.8C4.8 5.5 5.5 4.8 5.8 4H14.2C14.5 4.8 15.2 5.5 16 5.8V14.2C15.2 14.5 14.5 15.2 14.2 16ZM17 2C17.6 2 18 2.4 18 3C18 3.6 17.6 4 17 4C16.4 4 16 3.6 16 3C16 2.4 16.4 2 17 2ZM3 2C3.6 2 4 2.4 4 3C4 3.6 3.6 4 3 4C2.4 4 2 3.6 2 3C2 2.4 2.4 2 3 2ZM3 18C2.4 18 2 17.6 2 17C2 16.4 2.4 16 3 16C3.6 16 4 16.4 4 17C4 17.6 3.6 18 3 18ZM17 18C16.4 18 16 17.6 16 17C16 16.4 16.4 16 17 16C17.6 16 18 16.4 18 17C18 17.6 17.6 18 17 18Z" :fill="fill"/>
    </svg>

</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>