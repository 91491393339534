
// import { onMounted, ref } from "vue";
import ArrowUpperRight from "@/assets/IconsComponent/ArrowUpperRight.vue"
import TableMode from "@/assets/IconsComponent/TableMode.vue"
import CardMode from "@/assets/IconsComponent/CardMode.vue"
import ArrowUp from "@/assets/IconsComponent/ArrowUp.vue"
import ArrowDown from "@/assets/IconsComponent/ArrowDown.vue"

export default {
    props: ["modelValue", "modeCard", "expanded"],
    emits: ["update:modelValue", "changeMode", "changeExpanded"],
    components: { ArrowUpperRight, TableMode, CardMode, ArrowUp, ArrowDown },
    setup(props, { emit }) {
        const changeValue = (val) => {
            emit("update:modelValue", val)
        }

        const changeMode = () => {
            emit("changeMode")
        }

        const changeExpanded = () => emit("changeExpanded")

        return {
            changeValue,
            changeMode,
            changeExpanded,
        }
    },
};
