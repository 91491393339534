import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0270deae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "write" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputText, {
      type: "text",
      modelValue: $setup.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputValue) = $event)),
      placeholder: "Escreva aqui..."
    }, null, 8, ["modelValue"])
  ]))
}