import { HealthProtoServicePromiseClient } from '../grpc/generated/health_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new HealthProtoServicePromiseClient(apiUrl);


async function listPdfs(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listPdfs(request, metadata, {});

    return response.toObject();
}

async function listAppointment(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listAppointment(request, metadata, {});

    return response.toObject();
}

async function listAppointmentHistory(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listAppointmentHistory(request, metadata, {});

    return response.toObject();
}

async function listDrugAllergies(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listDrugAllergies(request, metadata, {});

    return response.toObject();
}

async function createDrugAllergy(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createDrugAllergy(request, metadata, {});

    return response.toObject();
}

async function searchDrugAllergies(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchDrugAllergies(request, metadata, {});

    return response.toObject();
}

async function deleteDrugAllergy(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteDrugAllergy(request, metadata, {});

    return response.toObject();
}

async function updateDrugAllergy(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateDrugAllergy(request, metadata, {});

    return response.toObject();
}


async function listDrugs(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listDrugs(request, metadata, {});

    return response.toObject();
}

async function searchListDrugs(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchListDrugs(request, metadata, {});

    return response.toObject();
}

async function deleteDrugInUse(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteDrugInUse(request, metadata, {});

    return response.toObject();
}

async function createDrugInUse(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createDrugInUse(request, metadata, {});

    return response.toObject();
}

async function startPatientCare(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.startPatientCare(request, metadata, {});

    return response.toObject();
}

async function stopPatientCare(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.stopPatientCare(request, metadata, {});

    return response.toObject();
}

async function listLifestyles(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listLifestyles(request, metadata, {});

    return response.toObject();
}

async function searchListLifestyles(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchListLifestyles(request, metadata, {});

    return response.toObject();
}

async function createExercise(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createExercise(request, metadata, {});

    return response.toObject();
}

async function deleteExercise(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteExercise(request, metadata, {});

    return response.toObject();
}

async function updateExercise(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateExercise(request, metadata, {});

    return response.toObject();
}

async function updateDrink(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateDrink(request, metadata, {});

    return response.toObject();
}

async function updateCigarette(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateCigarette(request, metadata, {});

    return response.toObject();
}

async function createDrink(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createDrink(request, metadata, {});

    return response.toObject();
}
async function deleteDrink(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteDrink(request, metadata, {});

    return response.toObject();
}

async function createCigarette(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createCigarette(request, metadata, {});

    return response.toObject();
}

async function deleteCigarette(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteCigarette(request, metadata, {});

    return response.toObject();
}

async function listExamHistory(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listExamHistory(request, metadata, {});

    return response.toObject();
}

async function createResultExam(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createResultExam(request, metadata, {});

    return response.toObject();
}

async function createRequestResult(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createRequestResult(request, metadata, {});

    return response.toObject();
}

async function listAnnotations(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listAnnotations(request, metadata, {});

    return response.toObject();
}

async function deleteAnnotation(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteAnnotation(request, metadata, {});

    return response.toObject();
}

async function createAnnotation(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.createAnnotation(request, metadata, {});

    return response.toObject();
}

async function updateAnnotation(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateAnnotation(request, metadata, {});

    return response.toObject();
}

async function listExam(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listExam(request, metadata, {});

    return response.toObject();
}

async function deleteExam(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteExam(request, metadata, {});

    return response.toObject();
}

async function listRequisition(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listRequisition(request, metadata, {});

    return response.toObject();
}

async function updateResultExam(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateResultExam(request, metadata, {});

    return response.toObject();
}

async function pausePatientCare(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.pausePatientCare(request, metadata, {});

    return response.toObject();
}

async function pauseToExam(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.pauseToExam(request, metadata, {});

    return response.toObject();
}

async function medicalRecord(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.medicalRecord(request, metadata, {});

    return response.toObject();
}

async function listMedicalCare(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listMedicalCare(request, metadata, {});

    return response.toObject();
}

async function listViewMore(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listViewMore(request, metadata, {});

    return response.toObject();
}

export default {
    listPdfs,
    listAppointment,
    listAppointmentHistory,
    listDrugAllergies,
    createDrugAllergy,
    searchDrugAllergies,
    deleteDrugAllergy,
    updateDrugAllergy,
    listDrugs,
    searchListDrugs,
    createDrugInUse,
    deleteDrugInUse,
    startPatientCare,
    stopPatientCare,
    listLifestyles,
    searchListLifestyles,
    createExercise,
    deleteExercise,
    updateExercise,
    createDrink,
    deleteDrink,
    updateDrink,
    createCigarette,
    deleteCigarette,
    updateCigarette,
    listExamHistory,
    createResultExam,
    createRequestResult,
    listExam,
    deleteExam,
    listRequisition,
    listAnnotations,
    deleteAnnotation,
    createAnnotation,
    updateAnnotation,
    updateResultExam,
    pausePatientCare,
    pauseToExam,
    medicalRecord,
    listMedicalCare,
    listViewMore,
}