<template>
    <div class="health-record--filter">
        <div 
            class="filter" 
            :class="filter === item && 'filter-active'"
            v-for="(item, index) in filters"
            @click="() => selectFilter(item)"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";

const filters = [
    "Histórico de saúde",
    "Histórico de exames",
    "Histórico de consultas"
]

export default {
    props: ["filter"],
    emits: ["changeFilter"],
    setup(props, { emit }) {
        // const bool = ref(false);

        const selectFilter = (item) => {
            emit("changeFilter", item)
        }

        return {
            filters,
            selectFilter
        }
    },
};
</script>


<style lang="scss" scoped>
.health-record--filter {
    display: flex;
    align-items: center;
    gap: 8px;
    .filter{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        background: #FAFAFA;
        border-radius: 100px;
        min-height: 34px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: .2px;
        color: #BDBDBD;
        border: 1px solid #FAFAFA;
        cursor: pointer;
        transition: background-color 280ms;

        &:hover{
            border: 1px solid rgb(189, 189, 189, .5);
            background: #e0e0e0;
            color: #828282;
            box-shadow: 0px 4px 4px rgb(189, 189, 189, .07);
        }
    }
    .filter-active{
        color: #4F4F4F;
    }
}



@media (min-width: 1920px) {
    .health-record--filter{
        .filter{
            height: 38px;
            padding: 0 18px !important;
            /* font-size: 16px; */
        }
    }
}

</style>