import cidService from "@/modules/core/services/cidService"
import cidPb from "@/modules/core/grpc/generated/cid_pb.js";

interface SearchItems {
    word: string;
    gender: string;
}

const Cid10Get = async (obj: SearchItems) => {
    try {
        const req = new cidPb.Cid10GetRequest();
        req.setWord(obj.word);
        req.setGender(obj.gender);

        const res = await cidService.cid10Get(req);
        
        return res
    } catch (error) {
        console.log(error);
        return error;
    }
}


export {
    Cid10Get,
}