<template>
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8332 0.666794H2.16654C1.72452 0.666794 1.30059 0.842388 0.988033 1.15495C0.675473 1.46751 0.499878 1.89143 0.499878 2.33346V4.00013C0.499878 4.44215 0.675473 4.86608 0.988033 5.17864C1.30059 5.4912 1.72452 5.66679 2.16654 5.66679V14.8335C2.16654 15.4965 2.42994 16.1324 2.89878 16.6012C3.36762 17.0701 4.0035 17.3335 4.66654 17.3335H11.3332C11.9963 17.3335 12.6321 17.0701 13.101 16.6012C13.5698 16.1324 13.8332 15.4965 13.8332 14.8335V5.66679C14.2752 5.66679 14.6992 5.4912 15.0117 5.17864C15.3243 4.86608 15.4999 4.44215 15.4999 4.00013V2.33346C15.4999 1.89143 15.3243 1.46751 15.0117 1.15495C14.6992 0.842388 14.2752 0.666794 13.8332 0.666794ZM12.1665 12.3335H7.16654V9.00013H12.1665V12.3335ZM12.1665 7.33346H6.33321C6.1122 7.33346 5.90024 7.42126 5.74396 7.57754C5.58768 7.73382 5.49988 7.94578 5.49988 8.16679V13.1668C5.49988 13.3878 5.58768 13.5998 5.74396 13.7561C5.90024 13.9123 6.1122 14.0001 6.33321 14.0001H12.1665V14.8335C12.1665 15.0545 12.0787 15.2664 11.9225 15.4227C11.7662 15.579 11.5542 15.6668 11.3332 15.6668H4.66654C4.44553 15.6668 4.23357 15.579 4.07729 15.4227C3.92101 15.2664 3.83321 15.0545 3.83321 14.8335V5.66679H12.1665V7.33346ZM2.16654 4.00013V2.33346H13.8332V4.00013H2.16654Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>