<template>
    <div class="min-height-loader flex flex-column gap-10 mt-20">
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.min-height-loader{
    min-height: 406px;
}

</style>