import drugService from "@/modules/core/services/drugService"
import drugPb from "@/modules/core/grpc/generated/drug_pb";

async function DrugSearchItems (obj) {
    try {
        const req = new drugPb.DrugSearchItemsRequest();
        req.setType(obj.type);
        req.setWord(obj.word);
        const res = await drugService.drugSearchItems(req);

        console.log("DrugSearchItems", obj)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function PrescriptionDrugSearchItems (word: string) {
    try {
        const req = new drugPb.PrescriptionDrugSearchItemsRequest();
        req.setWord(word);
        const res = await drugService.prescriptionDrugSearchItems(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function DosageSuggestion (apresentationId: string) {
    try {
        const req = new drugPb.DosageSuggestionRequest();
        req.setApresentationId(apresentationId);
        const res = await drugService.dosageSuggestion(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}



export {
    DrugSearchItems,
    PrescriptionDrugSearchItems,
    DosageSuggestion,
}