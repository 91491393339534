import { TemplateProtoServicePromiseClient } from '../grpc/generated/template_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new TemplateProtoServicePromiseClient(apiUrl);

async function listExamTemplates(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listExamTemplates(request, metadata, {});

    return response.toObject();
}

async function listExams(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listExams(request, metadata, {});

    return response.toObject();
}

async function listDrugTemplates(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listDrugTemplates(request, metadata, {});

    return response.toObject();
}

async function listDrugs(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listDrugs(request, metadata, {});

    return response.toObject();
}

async function listDiagnoses(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listDiagnoses(request, metadata, {});

    return response.toObject();
}

async function listForwardings(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listForwardings(request, metadata, {});

    return response.toObject();
}

async function listMedicalCertificateTemplates(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listMedicalCertificateTemplates(request, metadata, {});

    return response.toObject();
}

async function listMedicalCertificates(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listMedicalCertificates(request, metadata, {});

    return response.toObject();
}

async function listForwardingTemplates(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listForwardingTemplates(request, metadata, {});

    return response.toObject();
}

async function listDiagnosisTemplates(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listDiagnosisTemplates(request, metadata, {});

    return response.toObject();
}

export default {
    listExamTemplates,
    listExams,
    listDrugTemplates,
    listDrugs,
    listDiagnoses,
    listForwardings,
    listMedicalCertificateTemplates,
    listMedicalCertificates,
    listForwardingTemplates,
    listDiagnosisTemplates,
}