<template>
    <li v-for="label in list" > 
        {{ label }}
    </li>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["list"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

li{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    /* font-size: 12px; */
    font-size: 12px;
    
    line-height: 16px;
    color: #828282;
}


@media (min-width: 1920px) {
    li{
        font-size: 14px;
    }
}
</style>