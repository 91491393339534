<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 15.2L16 0.8C16 0.587827 15.9157 0.384344 15.7657 0.234315C15.6157 0.0842854 15.4122 -2.56947e-08 15.2 -3.49691e-08L0.800001 -6.64413e-07C0.587828 -6.73687e-07 0.384344 0.0842848 0.234315 0.234314C0.0842857 0.384343 9.2798e-07 0.587826 9.18705e-07 0.799999L2.89261e-07 15.2C2.79987e-07 15.4122 0.0842851 15.6157 0.234314 15.7657C0.384343 15.9157 0.587827 16 0.8 16L15.2 16C15.4122 16 15.6157 15.9157 15.7657 15.7657C15.9157 15.6157 16 15.4122 16 15.2ZM1.6 4.8L1.6 1.6L14.4 1.6L14.4 4.8L1.6 4.8ZM1.6 9.6L1.6 6.4L14.4 6.4L14.4 9.6L1.6 9.6ZM1.6 14.4L1.6 11.2L14.4 11.2L14.4 14.4L1.6 14.4Z" :fill="fill"/>
    </svg>

</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>