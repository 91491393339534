<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3215 0.5L0.821474 0.499999C0.371474 0.499999 0.0714741 0.799999 0.071474 1.25L0.0714738 7.25L15.0715 7.25L15.0715 1.25C15.0715 0.8 14.7715 0.5 14.3215 0.5ZM15.0715 14.75L15.0715 8.75L0.0714737 8.75L0.0714735 14.75C0.0714734 15.2 0.371474 15.5 0.821473 15.5L14.3215 15.5C14.7715 15.5 15.0715 15.2 15.0715 14.75Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>