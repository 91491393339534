/**
 * @fileoverview gRPC-Web generated client stub for patientProfileApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: patientProfile.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.patientProfileApi = require('./patientProfile_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.patientProfileApi.PatientProfileProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.EmptyRequest,
 *   !proto.patientProfileApi.GetInfoResponse>}
 */
const methodDescriptor_PatientProfileProtoService_GetInfo = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/GetInfo',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.EmptyRequest,
  proto.patientProfileApi.GetInfoResponse,
  /**
   * @param {!proto.patientProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.GetInfoResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.GetInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.GetInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.getInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/GetInfo',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_GetInfo,
      callback);
};


/**
 * @param {!proto.patientProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.GetInfoResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.getInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/GetInfo',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_GetInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.UpdateInfoRequest,
 *   !proto.patientProfileApi.UpdateInfoResponse>}
 */
const methodDescriptor_PatientProfileProtoService_UpdateInfo = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/UpdateInfo',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.UpdateInfoRequest,
  proto.patientProfileApi.UpdateInfoResponse,
  /**
   * @param {!proto.patientProfileApi.UpdateInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.UpdateInfoResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.UpdateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.UpdateInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.UpdateInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.updateInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/UpdateInfo',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_UpdateInfo,
      callback);
};


/**
 * @param {!proto.patientProfileApi.UpdateInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.UpdateInfoResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.updateInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/UpdateInfo',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_UpdateInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.AddLocationRequest,
 *   !proto.patientProfileApi.AddLocationResponse>}
 */
const methodDescriptor_PatientProfileProtoService_AddLocation = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/AddLocation',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.AddLocationRequest,
  proto.patientProfileApi.AddLocationResponse,
  /**
   * @param {!proto.patientProfileApi.AddLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.AddLocationResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.AddLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.AddLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.AddLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.addLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/AddLocation',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_AddLocation,
      callback);
};


/**
 * @param {!proto.patientProfileApi.AddLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.AddLocationResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.addLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/AddLocation',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_AddLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.SearchPatientsRequest,
 *   !proto.patientProfileApi.SearchPatientsResponse>}
 */
const methodDescriptor_PatientProfileProtoService_SearchPatients = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/SearchPatients',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.SearchPatientsRequest,
  proto.patientProfileApi.SearchPatientsResponse,
  /**
   * @param {!proto.patientProfileApi.SearchPatientsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.SearchPatientsResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.SearchPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.SearchPatientsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.SearchPatientsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.searchPatients =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/SearchPatients',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_SearchPatients,
      callback);
};


/**
 * @param {!proto.patientProfileApi.SearchPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.SearchPatientsResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.searchPatients =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/SearchPatients',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_SearchPatients);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.EmptyRequest,
 *   !proto.patientProfileApi.ListDocumentsResponse>}
 */
const methodDescriptor_PatientProfileProtoService_ListDocuments = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/ListDocuments',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.EmptyRequest,
  proto.patientProfileApi.ListDocumentsResponse,
  /**
   * @param {!proto.patientProfileApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.ListDocumentsResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.ListDocumentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.ListDocumentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.listDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_ListDocuments,
      callback);
};


/**
 * @param {!proto.patientProfileApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.ListDocumentsResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.listDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_ListDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.ListPatientsRequest,
 *   !proto.patientProfileApi.ListPatientsResponse>}
 */
const methodDescriptor_PatientProfileProtoService_ListPatients = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/ListPatients',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.ListPatientsRequest,
  proto.patientProfileApi.ListPatientsResponse,
  /**
   * @param {!proto.patientProfileApi.ListPatientsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.ListPatientsResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.ListPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.ListPatientsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.ListPatientsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.listPatients =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/ListPatients',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_ListPatients,
      callback);
};


/**
 * @param {!proto.patientProfileApi.ListPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.ListPatientsResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.listPatients =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/ListPatients',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_ListPatients);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.ListPatientHealthRequest,
 *   !proto.patientProfileApi.ListPatientHealthResponse>}
 */
const methodDescriptor_PatientProfileProtoService_ListPatientHealth = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/ListPatientHealth',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.ListPatientHealthRequest,
  proto.patientProfileApi.ListPatientHealthResponse,
  /**
   * @param {!proto.patientProfileApi.ListPatientHealthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.ListPatientHealthResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.ListPatientHealthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.ListPatientHealthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.ListPatientHealthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.listPatientHealth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/ListPatientHealth',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_ListPatientHealth,
      callback);
};


/**
 * @param {!proto.patientProfileApi.ListPatientHealthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.ListPatientHealthResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.listPatientHealth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/ListPatientHealth',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_ListPatientHealth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.patientProfileApi.GetPatientInfoRequest,
 *   !proto.patientProfileApi.GetPatientInfoResponse>}
 */
const methodDescriptor_PatientProfileProtoService_GetPatientInfo = new grpc.web.MethodDescriptor(
  '/patientProfileApi.PatientProfileProtoService/GetPatientInfo',
  grpc.web.MethodType.UNARY,
  proto.patientProfileApi.GetPatientInfoRequest,
  proto.patientProfileApi.GetPatientInfoResponse,
  /**
   * @param {!proto.patientProfileApi.GetPatientInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.patientProfileApi.GetPatientInfoResponse.deserializeBinary
);


/**
 * @param {!proto.patientProfileApi.GetPatientInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.patientProfileApi.GetPatientInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.patientProfileApi.GetPatientInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.patientProfileApi.PatientProfileProtoServiceClient.prototype.getPatientInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/GetPatientInfo',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_GetPatientInfo,
      callback);
};


/**
 * @param {!proto.patientProfileApi.GetPatientInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.patientProfileApi.GetPatientInfoResponse>}
 *     Promise that resolves to the response
 */
proto.patientProfileApi.PatientProfileProtoServicePromiseClient.prototype.getPatientInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/patientProfileApi.PatientProfileProtoService/GetPatientInfo',
      request,
      metadata || {},
      methodDescriptor_PatientProfileProtoService_GetPatientInfo);
};


module.exports = proto.patientProfileApi;

