
import { useHealth } from "@/store/health.js"
import Filter from "../components/Filter.vue"
import Search from "../components/Search.vue"

export default {
    props: ["search"],
    components: { Filter, Search },
    setup(props, { emit }) {
        const health = useHealth()


        return {
            health,
        }
    },
};
