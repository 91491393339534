<template>
    <div class="min-height-loader flex flex-column gap-10">
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="68px" border-radius="10px" />
        <Skeleton class="mt-10" width="100%" height="78px" border-radius="10px" />
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.min-height-loader{
    min-height: 416px;
}
</style>