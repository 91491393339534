import templateService from "@/modules/core/services/templateService"
import templatePb from "@/modules/core/grpc/generated/template_pb.js";


const ListExamTemplates = async (obj: any) => {
    try {
        const req = new templatePb.ListExamTemplatesRequest();
        req.setType(obj.type);
        req.setWord(obj.word);
        const res = await templateService.listExamTemplates(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListExams = async (examtemplateid: string) => {
    try {
        const req = new templatePb.ListExamsRequest();
        req.setExamTemplateId(examtemplateid);
        const res = await templateService.listExams(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListDrugTemplates = async (word: string) => {
    try {
        const req = new templatePb.ListDrugTemplatesRequest();
        req.setWord(word);
        const res = await templateService.listDrugTemplates(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListDrugs = async (DrugTemplateId: string) => {
    try {
        const req = new templatePb.ListDrugsRequest();
        req.setDrugTemplateId(DrugTemplateId);
        const res = await templateService.listDrugs(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListDiagnoses = async (DiagnosisTemplateId: string) => {
    try {
        const req = new templatePb.ListDiagnosesRequest();
        req.setDiagnosisTemplateId(DiagnosisTemplateId);
        const res = await templateService.listDiagnoses(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListForwardings = async (forwardingTemplateId: string) => {
    try {
        const req = new templatePb.ListForwardingsRequest();
        req.setForwardingTemplateId(forwardingTemplateId);
        const res = await templateService.listForwardings(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListMedicalCertificateTemplates = async (Word: string) => {
    try {
        const req = new templatePb.ListMedicalCertificateTemplatesRequest();
        req.setWord(Word);
        const res = await templateService.listMedicalCertificateTemplates(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListMedicalCertificates = async (medicalCertificateTemplateId: string) => {
    try {
        const req = new templatePb.ListMedicalCertificatesRequest();
        req.setMedicalCertificateTemplateId(medicalCertificateTemplateId);
        const res = await templateService.listMedicalCertificates(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListForwardingTemplates = async (word: string) => {
    try {
        const req = new templatePb.ListForwardingTemplatesRequest();
        req.setWord(word);
        const res = await templateService.listForwardingTemplates(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListDiagnosisTemplates = async (word: string) => {
    try {
        const req = new templatePb.ListDiagnosisTemplatesRequest();
        req.setWord(word);
        const res = await templateService.listDiagnosisTemplates(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    ListExamTemplates,
    ListExams,
    ListDrugTemplates,
    ListDrugs,
    ListDiagnoses,
    ListForwardings,
    ListMedicalCertificateTemplates,
    ListMedicalCertificates,
    ListForwardingTemplates,
    ListDiagnosisTemplates,
}