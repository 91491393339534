import { DrugProtoServicePromiseClient } from '../grpc/generated/drug_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new DrugProtoServicePromiseClient(apiUrl);

async function drugSearchItems(request) {
  const token = window.localStorage.getItem("token_secondary");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.drugSearchItems(request, metadata, {});

  return response.toObject();
}

async function prescriptionDrugSearchItems(request) {
  const token = window.localStorage.getItem("token_secondary");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.prescriptionDrugSearchItems(request, metadata, {});

  return response.toObject();
}

async function dosageSuggestion(request) {
  const token = window.localStorage.getItem("token_secondary");
  const metadata = { Authorization: "Bearer " + token };
  const response = await svc.dosageSuggestion(request, metadata, {});

  return response.toObject();
}

export default {
  drugSearchItems,
  prescriptionDrugSearchItems,
  dosageSuggestion,
}