import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../../assets/icons/health/arrow-down-right-grey.png'


const _withScopeId = n => (_pushScopeId("data-v-52b03abe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-header"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 1,
  class: "health-record--list"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "to-bottom" }
const _hoisted_7 = { class: "w-full flex align-items-center justify-content-end" }
const _hoisted_8 = {
  class: "cursor-pointer",
  src: _imports_0,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_CardPersonalized = _resolveComponent("CardPersonalized")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString($props.label), 1),
          _withDirectives(_createVNode(_component_Close, {
            class: "close",
            onClick: $setup.close
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              { value: 'Fechar' },
              void 0,
              { top: true }
            ]
          ])
        ]))
      : _createCommentVNode("", true),
    ($props.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "card--list zoom-in",
              onClick: ($event: any) => ($setup.clickCard(item))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(item.name), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("ul", null, [
                  _createVNode(_component_CardPersonalized, {
                    name: item.name,
                    obj: item?.obj,
                    list: item.list
                  }, null, 8, ["name", "obj", "list"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
                    [
                      _directive_tooltip,
                      { value: 'Ver Tudo' },
                      void 0,
                      { top: true }
                    ]
                  ])
                ])
              ])
            ], 8, _hoisted_4))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}