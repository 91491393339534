import { validateCode } from "../mapeamento"

const getErrorOnSiteCollection = (data: any, enabledError: boolean) => {
    if(!enabledError){ return "" }

    if(!!data.onSiteCollection && enabledError){
        if(data.onSiteCollection.name === 'sim' || data.onSiteCollection.name === 'não'){
            return ""
        }
    }
    
    return "Campo obrigatório."
}

const getObjectToResultName = (name: string, formData: any) => {
    return formData.find((el: any) => el.resultName === name);
}

const getObjectToName = (name: string, formData: any) => {
    return formData.find((el: any) => el.name1 === name);
}

const checkCompletedModel = (model: string, formDataModel: any) => {
    switch(model){
        case "Modelo 1":
            const eritograma = getObjectToResultName('ERITROGRAMA', formDataModel);
            const leucocitos = getObjectToResultName('LEUCOCITOS', formDataModel);
            const plaquetas = getObjectToResultName('PLAQUETAS', formDataModel);

            if( !!eritograma?.res1 && !!eritograma?.unit1
                && !!eritograma?.res2 && !!eritograma?.unit2 
                && !!eritograma?.res3 && !!eritograma?.unit3
                && !!eritograma?.res4 && !!eritograma?.unit4
                && !!eritograma?.res5 && !!eritograma?.unit5
                && !!eritograma?.res6 && !!eritograma?.unit6
                && !!eritograma?.res7 && !!eritograma?.unit7
                && !!leucocitos?.res1 && !!leucocitos?.unit1
                && !!leucocitos?.res2 && !!leucocitos?.unit2 && !!leucocitos?.res2Sec && !!leucocitos?.unit2Sec
                && !!leucocitos?.res3 && !!leucocitos?.unit3 && !!leucocitos?.res3Sec && !!leucocitos?.unit3Sec
                && !!leucocitos?.res4 && !!leucocitos?.unit4 && !!leucocitos?.res4Sec && !!leucocitos?.unit4Sec
                && !!leucocitos?.res5 && !!leucocitos?.unit5 && !!leucocitos?.res5Sec && !!leucocitos?.unit5Sec
                && !!leucocitos?.res6 && !!leucocitos?.unit6 && !!leucocitos?.res6Sec && !!leucocitos?.unit6Sec
                && !!plaquetas?.res1 && !!plaquetas?.unit1
                && !!plaquetas?.res2 && !!plaquetas?.unit2
            ){
                return true;
            }
            
            return false;
        break;
        case "Modelo 2":
            const resultado = getObjectToName('RESULTADO', formDataModel);
            
            if(resultado?.res1 && resultado?.unit1){
                return true;
            }
            return false;
        break;
        case "Modelo 3":
            const result = getObjectToName('RESULTADO', formDataModel);
            const tempo_jejum = getObjectToName('Tempo de jejum', formDataModel);
            
            if(result?.res1 && result?.unit1 && tempo_jejum?.res1 && tempo_jejum?.unit1){
                return true;
            }
            return false;
        default: 
            return false;
    }
}

const getTypeExamItem = (ExamItem) => {
    if('numberPage' in ExamItem){
        return 'pdf'
    }
    return 'manual'
    
}


function examItemIsCompleted(obj: any) {
    for (const prop in obj) {
      if (obj[prop] !== true) {
        return false;
      }
    }
    return true;
}

const getOnSiteCollection = (ExamType: string, ExamItem: any) => {
    if(ExamType === 'manual'){
        if(typeof ExamItem?.result?.onSiteCollection === 'boolean'){
            return true
        }
        if(ExamItem?.onSiteCollection?.name === 'sim' || ExamItem?.onSiteCollection?.name === 'não'){
            return true;
        }
    }else if( ExamType === 'pdf'){
        return true;
    }

    return false;
}

const checkCompleted = (arrBoolean: any) => {
    return arrBoolean.every((element: boolean) => element === true);
}

const fullFormDataCompleted = (obj: any) => {
    console.log(obj);
    
    const defaultInputs = obj.exam.map((ExamItem: any) => {
        const ExamType = getTypeExamItem(ExamItem);
        const ExamModel = validateCode(ExamItem?.tuss || ExamItem?.codeTuss)?.name;

        const result = ExamItem?.result?.result ?? ExamItem?.result

        // console.log(ExamModel);
        console.log(ExamType);
        const obj = {
            description: !!ExamItem.description,
            tuss: !!(ExamItem?.tuss || ExamItem?.codeTuss),
            group: !!ExamItem?.descGroup,
            subGroup: !!ExamItem?.descSubgroup,
            laboratory: !!(ExamItem?.laboratory ?? ExamItem?.result?.laboratory),
            exam: !!(ExamItem?.result.examName ?? ExamItem?.examReport),
            collectionDate: !!(ExamItem?.result?.collectionDate ?? ExamItem?.collectionDate),
            onSiteCollection: getOnSiteCollection(ExamType, ExamItem),
            models: checkCompletedModel(ExamModel, result)
        }
        console.log(obj);
        // console.log(ExamItem);


        return examItemIsCompleted(obj);
    })

    console.log(defaultInputs);
    console.log(checkCompleted(defaultInputs));

    return checkCompleted(defaultInputs);
}

export default {
    getErrorOnSiteCollection,
    checkCompletedModel,
    fullFormDataCompleted,
}