<template>
    <div class="w-full flex justify-content-center align-items-center">
        <div class="sem-registro">
            <img src="../../../../../assets/icons/health/folder.png" alt="">
            Sem registros
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["list"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.sem-registro{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: max-content;
    height: 40px;
    background: #FCFCFC;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 0.857rem;
    line-height: 16px;
    color: #BDBDBD;
    margin-bottom: 10px;
    padding: 0 14px;
}

@media (min-width: 1920px) {
    .sem-registro{
        font-size: 14px;
        letter-spacing: .6px;
        gap: 10px;
        border-radius: 10px;
    }
}

</style>