<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.46002 8.29002C8.27172 8.09641 8.01422 7.98554 7.74416 7.98179C7.47411 7.97804 7.21363 8.08172 7.02002 8.27002C6.82641 8.45833 6.71554 8.71583 6.71179 8.98588C6.70804 9.25593 6.81172 9.51641 7.00002 9.71002L9.34002 12L7.00002 14.29C6.90629 14.383 6.8319 14.4936 6.78113 14.6154C6.73036 14.7373 6.70422 14.868 6.70422 15C6.70422 15.132 6.73036 15.2627 6.78113 15.3846C6.8319 15.5065 6.90629 15.6171 7.00002 15.71C7.09298 15.8038 7.20359 15.8781 7.32545 15.9289C7.4473 15.9797 7.57801 16.0058 7.71002 16.0058C7.84203 16.0058 7.97274 15.9797 8.0946 15.9289C8.21646 15.8781 8.32706 15.8038 8.42002 15.71L11.42 12.71C11.5138 12.6171 11.5881 12.5065 11.6389 12.3846C11.6897 12.2627 11.7158 12.132 11.7158 12C11.7158 11.868 11.6897 11.7373 11.6389 11.6154C11.5881 11.4936 11.5138 11.383 11.42 11.29L8.46002 8.29002ZM16.96 11.29L13.96 8.29002C13.7717 8.10172 13.5163 7.99593 13.25 7.99593C12.9837 7.99593 12.7283 8.10172 12.54 8.29002C12.3517 8.47833 12.2459 8.73372 12.2459 9.00002C12.2459 9.26632 12.3517 9.52172 12.54 9.71002L14.84 12L12.54 14.29C12.4463 14.383 12.3719 14.4936 12.3211 14.6154C12.2704 14.7373 12.2442 14.868 12.2442 15C12.2442 15.132 12.2704 15.2627 12.3211 15.3846C12.3719 15.5065 12.4463 15.6171 12.54 15.71C12.633 15.8038 12.7436 15.8781 12.8654 15.9289C12.9873 15.9797 13.118 16.0058 13.25 16.0058C13.382 16.0058 13.5127 15.9797 13.6346 15.9289C13.7565 15.8781 13.8671 15.8038 13.96 15.71L16.96 12.71C17.0564 12.6197 17.134 12.5112 17.1882 12.3908C17.2424 12.2704 17.2723 12.1404 17.276 12.0084C17.2797 11.8763 17.2572 11.7449 17.2099 11.6216C17.1625 11.4983 17.0912 11.3856 17 11.29H16.96Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>