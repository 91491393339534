<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.7099 18.7899L16.9999 15.1099C18.44 13.3143 19.1374 11.0352 18.9487 8.7412C18.76 6.44721 17.6996 4.31269 15.9854 2.77655C14.2713 1.24041 12.0337 0.419414 9.73283 0.482375C7.43194 0.545335 5.24263 1.48747 3.61505 3.11505C1.98747 4.74263 1.04534 6.93194 0.982375 9.23283C0.919414 11.5337 1.74041 13.7713 3.27655 15.4854C4.81269 17.1996 6.94721 18.26 9.2412 18.4487C11.5352 18.6374 13.8143 17.94 15.6099 16.4999L19.2899 20.1799C19.3829 20.2736 19.4935 20.348 19.6153 20.3988C19.7372 20.4496 19.8679 20.4757 19.9999 20.4757C20.1319 20.4757 20.2626 20.4496 20.3845 20.3988C20.5063 20.348 20.6169 20.2736 20.7099 20.1799C20.8901 19.9934 20.9909 19.7442 20.9909 19.4849C20.9909 19.2256 20.8901 18.9764 20.7099 18.7899ZM9.9999 16.4999C8.61544 16.4999 7.26206 16.0894 6.11091 15.3202C4.95977 14.551 4.06256 13.4578 3.53275 12.1787C3.00293 10.8996 2.86431 9.49214 3.13441 8.13427C3.4045 6.77641 4.07119 5.52912 5.05016 4.55016C6.02912 3.57119 7.27641 2.9045 8.63427 2.63441C9.99214 2.36431 11.3996 2.50293 12.6787 3.03275C13.9578 3.56256 15.051 4.45977 15.8202 5.61091C16.5894 6.76206 16.9999 8.11544 16.9999 9.4999C16.9999 11.3564 16.2624 13.1369 14.9497 14.4497C13.6369 15.7624 11.8564 16.4999 9.9999 16.4999Z" :fill="fill"/>
    </svg>

</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>