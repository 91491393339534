import { CbhpmProtoServicePromiseClient } from '../grpc/generated/cbhpm_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new CbhpmProtoServicePromiseClient(apiUrl);


async function searchItems(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.cbhpmSearchItems(request, metadata, {});

    return response.toObject();
}

async function searchExam(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.searchExam(request, metadata, {});

    return response.toObject();
}


export default {
    searchItems,
    searchExam,
}