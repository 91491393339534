<template>
  <div class="box-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 11H17.06C16.9903 10.9905 16.9197 10.9905 16.85 11H16.68C16.6277 11.0298 16.5776 11.0632 16.53 11.1C16.4743 11.1368 16.4209 11.1768 16.37 11.22C16.3366 11.2609 16.3065 11.3044 16.28 11.35C16.2342 11.413 16.194 11.4799 16.16 11.55L14.56 15.96L10.39 4.66C10.3199 4.46695 10.1921 4.30015 10.0239 4.18228C9.85577 4.06441 9.65537 4.00117 9.45 4.00117C9.24463 4.00117 9.04423 4.06441 8.87605 4.18228C8.70788 4.30015 8.58007 4.46695 8.51 4.66L6.2 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H6.92H7.15H7.3C7.35684 12.9726 7.4105 12.9391 7.46 12.9C7.51568 12.8632 7.56911 12.8232 7.62 12.78L7.71 12.65C7.75766 12.5882 7.79794 12.5211 7.83 12.45L9.45 7.92L13.61 19.34C13.6799 19.5333 13.8076 19.7003 13.9758 19.8184C14.144 19.9365 14.3445 19.9999 14.55 20C14.7555 19.9999 14.956 19.9365 15.1242 19.8184C15.2924 19.7003 15.4201 19.5333 15.49 19.34L17.79 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
        fill="#333333" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.box-icon {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: white;
  filter: drop-shadow(0px 3px 6px rgba(61, 71, 102, 0.06)) drop-shadow(0px 8px 24px rgba(61, 71, 102, 0.08));
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>