<template>
  <div class="w-full d-flex flex-column gap-20">
    <div class="w-full d-flex gap-20">
      <Skeleton width="252px" height="244px" border-radius="12px" />

      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
    </div>

    <div class="w-full d-flex gap-20">
      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
    </div>
    <div class="w-full d-flex gap-20">
      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
      <Skeleton height="244px" border-radius="12px" style="flex: 1;" />
    </div>
  </div>
</template>