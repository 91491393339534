
import { ref } from "vue";
import Close from '@/assets/IconsComponent/Close.vue'
export default {
    props: ["label", "list", "visible"],
    emits: ["update:visible"],
    components: { Close },
    setup(props, { emit }) {
        const awnser = ref(null);

        return {
            awnser
        }
    },
};
