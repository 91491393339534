
import { ref } from "vue";
export default {
    setup(props, { emit }) {
        const inputValue = ref(null);

        return {
            inputValue
        }
    },
};
