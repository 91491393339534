// source: drug.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.drugApi.DosageSuggestionRequest', null, global);
goog.exportSymbol('proto.drugApi.DosageSuggestionResponse', null, global);
goog.exportSymbol('proto.drugApi.DrugList', null, global);
goog.exportSymbol('proto.drugApi.DrugProto', null, global);
goog.exportSymbol('proto.drugApi.DrugSearchItemsRequest', null, global);
goog.exportSymbol('proto.drugApi.DrugSearchItemsResponse', null, global);
goog.exportSymbol('proto.drugApi.PrescriptionDrugSearchItemsRequest', null, global);
goog.exportSymbol('proto.drugApi.PrescriptionDrugSearchItemsResponse', null, global);
goog.exportSymbol('proto.drugApi.Suggestion', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.DrugSearchItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.drugApi.DrugSearchItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.DrugSearchItemsRequest.displayName = 'proto.drugApi.DrugSearchItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.DrugSearchItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.drugApi.DrugSearchItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.drugApi.DrugSearchItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.DrugSearchItemsResponse.displayName = 'proto.drugApi.DrugSearchItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.DrugProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.drugApi.DrugProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.DrugProto.displayName = 'proto.drugApi.DrugProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.DosageSuggestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.drugApi.DosageSuggestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.DosageSuggestionRequest.displayName = 'proto.drugApi.DosageSuggestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.DosageSuggestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.drugApi.DosageSuggestionResponse.repeatedFields_, null);
};
goog.inherits(proto.drugApi.DosageSuggestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.DosageSuggestionResponse.displayName = 'proto.drugApi.DosageSuggestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.Suggestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.drugApi.Suggestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.Suggestion.displayName = 'proto.drugApi.Suggestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.drugApi.PrescriptionDrugSearchItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.PrescriptionDrugSearchItemsRequest.displayName = 'proto.drugApi.PrescriptionDrugSearchItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.drugApi.PrescriptionDrugSearchItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.drugApi.PrescriptionDrugSearchItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.PrescriptionDrugSearchItemsResponse.displayName = 'proto.drugApi.PrescriptionDrugSearchItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.drugApi.DrugList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.drugApi.DrugList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.drugApi.DrugList.displayName = 'proto.drugApi.DrugList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.DrugSearchItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.DrugSearchItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.DrugSearchItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugSearchItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    word: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.DrugSearchItemsRequest}
 */
proto.drugApi.DrugSearchItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.DrugSearchItemsRequest;
  return proto.drugApi.DrugSearchItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.DrugSearchItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.DrugSearchItemsRequest}
 */
proto.drugApi.DrugSearchItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.DrugSearchItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.DrugSearchItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.DrugSearchItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugSearchItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.drugApi.DrugSearchItemsRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugSearchItemsRequest} returns this
 */
proto.drugApi.DrugSearchItemsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string word = 2;
 * @return {string}
 */
proto.drugApi.DrugSearchItemsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugSearchItemsRequest} returns this
 */
proto.drugApi.DrugSearchItemsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.drugApi.DrugSearchItemsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.DrugSearchItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.DrugSearchItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugSearchItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.drugApi.DrugProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.DrugSearchItemsResponse}
 */
proto.drugApi.DrugSearchItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.DrugSearchItemsResponse;
  return proto.drugApi.DrugSearchItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.DrugSearchItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.DrugSearchItemsResponse}
 */
proto.drugApi.DrugSearchItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.drugApi.DrugProto;
      reader.readMessage(value,proto.drugApi.DrugProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.DrugSearchItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.DrugSearchItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugSearchItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.drugApi.DrugProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.DrugSearchItemsResponse} returns this
 */
proto.drugApi.DrugSearchItemsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugSearchItemsResponse} returns this
 */
proto.drugApi.DrugSearchItemsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugSearchItemsResponse} returns this
 */
proto.drugApi.DrugSearchItemsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DrugProto drugs = 4;
 * @return {!Array<!proto.drugApi.DrugProto>}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.drugApi.DrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.drugApi.DrugProto, 4));
};


/**
 * @param {!Array<!proto.drugApi.DrugProto>} value
 * @return {!proto.drugApi.DrugSearchItemsResponse} returns this
*/
proto.drugApi.DrugSearchItemsResponse.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.drugApi.DrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.drugApi.DrugProto}
 */
proto.drugApi.DrugSearchItemsResponse.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.drugApi.DrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.drugApi.DrugSearchItemsResponse} returns this
 */
proto.drugApi.DrugSearchItemsResponse.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.DrugProto.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.DrugProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.DrugProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 3, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 4, ""),
    drugAllergy: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    principleAllergy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    allergy: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.DrugProto}
 */
proto.drugApi.DrugProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.DrugProto;
  return proto.drugApi.DrugProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.DrugProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.DrugProto}
 */
proto.drugApi.DrugProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrugAllergy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipleAllergy(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllergy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.DrugProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.DrugProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.DrugProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDrugAllergy();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPrincipleAllergy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAllergy();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.drugApi.DrugProto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string active_principle = 2;
 * @return {string}
 */
proto.drugApi.DrugProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string regulatory_category = 3;
 * @return {string}
 */
proto.drugApi.DrugProto.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string therapeutic_class = 4;
 * @return {string}
 */
proto.drugApi.DrugProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool drug_allergy = 5;
 * @return {boolean}
 */
proto.drugApi.DrugProto.prototype.getDrugAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setDrugAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string principle_allergy = 6;
 * @return {string}
 */
proto.drugApi.DrugProto.prototype.getPrincipleAllergy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setPrincipleAllergy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool allergy = 7;
 * @return {boolean}
 */
proto.drugApi.DrugProto.prototype.getAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.DrugProto} returns this
 */
proto.drugApi.DrugProto.prototype.setAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.DosageSuggestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.DosageSuggestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.DosageSuggestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DosageSuggestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.DosageSuggestionRequest}
 */
proto.drugApi.DosageSuggestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.DosageSuggestionRequest;
  return proto.drugApi.DosageSuggestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.DosageSuggestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.DosageSuggestionRequest}
 */
proto.drugApi.DosageSuggestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.DosageSuggestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.DosageSuggestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.DosageSuggestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DosageSuggestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.drugApi.DosageSuggestionRequest.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DosageSuggestionRequest} returns this
 */
proto.drugApi.DosageSuggestionRequest.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.drugApi.DosageSuggestionResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.DosageSuggestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.DosageSuggestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.DosageSuggestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DosageSuggestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dosagesList: jspb.Message.toObjectList(msg.getDosagesList(),
    proto.drugApi.Suggestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.DosageSuggestionResponse}
 */
proto.drugApi.DosageSuggestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.DosageSuggestionResponse;
  return proto.drugApi.DosageSuggestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.DosageSuggestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.DosageSuggestionResponse}
 */
proto.drugApi.DosageSuggestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.drugApi.Suggestion;
      reader.readMessage(value,proto.drugApi.Suggestion.deserializeBinaryFromReader);
      msg.addDosages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.DosageSuggestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.DosageSuggestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.DosageSuggestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DosageSuggestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDosagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.drugApi.Suggestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.drugApi.DosageSuggestionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.DosageSuggestionResponse} returns this
 */
proto.drugApi.DosageSuggestionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.drugApi.DosageSuggestionResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DosageSuggestionResponse} returns this
 */
proto.drugApi.DosageSuggestionResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.drugApi.DosageSuggestionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DosageSuggestionResponse} returns this
 */
proto.drugApi.DosageSuggestionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Suggestion dosages = 4;
 * @return {!Array<!proto.drugApi.Suggestion>}
 */
proto.drugApi.DosageSuggestionResponse.prototype.getDosagesList = function() {
  return /** @type{!Array<!proto.drugApi.Suggestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.drugApi.Suggestion, 4));
};


/**
 * @param {!Array<!proto.drugApi.Suggestion>} value
 * @return {!proto.drugApi.DosageSuggestionResponse} returns this
*/
proto.drugApi.DosageSuggestionResponse.prototype.setDosagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.drugApi.Suggestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.drugApi.Suggestion}
 */
proto.drugApi.DosageSuggestionResponse.prototype.addDosages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.drugApi.Suggestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.drugApi.DosageSuggestionResponse} returns this
 */
proto.drugApi.DosageSuggestionResponse.prototype.clearDosagesList = function() {
  return this.setDosagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.Suggestion.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.Suggestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.Suggestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.Suggestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    disease: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.Suggestion}
 */
proto.drugApi.Suggestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.Suggestion;
  return proto.drugApi.Suggestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.Suggestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.Suggestion}
 */
proto.drugApi.Suggestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisease(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.Suggestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.Suggestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.Suggestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.Suggestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisease();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.drugApi.Suggestion.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.Suggestion} returns this
 */
proto.drugApi.Suggestion.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string disease = 2;
 * @return {string}
 */
proto.drugApi.Suggestion.prototype.getDisease = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.Suggestion} returns this
 */
proto.drugApi.Suggestion.prototype.setDisease = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string variation = 3;
 * @return {string}
 */
proto.drugApi.Suggestion.prototype.getVariation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.Suggestion} returns this
 */
proto.drugApi.Suggestion.prototype.setVariation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.PrescriptionDrugSearchItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsRequest}
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.PrescriptionDrugSearchItemsRequest;
  return proto.drugApi.PrescriptionDrugSearchItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsRequest}
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.PrescriptionDrugSearchItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsRequest} returns this
 */
proto.drugApi.PrescriptionDrugSearchItemsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.PrescriptionDrugSearchItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.drugApi.DrugList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.PrescriptionDrugSearchItemsResponse;
  return proto.drugApi.PrescriptionDrugSearchItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 5:
      var value = new proto.drugApi.DrugList;
      reader.readMessage(value,proto.drugApi.DrugList.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.PrescriptionDrugSearchItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.drugApi.DrugList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse} returns this
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse} returns this
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse} returns this
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 total = 4;
 * @return {number}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse} returns this
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated DrugList drugs = 5;
 * @return {!Array<!proto.drugApi.DrugList>}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.drugApi.DrugList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.drugApi.DrugList, 5));
};


/**
 * @param {!Array<!proto.drugApi.DrugList>} value
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse} returns this
*/
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.drugApi.DrugList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.drugApi.DrugList}
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.drugApi.DrugList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.drugApi.PrescriptionDrugSearchItemsResponse} returns this
 */
proto.drugApi.PrescriptionDrugSearchItemsResponse.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.drugApi.DrugList.prototype.toObject = function(opt_includeInstance) {
  return proto.drugApi.DrugList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.drugApi.DrugList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugList.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 9, 0),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    category: jspb.Message.getFieldWithDefault(msg, 11, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    drugAllergy: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    principleAllergy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    allergy: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.drugApi.DrugList}
 */
proto.drugApi.DrugList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.drugApi.DrugList;
  return proto.drugApi.DrugList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.drugApi.DrugList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.drugApi.DrugList}
 */
proto.drugApi.DrugList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndicatedAmount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrugAllergy(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipleAllergy(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllergy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.drugApi.DrugList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.drugApi.DrugList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.drugApi.DrugList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.drugApi.DrugList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDrugAllergy();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPrincipleAllergy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAllergy();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string concentration = 4;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pharmaceutical_form = 5;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amount = 6;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string producer = 7;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 number_copies = 9;
 * @return {number}
 */
proto.drugApi.DrugList.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 indicated_amount = 10;
 * @return {number}
 */
proto.drugApi.DrugList.prototype.getIndicatedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string category = 11;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string recommendation = 12;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string therapeutic_class = 13;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool drug_allergy = 14;
 * @return {boolean}
 */
proto.drugApi.DrugList.prototype.getDrugAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setDrugAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string principle_allergy = 15;
 * @return {string}
 */
proto.drugApi.DrugList.prototype.getPrincipleAllergy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setPrincipleAllergy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool allergy = 16;
 * @return {boolean}
 */
proto.drugApi.DrugList.prototype.getAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.drugApi.DrugList} returns this
 */
proto.drugApi.DrugList.prototype.setAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


goog.object.extend(exports, proto.drugApi);
