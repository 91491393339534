// source: health.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.healthApi.AddressInfo', null, global);
goog.exportSymbol('proto.healthApi.AllPdfsRequest', null, global);
goog.exportSymbol('proto.healthApi.AllPdfsResponse', null, global);
goog.exportSymbol('proto.healthApi.AllergyProto', null, global);
goog.exportSymbol('proto.healthApi.AnamnesisProto', null, global);
goog.exportSymbol('proto.healthApi.AnnotationPatient', null, global);
goog.exportSymbol('proto.healthApi.AppointmentPatient', null, global);
goog.exportSymbol('proto.healthApi.BloodPressureProto', null, global);
goog.exportSymbol('proto.healthApi.CategoryProto', null, global);
goog.exportSymbol('proto.healthApi.CidProto', null, global);
goog.exportSymbol('proto.healthApi.CreateAnnotationRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateAnnotationResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateCigaretteRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateCigaretteResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateDrinkRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateDrinkResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateDrugAllergyRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateDrugAllergyResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateDrugInUseProto', null, global);
goog.exportSymbol('proto.healthApi.CreateDrugInUseRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateDrugInUseResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateExerciseRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateExerciseResponse', null, global);
goog.exportSymbol('proto.healthApi.CreatePatientReportRequest', null, global);
goog.exportSymbol('proto.healthApi.CreatePatientReportResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateRequestResultRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateRequestResultResponse', null, global);
goog.exportSymbol('proto.healthApi.CreateResultExamRequest', null, global);
goog.exportSymbol('proto.healthApi.CreateResultExamResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteAnnotationRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteAnnotationResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteCigaretteRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteCigaretteResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteDrinkRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteDrinkResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteDrugAllergyRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteDrugAllergyResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteDrugInUseRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteDrugInUseResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteExamRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteExamResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteExamResultRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteExamResultResponse', null, global);
goog.exportSymbol('proto.healthApi.DeleteExerciseRequest', null, global);
goog.exportSymbol('proto.healthApi.DeleteExerciseResponse', null, global);
goog.exportSymbol('proto.healthApi.DiagnosesProto', null, global);
goog.exportSymbol('proto.healthApi.DiagnosisProto', null, global);
goog.exportSymbol('proto.healthApi.DisplayDrugProto', null, global);
goog.exportSymbol('proto.healthApi.DisplayExamProto', null, global);
goog.exportSymbol('proto.healthApi.DrinkProto', null, global);
goog.exportSymbol('proto.healthApi.DrugAllergyPatient', null, global);
goog.exportSymbol('proto.healthApi.DrugProto', null, global);
goog.exportSymbol('proto.healthApi.DrugTherapeuticClasses', null, global);
goog.exportSymbol('proto.healthApi.DrugsPatient', null, global);
goog.exportSymbol('proto.healthApi.DrugsProto', null, global);
goog.exportSymbol('proto.healthApi.EmptyRequest', null, global);
goog.exportSymbol('proto.healthApi.ExamHistoryProto', null, global);
goog.exportSymbol('proto.healthApi.ExamProto', null, global);
goog.exportSymbol('proto.healthApi.ExamReqProto', null, global);
goog.exportSymbol('proto.healthApi.ExamRequestProto', null, global);
goog.exportSymbol('proto.healthApi.ExamResultProto', null, global);
goog.exportSymbol('proto.healthApi.ExerciseProto', null, global);
goog.exportSymbol('proto.healthApi.ForwardingCidProto', null, global);
goog.exportSymbol('proto.healthApi.ForwardingProto', null, global);
goog.exportSymbol('proto.healthApi.GetDrug', null, global);
goog.exportSymbol('proto.healthApi.GetDrugAllergiesResponse', null, global);
goog.exportSymbol('proto.healthApi.HealthAnnotationPatient', null, global);
goog.exportSymbol('proto.healthApi.HealthPatient', null, global);
goog.exportSymbol('proto.healthApi.HealthPatientHistory', null, global);
goog.exportSymbol('proto.healthApi.HeartRateProto', null, global);
goog.exportSymbol('proto.healthApi.ImageProto', null, global);
goog.exportSymbol('proto.healthApi.InformationProto', null, global);
goog.exportSymbol('proto.healthApi.LifestylePatient', null, global);
goog.exportSymbol('proto.healthApi.LifestyleProto', null, global);
goog.exportSymbol('proto.healthApi.ListAnnotationsResponse', null, global);
goog.exportSymbol('proto.healthApi.ListAppointmentHistoryResponse', null, global);
goog.exportSymbol('proto.healthApi.ListAppointmentRequest', null, global);
goog.exportSymbol('proto.healthApi.ListAppointmentResponse', null, global);
goog.exportSymbol('proto.healthApi.ListCategoryReportResponse', null, global);
goog.exportSymbol('proto.healthApi.ListDrugAllergiesResponse', null, global);
goog.exportSymbol('proto.healthApi.ListDrugsRequest', null, global);
goog.exportSymbol('proto.healthApi.ListDrugsResponse', null, global);
goog.exportSymbol('proto.healthApi.ListExamHistoryResponse', null, global);
goog.exportSymbol('proto.healthApi.ListExamProto', null, global);
goog.exportSymbol('proto.healthApi.ListExamRequest', null, global);
goog.exportSymbol('proto.healthApi.ListExamRequestProto', null, global);
goog.exportSymbol('proto.healthApi.ListExamResponse', null, global);
goog.exportSymbol('proto.healthApi.ListExamResultProto', null, global);
goog.exportSymbol('proto.healthApi.ListHealthInformationResponse', null, global);
goog.exportSymbol('proto.healthApi.ListImagesProto', null, global);
goog.exportSymbol('proto.healthApi.ListLifestylesResponse', null, global);
goog.exportSymbol('proto.healthApi.ListMedicalCareRequest', null, global);
goog.exportSymbol('proto.healthApi.ListMedicalCareResponse', null, global);
goog.exportSymbol('proto.healthApi.ListPdfsRequest', null, global);
goog.exportSymbol('proto.healthApi.ListPdfsResponse', null, global);
goog.exportSymbol('proto.healthApi.ListProfessionalHomeResponse', null, global);
goog.exportSymbol('proto.healthApi.ListRequisitionProto', null, global);
goog.exportSymbol('proto.healthApi.ListRequisitionRequest', null, global);
goog.exportSymbol('proto.healthApi.ListRequisitionResponse', null, global);
goog.exportSymbol('proto.healthApi.ListResultsProto', null, global);
goog.exportSymbol('proto.healthApi.ListViewMoreRequest', null, global);
goog.exportSymbol('proto.healthApi.ListViewMoreResponse', null, global);
goog.exportSymbol('proto.healthApi.MedicalCertificateCidProto', null, global);
goog.exportSymbol('proto.healthApi.MedicalCertificateProto', null, global);
goog.exportSymbol('proto.healthApi.MedicalRecord', null, global);
goog.exportSymbol('proto.healthApi.MedicalRecordRequest', null, global);
goog.exportSymbol('proto.healthApi.MedicalRecordResponse', null, global);
goog.exportSymbol('proto.healthApi.PartnershipProto', null, global);
goog.exportSymbol('proto.healthApi.PatientProto', null, global);
goog.exportSymbol('proto.healthApi.PatientReportProto', null, global);
goog.exportSymbol('proto.healthApi.PausePatientCareRequest', null, global);
goog.exportSymbol('proto.healthApi.PausePatientCareResponse', null, global);
goog.exportSymbol('proto.healthApi.PauseToExamRequest', null, global);
goog.exportSymbol('proto.healthApi.PauseToExamResponse', null, global);
goog.exportSymbol('proto.healthApi.PdfsProto', null, global);
goog.exportSymbol('proto.healthApi.PrescribedProto', null, global);
goog.exportSymbol('proto.healthApi.PrescriptionDrugProto', null, global);
goog.exportSymbol('proto.healthApi.ProfessionalDisplayExamProto', null, global);
goog.exportSymbol('proto.healthApi.RestoreReadDbRequest', null, global);
goog.exportSymbol('proto.healthApi.RestoreReadDbResponse', null, global);
goog.exportSymbol('proto.healthApi.ResultProto', null, global);
goog.exportSymbol('proto.healthApi.ResultsProto', null, global);
goog.exportSymbol('proto.healthApi.SearchDrugAllergiesRequest', null, global);
goog.exportSymbol('proto.healthApi.SearchDrugAllergiesResponse', null, global);
goog.exportSymbol('proto.healthApi.SearchListExamHistoryRequest', null, global);
goog.exportSymbol('proto.healthApi.SearchListExamHistoryResponse', null, global);
goog.exportSymbol('proto.healthApi.SearchListLifestylesRequest', null, global);
goog.exportSymbol('proto.healthApi.SearchListLifestylesResponse', null, global);
goog.exportSymbol('proto.healthApi.SmokerProto', null, global);
goog.exportSymbol('proto.healthApi.SortingProto', null, global);
goog.exportSymbol('proto.healthApi.StartPatientCareRequest', null, global);
goog.exportSymbol('proto.healthApi.StartPatientCareResponse', null, global);
goog.exportSymbol('proto.healthApi.StopPatientCareRequest', null, global);
goog.exportSymbol('proto.healthApi.StopPatientCareResponse', null, global);
goog.exportSymbol('proto.healthApi.TemperatureProto', null, global);
goog.exportSymbol('proto.healthApi.TherapeuticClasses', null, global);
goog.exportSymbol('proto.healthApi.UpdateAnnotationRequest', null, global);
goog.exportSymbol('proto.healthApi.UpdateAnnotationResponse', null, global);
goog.exportSymbol('proto.healthApi.UpdateCigaretteRequest', null, global);
goog.exportSymbol('proto.healthApi.UpdateCigaretteResponse', null, global);
goog.exportSymbol('proto.healthApi.UpdateDrinkRequest', null, global);
goog.exportSymbol('proto.healthApi.UpdateDrinkResponse', null, global);
goog.exportSymbol('proto.healthApi.UpdateDrugAllergyRequest', null, global);
goog.exportSymbol('proto.healthApi.UpdateDrugAllergyResponse', null, global);
goog.exportSymbol('proto.healthApi.UpdateExerciseRequest', null, global);
goog.exportSymbol('proto.healthApi.UpdateExerciseResponse', null, global);
goog.exportSymbol('proto.healthApi.UpdateImageProto', null, global);
goog.exportSymbol('proto.healthApi.UpdateResultExamRequest', null, global);
goog.exportSymbol('proto.healthApi.UpdateResultExamResponse', null, global);
goog.exportSymbol('proto.healthApi.UpdateResultProto', null, global);
goog.exportSymbol('proto.healthApi.VerifyAllergyRequest', null, global);
goog.exportSymbol('proto.healthApi.VerifyAllergyResponse', null, global);
goog.exportSymbol('proto.healthApi.VerifyDrug', null, global);
goog.exportSymbol('proto.healthApi.ViewMore', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListViewMoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListViewMoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListViewMoreRequest.displayName = 'proto.healthApi.ListViewMoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListViewMoreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListViewMoreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListViewMoreResponse.displayName = 'proto.healthApi.ListViewMoreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ViewMore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ViewMore.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ViewMore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ViewMore.displayName = 'proto.healthApi.ViewMore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PatientProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PatientProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PatientProto.displayName = 'proto.healthApi.PatientProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DiagnosesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DiagnosesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DiagnosesProto.displayName = 'proto.healthApi.DiagnosesProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PatientReportProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PatientReportProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PatientReportProto.displayName = 'proto.healthApi.PatientReportProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreatePatientReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreatePatientReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreatePatientReportRequest.displayName = 'proto.healthApi.CreatePatientReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreatePatientReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreatePatientReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreatePatientReportResponse.displayName = 'proto.healthApi.CreatePatientReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListCategoryReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListCategoryReportResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListCategoryReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListCategoryReportResponse.displayName = 'proto.healthApi.ListCategoryReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CategoryProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CategoryProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CategoryProto.displayName = 'proto.healthApi.CategoryProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListDrugsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListDrugsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListDrugsRequest.displayName = 'proto.healthApi.ListDrugsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AllPdfsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AllPdfsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AllPdfsRequest.displayName = 'proto.healthApi.AllPdfsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AllPdfsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AllPdfsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AllPdfsResponse.displayName = 'proto.healthApi.AllPdfsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListMedicalCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListMedicalCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListMedicalCareRequest.displayName = 'proto.healthApi.ListMedicalCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListMedicalCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListMedicalCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListMedicalCareResponse.displayName = 'proto.healthApi.ListMedicalCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.MedicalRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.MedicalRecord.repeatedFields_, null);
};
goog.inherits(proto.healthApi.MedicalRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.MedicalRecord.displayName = 'proto.healthApi.MedicalRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.MedicalRecordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.MedicalRecordRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.MedicalRecordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.MedicalRecordRequest.displayName = 'proto.healthApi.MedicalRecordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.MedicalRecordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.MedicalRecordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.MedicalRecordResponse.displayName = 'proto.healthApi.MedicalRecordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PausePatientCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PausePatientCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PausePatientCareRequest.displayName = 'proto.healthApi.PausePatientCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PausePatientCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PausePatientCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PausePatientCareResponse.displayName = 'proto.healthApi.PausePatientCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PauseToExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PauseToExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PauseToExamRequest.displayName = 'proto.healthApi.PauseToExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PauseToExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PauseToExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PauseToExamResponse.displayName = 'proto.healthApi.PauseToExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListPdfsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListPdfsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListPdfsRequest.displayName = 'proto.healthApi.ListPdfsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListPdfsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListPdfsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListPdfsResponse.displayName = 'proto.healthApi.ListPdfsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PdfsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.PdfsProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.PdfsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PdfsProto.displayName = 'proto.healthApi.PdfsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PrescribedProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PrescribedProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PrescribedProto.displayName = 'proto.healthApi.PrescribedProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AddressInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AddressInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AddressInfo.displayName = 'proto.healthApi.AddressInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListProfessionalHomeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListProfessionalHomeResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListProfessionalHomeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListProfessionalHomeResponse.displayName = 'proto.healthApi.ListProfessionalHomeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ProfessionalDisplayExamProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ProfessionalDisplayExamProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ProfessionalDisplayExamProto.displayName = 'proto.healthApi.ProfessionalDisplayExamProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DisplayExamProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DisplayExamProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DisplayExamProto.displayName = 'proto.healthApi.DisplayExamProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DisplayDrugProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DisplayDrugProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DisplayDrugProto.displayName = 'proto.healthApi.DisplayDrugProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListHealthInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListHealthInformationResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListHealthInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListHealthInformationResponse.displayName = 'proto.healthApi.ListHealthInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.VerifyAllergyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.VerifyAllergyRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.VerifyAllergyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.VerifyAllergyRequest.displayName = 'proto.healthApi.VerifyAllergyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.VerifyDrug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.VerifyDrug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.VerifyDrug.displayName = 'proto.healthApi.VerifyDrug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.VerifyAllergyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.VerifyAllergyResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.VerifyAllergyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.VerifyAllergyResponse.displayName = 'proto.healthApi.VerifyAllergyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AllergyProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AllergyProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AllergyProto.displayName = 'proto.healthApi.AllergyProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.GetDrugAllergiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.GetDrugAllergiesResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.GetDrugAllergiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.GetDrugAllergiesResponse.displayName = 'proto.healthApi.GetDrugAllergiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.GetDrug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.GetDrug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.GetDrug.displayName = 'proto.healthApi.GetDrug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateResultExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.CreateResultExamRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.CreateResultExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateResultExamRequest.displayName = 'proto.healthApi.CreateResultExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateResultExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateResultExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateResultExamResponse.displayName = 'proto.healthApi.CreateResultExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateDrinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrinkRequest.displayName = 'proto.healthApi.CreateDrinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateDrinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrinkResponse.displayName = 'proto.healthApi.CreateDrinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListAppointmentRequest.displayName = 'proto.healthApi.ListAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListAppointmentResponse.displayName = 'proto.healthApi.ListAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.HealthPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.HealthPatient.repeatedFields_, null);
};
goog.inherits(proto.healthApi.HealthPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.HealthPatient.displayName = 'proto.healthApi.HealthPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AppointmentPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AppointmentPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AppointmentPatient.displayName = 'proto.healthApi.AppointmentPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DrugAllergyPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DrugAllergyPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DrugAllergyPatient.displayName = 'proto.healthApi.DrugAllergyPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DrugsPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DrugsPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DrugsPatient.displayName = 'proto.healthApi.DrugsPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.LifestylePatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.LifestylePatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.LifestylePatient.displayName = 'proto.healthApi.LifestylePatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.EmptyRequest.displayName = 'proto.healthApi.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListAppointmentHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListAppointmentHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListAppointmentHistoryResponse.displayName = 'proto.healthApi.ListAppointmentHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.HealthPatientHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.HealthPatientHistory.repeatedFields_, null);
};
goog.inherits(proto.healthApi.HealthPatientHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.HealthPatientHistory.displayName = 'proto.healthApi.HealthPatientHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListAnnotationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListAnnotationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListAnnotationsResponse.displayName = 'proto.healthApi.ListAnnotationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.HealthAnnotationPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.HealthAnnotationPatient.repeatedFields_, null);
};
goog.inherits(proto.healthApi.HealthAnnotationPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.HealthAnnotationPatient.displayName = 'proto.healthApi.HealthAnnotationPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AnnotationPatient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AnnotationPatient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AnnotationPatient.displayName = 'proto.healthApi.AnnotationPatient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListDrugAllergiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListDrugAllergiesResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListDrugAllergiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListDrugAllergiesResponse.displayName = 'proto.healthApi.ListDrugAllergiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.TherapeuticClasses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.TherapeuticClasses.repeatedFields_, null);
};
goog.inherits(proto.healthApi.TherapeuticClasses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.TherapeuticClasses.displayName = 'proto.healthApi.TherapeuticClasses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DrugProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DrugProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DrugProto.displayName = 'proto.healthApi.DrugProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.RestoreReadDbRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.RestoreReadDbRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.RestoreReadDbRequest.displayName = 'proto.healthApi.RestoreReadDbRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.RestoreReadDbResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.RestoreReadDbResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.RestoreReadDbResponse.displayName = 'proto.healthApi.RestoreReadDbResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrugAllergyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateDrugAllergyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrugAllergyRequest.displayName = 'proto.healthApi.CreateDrugAllergyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrugAllergyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateDrugAllergyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrugAllergyResponse.displayName = 'proto.healthApi.CreateDrugAllergyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteDrugAllergyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteDrugAllergyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteDrugAllergyRequest.displayName = 'proto.healthApi.DeleteDrugAllergyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteDrugAllergyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteDrugAllergyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteDrugAllergyResponse.displayName = 'proto.healthApi.DeleteDrugAllergyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateDrugAllergyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateDrugAllergyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateDrugAllergyRequest.displayName = 'proto.healthApi.UpdateDrugAllergyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateDrugAllergyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateDrugAllergyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateDrugAllergyResponse.displayName = 'proto.healthApi.UpdateDrugAllergyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateAnnotationRequest.displayName = 'proto.healthApi.CreateAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateAnnotationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateAnnotationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateAnnotationResponse.displayName = 'proto.healthApi.CreateAnnotationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateAnnotationRequest.displayName = 'proto.healthApi.UpdateAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateAnnotationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateAnnotationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateAnnotationResponse.displayName = 'proto.healthApi.UpdateAnnotationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteAnnotationRequest.displayName = 'proto.healthApi.DeleteAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteAnnotationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteAnnotationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteAnnotationResponse.displayName = 'proto.healthApi.DeleteAnnotationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.StartPatientCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.StartPatientCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.StartPatientCareRequest.displayName = 'proto.healthApi.StartPatientCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.StartPatientCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.StartPatientCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.StartPatientCareResponse.displayName = 'proto.healthApi.StartPatientCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.StopPatientCareRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.StopPatientCareRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.StopPatientCareRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.StopPatientCareRequest.displayName = 'proto.healthApi.StopPatientCareRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.MedicalCertificateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.MedicalCertificateProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.MedicalCertificateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.MedicalCertificateProto.displayName = 'proto.healthApi.MedicalCertificateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.MedicalCertificateCidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.MedicalCertificateCidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.MedicalCertificateCidProto.displayName = 'proto.healthApi.MedicalCertificateCidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ForwardingProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ForwardingProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ForwardingProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ForwardingProto.displayName = 'proto.healthApi.ForwardingProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ForwardingCidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ForwardingCidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ForwardingCidProto.displayName = 'proto.healthApi.ForwardingCidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PartnershipProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PartnershipProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PartnershipProto.displayName = 'proto.healthApi.PartnershipProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.PrescriptionDrugProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.PrescriptionDrugProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.PrescriptionDrugProto.displayName = 'proto.healthApi.PrescriptionDrugProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ExamRequestProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ExamRequestProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ExamRequestProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ExamRequestProto.displayName = 'proto.healthApi.ExamRequestProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ExamReqProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ExamReqProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ExamReqProto.displayName = 'proto.healthApi.ExamReqProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.AnamnesisProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.AnamnesisProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.AnamnesisProto.displayName = 'proto.healthApi.AnamnesisProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DiagnosisProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.DiagnosisProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.DiagnosisProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DiagnosisProto.displayName = 'proto.healthApi.DiagnosisProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CidProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CidProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CidProto.displayName = 'proto.healthApi.CidProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SortingProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.SortingProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SortingProto.displayName = 'proto.healthApi.SortingProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.BloodPressureProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.BloodPressureProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.BloodPressureProto.displayName = 'proto.healthApi.BloodPressureProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.HeartRateProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.HeartRateProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.HeartRateProto.displayName = 'proto.healthApi.HeartRateProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.TemperatureProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.TemperatureProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.TemperatureProto.displayName = 'proto.healthApi.TemperatureProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.StopPatientCareResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.StopPatientCareResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.StopPatientCareResponse.displayName = 'proto.healthApi.StopPatientCareResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrugInUseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.CreateDrugInUseRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.CreateDrugInUseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrugInUseRequest.displayName = 'proto.healthApi.CreateDrugInUseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrugInUseProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateDrugInUseProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrugInUseProto.displayName = 'proto.healthApi.CreateDrugInUseProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateDrugInUseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateDrugInUseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateDrugInUseResponse.displayName = 'proto.healthApi.CreateDrugInUseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteDrugInUseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteDrugInUseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteDrugInUseRequest.displayName = 'proto.healthApi.DeleteDrugInUseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteDrugInUseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteDrugInUseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteDrugInUseResponse.displayName = 'proto.healthApi.DeleteDrugInUseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListDrugsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListDrugsResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListDrugsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListDrugsResponse.displayName = 'proto.healthApi.ListDrugsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListLifestylesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListLifestylesResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListLifestylesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListLifestylesResponse.displayName = 'proto.healthApi.ListLifestylesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.LifestyleProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.LifestyleProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.LifestyleProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.LifestyleProto.displayName = 'proto.healthApi.LifestyleProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ExerciseProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ExerciseProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ExerciseProto.displayName = 'proto.healthApi.ExerciseProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DrinkProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DrinkProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DrinkProto.displayName = 'proto.healthApi.DrinkProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SmokerProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.SmokerProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SmokerProto.displayName = 'proto.healthApi.SmokerProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DrugTherapeuticClasses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.DrugTherapeuticClasses.repeatedFields_, null);
};
goog.inherits(proto.healthApi.DrugTherapeuticClasses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DrugTherapeuticClasses.displayName = 'proto.healthApi.DrugTherapeuticClasses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DrugsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DrugsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DrugsProto.displayName = 'proto.healthApi.DrugsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateDrinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateDrinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateDrinkRequest.displayName = 'proto.healthApi.UpdateDrinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateDrinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateDrinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateDrinkResponse.displayName = 'proto.healthApi.UpdateDrinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteDrinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteDrinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteDrinkRequest.displayName = 'proto.healthApi.DeleteDrinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteDrinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteDrinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteDrinkResponse.displayName = 'proto.healthApi.DeleteDrinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateExerciseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateExerciseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateExerciseRequest.displayName = 'proto.healthApi.CreateExerciseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateExerciseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateExerciseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateExerciseResponse.displayName = 'proto.healthApi.CreateExerciseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateExerciseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateExerciseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateExerciseRequest.displayName = 'proto.healthApi.UpdateExerciseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateExerciseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateExerciseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateExerciseResponse.displayName = 'proto.healthApi.UpdateExerciseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteExerciseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteExerciseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteExerciseRequest.displayName = 'proto.healthApi.DeleteExerciseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteExerciseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteExerciseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteExerciseResponse.displayName = 'proto.healthApi.DeleteExerciseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateCigaretteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateCigaretteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateCigaretteRequest.displayName = 'proto.healthApi.CreateCigaretteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateCigaretteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateCigaretteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateCigaretteResponse.displayName = 'proto.healthApi.CreateCigaretteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateCigaretteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateCigaretteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateCigaretteRequest.displayName = 'proto.healthApi.UpdateCigaretteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateCigaretteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateCigaretteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateCigaretteResponse.displayName = 'proto.healthApi.UpdateCigaretteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteCigaretteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteCigaretteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteCigaretteRequest.displayName = 'proto.healthApi.DeleteCigaretteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteCigaretteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteCigaretteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteCigaretteResponse.displayName = 'proto.healthApi.DeleteCigaretteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ResultsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ResultsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ResultsProto.displayName = 'proto.healthApi.ResultsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SearchDrugAllergiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.SearchDrugAllergiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SearchDrugAllergiesRequest.displayName = 'proto.healthApi.SearchDrugAllergiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SearchDrugAllergiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.SearchDrugAllergiesResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.SearchDrugAllergiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SearchDrugAllergiesResponse.displayName = 'proto.healthApi.SearchDrugAllergiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateResultExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.UpdateResultExamRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.UpdateResultExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateResultExamRequest.displayName = 'proto.healthApi.UpdateResultExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateResultProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateResultProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateResultProto.displayName = 'proto.healthApi.UpdateResultProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateImageProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateImageProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateImageProto.displayName = 'proto.healthApi.UpdateImageProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.UpdateResultExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.UpdateResultExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.UpdateResultExamResponse.displayName = 'proto.healthApi.UpdateResultExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateRequestResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.CreateRequestResultRequest.repeatedFields_, null);
};
goog.inherits(proto.healthApi.CreateRequestResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateRequestResultRequest.displayName = 'proto.healthApi.CreateRequestResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ExamProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ExamProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ExamProto.displayName = 'proto.healthApi.ExamProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ExamResultProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ExamResultProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ExamResultProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ExamResultProto.displayName = 'proto.healthApi.ExamResultProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ResultProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ResultProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ResultProto.displayName = 'proto.healthApi.ResultProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ImageProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ImageProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ImageProto.displayName = 'proto.healthApi.ImageProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.CreateRequestResultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.CreateRequestResultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.CreateRequestResultResponse.displayName = 'proto.healthApi.CreateRequestResultResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListExamHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListExamHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListExamHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListExamHistoryResponse.displayName = 'proto.healthApi.ListExamHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ExamHistoryProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ExamHistoryProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ExamHistoryProto.displayName = 'proto.healthApi.ExamHistoryProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListExamRequest.displayName = 'proto.healthApi.ListExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListExamResponse.displayName = 'proto.healthApi.ListExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListExamRequestProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListExamRequestProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListExamRequestProto.displayName = 'proto.healthApi.ListExamRequestProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.InformationProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.InformationProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.InformationProto.displayName = 'proto.healthApi.InformationProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListExamProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListExamProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListExamProto.displayName = 'proto.healthApi.ListExamProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListExamResultProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListExamResultProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListExamResultProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListExamResultProto.displayName = 'proto.healthApi.ListExamResultProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListImagesProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListImagesProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListImagesProto.displayName = 'proto.healthApi.ListImagesProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListResultsProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListResultsProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListResultsProto.displayName = 'proto.healthApi.ListResultsProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListRequisitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListRequisitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListRequisitionRequest.displayName = 'proto.healthApi.ListRequisitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListRequisitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.ListRequisitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListRequisitionResponse.displayName = 'proto.healthApi.ListRequisitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.ListRequisitionProto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.ListRequisitionProto.repeatedFields_, null);
};
goog.inherits(proto.healthApi.ListRequisitionProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.ListRequisitionProto.displayName = 'proto.healthApi.ListRequisitionProto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteExamRequest.displayName = 'proto.healthApi.DeleteExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteExamResponse.displayName = 'proto.healthApi.DeleteExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteExamResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteExamResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteExamResultRequest.displayName = 'proto.healthApi.DeleteExamResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.DeleteExamResultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.DeleteExamResultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.DeleteExamResultResponse.displayName = 'proto.healthApi.DeleteExamResultResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SearchListExamHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.SearchListExamHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SearchListExamHistoryRequest.displayName = 'proto.healthApi.SearchListExamHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SearchListExamHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.SearchListExamHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.SearchListExamHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SearchListExamHistoryResponse.displayName = 'proto.healthApi.SearchListExamHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SearchListLifestylesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.healthApi.SearchListLifestylesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SearchListLifestylesRequest.displayName = 'proto.healthApi.SearchListLifestylesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.healthApi.SearchListLifestylesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.healthApi.SearchListLifestylesResponse.repeatedFields_, null);
};
goog.inherits(proto.healthApi.SearchListLifestylesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.healthApi.SearchListLifestylesResponse.displayName = 'proto.healthApi.SearchListLifestylesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListViewMoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListViewMoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListViewMoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListViewMoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListViewMoreRequest}
 */
proto.healthApi.ListViewMoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListViewMoreRequest;
  return proto.healthApi.ListViewMoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListViewMoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListViewMoreRequest}
 */
proto.healthApi.ListViewMoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListViewMoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListViewMoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListViewMoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListViewMoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.ListViewMoreRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListViewMoreRequest} returns this
 */
proto.healthApi.ListViewMoreRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListViewMoreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListViewMoreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListViewMoreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListViewMoreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    view: (f = msg.getView()) && proto.healthApi.ViewMore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListViewMoreResponse}
 */
proto.healthApi.ListViewMoreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListViewMoreResponse;
  return proto.healthApi.ListViewMoreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListViewMoreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListViewMoreResponse}
 */
proto.healthApi.ListViewMoreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.ViewMore;
      reader.readMessage(value,proto.healthApi.ViewMore.deserializeBinaryFromReader);
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListViewMoreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListViewMoreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListViewMoreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListViewMoreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getView();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.ViewMore.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListViewMoreResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListViewMoreResponse} returns this
 */
proto.healthApi.ListViewMoreResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListViewMoreResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListViewMoreResponse} returns this
 */
proto.healthApi.ListViewMoreResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListViewMoreResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListViewMoreResponse} returns this
 */
proto.healthApi.ListViewMoreResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ViewMore view = 4;
 * @return {?proto.healthApi.ViewMore}
 */
proto.healthApi.ListViewMoreResponse.prototype.getView = function() {
  return /** @type{?proto.healthApi.ViewMore} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ViewMore, 4));
};


/**
 * @param {?proto.healthApi.ViewMore|undefined} value
 * @return {!proto.healthApi.ListViewMoreResponse} returns this
*/
proto.healthApi.ListViewMoreResponse.prototype.setView = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListViewMoreResponse} returns this
 */
proto.healthApi.ListViewMoreResponse.prototype.clearView = function() {
  return this.setView(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListViewMoreResponse.prototype.hasView = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ViewMore.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ViewMore.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ViewMore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ViewMore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ViewMore.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    healthRecordId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chiefComplaint: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.healthApi.PatientReportProto.toObject, includeInstance),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.DrugsPatient.toObject, includeInstance),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.healthApi.DiagnosesProto.toObject, includeInstance),
    patient: (f = msg.getPatient()) && proto.healthApi.PatientProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ViewMore}
 */
proto.healthApi.ViewMore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ViewMore;
  return proto.healthApi.ViewMore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ViewMore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ViewMore}
 */
proto.healthApi.ViewMore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthRecordId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChiefComplaint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new proto.healthApi.PatientReportProto;
      reader.readMessage(value,proto.healthApi.PatientReportProto.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    case 7:
      var value = new proto.healthApi.DrugsPatient;
      reader.readMessage(value,proto.healthApi.DrugsPatient.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    case 8:
      var value = new proto.healthApi.DiagnosesProto;
      reader.readMessage(value,proto.healthApi.DiagnosesProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    case 9:
      var value = new proto.healthApi.PatientProto;
      reader.readMessage(value,proto.healthApi.PatientProto.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ViewMore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ViewMore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ViewMore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ViewMore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHealthRecordId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChiefComplaint();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.healthApi.PatientReportProto.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.healthApi.DrugsPatient.serializeBinaryToWriter
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.healthApi.DiagnosesProto.serializeBinaryToWriter
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.healthApi.PatientProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.ViewMore.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string health_record_id = 2;
 * @return {string}
 */
proto.healthApi.ViewMore.prototype.getHealthRecordId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.setHealthRecordId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patient_id = 3;
 * @return {string}
 */
proto.healthApi.ViewMore.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string chief_complaint = 4;
 * @return {string}
 */
proto.healthApi.ViewMore.prototype.getChiefComplaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.setChiefComplaint = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.healthApi.ViewMore.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated PatientReportProto reports = 6;
 * @return {!Array<!proto.healthApi.PatientReportProto>}
 */
proto.healthApi.ViewMore.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.healthApi.PatientReportProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PatientReportProto, 6));
};


/**
 * @param {!Array<!proto.healthApi.PatientReportProto>} value
 * @return {!proto.healthApi.ViewMore} returns this
*/
proto.healthApi.ViewMore.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.healthApi.PatientReportProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PatientReportProto}
 */
proto.healthApi.ViewMore.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.healthApi.PatientReportProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};


/**
 * repeated DrugsPatient drugs = 7;
 * @return {!Array<!proto.healthApi.DrugsPatient>}
 */
proto.healthApi.ViewMore.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.DrugsPatient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrugsPatient, 7));
};


/**
 * @param {!Array<!proto.healthApi.DrugsPatient>} value
 * @return {!proto.healthApi.ViewMore} returns this
*/
proto.healthApi.ViewMore.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.healthApi.DrugsPatient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrugsPatient}
 */
proto.healthApi.ViewMore.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.healthApi.DrugsPatient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};


/**
 * repeated DiagnosesProto diagnoses = 8;
 * @return {!Array<!proto.healthApi.DiagnosesProto>}
 */
proto.healthApi.ViewMore.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.healthApi.DiagnosesProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DiagnosesProto, 8));
};


/**
 * @param {!Array<!proto.healthApi.DiagnosesProto>} value
 * @return {!proto.healthApi.ViewMore} returns this
*/
proto.healthApi.ViewMore.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.healthApi.DiagnosesProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DiagnosesProto}
 */
proto.healthApi.ViewMore.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.healthApi.DiagnosesProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};


/**
 * optional PatientProto patient = 9;
 * @return {?proto.healthApi.PatientProto}
 */
proto.healthApi.ViewMore.prototype.getPatient = function() {
  return /** @type{?proto.healthApi.PatientProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.PatientProto, 9));
};


/**
 * @param {?proto.healthApi.PatientProto|undefined} value
 * @return {!proto.healthApi.ViewMore} returns this
*/
proto.healthApi.ViewMore.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ViewMore} returns this
 */
proto.healthApi.ViewMore.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ViewMore.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PatientProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PatientProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PatientProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PatientProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    birthDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    motherName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fatherName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pictureFileId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cisgender: jspb.Message.getFieldWithDefault(msg, 10, ""),
    civilStatus: jspb.Message.getFieldWithDefault(msg, 11, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    organDonor: jspb.Message.getFieldWithDefault(msg, 13, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 14, ""),
    bloodType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    rhFactor: jspb.Message.getFieldWithDefault(msg, 16, ""),
    socialSecurityNumber: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PatientProto}
 */
proto.healthApi.PatientProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PatientProto;
  return proto.healthApi.PatientProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PatientProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PatientProto}
 */
proto.healthApi.PatientProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMotherName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFatherName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureFileId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCisgender(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCivilStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganDonor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBloodType(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRhFactor(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialSecurityNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PatientProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PatientProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PatientProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PatientProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBirthDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMotherName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFatherName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPictureFileId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCisgender();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCivilStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrganDonor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBloodType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRhFactor();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSocialSecurityNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string patient_id = 1;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string birth_date = 4;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getBirthDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setBirthDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mother_name = 5;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getMotherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setMotherName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string father_name = 6;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getFatherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setFatherName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string picture_file_id = 7;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getPictureFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setPictureFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string picture_url = 8;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string gender = 9;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cisgender = 10;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getCisgender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setCisgender = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string civil_status = 11;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getCivilStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setCivilStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string nickname = 12;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string organ_donor = 13;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getOrganDonor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setOrganDonor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string occupation = 14;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string blood_type = 15;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getBloodType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setBloodType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string rh_factor = 16;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getRhFactor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setRhFactor = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string social_security_number = 17;
 * @return {string}
 */
proto.healthApi.PatientProto.prototype.getSocialSecurityNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientProto} returns this
 */
proto.healthApi.PatientProto.prototype.setSocialSecurityNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DiagnosesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DiagnosesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DiagnosesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DiagnosesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DiagnosesProto}
 */
proto.healthApi.DiagnosesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DiagnosesProto;
  return proto.healthApi.DiagnosesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DiagnosesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DiagnosesProto}
 */
proto.healthApi.DiagnosesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DiagnosesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DiagnosesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DiagnosesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DiagnosesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.healthApi.DiagnosesProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DiagnosesProto} returns this
 */
proto.healthApi.DiagnosesProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orientation = 2;
 * @return {string}
 */
proto.healthApi.DiagnosesProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DiagnosesProto} returns this
 */
proto.healthApi.DiagnosesProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PatientReportProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PatientReportProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PatientReportProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PatientReportProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    patientReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryReportId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    category: jspb.Message.getFieldWithDefault(msg, 3, ""),
    color: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PatientReportProto}
 */
proto.healthApi.PatientReportProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PatientReportProto;
  return proto.healthApi.PatientReportProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PatientReportProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PatientReportProto}
 */
proto.healthApi.PatientReportProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientReportId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryReportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PatientReportProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PatientReportProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PatientReportProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PatientReportProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPatientReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryReportId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string patient_report_id = 1;
 * @return {string}
 */
proto.healthApi.PatientReportProto.prototype.getPatientReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientReportProto} returns this
 */
proto.healthApi.PatientReportProto.prototype.setPatientReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 category_report_id = 2;
 * @return {number}
 */
proto.healthApi.PatientReportProto.prototype.getCategoryReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.PatientReportProto} returns this
 */
proto.healthApi.PatientReportProto.prototype.setCategoryReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string category = 3;
 * @return {string}
 */
proto.healthApi.PatientReportProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientReportProto} returns this
 */
proto.healthApi.PatientReportProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string color = 4;
 * @return {string}
 */
proto.healthApi.PatientReportProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientReportProto} returns this
 */
proto.healthApi.PatientReportProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.healthApi.PatientReportProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientReportProto} returns this
 */
proto.healthApi.PatientReportProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.healthApi.PatientReportProto.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PatientReportProto} returns this
 */
proto.healthApi.PatientReportProto.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreatePatientReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreatePatientReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreatePatientReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreatePatientReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryReportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreatePatientReportRequest}
 */
proto.healthApi.CreatePatientReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreatePatientReportRequest;
  return proto.healthApi.CreatePatientReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreatePatientReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreatePatientReportRequest}
 */
proto.healthApi.CreatePatientReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreatePatientReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreatePatientReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreatePatientReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreatePatientReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryReportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 category_report_id = 1;
 * @return {number}
 */
proto.healthApi.CreatePatientReportRequest.prototype.getCategoryReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.CreatePatientReportRequest} returns this
 */
proto.healthApi.CreatePatientReportRequest.prototype.setCategoryReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.CreatePatientReportRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreatePatientReportRequest} returns this
 */
proto.healthApi.CreatePatientReportRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreatePatientReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreatePatientReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreatePatientReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreatePatientReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreatePatientReportResponse}
 */
proto.healthApi.CreatePatientReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreatePatientReportResponse;
  return proto.healthApi.CreatePatientReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreatePatientReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreatePatientReportResponse}
 */
proto.healthApi.CreatePatientReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreatePatientReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreatePatientReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreatePatientReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreatePatientReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreatePatientReportResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreatePatientReportResponse} returns this
 */
proto.healthApi.CreatePatientReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreatePatientReportResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreatePatientReportResponse} returns this
 */
proto.healthApi.CreatePatientReportResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreatePatientReportResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreatePatientReportResponse} returns this
 */
proto.healthApi.CreatePatientReportResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListCategoryReportResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListCategoryReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListCategoryReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListCategoryReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListCategoryReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.healthApi.CategoryProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListCategoryReportResponse}
 */
proto.healthApi.ListCategoryReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListCategoryReportResponse;
  return proto.healthApi.ListCategoryReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListCategoryReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListCategoryReportResponse}
 */
proto.healthApi.ListCategoryReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.CategoryProto;
      reader.readMessage(value,proto.healthApi.CategoryProto.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListCategoryReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListCategoryReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListCategoryReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListCategoryReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.CategoryProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListCategoryReportResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListCategoryReportResponse} returns this
 */
proto.healthApi.ListCategoryReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListCategoryReportResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListCategoryReportResponse} returns this
 */
proto.healthApi.ListCategoryReportResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListCategoryReportResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListCategoryReportResponse} returns this
 */
proto.healthApi.ListCategoryReportResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CategoryProto categories = 4;
 * @return {!Array<!proto.healthApi.CategoryProto>}
 */
proto.healthApi.ListCategoryReportResponse.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.healthApi.CategoryProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.CategoryProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.CategoryProto>} value
 * @return {!proto.healthApi.ListCategoryReportResponse} returns this
*/
proto.healthApi.ListCategoryReportResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.CategoryProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.CategoryProto}
 */
proto.healthApi.ListCategoryReportResponse.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.CategoryProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListCategoryReportResponse} returns this
 */
proto.healthApi.ListCategoryReportResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CategoryProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CategoryProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CategoryProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CategoryProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryReportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    colot: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CategoryProto}
 */
proto.healthApi.CategoryProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CategoryProto;
  return proto.healthApi.CategoryProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CategoryProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CategoryProto}
 */
proto.healthApi.CategoryProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CategoryProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CategoryProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CategoryProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CategoryProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryReportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColot();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 category_report_id = 1;
 * @return {number}
 */
proto.healthApi.CategoryProto.prototype.getCategoryReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.CategoryProto} returns this
 */
proto.healthApi.CategoryProto.prototype.setCategoryReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.CategoryProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CategoryProto} returns this
 */
proto.healthApi.CategoryProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string colot = 3;
 * @return {string}
 */
proto.healthApi.CategoryProto.prototype.getColot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CategoryProto} returns this
 */
proto.healthApi.CategoryProto.prototype.setColot = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListDrugsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListDrugsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListDrugsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListDrugsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListDrugsRequest}
 */
proto.healthApi.ListDrugsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListDrugsRequest;
  return proto.healthApi.ListDrugsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListDrugsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListDrugsRequest}
 */
proto.healthApi.ListDrugsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListDrugsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListDrugsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListDrugsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListDrugsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.healthApi.ListDrugsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListDrugsRequest} returns this
 */
proto.healthApi.ListDrugsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AllPdfsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AllPdfsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AllPdfsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AllPdfsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AllPdfsRequest}
 */
proto.healthApi.AllPdfsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AllPdfsRequest;
  return proto.healthApi.AllPdfsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AllPdfsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AllPdfsRequest}
 */
proto.healthApi.AllPdfsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AllPdfsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AllPdfsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AllPdfsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AllPdfsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.AllPdfsRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AllPdfsRequest} returns this
 */
proto.healthApi.AllPdfsRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AllPdfsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AllPdfsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AllPdfsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AllPdfsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pdf: (f = msg.getPdf()) && proto.healthApi.PrescribedProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AllPdfsResponse}
 */
proto.healthApi.AllPdfsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AllPdfsResponse;
  return proto.healthApi.AllPdfsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AllPdfsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AllPdfsResponse}
 */
proto.healthApi.AllPdfsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.healthApi.PrescribedProto;
      reader.readMessage(value,proto.healthApi.PrescribedProto.deserializeBinaryFromReader);
      msg.setPdf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AllPdfsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AllPdfsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AllPdfsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AllPdfsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPdf();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.healthApi.PrescribedProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.AllPdfsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.AllPdfsResponse} returns this
 */
proto.healthApi.AllPdfsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.healthApi.AllPdfsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AllPdfsResponse} returns this
 */
proto.healthApi.AllPdfsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PrescribedProto pdf = 3;
 * @return {?proto.healthApi.PrescribedProto}
 */
proto.healthApi.AllPdfsResponse.prototype.getPdf = function() {
  return /** @type{?proto.healthApi.PrescribedProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.PrescribedProto, 3));
};


/**
 * @param {?proto.healthApi.PrescribedProto|undefined} value
 * @return {!proto.healthApi.AllPdfsResponse} returns this
*/
proto.healthApi.AllPdfsResponse.prototype.setPdf = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.AllPdfsResponse} returns this
 */
proto.healthApi.AllPdfsResponse.prototype.clearPdf = function() {
  return this.setPdf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.AllPdfsResponse.prototype.hasPdf = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListMedicalCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListMedicalCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListMedicalCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListMedicalCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListMedicalCareRequest}
 */
proto.healthApi.ListMedicalCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListMedicalCareRequest;
  return proto.healthApi.ListMedicalCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListMedicalCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListMedicalCareRequest}
 */
proto.healthApi.ListMedicalCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListMedicalCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListMedicalCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListMedicalCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListMedicalCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.ListMedicalCareRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListMedicalCareRequest} returns this
 */
proto.healthApi.ListMedicalCareRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListMedicalCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListMedicalCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListMedicalCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListMedicalCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appointment: (f = msg.getAppointment()) && proto.healthApi.MedicalRecord.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListMedicalCareResponse}
 */
proto.healthApi.ListMedicalCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListMedicalCareResponse;
  return proto.healthApi.ListMedicalCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListMedicalCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListMedicalCareResponse}
 */
proto.healthApi.ListMedicalCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.MedicalRecord;
      reader.readMessage(value,proto.healthApi.MedicalRecord.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListMedicalCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListMedicalCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListMedicalCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListMedicalCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.MedicalRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListMedicalCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListMedicalCareResponse} returns this
 */
proto.healthApi.ListMedicalCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListMedicalCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListMedicalCareResponse} returns this
 */
proto.healthApi.ListMedicalCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListMedicalCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListMedicalCareResponse} returns this
 */
proto.healthApi.ListMedicalCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MedicalRecord appointment = 4;
 * @return {?proto.healthApi.MedicalRecord}
 */
proto.healthApi.ListMedicalCareResponse.prototype.getAppointment = function() {
  return /** @type{?proto.healthApi.MedicalRecord} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.MedicalRecord, 4));
};


/**
 * @param {?proto.healthApi.MedicalRecord|undefined} value
 * @return {!proto.healthApi.ListMedicalCareResponse} returns this
*/
proto.healthApi.ListMedicalCareResponse.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListMedicalCareResponse} returns this
 */
proto.healthApi.ListMedicalCareResponse.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListMedicalCareResponse.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.MedicalRecord.repeatedFields_ = [4,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.MedicalRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.MedicalRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.MedicalRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sorting: (f = msg.getSorting()) && proto.healthApi.SortingProto.toObject(includeInstance, f),
    anamnesis: (f = msg.getAnamnesis()) && proto.healthApi.AnamnesisProto.toObject(includeInstance, f),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.healthApi.DiagnosisProto.toObject, includeInstance),
    laboratoryExam: (f = msg.getLaboratoryExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    imagingExam: (f = msg.getImagingExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    otherExam: (f = msg.getOtherExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.PrescriptionDrugProto.toObject, includeInstance),
    forwardingsList: jspb.Message.toObjectList(msg.getForwardingsList(),
    proto.healthApi.ForwardingProto.toObject, includeInstance),
    medicalCertificatesList: jspb.Message.toObjectList(msg.getMedicalCertificatesList(),
    proto.healthApi.MedicalCertificateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.MedicalRecord}
 */
proto.healthApi.MedicalRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.MedicalRecord;
  return proto.healthApi.MedicalRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.MedicalRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.MedicalRecord}
 */
proto.healthApi.MedicalRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = new proto.healthApi.SortingProto;
      reader.readMessage(value,proto.healthApi.SortingProto.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    case 3:
      var value = new proto.healthApi.AnamnesisProto;
      reader.readMessage(value,proto.healthApi.AnamnesisProto.deserializeBinaryFromReader);
      msg.setAnamnesis(value);
      break;
    case 4:
      var value = new proto.healthApi.DiagnosisProto;
      reader.readMessage(value,proto.healthApi.DiagnosisProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    case 5:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setLaboratoryExam(value);
      break;
    case 6:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setImagingExam(value);
      break;
    case 7:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setOtherExam(value);
      break;
    case 8:
      var value = new proto.healthApi.PrescriptionDrugProto;
      reader.readMessage(value,proto.healthApi.PrescriptionDrugProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    case 9:
      var value = new proto.healthApi.ForwardingProto;
      reader.readMessage(value,proto.healthApi.ForwardingProto.deserializeBinaryFromReader);
      msg.addForwardings(value);
      break;
    case 10:
      var value = new proto.healthApi.MedicalCertificateProto;
      reader.readMessage(value,proto.healthApi.MedicalCertificateProto.deserializeBinaryFromReader);
      msg.addMedicalCertificates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.MedicalRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.MedicalRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.MedicalRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.healthApi.SortingProto.serializeBinaryToWriter
    );
  }
  f = message.getAnamnesis();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.healthApi.AnamnesisProto.serializeBinaryToWriter
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.DiagnosisProto.serializeBinaryToWriter
    );
  }
  f = message.getLaboratoryExam();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getImagingExam();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getOtherExam();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.healthApi.PrescriptionDrugProto.serializeBinaryToWriter
    );
  }
  f = message.getForwardingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.healthApi.ForwardingProto.serializeBinaryToWriter
    );
  }
  f = message.getMedicalCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.healthApi.MedicalCertificateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.MedicalRecord.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SortingProto sorting = 2;
 * @return {?proto.healthApi.SortingProto}
 */
proto.healthApi.MedicalRecord.prototype.getSorting = function() {
  return /** @type{?proto.healthApi.SortingProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.SortingProto, 2));
};


/**
 * @param {?proto.healthApi.SortingProto|undefined} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecord.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnamnesisProto anamnesis = 3;
 * @return {?proto.healthApi.AnamnesisProto}
 */
proto.healthApi.MedicalRecord.prototype.getAnamnesis = function() {
  return /** @type{?proto.healthApi.AnamnesisProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.AnamnesisProto, 3));
};


/**
 * @param {?proto.healthApi.AnamnesisProto|undefined} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setAnamnesis = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearAnamnesis = function() {
  return this.setAnamnesis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecord.prototype.hasAnamnesis = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated DiagnosisProto diagnoses = 4;
 * @return {!Array<!proto.healthApi.DiagnosisProto>}
 */
proto.healthApi.MedicalRecord.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.healthApi.DiagnosisProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DiagnosisProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.DiagnosisProto>} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.DiagnosisProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DiagnosisProto}
 */
proto.healthApi.MedicalRecord.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.DiagnosisProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};


/**
 * optional ExamRequestProto laboratory_exam = 5;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.MedicalRecord.prototype.getLaboratoryExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 5));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setLaboratoryExam = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearLaboratoryExam = function() {
  return this.setLaboratoryExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecord.prototype.hasLaboratoryExam = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ExamRequestProto imaging_exam = 6;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.MedicalRecord.prototype.getImagingExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 6));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setImagingExam = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearImagingExam = function() {
  return this.setImagingExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecord.prototype.hasImagingExam = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExamRequestProto other_exam = 7;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.MedicalRecord.prototype.getOtherExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 7));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setOtherExam = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearOtherExam = function() {
  return this.setOtherExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecord.prototype.hasOtherExam = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated PrescriptionDrugProto drugs = 8;
 * @return {!Array<!proto.healthApi.PrescriptionDrugProto>}
 */
proto.healthApi.MedicalRecord.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.PrescriptionDrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescriptionDrugProto, 8));
};


/**
 * @param {!Array<!proto.healthApi.PrescriptionDrugProto>} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.healthApi.PrescriptionDrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescriptionDrugProto}
 */
proto.healthApi.MedicalRecord.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.healthApi.PrescriptionDrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};


/**
 * repeated ForwardingProto forwardings = 9;
 * @return {!Array<!proto.healthApi.ForwardingProto>}
 */
proto.healthApi.MedicalRecord.prototype.getForwardingsList = function() {
  return /** @type{!Array<!proto.healthApi.ForwardingProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ForwardingProto, 9));
};


/**
 * @param {!Array<!proto.healthApi.ForwardingProto>} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setForwardingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.healthApi.ForwardingProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ForwardingProto}
 */
proto.healthApi.MedicalRecord.prototype.addForwardings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.healthApi.ForwardingProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearForwardingsList = function() {
  return this.setForwardingsList([]);
};


/**
 * repeated MedicalCertificateProto medical_certificates = 10;
 * @return {!Array<!proto.healthApi.MedicalCertificateProto>}
 */
proto.healthApi.MedicalRecord.prototype.getMedicalCertificatesList = function() {
  return /** @type{!Array<!proto.healthApi.MedicalCertificateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.MedicalCertificateProto, 10));
};


/**
 * @param {!Array<!proto.healthApi.MedicalCertificateProto>} value
 * @return {!proto.healthApi.MedicalRecord} returns this
*/
proto.healthApi.MedicalRecord.prototype.setMedicalCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.healthApi.MedicalCertificateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.MedicalCertificateProto}
 */
proto.healthApi.MedicalRecord.prototype.addMedicalCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.healthApi.MedicalCertificateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecord} returns this
 */
proto.healthApi.MedicalRecord.prototype.clearMedicalCertificatesList = function() {
  return this.setMedicalCertificatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.MedicalRecordRequest.repeatedFields_ = [4,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.MedicalRecordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.MedicalRecordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.MedicalRecordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalRecordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sorting: (f = msg.getSorting()) && proto.healthApi.SortingProto.toObject(includeInstance, f),
    anamnesis: (f = msg.getAnamnesis()) && proto.healthApi.AnamnesisProto.toObject(includeInstance, f),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.healthApi.DiagnosisProto.toObject, includeInstance),
    laboratoryExam: (f = msg.getLaboratoryExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    imagingExam: (f = msg.getImagingExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    otherExam: (f = msg.getOtherExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.PrescriptionDrugProto.toObject, includeInstance),
    forwardingsList: jspb.Message.toObjectList(msg.getForwardingsList(),
    proto.healthApi.ForwardingProto.toObject, includeInstance),
    medicalCertificatesList: jspb.Message.toObjectList(msg.getMedicalCertificatesList(),
    proto.healthApi.MedicalCertificateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.MedicalRecordRequest}
 */
proto.healthApi.MedicalRecordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.MedicalRecordRequest;
  return proto.healthApi.MedicalRecordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.MedicalRecordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.MedicalRecordRequest}
 */
proto.healthApi.MedicalRecordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = new proto.healthApi.SortingProto;
      reader.readMessage(value,proto.healthApi.SortingProto.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    case 3:
      var value = new proto.healthApi.AnamnesisProto;
      reader.readMessage(value,proto.healthApi.AnamnesisProto.deserializeBinaryFromReader);
      msg.setAnamnesis(value);
      break;
    case 4:
      var value = new proto.healthApi.DiagnosisProto;
      reader.readMessage(value,proto.healthApi.DiagnosisProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    case 5:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setLaboratoryExam(value);
      break;
    case 6:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setImagingExam(value);
      break;
    case 7:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setOtherExam(value);
      break;
    case 8:
      var value = new proto.healthApi.PrescriptionDrugProto;
      reader.readMessage(value,proto.healthApi.PrescriptionDrugProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    case 9:
      var value = new proto.healthApi.ForwardingProto;
      reader.readMessage(value,proto.healthApi.ForwardingProto.deserializeBinaryFromReader);
      msg.addForwardings(value);
      break;
    case 10:
      var value = new proto.healthApi.MedicalCertificateProto;
      reader.readMessage(value,proto.healthApi.MedicalCertificateProto.deserializeBinaryFromReader);
      msg.addMedicalCertificates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.MedicalRecordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.MedicalRecordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.MedicalRecordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalRecordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.healthApi.SortingProto.serializeBinaryToWriter
    );
  }
  f = message.getAnamnesis();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.healthApi.AnamnesisProto.serializeBinaryToWriter
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.DiagnosisProto.serializeBinaryToWriter
    );
  }
  f = message.getLaboratoryExam();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getImagingExam();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getOtherExam();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.healthApi.PrescriptionDrugProto.serializeBinaryToWriter
    );
  }
  f = message.getForwardingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.healthApi.ForwardingProto.serializeBinaryToWriter
    );
  }
  f = message.getMedicalCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.healthApi.MedicalCertificateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.MedicalRecordRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SortingProto sorting = 2;
 * @return {?proto.healthApi.SortingProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.getSorting = function() {
  return /** @type{?proto.healthApi.SortingProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.SortingProto, 2));
};


/**
 * @param {?proto.healthApi.SortingProto|undefined} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecordRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnamnesisProto anamnesis = 3;
 * @return {?proto.healthApi.AnamnesisProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.getAnamnesis = function() {
  return /** @type{?proto.healthApi.AnamnesisProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.AnamnesisProto, 3));
};


/**
 * @param {?proto.healthApi.AnamnesisProto|undefined} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setAnamnesis = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearAnamnesis = function() {
  return this.setAnamnesis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecordRequest.prototype.hasAnamnesis = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated DiagnosisProto diagnoses = 4;
 * @return {!Array<!proto.healthApi.DiagnosisProto>}
 */
proto.healthApi.MedicalRecordRequest.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.healthApi.DiagnosisProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DiagnosisProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.DiagnosisProto>} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.DiagnosisProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DiagnosisProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.DiagnosisProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};


/**
 * optional ExamRequestProto laboratory_exam = 5;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.getLaboratoryExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 5));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setLaboratoryExam = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearLaboratoryExam = function() {
  return this.setLaboratoryExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecordRequest.prototype.hasLaboratoryExam = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ExamRequestProto imaging_exam = 6;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.getImagingExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 6));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setImagingExam = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearImagingExam = function() {
  return this.setImagingExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecordRequest.prototype.hasImagingExam = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExamRequestProto other_exam = 7;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.getOtherExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 7));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setOtherExam = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearOtherExam = function() {
  return this.setOtherExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.MedicalRecordRequest.prototype.hasOtherExam = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated PrescriptionDrugProto drugs = 8;
 * @return {!Array<!proto.healthApi.PrescriptionDrugProto>}
 */
proto.healthApi.MedicalRecordRequest.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.PrescriptionDrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescriptionDrugProto, 8));
};


/**
 * @param {!Array<!proto.healthApi.PrescriptionDrugProto>} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.healthApi.PrescriptionDrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescriptionDrugProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.healthApi.PrescriptionDrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};


/**
 * repeated ForwardingProto forwardings = 9;
 * @return {!Array<!proto.healthApi.ForwardingProto>}
 */
proto.healthApi.MedicalRecordRequest.prototype.getForwardingsList = function() {
  return /** @type{!Array<!proto.healthApi.ForwardingProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ForwardingProto, 9));
};


/**
 * @param {!Array<!proto.healthApi.ForwardingProto>} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setForwardingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.healthApi.ForwardingProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ForwardingProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.addForwardings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.healthApi.ForwardingProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearForwardingsList = function() {
  return this.setForwardingsList([]);
};


/**
 * repeated MedicalCertificateProto medical_certificates = 10;
 * @return {!Array<!proto.healthApi.MedicalCertificateProto>}
 */
proto.healthApi.MedicalRecordRequest.prototype.getMedicalCertificatesList = function() {
  return /** @type{!Array<!proto.healthApi.MedicalCertificateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.MedicalCertificateProto, 10));
};


/**
 * @param {!Array<!proto.healthApi.MedicalCertificateProto>} value
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
*/
proto.healthApi.MedicalRecordRequest.prototype.setMedicalCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.healthApi.MedicalCertificateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.MedicalCertificateProto}
 */
proto.healthApi.MedicalRecordRequest.prototype.addMedicalCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.healthApi.MedicalCertificateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalRecordRequest} returns this
 */
proto.healthApi.MedicalRecordRequest.prototype.clearMedicalCertificatesList = function() {
  return this.setMedicalCertificatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.MedicalRecordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.MedicalRecordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.MedicalRecordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalRecordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.MedicalRecordResponse}
 */
proto.healthApi.MedicalRecordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.MedicalRecordResponse;
  return proto.healthApi.MedicalRecordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.MedicalRecordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.MedicalRecordResponse}
 */
proto.healthApi.MedicalRecordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.MedicalRecordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.MedicalRecordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.MedicalRecordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalRecordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.MedicalRecordResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.MedicalRecordResponse} returns this
 */
proto.healthApi.MedicalRecordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.MedicalRecordResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalRecordResponse} returns this
 */
proto.healthApi.MedicalRecordResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.MedicalRecordResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalRecordResponse} returns this
 */
proto.healthApi.MedicalRecordResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PausePatientCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PausePatientCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PausePatientCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PausePatientCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PausePatientCareRequest}
 */
proto.healthApi.PausePatientCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PausePatientCareRequest;
  return proto.healthApi.PausePatientCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PausePatientCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PausePatientCareRequest}
 */
proto.healthApi.PausePatientCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PausePatientCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PausePatientCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PausePatientCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PausePatientCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.PausePatientCareRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PausePatientCareRequest} returns this
 */
proto.healthApi.PausePatientCareRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PausePatientCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PausePatientCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PausePatientCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PausePatientCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PausePatientCareResponse}
 */
proto.healthApi.PausePatientCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PausePatientCareResponse;
  return proto.healthApi.PausePatientCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PausePatientCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PausePatientCareResponse}
 */
proto.healthApi.PausePatientCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PausePatientCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PausePatientCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PausePatientCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PausePatientCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.PausePatientCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.PausePatientCareResponse} returns this
 */
proto.healthApi.PausePatientCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.PausePatientCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PausePatientCareResponse} returns this
 */
proto.healthApi.PausePatientCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.PausePatientCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PausePatientCareResponse} returns this
 */
proto.healthApi.PausePatientCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PauseToExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PauseToExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PauseToExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PauseToExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    waitTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PauseToExamRequest}
 */
proto.healthApi.PauseToExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PauseToExamRequest;
  return proto.healthApi.PauseToExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PauseToExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PauseToExamRequest}
 */
proto.healthApi.PauseToExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWaitTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PauseToExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PauseToExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PauseToExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PauseToExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWaitTime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.PauseToExamRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PauseToExamRequest} returns this
 */
proto.healthApi.PauseToExamRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 wait_time = 2;
 * @return {number}
 */
proto.healthApi.PauseToExamRequest.prototype.getWaitTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.PauseToExamRequest} returns this
 */
proto.healthApi.PauseToExamRequest.prototype.setWaitTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PauseToExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PauseToExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PauseToExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PauseToExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PauseToExamResponse}
 */
proto.healthApi.PauseToExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PauseToExamResponse;
  return proto.healthApi.PauseToExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PauseToExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PauseToExamResponse}
 */
proto.healthApi.PauseToExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PauseToExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PauseToExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PauseToExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PauseToExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.PauseToExamResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.PauseToExamResponse} returns this
 */
proto.healthApi.PauseToExamResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.PauseToExamResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PauseToExamResponse} returns this
 */
proto.healthApi.PauseToExamResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.PauseToExamResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PauseToExamResponse} returns this
 */
proto.healthApi.PauseToExamResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListPdfsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListPdfsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListPdfsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListPdfsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListPdfsRequest}
 */
proto.healthApi.ListPdfsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListPdfsRequest;
  return proto.healthApi.ListPdfsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListPdfsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListPdfsRequest}
 */
proto.healthApi.ListPdfsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListPdfsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListPdfsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListPdfsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListPdfsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.ListPdfsRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListPdfsRequest} returns this
 */
proto.healthApi.ListPdfsRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListPdfsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListPdfsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListPdfsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListPdfsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pdfs: (f = msg.getPdfs()) && proto.healthApi.PdfsProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListPdfsResponse}
 */
proto.healthApi.ListPdfsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListPdfsResponse;
  return proto.healthApi.ListPdfsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListPdfsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListPdfsResponse}
 */
proto.healthApi.ListPdfsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.PdfsProto;
      reader.readMessage(value,proto.healthApi.PdfsProto.deserializeBinaryFromReader);
      msg.setPdfs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListPdfsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListPdfsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListPdfsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListPdfsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPdfs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.PdfsProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListPdfsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListPdfsResponse} returns this
 */
proto.healthApi.ListPdfsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListPdfsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListPdfsResponse} returns this
 */
proto.healthApi.ListPdfsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListPdfsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListPdfsResponse} returns this
 */
proto.healthApi.ListPdfsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PdfsProto pdfs = 4;
 * @return {?proto.healthApi.PdfsProto}
 */
proto.healthApi.ListPdfsResponse.prototype.getPdfs = function() {
  return /** @type{?proto.healthApi.PdfsProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.PdfsProto, 4));
};


/**
 * @param {?proto.healthApi.PdfsProto|undefined} value
 * @return {!proto.healthApi.ListPdfsResponse} returns this
*/
proto.healthApi.ListPdfsResponse.prototype.setPdfs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListPdfsResponse} returns this
 */
proto.healthApi.ListPdfsResponse.prototype.clearPdfs = function() {
  return this.setPdfs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListPdfsResponse.prototype.hasPdfs = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.PdfsProto.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PdfsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PdfsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PdfsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PdfsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    forwardingsList: jspb.Message.toObjectList(msg.getForwardingsList(),
    proto.healthApi.PrescribedProto.toObject, includeInstance),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.healthApi.PrescribedProto.toObject, includeInstance),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.PrescribedProto.toObject, includeInstance),
    examsList: jspb.Message.toObjectList(msg.getExamsList(),
    proto.healthApi.PrescribedProto.toObject, includeInstance),
    medicalCertificatesList: jspb.Message.toObjectList(msg.getMedicalCertificatesList(),
    proto.healthApi.PrescribedProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PdfsProto}
 */
proto.healthApi.PdfsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PdfsProto;
  return proto.healthApi.PdfsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PdfsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PdfsProto}
 */
proto.healthApi.PdfsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = new proto.healthApi.PrescribedProto;
      reader.readMessage(value,proto.healthApi.PrescribedProto.deserializeBinaryFromReader);
      msg.addForwardings(value);
      break;
    case 3:
      var value = new proto.healthApi.PrescribedProto;
      reader.readMessage(value,proto.healthApi.PrescribedProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    case 4:
      var value = new proto.healthApi.PrescribedProto;
      reader.readMessage(value,proto.healthApi.PrescribedProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    case 5:
      var value = new proto.healthApi.PrescribedProto;
      reader.readMessage(value,proto.healthApi.PrescribedProto.deserializeBinaryFromReader);
      msg.addExams(value);
      break;
    case 6:
      var value = new proto.healthApi.PrescribedProto;
      reader.readMessage(value,proto.healthApi.PrescribedProto.deserializeBinaryFromReader);
      msg.addMedicalCertificates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PdfsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PdfsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PdfsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PdfsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForwardingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.PrescribedProto.serializeBinaryToWriter
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.PrescribedProto.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.PrescribedProto.serializeBinaryToWriter
    );
  }
  f = message.getExamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.healthApi.PrescribedProto.serializeBinaryToWriter
    );
  }
  f = message.getMedicalCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.healthApi.PrescribedProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.PdfsProto.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PdfsProto} returns this
 */
proto.healthApi.PdfsProto.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PrescribedProto forwardings = 2;
 * @return {!Array<!proto.healthApi.PrescribedProto>}
 */
proto.healthApi.PdfsProto.prototype.getForwardingsList = function() {
  return /** @type{!Array<!proto.healthApi.PrescribedProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescribedProto, 2));
};


/**
 * @param {!Array<!proto.healthApi.PrescribedProto>} value
 * @return {!proto.healthApi.PdfsProto} returns this
*/
proto.healthApi.PdfsProto.prototype.setForwardingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.PrescribedProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PdfsProto.prototype.addForwardings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.PrescribedProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.PdfsProto} returns this
 */
proto.healthApi.PdfsProto.prototype.clearForwardingsList = function() {
  return this.setForwardingsList([]);
};


/**
 * repeated PrescribedProto diagnoses = 3;
 * @return {!Array<!proto.healthApi.PrescribedProto>}
 */
proto.healthApi.PdfsProto.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.healthApi.PrescribedProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescribedProto, 3));
};


/**
 * @param {!Array<!proto.healthApi.PrescribedProto>} value
 * @return {!proto.healthApi.PdfsProto} returns this
*/
proto.healthApi.PdfsProto.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.PrescribedProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PdfsProto.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.PrescribedProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.PdfsProto} returns this
 */
proto.healthApi.PdfsProto.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};


/**
 * repeated PrescribedProto drugs = 4;
 * @return {!Array<!proto.healthApi.PrescribedProto>}
 */
proto.healthApi.PdfsProto.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.PrescribedProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescribedProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.PrescribedProto>} value
 * @return {!proto.healthApi.PdfsProto} returns this
*/
proto.healthApi.PdfsProto.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.PrescribedProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PdfsProto.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.PrescribedProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.PdfsProto} returns this
 */
proto.healthApi.PdfsProto.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};


/**
 * repeated PrescribedProto exams = 5;
 * @return {!Array<!proto.healthApi.PrescribedProto>}
 */
proto.healthApi.PdfsProto.prototype.getExamsList = function() {
  return /** @type{!Array<!proto.healthApi.PrescribedProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescribedProto, 5));
};


/**
 * @param {!Array<!proto.healthApi.PrescribedProto>} value
 * @return {!proto.healthApi.PdfsProto} returns this
*/
proto.healthApi.PdfsProto.prototype.setExamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.healthApi.PrescribedProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PdfsProto.prototype.addExams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.healthApi.PrescribedProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.PdfsProto} returns this
 */
proto.healthApi.PdfsProto.prototype.clearExamsList = function() {
  return this.setExamsList([]);
};


/**
 * repeated PrescribedProto medical_certificates = 6;
 * @return {!Array<!proto.healthApi.PrescribedProto>}
 */
proto.healthApi.PdfsProto.prototype.getMedicalCertificatesList = function() {
  return /** @type{!Array<!proto.healthApi.PrescribedProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescribedProto, 6));
};


/**
 * @param {!Array<!proto.healthApi.PrescribedProto>} value
 * @return {!proto.healthApi.PdfsProto} returns this
*/
proto.healthApi.PdfsProto.prototype.setMedicalCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.healthApi.PrescribedProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PdfsProto.prototype.addMedicalCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.healthApi.PrescribedProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.PdfsProto} returns this
 */
proto.healthApi.PdfsProto.prototype.clearMedicalCertificatesList = function() {
  return this.setMedicalCertificatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PrescribedProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PrescribedProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PrescribedProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PrescribedProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PrescribedProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PrescribedProto;
  return proto.healthApi.PrescribedProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PrescribedProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PrescribedProto}
 */
proto.healthApi.PrescribedProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PrescribedProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PrescribedProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PrescribedProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PrescribedProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.healthApi.PrescribedProto.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.healthApi.PrescribedProto.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.healthApi.PrescribedProto.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.healthApi.PrescribedProto} returns this
 */
proto.healthApi.PrescribedProto.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.healthApi.PrescribedProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescribedProto} returns this
 */
proto.healthApi.PrescribedProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AddressInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AddressInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AddressInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AddressInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 3, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AddressInfo}
 */
proto.healthApi.AddressInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AddressInfo;
  return proto.healthApi.AddressInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AddressInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AddressInfo}
 */
proto.healthApi.AddressInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AddressInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AddressInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AddressInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AddressInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.healthApi.AddressInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AddressInfo} returns this
 */
proto.healthApi.AddressInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.healthApi.AddressInfo.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AddressInfo} returns this
 */
proto.healthApi.AddressInfo.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string complement = 3;
 * @return {string}
 */
proto.healthApi.AddressInfo.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AddressInfo} returns this
 */
proto.healthApi.AddressInfo.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string neighborhood = 4;
 * @return {string}
 */
proto.healthApi.AddressInfo.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AddressInfo} returns this
 */
proto.healthApi.AddressInfo.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.healthApi.AddressInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AddressInfo} returns this
 */
proto.healthApi.AddressInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.healthApi.AddressInfo.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AddressInfo} returns this
 */
proto.healthApi.AddressInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListProfessionalHomeResponse.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListProfessionalHomeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListProfessionalHomeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListProfessionalHomeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    laboratoryExamList: jspb.Message.toObjectList(msg.getLaboratoryExamList(),
    proto.healthApi.ProfessionalDisplayExamProto.toObject, includeInstance),
    imagingExamList: jspb.Message.toObjectList(msg.getImagingExamList(),
    proto.healthApi.ProfessionalDisplayExamProto.toObject, includeInstance),
    otherExamList: jspb.Message.toObjectList(msg.getOtherExamList(),
    proto.healthApi.ProfessionalDisplayExamProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListProfessionalHomeResponse}
 */
proto.healthApi.ListProfessionalHomeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListProfessionalHomeResponse;
  return proto.healthApi.ListProfessionalHomeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListProfessionalHomeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListProfessionalHomeResponse}
 */
proto.healthApi.ListProfessionalHomeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.ProfessionalDisplayExamProto;
      reader.readMessage(value,proto.healthApi.ProfessionalDisplayExamProto.deserializeBinaryFromReader);
      msg.addLaboratoryExam(value);
      break;
    case 5:
      var value = new proto.healthApi.ProfessionalDisplayExamProto;
      reader.readMessage(value,proto.healthApi.ProfessionalDisplayExamProto.deserializeBinaryFromReader);
      msg.addImagingExam(value);
      break;
    case 6:
      var value = new proto.healthApi.ProfessionalDisplayExamProto;
      reader.readMessage(value,proto.healthApi.ProfessionalDisplayExamProto.deserializeBinaryFromReader);
      msg.addOtherExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListProfessionalHomeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListProfessionalHomeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListProfessionalHomeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLaboratoryExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.ProfessionalDisplayExamProto.serializeBinaryToWriter
    );
  }
  f = message.getImagingExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.healthApi.ProfessionalDisplayExamProto.serializeBinaryToWriter
    );
  }
  f = message.getOtherExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.healthApi.ProfessionalDisplayExamProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProfessionalDisplayExamProto laboratory_exam = 4;
 * @return {!Array<!proto.healthApi.ProfessionalDisplayExamProto>}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.getLaboratoryExamList = function() {
  return /** @type{!Array<!proto.healthApi.ProfessionalDisplayExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ProfessionalDisplayExamProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.ProfessionalDisplayExamProto>} value
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
*/
proto.healthApi.ListProfessionalHomeResponse.prototype.setLaboratoryExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.ProfessionalDisplayExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ProfessionalDisplayExamProto}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.addLaboratoryExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.ProfessionalDisplayExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.clearLaboratoryExamList = function() {
  return this.setLaboratoryExamList([]);
};


/**
 * repeated ProfessionalDisplayExamProto imaging_exam = 5;
 * @return {!Array<!proto.healthApi.ProfessionalDisplayExamProto>}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.getImagingExamList = function() {
  return /** @type{!Array<!proto.healthApi.ProfessionalDisplayExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ProfessionalDisplayExamProto, 5));
};


/**
 * @param {!Array<!proto.healthApi.ProfessionalDisplayExamProto>} value
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
*/
proto.healthApi.ListProfessionalHomeResponse.prototype.setImagingExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.healthApi.ProfessionalDisplayExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ProfessionalDisplayExamProto}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.addImagingExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.healthApi.ProfessionalDisplayExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.clearImagingExamList = function() {
  return this.setImagingExamList([]);
};


/**
 * repeated ProfessionalDisplayExamProto other_exam = 6;
 * @return {!Array<!proto.healthApi.ProfessionalDisplayExamProto>}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.getOtherExamList = function() {
  return /** @type{!Array<!proto.healthApi.ProfessionalDisplayExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ProfessionalDisplayExamProto, 6));
};


/**
 * @param {!Array<!proto.healthApi.ProfessionalDisplayExamProto>} value
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
*/
proto.healthApi.ListProfessionalHomeResponse.prototype.setOtherExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.healthApi.ProfessionalDisplayExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ProfessionalDisplayExamProto}
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.addOtherExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.healthApi.ProfessionalDisplayExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListProfessionalHomeResponse} returns this
 */
proto.healthApi.ListProfessionalHomeResponse.prototype.clearOtherExamList = function() {
  return this.setOtherExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ProfessionalDisplayExamProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ProfessionalDisplayExamProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ProfessionalDisplayExamProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examRequestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 5, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    statusColor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    healthRecordId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    type: jspb.Message.getFieldWithDefault(msg, 12, ""),
    patientName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ProfessionalDisplayExamProto}
 */
proto.healthApi.ProfessionalDisplayExamProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ProfessionalDisplayExamProto;
  return proto.healthApi.ProfessionalDisplayExamProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ProfessionalDisplayExamProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ProfessionalDisplayExamProto}
 */
proto.healthApi.ProfessionalDisplayExamProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusColor(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthRecordId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ProfessionalDisplayExamProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ProfessionalDisplayExamProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ProfessionalDisplayExamProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatusColor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHealthRecordId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLastUpdate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPatientName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string exam_request_id = 1;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getExamRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setExamRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_id = 2;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_group = 4;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string desc_subgroup = 5;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code_tuss = 6;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status_color = 8;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getStatusColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setStatusColor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string patient_id = 9;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string health_record_id = 10;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getHealthRecordId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setHealthRecordId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string last_update = 11;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getLastUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setLastUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string type = 12;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string patient_name = 13;
 * @return {string}
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.getPatientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ProfessionalDisplayExamProto} returns this
 */
proto.healthApi.ProfessionalDisplayExamProto.prototype.setPatientName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DisplayExamProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DisplayExamProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DisplayExamProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DisplayExamProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examRequestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 5, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    statusColor: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DisplayExamProto}
 */
proto.healthApi.DisplayExamProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DisplayExamProto;
  return proto.healthApi.DisplayExamProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DisplayExamProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DisplayExamProto}
 */
proto.healthApi.DisplayExamProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DisplayExamProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DisplayExamProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DisplayExamProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DisplayExamProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatusColor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string exam_request_id = 1;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getExamRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setExamRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_id = 2;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_group = 4;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string desc_subgroup = 5;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code_tuss = 6;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status_color = 8;
 * @return {string}
 */
proto.healthApi.DisplayExamProto.prototype.getStatusColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayExamProto} returns this
 */
proto.healthApi.DisplayExamProto.prototype.setStatusColor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DisplayDrugProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DisplayDrugProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DisplayDrugProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DisplayDrugProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    prescriptionDrugId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugRequestId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apresentationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 5, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 7, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 8, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 9, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DisplayDrugProto}
 */
proto.healthApi.DisplayDrugProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DisplayDrugProto;
  return proto.healthApi.DisplayDrugProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DisplayDrugProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DisplayDrugProto}
 */
proto.healthApi.DisplayDrugProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrescriptionDrugId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugRequestId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DisplayDrugProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DisplayDrugProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DisplayDrugProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DisplayDrugProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrescriptionDrugId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugRequestId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string prescription_drug_id = 1;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getPrescriptionDrugId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setPrescriptionDrugId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string drug_request_id = 2;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getDrugRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setDrugRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string apresentation_id = 3;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string active_principle = 5;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string concentration = 6;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string pharmaceutical_form = 7;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string amount = 8;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string indicated_amount = 9;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string recommendation = 10;
 * @return {string}
 */
proto.healthApi.DisplayDrugProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DisplayDrugProto} returns this
 */
proto.healthApi.DisplayDrugProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListHealthInformationResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListHealthInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListHealthInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListHealthInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListHealthInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examsList: jspb.Message.toObjectList(msg.getExamsList(),
    proto.healthApi.DisplayExamProto.toObject, includeInstance),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.DisplayDrugProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListHealthInformationResponse}
 */
proto.healthApi.ListHealthInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListHealthInformationResponse;
  return proto.healthApi.ListHealthInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListHealthInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListHealthInformationResponse}
 */
proto.healthApi.ListHealthInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.DisplayExamProto;
      reader.readMessage(value,proto.healthApi.DisplayExamProto.deserializeBinaryFromReader);
      msg.addExams(value);
      break;
    case 5:
      var value = new proto.healthApi.DisplayDrugProto;
      reader.readMessage(value,proto.healthApi.DisplayDrugProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListHealthInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListHealthInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListHealthInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListHealthInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.DisplayExamProto.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.healthApi.DisplayDrugProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListHealthInformationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
 */
proto.healthApi.ListHealthInformationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListHealthInformationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
 */
proto.healthApi.ListHealthInformationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListHealthInformationResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
 */
proto.healthApi.ListHealthInformationResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DisplayExamProto exams = 4;
 * @return {!Array<!proto.healthApi.DisplayExamProto>}
 */
proto.healthApi.ListHealthInformationResponse.prototype.getExamsList = function() {
  return /** @type{!Array<!proto.healthApi.DisplayExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DisplayExamProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.DisplayExamProto>} value
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
*/
proto.healthApi.ListHealthInformationResponse.prototype.setExamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.DisplayExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DisplayExamProto}
 */
proto.healthApi.ListHealthInformationResponse.prototype.addExams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.DisplayExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
 */
proto.healthApi.ListHealthInformationResponse.prototype.clearExamsList = function() {
  return this.setExamsList([]);
};


/**
 * repeated DisplayDrugProto drugs = 5;
 * @return {!Array<!proto.healthApi.DisplayDrugProto>}
 */
proto.healthApi.ListHealthInformationResponse.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.DisplayDrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DisplayDrugProto, 5));
};


/**
 * @param {!Array<!proto.healthApi.DisplayDrugProto>} value
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
*/
proto.healthApi.ListHealthInformationResponse.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.healthApi.DisplayDrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DisplayDrugProto}
 */
proto.healthApi.ListHealthInformationResponse.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.healthApi.DisplayDrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListHealthInformationResponse} returns this
 */
proto.healthApi.ListHealthInformationResponse.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.VerifyAllergyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.VerifyAllergyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.VerifyAllergyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.VerifyAllergyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.VerifyAllergyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.VerifyDrug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.VerifyAllergyRequest}
 */
proto.healthApi.VerifyAllergyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.VerifyAllergyRequest;
  return proto.healthApi.VerifyAllergyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.VerifyAllergyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.VerifyAllergyRequest}
 */
proto.healthApi.VerifyAllergyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.healthApi.VerifyDrug;
      reader.readMessage(value,proto.healthApi.VerifyDrug.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.VerifyAllergyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.VerifyAllergyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.VerifyAllergyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.VerifyAllergyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.healthApi.VerifyDrug.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VerifyDrug drugs = 1;
 * @return {!Array<!proto.healthApi.VerifyDrug>}
 */
proto.healthApi.VerifyAllergyRequest.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.VerifyDrug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.VerifyDrug, 1));
};


/**
 * @param {!Array<!proto.healthApi.VerifyDrug>} value
 * @return {!proto.healthApi.VerifyAllergyRequest} returns this
*/
proto.healthApi.VerifyAllergyRequest.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.healthApi.VerifyDrug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.VerifyDrug}
 */
proto.healthApi.VerifyAllergyRequest.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.healthApi.VerifyDrug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.VerifyAllergyRequest} returns this
 */
proto.healthApi.VerifyAllergyRequest.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.VerifyDrug.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.VerifyDrug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.VerifyDrug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.VerifyDrug.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.VerifyDrug}
 */
proto.healthApi.VerifyDrug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.VerifyDrug;
  return proto.healthApi.VerifyDrug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.VerifyDrug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.VerifyDrug}
 */
proto.healthApi.VerifyDrug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.VerifyDrug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.VerifyDrug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.VerifyDrug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.VerifyDrug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.healthApi.VerifyDrug.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.VerifyDrug} returns this
 */
proto.healthApi.VerifyDrug.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.healthApi.VerifyDrug.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.VerifyDrug} returns this
 */
proto.healthApi.VerifyDrug.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.healthApi.VerifyDrug.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.VerifyDrug} returns this
 */
proto.healthApi.VerifyDrug.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.VerifyAllergyResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.VerifyAllergyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.VerifyAllergyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.VerifyAllergyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.VerifyAllergyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allergiesList: jspb.Message.toObjectList(msg.getAllergiesList(),
    proto.healthApi.AllergyProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.VerifyAllergyResponse}
 */
proto.healthApi.VerifyAllergyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.VerifyAllergyResponse;
  return proto.healthApi.VerifyAllergyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.VerifyAllergyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.VerifyAllergyResponse}
 */
proto.healthApi.VerifyAllergyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.AllergyProto;
      reader.readMessage(value,proto.healthApi.AllergyProto.deserializeBinaryFromReader);
      msg.addAllergies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.VerifyAllergyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.VerifyAllergyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.VerifyAllergyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.VerifyAllergyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllergiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.AllergyProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.VerifyAllergyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.VerifyAllergyResponse} returns this
 */
proto.healthApi.VerifyAllergyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.VerifyAllergyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.VerifyAllergyResponse} returns this
 */
proto.healthApi.VerifyAllergyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.VerifyAllergyResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.VerifyAllergyResponse} returns this
 */
proto.healthApi.VerifyAllergyResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AllergyProto allergies = 4;
 * @return {!Array<!proto.healthApi.AllergyProto>}
 */
proto.healthApi.VerifyAllergyResponse.prototype.getAllergiesList = function() {
  return /** @type{!Array<!proto.healthApi.AllergyProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.AllergyProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.AllergyProto>} value
 * @return {!proto.healthApi.VerifyAllergyResponse} returns this
*/
proto.healthApi.VerifyAllergyResponse.prototype.setAllergiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.AllergyProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.AllergyProto}
 */
proto.healthApi.VerifyAllergyResponse.prototype.addAllergies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.AllergyProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.VerifyAllergyResponse} returns this
 */
proto.healthApi.VerifyAllergyResponse.prototype.clearAllergiesList = function() {
  return this.setAllergiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AllergyProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AllergyProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AllergyProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AllergyProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugAllergy: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    principleAllergy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allergy: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AllergyProto}
 */
proto.healthApi.AllergyProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AllergyProto;
  return proto.healthApi.AllergyProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AllergyProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AllergyProto}
 */
proto.healthApi.AllergyProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrugAllergy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipleAllergy(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllergy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AllergyProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AllergyProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AllergyProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AllergyProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugAllergy();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPrincipleAllergy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllergy();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.healthApi.AllergyProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AllergyProto} returns this
 */
proto.healthApi.AllergyProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool drug_allergy = 2;
 * @return {boolean}
 */
proto.healthApi.AllergyProto.prototype.getDrugAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.AllergyProto} returns this
 */
proto.healthApi.AllergyProto.prototype.setDrugAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string principle_allergy = 3;
 * @return {string}
 */
proto.healthApi.AllergyProto.prototype.getPrincipleAllergy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AllergyProto} returns this
 */
proto.healthApi.AllergyProto.prototype.setPrincipleAllergy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool allergy = 4;
 * @return {boolean}
 */
proto.healthApi.AllergyProto.prototype.getAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.AllergyProto} returns this
 */
proto.healthApi.AllergyProto.prototype.setAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.GetDrugAllergiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.GetDrugAllergiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.GetDrugAllergiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.GetDrugAllergiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.GetDrug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.GetDrugAllergiesResponse}
 */
proto.healthApi.GetDrugAllergiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.GetDrugAllergiesResponse;
  return proto.healthApi.GetDrugAllergiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.GetDrugAllergiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.GetDrugAllergiesResponse}
 */
proto.healthApi.GetDrugAllergiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.healthApi.GetDrug;
      reader.readMessage(value,proto.healthApi.GetDrug.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.GetDrugAllergiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.GetDrugAllergiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.GetDrugAllergiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.GetDrug.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.GetDrugAllergiesResponse} returns this
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated GetDrug drugs = 2;
 * @return {!Array<!proto.healthApi.GetDrug>}
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.GetDrug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.GetDrug, 2));
};


/**
 * @param {!Array<!proto.healthApi.GetDrug>} value
 * @return {!proto.healthApi.GetDrugAllergiesResponse} returns this
*/
proto.healthApi.GetDrugAllergiesResponse.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.GetDrug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.GetDrug}
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.GetDrug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.GetDrugAllergiesResponse} returns this
 */
proto.healthApi.GetDrugAllergiesResponse.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.GetDrug.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.GetDrug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.GetDrug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.GetDrug.toObject = function(includeInstance, msg) {
  var f, obj = {
    productname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activeprinciple: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.GetDrug}
 */
proto.healthApi.GetDrug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.GetDrug;
  return proto.healthApi.GetDrug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.GetDrug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.GetDrug}
 */
proto.healthApi.GetDrug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveprinciple(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.GetDrug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.GetDrug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.GetDrug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.GetDrug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActiveprinciple();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string productName = 1;
 * @return {string}
 */
proto.healthApi.GetDrug.prototype.getProductname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.GetDrug} returns this
 */
proto.healthApi.GetDrug.prototype.setProductname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activePrinciple = 2;
 * @return {string}
 */
proto.healthApi.GetDrug.prototype.getActiveprinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.GetDrug} returns this
 */
proto.healthApi.GetDrug.prototype.setActiveprinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.CreateResultExamRequest.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateResultExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateResultExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateResultExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateResultExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collectionDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onSiteCollection: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    material: jspb.Message.getFieldWithDefault(msg, 5, ""),
    method: jspb.Message.getFieldWithDefault(msg, 6, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    laboratory: jspb.Message.getFieldWithDefault(msg, 8, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 9, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    releasedate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    profName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    registry: jspb.Message.getFieldWithDefault(msg, 13, ""),
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.healthApi.ResultProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateResultExamRequest}
 */
proto.healthApi.CreateResultExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateResultExamRequest;
  return proto.healthApi.CreateResultExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateResultExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateResultExamRequest}
 */
proto.healthApi.CreateResultExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectionDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnSiteCollection(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLaboratory(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleasedate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistry(value);
      break;
    case 14:
      var value = new proto.healthApi.ResultProto;
      reader.readMessage(value,proto.healthApi.ResultProto.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateResultExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateResultExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateResultExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateResultExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollectionDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnSiteCollection();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLaboratory();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReleasedate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProfName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRegistry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.healthApi.ResultProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_name = 2;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getExamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setExamName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string collection_date = 3;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getCollectionDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setCollectionDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool on_site_collection = 4;
 * @return {boolean}
 */
proto.healthApi.CreateResultExamRequest.prototype.getOnSiteCollection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setOnSiteCollection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string material = 5;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string method = 6;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string equipment = 7;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string laboratory = 8;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getLaboratory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setLaboratory = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string unit = 9;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string observation = 10;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string releaseDate = 11;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getReleasedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setReleasedate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string prof_name = 12;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getProfName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setProfName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string registry = 13;
 * @return {string}
 */
proto.healthApi.CreateResultExamRequest.prototype.getRegistry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated ResultProto result = 14;
 * @return {!Array<!proto.healthApi.ResultProto>}
 */
proto.healthApi.CreateResultExamRequest.prototype.getResultList = function() {
  return /** @type{!Array<!proto.healthApi.ResultProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ResultProto, 14));
};


/**
 * @param {!Array<!proto.healthApi.ResultProto>} value
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
*/
proto.healthApi.CreateResultExamRequest.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.healthApi.ResultProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ResultProto}
 */
proto.healthApi.CreateResultExamRequest.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.healthApi.ResultProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.CreateResultExamRequest} returns this
 */
proto.healthApi.CreateResultExamRequest.prototype.clearResultList = function() {
  return this.setResultList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateResultExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateResultExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateResultExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateResultExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateResultExamResponse}
 */
proto.healthApi.CreateResultExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateResultExamResponse;
  return proto.healthApi.CreateResultExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateResultExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateResultExamResponse}
 */
proto.healthApi.CreateResultExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateResultExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateResultExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateResultExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateResultExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateResultExamResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateResultExamResponse} returns this
 */
proto.healthApi.CreateResultExamResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateResultExamResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamResponse} returns this
 */
proto.healthApi.CreateResultExamResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateResultExamResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateResultExamResponse} returns this
 */
proto.healthApi.CreateResultExamResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drinkDesc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    measure: jspb.Message.getFieldWithDefault(msg, 6, ""),
    when: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrinkRequest}
 */
proto.healthApi.CreateDrinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrinkRequest;
  return proto.healthApi.CreateDrinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrinkRequest}
 */
proto.healthApi.CreateDrinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrinkDesc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrinkDesc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.CreateDrinkRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkRequest} returns this
 */
proto.healthApi.CreateDrinkRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string drink_desc = 3;
 * @return {string}
 */
proto.healthApi.CreateDrinkRequest.prototype.getDrinkDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkRequest} returns this
 */
proto.healthApi.CreateDrinkRequest.prototype.setDrinkDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.healthApi.CreateDrinkRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkRequest} returns this
 */
proto.healthApi.CreateDrinkRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.healthApi.CreateDrinkRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.CreateDrinkRequest} returns this
 */
proto.healthApi.CreateDrinkRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string measure = 6;
 * @return {string}
 */
proto.healthApi.CreateDrinkRequest.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkRequest} returns this
 */
proto.healthApi.CreateDrinkRequest.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string when = 7;
 * @return {string}
 */
proto.healthApi.CreateDrinkRequest.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkRequest} returns this
 */
proto.healthApi.CreateDrinkRequest.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrinkResponse}
 */
proto.healthApi.CreateDrinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrinkResponse;
  return proto.healthApi.CreateDrinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrinkResponse}
 */
proto.healthApi.CreateDrinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateDrinkResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateDrinkResponse} returns this
 */
proto.healthApi.CreateDrinkResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateDrinkResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkResponse} returns this
 */
proto.healthApi.CreateDrinkResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateDrinkResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrinkResponse} returns this
 */
proto.healthApi.CreateDrinkResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListAppointmentRequest}
 */
proto.healthApi.ListAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListAppointmentRequest;
  return proto.healthApi.ListAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListAppointmentRequest}
 */
proto.healthApi.ListAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.ListAppointmentRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAppointmentRequest} returns this
 */
proto.healthApi.ListAppointmentRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    health: (f = msg.getHealth()) && proto.healthApi.HealthPatient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListAppointmentResponse}
 */
proto.healthApi.ListAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListAppointmentResponse;
  return proto.healthApi.ListAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListAppointmentResponse}
 */
proto.healthApi.ListAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.HealthPatient;
      reader.readMessage(value,proto.healthApi.HealthPatient.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.HealthPatient.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListAppointmentResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListAppointmentResponse} returns this
 */
proto.healthApi.ListAppointmentResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListAppointmentResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAppointmentResponse} returns this
 */
proto.healthApi.ListAppointmentResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListAppointmentResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAppointmentResponse} returns this
 */
proto.healthApi.ListAppointmentResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional HealthPatient health = 4;
 * @return {?proto.healthApi.HealthPatient}
 */
proto.healthApi.ListAppointmentResponse.prototype.getHealth = function() {
  return /** @type{?proto.healthApi.HealthPatient} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.HealthPatient, 4));
};


/**
 * @param {?proto.healthApi.HealthPatient|undefined} value
 * @return {!proto.healthApi.ListAppointmentResponse} returns this
*/
proto.healthApi.ListAppointmentResponse.prototype.setHealth = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListAppointmentResponse} returns this
 */
proto.healthApi.ListAppointmentResponse.prototype.clearHealth = function() {
  return this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListAppointmentResponse.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.HealthPatient.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.HealthPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.HealthPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.HealthPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HealthPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    healthRecordId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appointment: (f = msg.getAppointment()) && proto.healthApi.AppointmentPatient.toObject(includeInstance, f),
    drugAllergyList: jspb.Message.toObjectList(msg.getDrugAllergyList(),
    proto.healthApi.DrugAllergyPatient.toObject, includeInstance),
    lifestyle: (f = msg.getLifestyle()) && proto.healthApi.LifestylePatient.toObject(includeInstance, f),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.DrugsPatient.toObject, includeInstance),
    patient: (f = msg.getPatient()) && proto.healthApi.PatientProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.HealthPatient}
 */
proto.healthApi.HealthPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.HealthPatient;
  return proto.healthApi.HealthPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.HealthPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.HealthPatient}
 */
proto.healthApi.HealthPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthRecordId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = new proto.healthApi.AppointmentPatient;
      reader.readMessage(value,proto.healthApi.AppointmentPatient.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    case 4:
      var value = new proto.healthApi.DrugAllergyPatient;
      reader.readMessage(value,proto.healthApi.DrugAllergyPatient.deserializeBinaryFromReader);
      msg.addDrugAllergy(value);
      break;
    case 5:
      var value = new proto.healthApi.LifestylePatient;
      reader.readMessage(value,proto.healthApi.LifestylePatient.deserializeBinaryFromReader);
      msg.setLifestyle(value);
      break;
    case 6:
      var value = new proto.healthApi.DrugsPatient;
      reader.readMessage(value,proto.healthApi.DrugsPatient.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    case 7:
      var value = new proto.healthApi.PatientProto;
      reader.readMessage(value,proto.healthApi.PatientProto.deserializeBinaryFromReader);
      msg.setPatient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.HealthPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.HealthPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.HealthPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HealthPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHealthRecordId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.healthApi.AppointmentPatient.serializeBinaryToWriter
    );
  }
  f = message.getDrugAllergyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.DrugAllergyPatient.serializeBinaryToWriter
    );
  }
  f = message.getLifestyle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.healthApi.LifestylePatient.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.healthApi.DrugsPatient.serializeBinaryToWriter
    );
  }
  f = message.getPatient();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.healthApi.PatientProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string health_record_id = 1;
 * @return {string}
 */
proto.healthApi.HealthPatient.prototype.getHealthRecordId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.setHealthRecordId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.healthApi.HealthPatient.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AppointmentPatient appointment = 3;
 * @return {?proto.healthApi.AppointmentPatient}
 */
proto.healthApi.HealthPatient.prototype.getAppointment = function() {
  return /** @type{?proto.healthApi.AppointmentPatient} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.AppointmentPatient, 3));
};


/**
 * @param {?proto.healthApi.AppointmentPatient|undefined} value
 * @return {!proto.healthApi.HealthPatient} returns this
*/
proto.healthApi.HealthPatient.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.HealthPatient.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated DrugAllergyPatient drug_allergy = 4;
 * @return {!Array<!proto.healthApi.DrugAllergyPatient>}
 */
proto.healthApi.HealthPatient.prototype.getDrugAllergyList = function() {
  return /** @type{!Array<!proto.healthApi.DrugAllergyPatient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrugAllergyPatient, 4));
};


/**
 * @param {!Array<!proto.healthApi.DrugAllergyPatient>} value
 * @return {!proto.healthApi.HealthPatient} returns this
*/
proto.healthApi.HealthPatient.prototype.setDrugAllergyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.DrugAllergyPatient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrugAllergyPatient}
 */
proto.healthApi.HealthPatient.prototype.addDrugAllergy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.DrugAllergyPatient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.clearDrugAllergyList = function() {
  return this.setDrugAllergyList([]);
};


/**
 * optional LifestylePatient lifestyle = 5;
 * @return {?proto.healthApi.LifestylePatient}
 */
proto.healthApi.HealthPatient.prototype.getLifestyle = function() {
  return /** @type{?proto.healthApi.LifestylePatient} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.LifestylePatient, 5));
};


/**
 * @param {?proto.healthApi.LifestylePatient|undefined} value
 * @return {!proto.healthApi.HealthPatient} returns this
*/
proto.healthApi.HealthPatient.prototype.setLifestyle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.clearLifestyle = function() {
  return this.setLifestyle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.HealthPatient.prototype.hasLifestyle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated DrugsPatient drugs = 6;
 * @return {!Array<!proto.healthApi.DrugsPatient>}
 */
proto.healthApi.HealthPatient.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.DrugsPatient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrugsPatient, 6));
};


/**
 * @param {!Array<!proto.healthApi.DrugsPatient>} value
 * @return {!proto.healthApi.HealthPatient} returns this
*/
proto.healthApi.HealthPatient.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.healthApi.DrugsPatient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrugsPatient}
 */
proto.healthApi.HealthPatient.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.healthApi.DrugsPatient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};


/**
 * optional PatientProto patient = 7;
 * @return {?proto.healthApi.PatientProto}
 */
proto.healthApi.HealthPatient.prototype.getPatient = function() {
  return /** @type{?proto.healthApi.PatientProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.PatientProto, 7));
};


/**
 * @param {?proto.healthApi.PatientProto|undefined} value
 * @return {!proto.healthApi.HealthPatient} returns this
*/
proto.healthApi.HealthPatient.prototype.setPatient = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.HealthPatient} returns this
 */
proto.healthApi.HealthPatient.prototype.clearPatient = function() {
  return this.setPatient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.HealthPatient.prototype.hasPatient = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AppointmentPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AppointmentPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AppointmentPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AppointmentPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    healthRecordId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appointmentColor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    professionalId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actuation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    speciality: jspb.Message.getFieldWithDefault(msg, 8, ""),
    chiefComplaint: jspb.Message.getFieldWithDefault(msg, 9, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AppointmentPatient}
 */
proto.healthApi.AppointmentPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AppointmentPatient;
  return proto.healthApi.AppointmentPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AppointmentPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AppointmentPatient}
 */
proto.healthApi.AppointmentPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthRecordId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentColor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActuation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpeciality(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setChiefComplaint(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AppointmentPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AppointmentPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AppointmentPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AppointmentPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHealthRecordId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppointmentColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActuation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSpeciality();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getChiefComplaint();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string health_record_id = 2;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getHealthRecordId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setHealthRecordId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string appointment_status = 3;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getAppointmentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string appointment_color = 4;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getAppointmentColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setAppointmentColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string professional_id = 5;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string actuation = 6;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getActuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setActuation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string occupation = 7;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string speciality = 8;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getSpeciality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setSpeciality = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string chief_complaint = 9;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getChiefComplaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setChiefComplaint = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string initial_date = 10;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string final_date = 11;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string service_name = 12;
 * @return {string}
 */
proto.healthApi.AppointmentPatient.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AppointmentPatient} returns this
 */
proto.healthApi.AppointmentPatient.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DrugAllergyPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DrugAllergyPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DrugAllergyPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugAllergyPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DrugAllergyPatient}
 */
proto.healthApi.DrugAllergyPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DrugAllergyPatient;
  return proto.healthApi.DrugAllergyPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DrugAllergyPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DrugAllergyPatient}
 */
proto.healthApi.DrugAllergyPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DrugAllergyPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DrugAllergyPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DrugAllergyPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugAllergyPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.healthApi.DrugAllergyPatient.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugAllergyPatient} returns this
 */
proto.healthApi.DrugAllergyPatient.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DrugsPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DrugsPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DrugsPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugsPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DrugsPatient}
 */
proto.healthApi.DrugsPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DrugsPatient;
  return proto.healthApi.DrugsPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DrugsPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DrugsPatient}
 */
proto.healthApi.DrugsPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DrugsPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DrugsPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DrugsPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugsPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.healthApi.DrugsPatient.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsPatient} returns this
 */
proto.healthApi.DrugsPatient.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.LifestylePatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.LifestylePatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.LifestylePatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.LifestylePatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    exercise: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    drink: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    smoker: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.LifestylePatient}
 */
proto.healthApi.LifestylePatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.LifestylePatient;
  return proto.healthApi.LifestylePatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.LifestylePatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.LifestylePatient}
 */
proto.healthApi.LifestylePatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExercise(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrink(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmoker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.LifestylePatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.LifestylePatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.LifestylePatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.LifestylePatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExercise();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDrink();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSmoker();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool exercise = 1;
 * @return {boolean}
 */
proto.healthApi.LifestylePatient.prototype.getExercise = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.LifestylePatient} returns this
 */
proto.healthApi.LifestylePatient.prototype.setExercise = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool drink = 2;
 * @return {boolean}
 */
proto.healthApi.LifestylePatient.prototype.getDrink = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.LifestylePatient} returns this
 */
proto.healthApi.LifestylePatient.prototype.setDrink = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool smoker = 3;
 * @return {boolean}
 */
proto.healthApi.LifestylePatient.prototype.getSmoker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.LifestylePatient} returns this
 */
proto.healthApi.LifestylePatient.prototype.setSmoker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.EmptyRequest}
 */
proto.healthApi.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.EmptyRequest;
  return proto.healthApi.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.EmptyRequest}
 */
proto.healthApi.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListAppointmentHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListAppointmentHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAppointmentHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    health: (f = msg.getHealth()) && proto.healthApi.HealthPatientHistory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListAppointmentHistoryResponse}
 */
proto.healthApi.ListAppointmentHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListAppointmentHistoryResponse;
  return proto.healthApi.ListAppointmentHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListAppointmentHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListAppointmentHistoryResponse}
 */
proto.healthApi.ListAppointmentHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.HealthPatientHistory;
      reader.readMessage(value,proto.healthApi.HealthPatientHistory.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListAppointmentHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListAppointmentHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAppointmentHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.HealthPatientHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListAppointmentHistoryResponse} returns this
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAppointmentHistoryResponse} returns this
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAppointmentHistoryResponse} returns this
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional HealthPatientHistory health = 4;
 * @return {?proto.healthApi.HealthPatientHistory}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.getHealth = function() {
  return /** @type{?proto.healthApi.HealthPatientHistory} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.HealthPatientHistory, 4));
};


/**
 * @param {?proto.healthApi.HealthPatientHistory|undefined} value
 * @return {!proto.healthApi.ListAppointmentHistoryResponse} returns this
*/
proto.healthApi.ListAppointmentHistoryResponse.prototype.setHealth = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListAppointmentHistoryResponse} returns this
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.clearHealth = function() {
  return this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListAppointmentHistoryResponse.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.HealthPatientHistory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.HealthPatientHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.HealthPatientHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.HealthPatientHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HealthPatientHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    healthRecordId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appointmentList: jspb.Message.toObjectList(msg.getAppointmentList(),
    proto.healthApi.AppointmentPatient.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.HealthPatientHistory}
 */
proto.healthApi.HealthPatientHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.HealthPatientHistory;
  return proto.healthApi.HealthPatientHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.HealthPatientHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.HealthPatientHistory}
 */
proto.healthApi.HealthPatientHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthRecordId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = new proto.healthApi.AppointmentPatient;
      reader.readMessage(value,proto.healthApi.AppointmentPatient.deserializeBinaryFromReader);
      msg.addAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.HealthPatientHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.HealthPatientHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.HealthPatientHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HealthPatientHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHealthRecordId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppointmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.AppointmentPatient.serializeBinaryToWriter
    );
  }
};


/**
 * optional string health_record_id = 1;
 * @return {string}
 */
proto.healthApi.HealthPatientHistory.prototype.getHealthRecordId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.HealthPatientHistory} returns this
 */
proto.healthApi.HealthPatientHistory.prototype.setHealthRecordId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.healthApi.HealthPatientHistory.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.HealthPatientHistory} returns this
 */
proto.healthApi.HealthPatientHistory.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AppointmentPatient appointment = 3;
 * @return {!Array<!proto.healthApi.AppointmentPatient>}
 */
proto.healthApi.HealthPatientHistory.prototype.getAppointmentList = function() {
  return /** @type{!Array<!proto.healthApi.AppointmentPatient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.AppointmentPatient, 3));
};


/**
 * @param {!Array<!proto.healthApi.AppointmentPatient>} value
 * @return {!proto.healthApi.HealthPatientHistory} returns this
*/
proto.healthApi.HealthPatientHistory.prototype.setAppointmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.AppointmentPatient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.AppointmentPatient}
 */
proto.healthApi.HealthPatientHistory.prototype.addAppointment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.AppointmentPatient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.HealthPatientHistory} returns this
 */
proto.healthApi.HealthPatientHistory.prototype.clearAppointmentList = function() {
  return this.setAppointmentList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListAnnotationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListAnnotationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListAnnotationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAnnotationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    health: (f = msg.getHealth()) && proto.healthApi.HealthAnnotationPatient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListAnnotationsResponse}
 */
proto.healthApi.ListAnnotationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListAnnotationsResponse;
  return proto.healthApi.ListAnnotationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListAnnotationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListAnnotationsResponse}
 */
proto.healthApi.ListAnnotationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.HealthAnnotationPatient;
      reader.readMessage(value,proto.healthApi.HealthAnnotationPatient.deserializeBinaryFromReader);
      msg.setHealth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListAnnotationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListAnnotationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListAnnotationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListAnnotationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHealth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.HealthAnnotationPatient.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListAnnotationsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListAnnotationsResponse} returns this
 */
proto.healthApi.ListAnnotationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListAnnotationsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAnnotationsResponse} returns this
 */
proto.healthApi.ListAnnotationsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListAnnotationsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListAnnotationsResponse} returns this
 */
proto.healthApi.ListAnnotationsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional HealthAnnotationPatient health = 4;
 * @return {?proto.healthApi.HealthAnnotationPatient}
 */
proto.healthApi.ListAnnotationsResponse.prototype.getHealth = function() {
  return /** @type{?proto.healthApi.HealthAnnotationPatient} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.HealthAnnotationPatient, 4));
};


/**
 * @param {?proto.healthApi.HealthAnnotationPatient|undefined} value
 * @return {!proto.healthApi.ListAnnotationsResponse} returns this
*/
proto.healthApi.ListAnnotationsResponse.prototype.setHealth = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListAnnotationsResponse} returns this
 */
proto.healthApi.ListAnnotationsResponse.prototype.clearHealth = function() {
  return this.setHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListAnnotationsResponse.prototype.hasHealth = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.HealthAnnotationPatient.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.HealthAnnotationPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.HealthAnnotationPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.HealthAnnotationPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HealthAnnotationPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    healthRecordId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    annotationsList: jspb.Message.toObjectList(msg.getAnnotationsList(),
    proto.healthApi.AnnotationPatient.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.HealthAnnotationPatient}
 */
proto.healthApi.HealthAnnotationPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.HealthAnnotationPatient;
  return proto.healthApi.HealthAnnotationPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.HealthAnnotationPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.HealthAnnotationPatient}
 */
proto.healthApi.HealthAnnotationPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthRecordId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientId(value);
      break;
    case 3:
      var value = new proto.healthApi.AnnotationPatient;
      reader.readMessage(value,proto.healthApi.AnnotationPatient.deserializeBinaryFromReader);
      msg.addAnnotations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.HealthAnnotationPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.HealthAnnotationPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.HealthAnnotationPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HealthAnnotationPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHealthRecordId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.AnnotationPatient.serializeBinaryToWriter
    );
  }
};


/**
 * optional string health_record_id = 1;
 * @return {string}
 */
proto.healthApi.HealthAnnotationPatient.prototype.getHealthRecordId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.HealthAnnotationPatient} returns this
 */
proto.healthApi.HealthAnnotationPatient.prototype.setHealthRecordId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string patient_id = 2;
 * @return {string}
 */
proto.healthApi.HealthAnnotationPatient.prototype.getPatientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.HealthAnnotationPatient} returns this
 */
proto.healthApi.HealthAnnotationPatient.prototype.setPatientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AnnotationPatient annotations = 3;
 * @return {!Array<!proto.healthApi.AnnotationPatient>}
 */
proto.healthApi.HealthAnnotationPatient.prototype.getAnnotationsList = function() {
  return /** @type{!Array<!proto.healthApi.AnnotationPatient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.AnnotationPatient, 3));
};


/**
 * @param {!Array<!proto.healthApi.AnnotationPatient>} value
 * @return {!proto.healthApi.HealthAnnotationPatient} returns this
*/
proto.healthApi.HealthAnnotationPatient.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.AnnotationPatient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.AnnotationPatient}
 */
proto.healthApi.HealthAnnotationPatient.prototype.addAnnotations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.AnnotationPatient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.HealthAnnotationPatient} returns this
 */
proto.healthApi.HealthAnnotationPatient.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AnnotationPatient.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AnnotationPatient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AnnotationPatient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AnnotationPatient.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appointmentId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AnnotationPatient}
 */
proto.healthApi.AnnotationPatient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AnnotationPatient;
  return proto.healthApi.AnnotationPatient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AnnotationPatient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AnnotationPatient}
 */
proto.healthApi.AnnotationPatient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnotationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AnnotationPatient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AnnotationPatient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AnnotationPatient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AnnotationPatient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string annotation_id = 1;
 * @return {string}
 */
proto.healthApi.AnnotationPatient.prototype.getAnnotationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnnotationPatient} returns this
 */
proto.healthApi.AnnotationPatient.prototype.setAnnotationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.healthApi.AnnotationPatient.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnnotationPatient} returns this
 */
proto.healthApi.AnnotationPatient.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.AnnotationPatient.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnnotationPatient} returns this
 */
proto.healthApi.AnnotationPatient.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string appointment_id = 4;
 * @return {string}
 */
proto.healthApi.AnnotationPatient.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnnotationPatient} returns this
 */
proto.healthApi.AnnotationPatient.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.healthApi.AnnotationPatient.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnnotationPatient} returns this
 */
proto.healthApi.AnnotationPatient.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string updated_at = 6;
 * @return {string}
 */
proto.healthApi.AnnotationPatient.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnnotationPatient} returns this
 */
proto.healthApi.AnnotationPatient.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListDrugAllergiesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListDrugAllergiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListDrugAllergiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListDrugAllergiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    drugAllergiesList: jspb.Message.toObjectList(msg.getDrugAllergiesList(),
    proto.healthApi.TherapeuticClasses.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListDrugAllergiesResponse}
 */
proto.healthApi.ListDrugAllergiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListDrugAllergiesResponse;
  return proto.healthApi.ListDrugAllergiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListDrugAllergiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListDrugAllergiesResponse}
 */
proto.healthApi.ListDrugAllergiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.TherapeuticClasses;
      reader.readMessage(value,proto.healthApi.TherapeuticClasses.deserializeBinaryFromReader);
      msg.addDrugAllergies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListDrugAllergiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListDrugAllergiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListDrugAllergiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDrugAllergiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.TherapeuticClasses.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListDrugAllergiesResponse} returns this
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListDrugAllergiesResponse} returns this
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListDrugAllergiesResponse} returns this
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TherapeuticClasses drug_allergies = 4;
 * @return {!Array<!proto.healthApi.TherapeuticClasses>}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.getDrugAllergiesList = function() {
  return /** @type{!Array<!proto.healthApi.TherapeuticClasses>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.TherapeuticClasses, 4));
};


/**
 * @param {!Array<!proto.healthApi.TherapeuticClasses>} value
 * @return {!proto.healthApi.ListDrugAllergiesResponse} returns this
*/
proto.healthApi.ListDrugAllergiesResponse.prototype.setDrugAllergiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.TherapeuticClasses=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.TherapeuticClasses}
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.addDrugAllergies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.TherapeuticClasses, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListDrugAllergiesResponse} returns this
 */
proto.healthApi.ListDrugAllergiesResponse.prototype.clearDrugAllergiesList = function() {
  return this.setDrugAllergiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.TherapeuticClasses.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.TherapeuticClasses.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.TherapeuticClasses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.TherapeuticClasses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.TherapeuticClasses.toObject = function(includeInstance, msg) {
  var f, obj = {
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.DrugProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.TherapeuticClasses}
 */
proto.healthApi.TherapeuticClasses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.TherapeuticClasses;
  return proto.healthApi.TherapeuticClasses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.TherapeuticClasses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.TherapeuticClasses}
 */
proto.healthApi.TherapeuticClasses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 2:
      var value = new proto.healthApi.DrugProto;
      reader.readMessage(value,proto.healthApi.DrugProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.TherapeuticClasses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.TherapeuticClasses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.TherapeuticClasses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.TherapeuticClasses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.DrugProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string therapeutic_class = 1;
 * @return {string}
 */
proto.healthApi.TherapeuticClasses.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.TherapeuticClasses} returns this
 */
proto.healthApi.TherapeuticClasses.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DrugProto drugs = 2;
 * @return {!Array<!proto.healthApi.DrugProto>}
 */
proto.healthApi.TherapeuticClasses.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.DrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrugProto, 2));
};


/**
 * @param {!Array<!proto.healthApi.DrugProto>} value
 * @return {!proto.healthApi.TherapeuticClasses} returns this
*/
proto.healthApi.TherapeuticClasses.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.DrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrugProto}
 */
proto.healthApi.TherapeuticClasses.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.DrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.TherapeuticClasses} returns this
 */
proto.healthApi.TherapeuticClasses.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DrugProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DrugProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DrugProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugAllergyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symptoms: jspb.Message.getFieldWithDefault(msg, 8, ""),
    comments: jspb.Message.getFieldWithDefault(msg, 9, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 10, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DrugProto}
 */
proto.healthApi.DrugProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DrugProto;
  return proto.healthApi.DrugProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DrugProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DrugProto}
 */
proto.healthApi.DrugProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugAllergyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymptoms(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DrugProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DrugProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DrugProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugAllergyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymptoms();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string drug_allergy_id = 1;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getDrugAllergyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setDrugAllergyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string regulatory_category = 4;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_type = 5;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symptoms = 8;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getSymptoms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setSymptoms = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string comments = 9;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string therapeutic_class = 10;
 * @return {string}
 */
proto.healthApi.DrugProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool active_edit = 11;
 * @return {boolean}
 */
proto.healthApi.DrugProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool active_delete = 12;
 * @return {boolean}
 */
proto.healthApi.DrugProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrugProto} returns this
 */
proto.healthApi.DrugProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.RestoreReadDbRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.RestoreReadDbRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.RestoreReadDbRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.RestoreReadDbRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.RestoreReadDbRequest}
 */
proto.healthApi.RestoreReadDbRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.RestoreReadDbRequest;
  return proto.healthApi.RestoreReadDbRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.RestoreReadDbRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.RestoreReadDbRequest}
 */
proto.healthApi.RestoreReadDbRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.RestoreReadDbRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.RestoreReadDbRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.RestoreReadDbRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.RestoreReadDbRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.RestoreReadDbResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.RestoreReadDbResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.RestoreReadDbResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.RestoreReadDbResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.RestoreReadDbResponse}
 */
proto.healthApi.RestoreReadDbResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.RestoreReadDbResponse;
  return proto.healthApi.RestoreReadDbResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.RestoreReadDbResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.RestoreReadDbResponse}
 */
proto.healthApi.RestoreReadDbResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.RestoreReadDbResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.RestoreReadDbResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.RestoreReadDbResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.RestoreReadDbResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.RestoreReadDbResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.RestoreReadDbResponse} returns this
 */
proto.healthApi.RestoreReadDbResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.RestoreReadDbResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.RestoreReadDbResponse} returns this
 */
proto.healthApi.RestoreReadDbResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.RestoreReadDbResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.RestoreReadDbResponse} returns this
 */
proto.healthApi.RestoreReadDbResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrugAllergyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrugAllergyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugAllergyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 4, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 5, ""),
    symptoms: jspb.Message.getFieldWithDefault(msg, 6, ""),
    comments: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrugAllergyRequest}
 */
proto.healthApi.CreateDrugAllergyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrugAllergyRequest;
  return proto.healthApi.CreateDrugAllergyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrugAllergyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrugAllergyRequest}
 */
proto.healthApi.CreateDrugAllergyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymptoms(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrugAllergyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrugAllergyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugAllergyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSymptoms();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string therapeutic_class = 2;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string active_principle = 4;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string regulatory_category = 5;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string symptoms = 6;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getSymptoms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setSymptoms = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string comments = 7;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyRequest} returns this
 */
proto.healthApi.CreateDrugAllergyRequest.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrugAllergyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrugAllergyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugAllergyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrugAllergyResponse}
 */
proto.healthApi.CreateDrugAllergyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrugAllergyResponse;
  return proto.healthApi.CreateDrugAllergyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrugAllergyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrugAllergyResponse}
 */
proto.healthApi.CreateDrugAllergyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrugAllergyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrugAllergyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugAllergyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateDrugAllergyResponse} returns this
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyResponse} returns this
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugAllergyResponse} returns this
 */
proto.healthApi.CreateDrugAllergyResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteDrugAllergyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteDrugAllergyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteDrugAllergyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugAllergyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugAllergyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteDrugAllergyRequest}
 */
proto.healthApi.DeleteDrugAllergyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteDrugAllergyRequest;
  return proto.healthApi.DeleteDrugAllergyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteDrugAllergyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteDrugAllergyRequest}
 */
proto.healthApi.DeleteDrugAllergyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugAllergyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteDrugAllergyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteDrugAllergyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteDrugAllergyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugAllergyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugAllergyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string drug_allergy_id = 1;
 * @return {string}
 */
proto.healthApi.DeleteDrugAllergyRequest.prototype.getDrugAllergyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrugAllergyRequest} returns this
 */
proto.healthApi.DeleteDrugAllergyRequest.prototype.setDrugAllergyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteDrugAllergyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteDrugAllergyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugAllergyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteDrugAllergyResponse}
 */
proto.healthApi.DeleteDrugAllergyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteDrugAllergyResponse;
  return proto.healthApi.DeleteDrugAllergyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteDrugAllergyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteDrugAllergyResponse}
 */
proto.healthApi.DeleteDrugAllergyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteDrugAllergyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteDrugAllergyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugAllergyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteDrugAllergyResponse} returns this
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrugAllergyResponse} returns this
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrugAllergyResponse} returns this
 */
proto.healthApi.DeleteDrugAllergyResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateDrugAllergyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateDrugAllergyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrugAllergyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drugAllergyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 5, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 6, ""),
    symptoms: jspb.Message.getFieldWithDefault(msg, 7, ""),
    comments: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateDrugAllergyRequest}
 */
proto.healthApi.UpdateDrugAllergyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateDrugAllergyRequest;
  return proto.healthApi.UpdateDrugAllergyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateDrugAllergyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateDrugAllergyRequest}
 */
proto.healthApi.UpdateDrugAllergyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugAllergyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymptoms(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateDrugAllergyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateDrugAllergyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrugAllergyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrugAllergyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSymptoms();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string drug_allergy_id = 1;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getDrugAllergyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setDrugAllergyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointment_id = 2;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string therapeutic_class = 3;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string active_principle = 5;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string regulatory_category = 6;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string symptoms = 7;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getSymptoms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setSymptoms = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string comments = 8;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyRequest} returns this
 */
proto.healthApi.UpdateDrugAllergyRequest.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateDrugAllergyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateDrugAllergyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrugAllergyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateDrugAllergyResponse}
 */
proto.healthApi.UpdateDrugAllergyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateDrugAllergyResponse;
  return proto.healthApi.UpdateDrugAllergyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateDrugAllergyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateDrugAllergyResponse}
 */
proto.healthApi.UpdateDrugAllergyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateDrugAllergyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateDrugAllergyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrugAllergyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateDrugAllergyResponse} returns this
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyResponse} returns this
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrugAllergyResponse} returns this
 */
proto.healthApi.UpdateDrugAllergyResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateAnnotationRequest}
 */
proto.healthApi.CreateAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateAnnotationRequest;
  return proto.healthApi.CreateAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateAnnotationRequest}
 */
proto.healthApi.CreateAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.CreateAnnotationRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateAnnotationRequest} returns this
 */
proto.healthApi.CreateAnnotationRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.healthApi.CreateAnnotationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateAnnotationRequest} returns this
 */
proto.healthApi.CreateAnnotationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.CreateAnnotationRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateAnnotationRequest} returns this
 */
proto.healthApi.CreateAnnotationRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateAnnotationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateAnnotationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateAnnotationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateAnnotationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateAnnotationResponse}
 */
proto.healthApi.CreateAnnotationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateAnnotationResponse;
  return proto.healthApi.CreateAnnotationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateAnnotationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateAnnotationResponse}
 */
proto.healthApi.CreateAnnotationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateAnnotationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateAnnotationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateAnnotationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateAnnotationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateAnnotationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateAnnotationResponse} returns this
 */
proto.healthApi.CreateAnnotationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateAnnotationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateAnnotationResponse} returns this
 */
proto.healthApi.CreateAnnotationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateAnnotationResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateAnnotationResponse} returns this
 */
proto.healthApi.CreateAnnotationResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateAnnotationRequest}
 */
proto.healthApi.UpdateAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateAnnotationRequest;
  return proto.healthApi.UpdateAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateAnnotationRequest}
 */
proto.healthApi.UpdateAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnotationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string annotation_id = 1;
 * @return {string}
 */
proto.healthApi.UpdateAnnotationRequest.prototype.getAnnotationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateAnnotationRequest} returns this
 */
proto.healthApi.UpdateAnnotationRequest.prototype.setAnnotationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.healthApi.UpdateAnnotationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateAnnotationRequest} returns this
 */
proto.healthApi.UpdateAnnotationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.UpdateAnnotationRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateAnnotationRequest} returns this
 */
proto.healthApi.UpdateAnnotationRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateAnnotationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateAnnotationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateAnnotationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateAnnotationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateAnnotationResponse}
 */
proto.healthApi.UpdateAnnotationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateAnnotationResponse;
  return proto.healthApi.UpdateAnnotationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateAnnotationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateAnnotationResponse}
 */
proto.healthApi.UpdateAnnotationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateAnnotationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateAnnotationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateAnnotationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateAnnotationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.UpdateAnnotationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateAnnotationResponse} returns this
 */
proto.healthApi.UpdateAnnotationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.UpdateAnnotationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateAnnotationResponse} returns this
 */
proto.healthApi.UpdateAnnotationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.UpdateAnnotationResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateAnnotationResponse} returns this
 */
proto.healthApi.UpdateAnnotationResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteAnnotationRequest}
 */
proto.healthApi.DeleteAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteAnnotationRequest;
  return proto.healthApi.DeleteAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteAnnotationRequest}
 */
proto.healthApi.DeleteAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnotationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string annotation_id = 1;
 * @return {string}
 */
proto.healthApi.DeleteAnnotationRequest.prototype.getAnnotationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteAnnotationRequest} returns this
 */
proto.healthApi.DeleteAnnotationRequest.prototype.setAnnotationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteAnnotationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteAnnotationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteAnnotationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteAnnotationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteAnnotationResponse}
 */
proto.healthApi.DeleteAnnotationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteAnnotationResponse;
  return proto.healthApi.DeleteAnnotationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteAnnotationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteAnnotationResponse}
 */
proto.healthApi.DeleteAnnotationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteAnnotationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteAnnotationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteAnnotationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteAnnotationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteAnnotationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteAnnotationResponse} returns this
 */
proto.healthApi.DeleteAnnotationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteAnnotationResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteAnnotationResponse} returns this
 */
proto.healthApi.DeleteAnnotationResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteAnnotationResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteAnnotationResponse} returns this
 */
proto.healthApi.DeleteAnnotationResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.StartPatientCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.StartPatientCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.StartPatientCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StartPatientCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.StartPatientCareRequest}
 */
proto.healthApi.StartPatientCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.StartPatientCareRequest;
  return proto.healthApi.StartPatientCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.StartPatientCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.StartPatientCareRequest}
 */
proto.healthApi.StartPatientCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.StartPatientCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.StartPatientCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.StartPatientCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StartPatientCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.StartPatientCareRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.StartPatientCareRequest} returns this
 */
proto.healthApi.StartPatientCareRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.StartPatientCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.StartPatientCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.StartPatientCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StartPatientCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.StartPatientCareResponse}
 */
proto.healthApi.StartPatientCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.StartPatientCareResponse;
  return proto.healthApi.StartPatientCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.StartPatientCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.StartPatientCareResponse}
 */
proto.healthApi.StartPatientCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.StartPatientCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.StartPatientCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.StartPatientCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StartPatientCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.StartPatientCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.StartPatientCareResponse} returns this
 */
proto.healthApi.StartPatientCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.StartPatientCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.StartPatientCareResponse} returns this
 */
proto.healthApi.StartPatientCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.StartPatientCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.StartPatientCareResponse} returns this
 */
proto.healthApi.StartPatientCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.StopPatientCareRequest.repeatedFields_ = [4,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.StopPatientCareRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.StopPatientCareRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.StopPatientCareRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StopPatientCareRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sorting: (f = msg.getSorting()) && proto.healthApi.SortingProto.toObject(includeInstance, f),
    anamnesis: (f = msg.getAnamnesis()) && proto.healthApi.AnamnesisProto.toObject(includeInstance, f),
    diagnosesList: jspb.Message.toObjectList(msg.getDiagnosesList(),
    proto.healthApi.DiagnosisProto.toObject, includeInstance),
    laboratoryExam: (f = msg.getLaboratoryExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    imagingExam: (f = msg.getImagingExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    otherExam: (f = msg.getOtherExam()) && proto.healthApi.ExamRequestProto.toObject(includeInstance, f),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.PrescriptionDrugProto.toObject, includeInstance),
    forwardingsList: jspb.Message.toObjectList(msg.getForwardingsList(),
    proto.healthApi.ForwardingProto.toObject, includeInstance),
    medicalCertificatesList: jspb.Message.toObjectList(msg.getMedicalCertificatesList(),
    proto.healthApi.MedicalCertificateProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.StopPatientCareRequest}
 */
proto.healthApi.StopPatientCareRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.StopPatientCareRequest;
  return proto.healthApi.StopPatientCareRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.StopPatientCareRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.StopPatientCareRequest}
 */
proto.healthApi.StopPatientCareRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = new proto.healthApi.SortingProto;
      reader.readMessage(value,proto.healthApi.SortingProto.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    case 3:
      var value = new proto.healthApi.AnamnesisProto;
      reader.readMessage(value,proto.healthApi.AnamnesisProto.deserializeBinaryFromReader);
      msg.setAnamnesis(value);
      break;
    case 4:
      var value = new proto.healthApi.DiagnosisProto;
      reader.readMessage(value,proto.healthApi.DiagnosisProto.deserializeBinaryFromReader);
      msg.addDiagnoses(value);
      break;
    case 5:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setLaboratoryExam(value);
      break;
    case 6:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setImagingExam(value);
      break;
    case 7:
      var value = new proto.healthApi.ExamRequestProto;
      reader.readMessage(value,proto.healthApi.ExamRequestProto.deserializeBinaryFromReader);
      msg.setOtherExam(value);
      break;
    case 8:
      var value = new proto.healthApi.PrescriptionDrugProto;
      reader.readMessage(value,proto.healthApi.PrescriptionDrugProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    case 9:
      var value = new proto.healthApi.ForwardingProto;
      reader.readMessage(value,proto.healthApi.ForwardingProto.deserializeBinaryFromReader);
      msg.addForwardings(value);
      break;
    case 10:
      var value = new proto.healthApi.MedicalCertificateProto;
      reader.readMessage(value,proto.healthApi.MedicalCertificateProto.deserializeBinaryFromReader);
      msg.addMedicalCertificates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.StopPatientCareRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.StopPatientCareRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.StopPatientCareRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StopPatientCareRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.healthApi.SortingProto.serializeBinaryToWriter
    );
  }
  f = message.getAnamnesis();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.healthApi.AnamnesisProto.serializeBinaryToWriter
    );
  }
  f = message.getDiagnosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.DiagnosisProto.serializeBinaryToWriter
    );
  }
  f = message.getLaboratoryExam();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getImagingExam();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getOtherExam();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.healthApi.ExamRequestProto.serializeBinaryToWriter
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.healthApi.PrescriptionDrugProto.serializeBinaryToWriter
    );
  }
  f = message.getForwardingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.healthApi.ForwardingProto.serializeBinaryToWriter
    );
  }
  f = message.getMedicalCertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.healthApi.MedicalCertificateProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.StopPatientCareRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SortingProto sorting = 2;
 * @return {?proto.healthApi.SortingProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.getSorting = function() {
  return /** @type{?proto.healthApi.SortingProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.SortingProto, 2));
};


/**
 * @param {?proto.healthApi.SortingProto|undefined} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.StopPatientCareRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnamnesisProto anamnesis = 3;
 * @return {?proto.healthApi.AnamnesisProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.getAnamnesis = function() {
  return /** @type{?proto.healthApi.AnamnesisProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.AnamnesisProto, 3));
};


/**
 * @param {?proto.healthApi.AnamnesisProto|undefined} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setAnamnesis = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearAnamnesis = function() {
  return this.setAnamnesis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.StopPatientCareRequest.prototype.hasAnamnesis = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated DiagnosisProto diagnoses = 4;
 * @return {!Array<!proto.healthApi.DiagnosisProto>}
 */
proto.healthApi.StopPatientCareRequest.prototype.getDiagnosesList = function() {
  return /** @type{!Array<!proto.healthApi.DiagnosisProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DiagnosisProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.DiagnosisProto>} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setDiagnosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.DiagnosisProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DiagnosisProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.addDiagnoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.DiagnosisProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearDiagnosesList = function() {
  return this.setDiagnosesList([]);
};


/**
 * optional ExamRequestProto laboratory_exam = 5;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.getLaboratoryExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 5));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setLaboratoryExam = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearLaboratoryExam = function() {
  return this.setLaboratoryExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.StopPatientCareRequest.prototype.hasLaboratoryExam = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ExamRequestProto imaging_exam = 6;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.getImagingExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 6));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setImagingExam = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearImagingExam = function() {
  return this.setImagingExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.StopPatientCareRequest.prototype.hasImagingExam = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExamRequestProto other_exam = 7;
 * @return {?proto.healthApi.ExamRequestProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.getOtherExam = function() {
  return /** @type{?proto.healthApi.ExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamRequestProto, 7));
};


/**
 * @param {?proto.healthApi.ExamRequestProto|undefined} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setOtherExam = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearOtherExam = function() {
  return this.setOtherExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.StopPatientCareRequest.prototype.hasOtherExam = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated PrescriptionDrugProto drugs = 8;
 * @return {!Array<!proto.healthApi.PrescriptionDrugProto>}
 */
proto.healthApi.StopPatientCareRequest.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.PrescriptionDrugProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PrescriptionDrugProto, 8));
};


/**
 * @param {!Array<!proto.healthApi.PrescriptionDrugProto>} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.healthApi.PrescriptionDrugProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PrescriptionDrugProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.healthApi.PrescriptionDrugProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};


/**
 * repeated ForwardingProto forwardings = 9;
 * @return {!Array<!proto.healthApi.ForwardingProto>}
 */
proto.healthApi.StopPatientCareRequest.prototype.getForwardingsList = function() {
  return /** @type{!Array<!proto.healthApi.ForwardingProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ForwardingProto, 9));
};


/**
 * @param {!Array<!proto.healthApi.ForwardingProto>} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setForwardingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.healthApi.ForwardingProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ForwardingProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.addForwardings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.healthApi.ForwardingProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearForwardingsList = function() {
  return this.setForwardingsList([]);
};


/**
 * repeated MedicalCertificateProto medical_certificates = 10;
 * @return {!Array<!proto.healthApi.MedicalCertificateProto>}
 */
proto.healthApi.StopPatientCareRequest.prototype.getMedicalCertificatesList = function() {
  return /** @type{!Array<!proto.healthApi.MedicalCertificateProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.MedicalCertificateProto, 10));
};


/**
 * @param {!Array<!proto.healthApi.MedicalCertificateProto>} value
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
*/
proto.healthApi.StopPatientCareRequest.prototype.setMedicalCertificatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.healthApi.MedicalCertificateProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.MedicalCertificateProto}
 */
proto.healthApi.StopPatientCareRequest.prototype.addMedicalCertificates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.healthApi.MedicalCertificateProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.StopPatientCareRequest} returns this
 */
proto.healthApi.StopPatientCareRequest.prototype.clearMedicalCertificatesList = function() {
  return this.setMedicalCertificatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.MedicalCertificateProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.MedicalCertificateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.MedicalCertificateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.MedicalCertificateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalCertificateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.healthApi.MedicalCertificateCidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.MedicalCertificateProto}
 */
proto.healthApi.MedicalCertificateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.MedicalCertificateProto;
  return proto.healthApi.MedicalCertificateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.MedicalCertificateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.MedicalCertificateProto}
 */
proto.healthApi.MedicalCertificateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 3:
      var value = new proto.healthApi.MedicalCertificateCidProto;
      reader.readMessage(value,proto.healthApi.MedicalCertificateCidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.MedicalCertificateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.MedicalCertificateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.MedicalCertificateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalCertificateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.MedicalCertificateCidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.healthApi.MedicalCertificateProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalCertificateProto} returns this
 */
proto.healthApi.MedicalCertificateProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orientation = 2;
 * @return {string}
 */
proto.healthApi.MedicalCertificateProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalCertificateProto} returns this
 */
proto.healthApi.MedicalCertificateProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MedicalCertificateCidProto cids = 3;
 * @return {!Array<!proto.healthApi.MedicalCertificateCidProto>}
 */
proto.healthApi.MedicalCertificateProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.healthApi.MedicalCertificateCidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.MedicalCertificateCidProto, 3));
};


/**
 * @param {!Array<!proto.healthApi.MedicalCertificateCidProto>} value
 * @return {!proto.healthApi.MedicalCertificateProto} returns this
*/
proto.healthApi.MedicalCertificateProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.MedicalCertificateCidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.MedicalCertificateCidProto}
 */
proto.healthApi.MedicalCertificateProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.MedicalCertificateCidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.MedicalCertificateProto} returns this
 */
proto.healthApi.MedicalCertificateProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.MedicalCertificateCidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.MedicalCertificateCidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.MedicalCertificateCidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalCertificateCidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.MedicalCertificateCidProto}
 */
proto.healthApi.MedicalCertificateCidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.MedicalCertificateCidProto;
  return proto.healthApi.MedicalCertificateCidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.MedicalCertificateCidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.MedicalCertificateCidProto}
 */
proto.healthApi.MedicalCertificateCidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.MedicalCertificateCidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.MedicalCertificateCidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.MedicalCertificateCidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.MedicalCertificateCidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.healthApi.MedicalCertificateCidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalCertificateCidProto} returns this
 */
proto.healthApi.MedicalCertificateCidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.MedicalCertificateCidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.MedicalCertificateCidProto} returns this
 */
proto.healthApi.MedicalCertificateCidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ForwardingProto.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ForwardingProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ForwardingProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ForwardingProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ForwardingProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    orientation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profession: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.healthApi.ForwardingCidProto.toObject, includeInstance),
    partnershipsList: jspb.Message.toObjectList(msg.getPartnershipsList(),
    proto.healthApi.PartnershipProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ForwardingProto}
 */
proto.healthApi.ForwardingProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ForwardingProto;
  return proto.healthApi.ForwardingProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ForwardingProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ForwardingProto}
 */
proto.healthApi.ForwardingProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfession(value);
      break;
    case 3:
      var value = new proto.healthApi.ForwardingCidProto;
      reader.readMessage(value,proto.healthApi.ForwardingCidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    case 4:
      var value = new proto.healthApi.PartnershipProto;
      reader.readMessage(value,proto.healthApi.PartnershipProto.deserializeBinaryFromReader);
      msg.addPartnerships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ForwardingProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ForwardingProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ForwardingProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ForwardingProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfession();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.ForwardingCidProto.serializeBinaryToWriter
    );
  }
  f = message.getPartnershipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.PartnershipProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string orientation = 1;
 * @return {string}
 */
proto.healthApi.ForwardingProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ForwardingProto} returns this
 */
proto.healthApi.ForwardingProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profession = 2;
 * @return {string}
 */
proto.healthApi.ForwardingProto.prototype.getProfession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ForwardingProto} returns this
 */
proto.healthApi.ForwardingProto.prototype.setProfession = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ForwardingCidProto cids = 3;
 * @return {!Array<!proto.healthApi.ForwardingCidProto>}
 */
proto.healthApi.ForwardingProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.healthApi.ForwardingCidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ForwardingCidProto, 3));
};


/**
 * @param {!Array<!proto.healthApi.ForwardingCidProto>} value
 * @return {!proto.healthApi.ForwardingProto} returns this
*/
proto.healthApi.ForwardingProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.ForwardingCidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ForwardingCidProto}
 */
proto.healthApi.ForwardingProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.ForwardingCidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ForwardingProto} returns this
 */
proto.healthApi.ForwardingProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};


/**
 * repeated PartnershipProto partnerships = 4;
 * @return {!Array<!proto.healthApi.PartnershipProto>}
 */
proto.healthApi.ForwardingProto.prototype.getPartnershipsList = function() {
  return /** @type{!Array<!proto.healthApi.PartnershipProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.PartnershipProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.PartnershipProto>} value
 * @return {!proto.healthApi.ForwardingProto} returns this
*/
proto.healthApi.ForwardingProto.prototype.setPartnershipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.PartnershipProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.PartnershipProto}
 */
proto.healthApi.ForwardingProto.prototype.addPartnerships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.PartnershipProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ForwardingProto} returns this
 */
proto.healthApi.ForwardingProto.prototype.clearPartnershipsList = function() {
  return this.setPartnershipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ForwardingCidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ForwardingCidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ForwardingCidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ForwardingCidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ForwardingCidProto}
 */
proto.healthApi.ForwardingCidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ForwardingCidProto;
  return proto.healthApi.ForwardingCidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ForwardingCidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ForwardingCidProto}
 */
proto.healthApi.ForwardingCidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ForwardingCidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ForwardingCidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ForwardingCidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ForwardingCidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.healthApi.ForwardingCidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ForwardingCidProto} returns this
 */
proto.healthApi.ForwardingCidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.ForwardingCidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ForwardingCidProto} returns this
 */
proto.healthApi.ForwardingCidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PartnershipProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PartnershipProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PartnershipProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PartnershipProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    professionalId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PartnershipProto}
 */
proto.healthApi.PartnershipProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PartnershipProto;
  return proto.healthApi.PartnershipProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PartnershipProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PartnershipProto}
 */
proto.healthApi.PartnershipProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PartnershipProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PartnershipProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PartnershipProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PartnershipProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfessionalId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.healthApi.PartnershipProto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PartnershipProto} returns this
 */
proto.healthApi.PartnershipProto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string picture_url = 2;
 * @return {string}
 */
proto.healthApi.PartnershipProto.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PartnershipProto} returns this
 */
proto.healthApi.PartnershipProto.prototype.setPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string professional_id = 3;
 * @return {string}
 */
proto.healthApi.PartnershipProto.prototype.getProfessionalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PartnershipProto} returns this
 */
proto.healthApi.PartnershipProto.prototype.setProfessionalId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.PrescriptionDrugProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.PrescriptionDrugProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.PrescriptionDrugProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PrescriptionDrugProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 9, 0),
    posology: jspb.Message.getFieldWithDefault(msg, 10, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    category: jspb.Message.getFieldWithDefault(msg, 12, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.PrescriptionDrugProto}
 */
proto.healthApi.PrescriptionDrugProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.PrescriptionDrugProto;
  return proto.healthApi.PrescriptionDrugProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.PrescriptionDrugProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.PrescriptionDrugProto}
 */
proto.healthApi.PrescriptionDrugProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosology(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.PrescriptionDrugProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.PrescriptionDrugProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.PrescriptionDrugProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.PrescriptionDrugProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPosology();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string concentration = 4;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pharmaceutical_form = 5;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amount = 6;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string producer = 7;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 number_copies = 9;
 * @return {number}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string posology = 10;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getPosology = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setPosology = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string indicated_amount = 11;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string category = 12;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string therapeutic_class = 13;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string recommendation = 14;
 * @return {string}
 */
proto.healthApi.PrescriptionDrugProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.PrescriptionDrugProto} returns this
 */
proto.healthApi.PrescriptionDrugProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ExamRequestProto.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ExamRequestProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ExamRequestProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ExamRequestProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamRequestProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    orientation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examList: jspb.Message.toObjectList(msg.getExamList(),
    proto.healthApi.ExamReqProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ExamRequestProto}
 */
proto.healthApi.ExamRequestProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ExamRequestProto;
  return proto.healthApi.ExamRequestProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ExamRequestProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ExamRequestProto}
 */
proto.healthApi.ExamRequestProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 2:
      var value = new proto.healthApi.ExamReqProto;
      reader.readMessage(value,proto.healthApi.ExamReqProto.deserializeBinaryFromReader);
      msg.addExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ExamRequestProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ExamRequestProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ExamRequestProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamRequestProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.ExamReqProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string orientation = 1;
 * @return {string}
 */
proto.healthApi.ExamRequestProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamRequestProto} returns this
 */
proto.healthApi.ExamRequestProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExamReqProto exam = 2;
 * @return {!Array<!proto.healthApi.ExamReqProto>}
 */
proto.healthApi.ExamRequestProto.prototype.getExamList = function() {
  return /** @type{!Array<!proto.healthApi.ExamReqProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ExamReqProto, 2));
};


/**
 * @param {!Array<!proto.healthApi.ExamReqProto>} value
 * @return {!proto.healthApi.ExamRequestProto} returns this
*/
proto.healthApi.ExamRequestProto.prototype.setExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.ExamReqProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ExamReqProto}
 */
proto.healthApi.ExamRequestProto.prototype.addExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.ExamReqProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ExamRequestProto} returns this
 */
proto.healthApi.ExamRequestProto.prototype.clearExamList = function() {
  return this.setExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ExamReqProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ExamReqProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ExamReqProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamReqProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeTuss: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    justification: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ExamReqProto}
 */
proto.healthApi.ExamReqProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ExamReqProto;
  return proto.healthApi.ExamReqProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ExamReqProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ExamReqProto}
 */
proto.healthApi.ExamReqProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ExamReqProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ExamReqProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ExamReqProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamReqProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJustification();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string code_tuss = 1;
 * @return {string}
 */
proto.healthApi.ExamReqProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamReqProto} returns this
 */
proto.healthApi.ExamReqProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.ExamReqProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamReqProto} returns this
 */
proto.healthApi.ExamReqProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string desc_group = 3;
 * @return {string}
 */
proto.healthApi.ExamReqProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamReqProto} returns this
 */
proto.healthApi.ExamReqProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_subgroup = 4;
 * @return {string}
 */
proto.healthApi.ExamReqProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamReqProto} returns this
 */
proto.healthApi.ExamReqProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orientation = 5;
 * @return {string}
 */
proto.healthApi.ExamReqProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamReqProto} returns this
 */
proto.healthApi.ExamReqProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string justification = 6;
 * @return {string}
 */
proto.healthApi.ExamReqProto.prototype.getJustification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamReqProto} returns this
 */
proto.healthApi.ExamReqProto.prototype.setJustification = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.AnamnesisProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.AnamnesisProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.AnamnesisProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AnamnesisProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.AnamnesisProto}
 */
proto.healthApi.AnamnesisProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.AnamnesisProto;
  return proto.healthApi.AnamnesisProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.AnamnesisProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.AnamnesisProto}
 */
proto.healthApi.AnamnesisProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.AnamnesisProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.AnamnesisProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.AnamnesisProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.AnamnesisProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.healthApi.AnamnesisProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.AnamnesisProto} returns this
 */
proto.healthApi.AnamnesisProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.DiagnosisProto.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DiagnosisProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DiagnosisProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DiagnosisProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DiagnosisProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidsList: jspb.Message.toObjectList(msg.getCidsList(),
    proto.healthApi.CidProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DiagnosisProto}
 */
proto.healthApi.DiagnosisProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DiagnosisProto;
  return proto.healthApi.DiagnosisProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DiagnosisProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DiagnosisProto}
 */
proto.healthApi.DiagnosisProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 3:
      var value = new proto.healthApi.CidProto;
      reader.readMessage(value,proto.healthApi.CidProto.deserializeBinaryFromReader);
      msg.addCids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DiagnosisProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DiagnosisProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DiagnosisProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DiagnosisProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.CidProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.healthApi.DiagnosisProto.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DiagnosisProto} returns this
 */
proto.healthApi.DiagnosisProto.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orientation = 2;
 * @return {string}
 */
proto.healthApi.DiagnosisProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DiagnosisProto} returns this
 */
proto.healthApi.DiagnosisProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CidProto cids = 3;
 * @return {!Array<!proto.healthApi.CidProto>}
 */
proto.healthApi.DiagnosisProto.prototype.getCidsList = function() {
  return /** @type{!Array<!proto.healthApi.CidProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.CidProto, 3));
};


/**
 * @param {!Array<!proto.healthApi.CidProto>} value
 * @return {!proto.healthApi.DiagnosisProto} returns this
*/
proto.healthApi.DiagnosisProto.prototype.setCidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.CidProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.CidProto}
 */
proto.healthApi.DiagnosisProto.prototype.addCids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.CidProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.DiagnosisProto} returns this
 */
proto.healthApi.DiagnosisProto.prototype.clearCidsList = function() {
  return this.setCidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CidProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CidProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CidProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CidProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CidProto}
 */
proto.healthApi.CidProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CidProto;
  return proto.healthApi.CidProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CidProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CidProto}
 */
proto.healthApi.CidProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CidProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CidProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CidProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CidProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.healthApi.CidProto.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CidProto} returns this
 */
proto.healthApi.CidProto.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.CidProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CidProto} returns this
 */
proto.healthApi.CidProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SortingProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SortingProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SortingProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SortingProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bloodPressure: (f = msg.getBloodPressure()) && proto.healthApi.BloodPressureProto.toObject(includeInstance, f),
    heartRate: (f = msg.getHeartRate()) && proto.healthApi.HeartRateProto.toObject(includeInstance, f),
    temperature: (f = msg.getTemperature()) && proto.healthApi.TemperatureProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SortingProto}
 */
proto.healthApi.SortingProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SortingProto;
  return proto.healthApi.SortingProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SortingProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SortingProto}
 */
proto.healthApi.SortingProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new proto.healthApi.BloodPressureProto;
      reader.readMessage(value,proto.healthApi.BloodPressureProto.deserializeBinaryFromReader);
      msg.setBloodPressure(value);
      break;
    case 3:
      var value = new proto.healthApi.HeartRateProto;
      reader.readMessage(value,proto.healthApi.HeartRateProto.deserializeBinaryFromReader);
      msg.setHeartRate(value);
      break;
    case 4:
      var value = new proto.healthApi.TemperatureProto;
      reader.readMessage(value,proto.healthApi.TemperatureProto.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SortingProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SortingProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SortingProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SortingProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBloodPressure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.healthApi.BloodPressureProto.serializeBinaryToWriter
    );
  }
  f = message.getHeartRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.healthApi.HeartRateProto.serializeBinaryToWriter
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.TemperatureProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.healthApi.SortingProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SortingProto} returns this
 */
proto.healthApi.SortingProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BloodPressureProto blood_pressure = 2;
 * @return {?proto.healthApi.BloodPressureProto}
 */
proto.healthApi.SortingProto.prototype.getBloodPressure = function() {
  return /** @type{?proto.healthApi.BloodPressureProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.BloodPressureProto, 2));
};


/**
 * @param {?proto.healthApi.BloodPressureProto|undefined} value
 * @return {!proto.healthApi.SortingProto} returns this
*/
proto.healthApi.SortingProto.prototype.setBloodPressure = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.SortingProto} returns this
 */
proto.healthApi.SortingProto.prototype.clearBloodPressure = function() {
  return this.setBloodPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.SortingProto.prototype.hasBloodPressure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HeartRateProto heart_rate = 3;
 * @return {?proto.healthApi.HeartRateProto}
 */
proto.healthApi.SortingProto.prototype.getHeartRate = function() {
  return /** @type{?proto.healthApi.HeartRateProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.HeartRateProto, 3));
};


/**
 * @param {?proto.healthApi.HeartRateProto|undefined} value
 * @return {!proto.healthApi.SortingProto} returns this
*/
proto.healthApi.SortingProto.prototype.setHeartRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.SortingProto} returns this
 */
proto.healthApi.SortingProto.prototype.clearHeartRate = function() {
  return this.setHeartRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.SortingProto.prototype.hasHeartRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TemperatureProto temperature = 4;
 * @return {?proto.healthApi.TemperatureProto}
 */
proto.healthApi.SortingProto.prototype.getTemperature = function() {
  return /** @type{?proto.healthApi.TemperatureProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.TemperatureProto, 4));
};


/**
 * @param {?proto.healthApi.TemperatureProto|undefined} value
 * @return {!proto.healthApi.SortingProto} returns this
*/
proto.healthApi.SortingProto.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.SortingProto} returns this
 */
proto.healthApi.SortingProto.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.SortingProto.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.BloodPressureProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.BloodPressureProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.BloodPressureProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.BloodPressureProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    bloodPressureS: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bloodPressureD: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.BloodPressureProto}
 */
proto.healthApi.BloodPressureProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.BloodPressureProto;
  return proto.healthApi.BloodPressureProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.BloodPressureProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.BloodPressureProto}
 */
proto.healthApi.BloodPressureProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBloodPressureS(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBloodPressureD(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.BloodPressureProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.BloodPressureProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.BloodPressureProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.BloodPressureProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBloodPressureS();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBloodPressureD();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 blood_pressure_s = 1;
 * @return {number}
 */
proto.healthApi.BloodPressureProto.prototype.getBloodPressureS = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.BloodPressureProto} returns this
 */
proto.healthApi.BloodPressureProto.prototype.setBloodPressureS = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 blood_pressure_d = 2;
 * @return {number}
 */
proto.healthApi.BloodPressureProto.prototype.getBloodPressureD = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.BloodPressureProto} returns this
 */
proto.healthApi.BloodPressureProto.prototype.setBloodPressureD = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.HeartRateProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.HeartRateProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.HeartRateProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HeartRateProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    rhythm: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.HeartRateProto}
 */
proto.healthApi.HeartRateProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.HeartRateProto;
  return proto.healthApi.HeartRateProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.HeartRateProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.HeartRateProto}
 */
proto.healthApi.HeartRateProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRhythm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.HeartRateProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.HeartRateProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.HeartRateProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.HeartRateProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRhythm();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 rhythm = 1;
 * @return {number}
 */
proto.healthApi.HeartRateProto.prototype.getRhythm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.HeartRateProto} returns this
 */
proto.healthApi.HeartRateProto.prototype.setRhythm = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.TemperatureProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.TemperatureProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.TemperatureProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.TemperatureProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.TemperatureProto}
 */
proto.healthApi.TemperatureProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.TemperatureProto;
  return proto.healthApi.TemperatureProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.TemperatureProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.TemperatureProto}
 */
proto.healthApi.TemperatureProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.TemperatureProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.TemperatureProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.TemperatureProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.TemperatureProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.healthApi.TemperatureProto.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.TemperatureProto} returns this
 */
proto.healthApi.TemperatureProto.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.StopPatientCareResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.StopPatientCareResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.StopPatientCareResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StopPatientCareResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.StopPatientCareResponse}
 */
proto.healthApi.StopPatientCareResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.StopPatientCareResponse;
  return proto.healthApi.StopPatientCareResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.StopPatientCareResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.StopPatientCareResponse}
 */
proto.healthApi.StopPatientCareResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.StopPatientCareResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.StopPatientCareResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.StopPatientCareResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.StopPatientCareResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.StopPatientCareResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.StopPatientCareResponse} returns this
 */
proto.healthApi.StopPatientCareResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.StopPatientCareResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.StopPatientCareResponse} returns this
 */
proto.healthApi.StopPatientCareResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.StopPatientCareResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.StopPatientCareResponse} returns this
 */
proto.healthApi.StopPatientCareResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.CreateDrugInUseRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrugInUseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrugInUseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrugInUseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugInUseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.CreateDrugInUseProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrugInUseRequest}
 */
proto.healthApi.CreateDrugInUseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrugInUseRequest;
  return proto.healthApi.CreateDrugInUseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrugInUseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrugInUseRequest}
 */
proto.healthApi.CreateDrugInUseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = new proto.healthApi.CreateDrugInUseProto;
      reader.readMessage(value,proto.healthApi.CreateDrugInUseProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrugInUseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrugInUseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrugInUseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugInUseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.CreateDrugInUseProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseRequest} returns this
 */
proto.healthApi.CreateDrugInUseRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CreateDrugInUseProto drugs = 2;
 * @return {!Array<!proto.healthApi.CreateDrugInUseProto>}
 */
proto.healthApi.CreateDrugInUseRequest.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.CreateDrugInUseProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.CreateDrugInUseProto, 2));
};


/**
 * @param {!Array<!proto.healthApi.CreateDrugInUseProto>} value
 * @return {!proto.healthApi.CreateDrugInUseRequest} returns this
*/
proto.healthApi.CreateDrugInUseRequest.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.CreateDrugInUseProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.CreateDrugInUseProto}
 */
proto.healthApi.CreateDrugInUseRequest.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.CreateDrugInUseProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.CreateDrugInUseRequest} returns this
 */
proto.healthApi.CreateDrugInUseRequest.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrugInUseProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrugInUseProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrugInUseProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugInUseProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    apresentationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 9, 0),
    posology: jspb.Message.getFieldWithDefault(msg, 10, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    category: jspb.Message.getFieldWithDefault(msg, 12, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 13, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 14, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 15, ""),
    finalDate: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrugInUseProto}
 */
proto.healthApi.CreateDrugInUseProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrugInUseProto;
  return proto.healthApi.CreateDrugInUseProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrugInUseProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrugInUseProto}
 */
proto.healthApi.CreateDrugInUseProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosology(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrugInUseProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrugInUseProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrugInUseProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugInUseProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPosology();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string apresentation_id = 1;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string concentration = 4;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pharmaceutical_form = 5;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amount = 6;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string producer = 7;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 number_copies = 9;
 * @return {number}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string posology = 10;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getPosology = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setPosology = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string indicated_amount = 11;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string category = 12;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string therapeutic_class = 13;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string recommendation = 14;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string initial_date = 15;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string final_date = 16;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseProto.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseProto} returns this
 */
proto.healthApi.CreateDrugInUseProto.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateDrugInUseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateDrugInUseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateDrugInUseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugInUseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateDrugInUseResponse}
 */
proto.healthApi.CreateDrugInUseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateDrugInUseResponse;
  return proto.healthApi.CreateDrugInUseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateDrugInUseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateDrugInUseResponse}
 */
proto.healthApi.CreateDrugInUseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateDrugInUseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateDrugInUseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateDrugInUseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateDrugInUseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateDrugInUseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateDrugInUseResponse} returns this
 */
proto.healthApi.CreateDrugInUseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseResponse} returns this
 */
proto.healthApi.CreateDrugInUseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateDrugInUseResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateDrugInUseResponse} returns this
 */
proto.healthApi.CreateDrugInUseResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteDrugInUseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteDrugInUseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteDrugInUseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugInUseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    prescriptionDrugId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteDrugInUseRequest}
 */
proto.healthApi.DeleteDrugInUseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteDrugInUseRequest;
  return proto.healthApi.DeleteDrugInUseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteDrugInUseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteDrugInUseRequest}
 */
proto.healthApi.DeleteDrugInUseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrescriptionDrugId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteDrugInUseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteDrugInUseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteDrugInUseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugInUseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrescriptionDrugId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string prescription_drug_id = 1;
 * @return {string}
 */
proto.healthApi.DeleteDrugInUseRequest.prototype.getPrescriptionDrugId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrugInUseRequest} returns this
 */
proto.healthApi.DeleteDrugInUseRequest.prototype.setPrescriptionDrugId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteDrugInUseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteDrugInUseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugInUseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteDrugInUseResponse}
 */
proto.healthApi.DeleteDrugInUseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteDrugInUseResponse;
  return proto.healthApi.DeleteDrugInUseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteDrugInUseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteDrugInUseResponse}
 */
proto.healthApi.DeleteDrugInUseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteDrugInUseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteDrugInUseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrugInUseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteDrugInUseResponse} returns this
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrugInUseResponse} returns this
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrugInUseResponse} returns this
 */
proto.healthApi.DeleteDrugInUseResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListDrugsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListDrugsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListDrugsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListDrugsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListDrugsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.DrugTherapeuticClasses.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListDrugsResponse}
 */
proto.healthApi.ListDrugsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListDrugsResponse;
  return proto.healthApi.ListDrugsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListDrugsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListDrugsResponse}
 */
proto.healthApi.ListDrugsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.DrugTherapeuticClasses;
      reader.readMessage(value,proto.healthApi.DrugTherapeuticClasses.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListDrugsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListDrugsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListDrugsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListDrugsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.DrugTherapeuticClasses.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListDrugsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListDrugsResponse} returns this
 */
proto.healthApi.ListDrugsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListDrugsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListDrugsResponse} returns this
 */
proto.healthApi.ListDrugsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListDrugsResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListDrugsResponse} returns this
 */
proto.healthApi.ListDrugsResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DrugTherapeuticClasses drugs = 4;
 * @return {!Array<!proto.healthApi.DrugTherapeuticClasses>}
 */
proto.healthApi.ListDrugsResponse.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.DrugTherapeuticClasses>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrugTherapeuticClasses, 4));
};


/**
 * @param {!Array<!proto.healthApi.DrugTherapeuticClasses>} value
 * @return {!proto.healthApi.ListDrugsResponse} returns this
*/
proto.healthApi.ListDrugsResponse.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.DrugTherapeuticClasses=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrugTherapeuticClasses}
 */
proto.healthApi.ListDrugsResponse.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.DrugTherapeuticClasses, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListDrugsResponse} returns this
 */
proto.healthApi.ListDrugsResponse.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListLifestylesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListLifestylesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListLifestylesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListLifestylesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListLifestylesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lifestylesList: jspb.Message.toObjectList(msg.getLifestylesList(),
    proto.healthApi.LifestyleProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListLifestylesResponse}
 */
proto.healthApi.ListLifestylesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListLifestylesResponse;
  return proto.healthApi.ListLifestylesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListLifestylesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListLifestylesResponse}
 */
proto.healthApi.ListLifestylesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.LifestyleProto;
      reader.readMessage(value,proto.healthApi.LifestyleProto.deserializeBinaryFromReader);
      msg.addLifestyles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListLifestylesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListLifestylesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListLifestylesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListLifestylesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLifestylesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.LifestyleProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListLifestylesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListLifestylesResponse} returns this
 */
proto.healthApi.ListLifestylesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListLifestylesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListLifestylesResponse} returns this
 */
proto.healthApi.ListLifestylesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListLifestylesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListLifestylesResponse} returns this
 */
proto.healthApi.ListLifestylesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated LifestyleProto lifestyles = 4;
 * @return {!Array<!proto.healthApi.LifestyleProto>}
 */
proto.healthApi.ListLifestylesResponse.prototype.getLifestylesList = function() {
  return /** @type{!Array<!proto.healthApi.LifestyleProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.LifestyleProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.LifestyleProto>} value
 * @return {!proto.healthApi.ListLifestylesResponse} returns this
*/
proto.healthApi.ListLifestylesResponse.prototype.setLifestylesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.LifestyleProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.LifestyleProto}
 */
proto.healthApi.ListLifestylesResponse.prototype.addLifestyles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.LifestyleProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListLifestylesResponse} returns this
 */
proto.healthApi.ListLifestylesResponse.prototype.clearLifestylesList = function() {
  return this.setLifestylesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.LifestyleProto.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.LifestyleProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.LifestyleProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.LifestyleProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.LifestyleProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    exercisesList: jspb.Message.toObjectList(msg.getExercisesList(),
    proto.healthApi.ExerciseProto.toObject, includeInstance),
    drinksList: jspb.Message.toObjectList(msg.getDrinksList(),
    proto.healthApi.DrinkProto.toObject, includeInstance),
    smokersList: jspb.Message.toObjectList(msg.getSmokersList(),
    proto.healthApi.SmokerProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.LifestyleProto}
 */
proto.healthApi.LifestyleProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.LifestyleProto;
  return proto.healthApi.LifestyleProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.LifestyleProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.LifestyleProto}
 */
proto.healthApi.LifestyleProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.healthApi.ExerciseProto;
      reader.readMessage(value,proto.healthApi.ExerciseProto.deserializeBinaryFromReader);
      msg.addExercises(value);
      break;
    case 2:
      var value = new proto.healthApi.DrinkProto;
      reader.readMessage(value,proto.healthApi.DrinkProto.deserializeBinaryFromReader);
      msg.addDrinks(value);
      break;
    case 3:
      var value = new proto.healthApi.SmokerProto;
      reader.readMessage(value,proto.healthApi.SmokerProto.deserializeBinaryFromReader);
      msg.addSmokers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.LifestyleProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.LifestyleProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.LifestyleProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.LifestyleProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExercisesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.healthApi.ExerciseProto.serializeBinaryToWriter
    );
  }
  f = message.getDrinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.DrinkProto.serializeBinaryToWriter
    );
  }
  f = message.getSmokersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.healthApi.SmokerProto.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExerciseProto exercises = 1;
 * @return {!Array<!proto.healthApi.ExerciseProto>}
 */
proto.healthApi.LifestyleProto.prototype.getExercisesList = function() {
  return /** @type{!Array<!proto.healthApi.ExerciseProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ExerciseProto, 1));
};


/**
 * @param {!Array<!proto.healthApi.ExerciseProto>} value
 * @return {!proto.healthApi.LifestyleProto} returns this
*/
proto.healthApi.LifestyleProto.prototype.setExercisesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.healthApi.ExerciseProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ExerciseProto}
 */
proto.healthApi.LifestyleProto.prototype.addExercises = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.healthApi.ExerciseProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.LifestyleProto} returns this
 */
proto.healthApi.LifestyleProto.prototype.clearExercisesList = function() {
  return this.setExercisesList([]);
};


/**
 * repeated DrinkProto drinks = 2;
 * @return {!Array<!proto.healthApi.DrinkProto>}
 */
proto.healthApi.LifestyleProto.prototype.getDrinksList = function() {
  return /** @type{!Array<!proto.healthApi.DrinkProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrinkProto, 2));
};


/**
 * @param {!Array<!proto.healthApi.DrinkProto>} value
 * @return {!proto.healthApi.LifestyleProto} returns this
*/
proto.healthApi.LifestyleProto.prototype.setDrinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.DrinkProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrinkProto}
 */
proto.healthApi.LifestyleProto.prototype.addDrinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.DrinkProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.LifestyleProto} returns this
 */
proto.healthApi.LifestyleProto.prototype.clearDrinksList = function() {
  return this.setDrinksList([]);
};


/**
 * repeated SmokerProto smokers = 3;
 * @return {!Array<!proto.healthApi.SmokerProto>}
 */
proto.healthApi.LifestyleProto.prototype.getSmokersList = function() {
  return /** @type{!Array<!proto.healthApi.SmokerProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.SmokerProto, 3));
};


/**
 * @param {!Array<!proto.healthApi.SmokerProto>} value
 * @return {!proto.healthApi.LifestyleProto} returns this
*/
proto.healthApi.LifestyleProto.prototype.setSmokersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.healthApi.SmokerProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.SmokerProto}
 */
proto.healthApi.LifestyleProto.prototype.addSmokers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.healthApi.SmokerProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.LifestyleProto} returns this
 */
proto.healthApi.LifestyleProto.prototype.clearSmokersList = function() {
  return this.setSmokersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ExerciseProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ExerciseProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ExerciseProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExerciseProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    exerciseId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typeExercise: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    when: jspb.Message.getFieldWithDefault(msg, 5, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ExerciseProto}
 */
proto.healthApi.ExerciseProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ExerciseProto;
  return proto.healthApi.ExerciseProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ExerciseProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ExerciseProto}
 */
proto.healthApi.ExerciseProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExerciseId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeExercise(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ExerciseProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ExerciseProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ExerciseProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExerciseProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExerciseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypeExercise();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string exercise_id = 1;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getExerciseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setExerciseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type_exercise = 2;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getTypeExercise = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setTypeExercise = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string frequency = 3;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.healthApi.ExerciseProto.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string when = 5;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string duration = 6;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_type = 8;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.healthApi.ExerciseProto.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool active_edit = 10;
 * @return {boolean}
 */
proto.healthApi.ExerciseProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool active_delete = 11;
 * @return {boolean}
 */
proto.healthApi.ExerciseProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ExerciseProto} returns this
 */
proto.healthApi.ExerciseProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DrinkProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DrinkProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DrinkProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrinkProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    drinkId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drinkDesc: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    measure: jspb.Message.getFieldWithDefault(msg, 5, ""),
    when: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DrinkProto}
 */
proto.healthApi.DrinkProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DrinkProto;
  return proto.healthApi.DrinkProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DrinkProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DrinkProto}
 */
proto.healthApi.DrinkProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrinkId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrinkDesc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DrinkProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DrinkProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DrinkProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrinkProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrinkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrinkDesc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string drink_id = 1;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getDrinkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setDrinkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string drink_desc = 2;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getDrinkDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setDrinkDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string frequency = 3;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.healthApi.DrinkProto.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string measure = 5;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string when = 6;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_type = 8;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.healthApi.DrinkProto.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool active_edit = 10;
 * @return {boolean}
 */
proto.healthApi.DrinkProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool active_delete = 11;
 * @return {boolean}
 */
proto.healthApi.DrinkProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrinkProto} returns this
 */
proto.healthApi.DrinkProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SmokerProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SmokerProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SmokerProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SmokerProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    smokerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    measure: jspb.Message.getFieldWithDefault(msg, 5, ""),
    when: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SmokerProto}
 */
proto.healthApi.SmokerProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SmokerProto;
  return proto.healthApi.SmokerProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SmokerProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SmokerProto}
 */
proto.healthApi.SmokerProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmokerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SmokerProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SmokerProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SmokerProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SmokerProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmokerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string smoker_id = 1;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getSmokerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setSmokerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string frequency = 3;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.healthApi.SmokerProto.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string measure = 5;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string when = 6;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_type = 8;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.healthApi.SmokerProto.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool active_edit = 10;
 * @return {boolean}
 */
proto.healthApi.SmokerProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool active_delete = 11;
 * @return {boolean}
 */
proto.healthApi.SmokerProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.SmokerProto} returns this
 */
proto.healthApi.SmokerProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.DrugTherapeuticClasses.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DrugTherapeuticClasses.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DrugTherapeuticClasses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DrugTherapeuticClasses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugTherapeuticClasses.toObject = function(includeInstance, msg) {
  var f, obj = {
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.healthApi.DrugsProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DrugTherapeuticClasses}
 */
proto.healthApi.DrugTherapeuticClasses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DrugTherapeuticClasses;
  return proto.healthApi.DrugTherapeuticClasses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DrugTherapeuticClasses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DrugTherapeuticClasses}
 */
proto.healthApi.DrugTherapeuticClasses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 2:
      var value = new proto.healthApi.DrugsProto;
      reader.readMessage(value,proto.healthApi.DrugsProto.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DrugTherapeuticClasses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DrugTherapeuticClasses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DrugTherapeuticClasses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugTherapeuticClasses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.healthApi.DrugsProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string therapeutic_class = 1;
 * @return {string}
 */
proto.healthApi.DrugTherapeuticClasses.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugTherapeuticClasses} returns this
 */
proto.healthApi.DrugTherapeuticClasses.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DrugsProto drugs = 2;
 * @return {!Array<!proto.healthApi.DrugsProto>}
 */
proto.healthApi.DrugTherapeuticClasses.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.healthApi.DrugsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.DrugsProto, 2));
};


/**
 * @param {!Array<!proto.healthApi.DrugsProto>} value
 * @return {!proto.healthApi.DrugTherapeuticClasses} returns this
*/
proto.healthApi.DrugTherapeuticClasses.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.healthApi.DrugsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.DrugsProto}
 */
proto.healthApi.DrugTherapeuticClasses.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.healthApi.DrugsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.DrugTherapeuticClasses} returns this
 */
proto.healthApi.DrugTherapeuticClasses.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DrugsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DrugsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DrugsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    prescriptionDrugId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    drugRequestId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apresentationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 5, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pharmaceuticalForm: jspb.Message.getFieldWithDefault(msg, 7, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 8, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 9, ""),
    type: jspb.Message.getFieldWithDefault(msg, 10, ""),
    numberCopies: jspb.Message.getFieldWithDefault(msg, 11, 0),
    posology: jspb.Message.getFieldWithDefault(msg, 12, ""),
    indicatedAmount: jspb.Message.getFieldWithDefault(msg, 13, ""),
    category: jspb.Message.getFieldWithDefault(msg, 14, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 15, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 16, ""),
    initialDate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    continuousUse: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    finalDate: jspb.Message.getFieldWithDefault(msg, 19, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 20, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    professionalName: jspb.Message.getFieldWithDefault(msg, 22, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 23, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 25, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DrugsProto}
 */
proto.healthApi.DrugsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DrugsProto;
  return proto.healthApi.DrugsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DrugsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DrugsProto}
 */
proto.healthApi.DrugsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrescriptionDrugId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugRequestId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPharmaceuticalForm(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberCopies(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosology(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndicatedAmount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialDate(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinuousUse(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinalDate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalName(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DrugsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DrugsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DrugsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DrugsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrescriptionDrugId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDrugRequestId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApresentationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPharmaceuticalForm();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNumberCopies();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPosology();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIndicatedAmount();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getInitialDate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getContinuousUse();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getFinalDate();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getProfessionalName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
};


/**
 * optional string prescription_drug_id = 1;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getPrescriptionDrugId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setPrescriptionDrugId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string drug_request_id = 2;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getDrugRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setDrugRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string apresentation_id = 3;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getApresentationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setApresentationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string active_principle = 5;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string concentration = 6;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string pharmaceutical_form = 7;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getPharmaceuticalForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setPharmaceuticalForm = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string amount = 8;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string producer = 9;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string type = 10;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 number_copies = 11;
 * @return {number}
 */
proto.healthApi.DrugsProto.prototype.getNumberCopies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setNumberCopies = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string posology = 12;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getPosology = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setPosology = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string indicated_amount = 13;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getIndicatedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setIndicatedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string category = 14;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string therapeutic_class = 15;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string recommendation = 16;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string initial_date = 17;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getInitialDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setInitialDate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool continuous_use = 18;
 * @return {boolean}
 */
proto.healthApi.DrugsProto.prototype.getContinuousUse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setContinuousUse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string final_date = 19;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getFinalDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setFinalDate = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string user_type = 20;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string user_id = 21;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string professional_name = 22;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getProfessionalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setProfessionalName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string created_at = 23;
 * @return {string}
 */
proto.healthApi.DrugsProto.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool active_edit = 24;
 * @return {boolean}
 */
proto.healthApi.DrugsProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool active_delete = 25;
 * @return {boolean}
 */
proto.healthApi.DrugsProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DrugsProto} returns this
 */
proto.healthApi.DrugsProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateDrinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateDrinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateDrinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drinkid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    drinkdesc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    measure: jspb.Message.getFieldWithDefault(msg, 6, ""),
    when: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateDrinkRequest}
 */
proto.healthApi.UpdateDrinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateDrinkRequest;
  return proto.healthApi.UpdateDrinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateDrinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateDrinkRequest}
 */
proto.healthApi.UpdateDrinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrinkid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrinkdesc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateDrinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateDrinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateDrinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDrinkdesc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string drinkId = 1;
 * @return {string}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getDrinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setDrinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointmentId = 2;
 * @return {string}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string drinkDesc = 3;
 * @return {string}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getDrinkdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setDrinkdesc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string measure = 6;
 * @return {string}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string when = 7;
 * @return {string}
 */
proto.healthApi.UpdateDrinkRequest.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkRequest} returns this
 */
proto.healthApi.UpdateDrinkRequest.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateDrinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateDrinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateDrinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateDrinkResponse}
 */
proto.healthApi.UpdateDrinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateDrinkResponse;
  return proto.healthApi.UpdateDrinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateDrinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateDrinkResponse}
 */
proto.healthApi.UpdateDrinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateDrinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateDrinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateDrinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateDrinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.UpdateDrinkResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateDrinkResponse} returns this
 */
proto.healthApi.UpdateDrinkResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.UpdateDrinkResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkResponse} returns this
 */
proto.healthApi.UpdateDrinkResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.UpdateDrinkResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateDrinkResponse} returns this
 */
proto.healthApi.UpdateDrinkResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteDrinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteDrinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteDrinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drinkid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteDrinkRequest}
 */
proto.healthApi.DeleteDrinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteDrinkRequest;
  return proto.healthApi.DeleteDrinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteDrinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteDrinkRequest}
 */
proto.healthApi.DeleteDrinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrinkid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteDrinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteDrinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteDrinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string drinkId = 1;
 * @return {string}
 */
proto.healthApi.DeleteDrinkRequest.prototype.getDrinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrinkRequest} returns this
 */
proto.healthApi.DeleteDrinkRequest.prototype.setDrinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteDrinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteDrinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteDrinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteDrinkResponse}
 */
proto.healthApi.DeleteDrinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteDrinkResponse;
  return proto.healthApi.DeleteDrinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteDrinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteDrinkResponse}
 */
proto.healthApi.DeleteDrinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteDrinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteDrinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteDrinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteDrinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteDrinkResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteDrinkResponse} returns this
 */
proto.healthApi.DeleteDrinkResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteDrinkResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrinkResponse} returns this
 */
proto.healthApi.DeleteDrinkResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteDrinkResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteDrinkResponse} returns this
 */
proto.healthApi.DeleteDrinkResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateExerciseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateExerciseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateExerciseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateExerciseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typeexercise: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    when: jspb.Message.getFieldWithDefault(msg, 5, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateExerciseRequest}
 */
proto.healthApi.CreateExerciseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateExerciseRequest;
  return proto.healthApi.CreateExerciseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateExerciseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateExerciseRequest}
 */
proto.healthApi.CreateExerciseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeexercise(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateExerciseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateExerciseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateExerciseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateExerciseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypeexercise();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.healthApi.CreateExerciseRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseRequest} returns this
 */
proto.healthApi.CreateExerciseRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string typeExercise = 2;
 * @return {string}
 */
proto.healthApi.CreateExerciseRequest.prototype.getTypeexercise = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseRequest} returns this
 */
proto.healthApi.CreateExerciseRequest.prototype.setTypeexercise = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string frequency = 3;
 * @return {string}
 */
proto.healthApi.CreateExerciseRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseRequest} returns this
 */
proto.healthApi.CreateExerciseRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.healthApi.CreateExerciseRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.CreateExerciseRequest} returns this
 */
proto.healthApi.CreateExerciseRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string when = 5;
 * @return {string}
 */
proto.healthApi.CreateExerciseRequest.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseRequest} returns this
 */
proto.healthApi.CreateExerciseRequest.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string duration = 6;
 * @return {string}
 */
proto.healthApi.CreateExerciseRequest.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseRequest} returns this
 */
proto.healthApi.CreateExerciseRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateExerciseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateExerciseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateExerciseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateExerciseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateExerciseResponse}
 */
proto.healthApi.CreateExerciseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateExerciseResponse;
  return proto.healthApi.CreateExerciseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateExerciseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateExerciseResponse}
 */
proto.healthApi.CreateExerciseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateExerciseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateExerciseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateExerciseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateExerciseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateExerciseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateExerciseResponse} returns this
 */
proto.healthApi.CreateExerciseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateExerciseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseResponse} returns this
 */
proto.healthApi.CreateExerciseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateExerciseResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateExerciseResponse} returns this
 */
proto.healthApi.CreateExerciseResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateExerciseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateExerciseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateExerciseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateExerciseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    exerciseid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeexercise: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    when: jspb.Message.getFieldWithDefault(msg, 6, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateExerciseRequest}
 */
proto.healthApi.UpdateExerciseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateExerciseRequest;
  return proto.healthApi.UpdateExerciseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateExerciseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateExerciseRequest}
 */
proto.healthApi.UpdateExerciseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExerciseid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeexercise(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateExerciseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateExerciseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateExerciseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateExerciseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExerciseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeexercise();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string exerciseId = 1;
 * @return {string}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getExerciseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setExerciseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointmentId = 2;
 * @return {string}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string typeExercise = 3;
 * @return {string}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getTypeexercise = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setTypeexercise = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string when = 6;
 * @return {string}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string duration = 7;
 * @return {string}
 */
proto.healthApi.UpdateExerciseRequest.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseRequest} returns this
 */
proto.healthApi.UpdateExerciseRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateExerciseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateExerciseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateExerciseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateExerciseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateExerciseResponse}
 */
proto.healthApi.UpdateExerciseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateExerciseResponse;
  return proto.healthApi.UpdateExerciseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateExerciseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateExerciseResponse}
 */
proto.healthApi.UpdateExerciseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateExerciseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateExerciseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateExerciseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateExerciseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.UpdateExerciseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateExerciseResponse} returns this
 */
proto.healthApi.UpdateExerciseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.UpdateExerciseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseResponse} returns this
 */
proto.healthApi.UpdateExerciseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.UpdateExerciseResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateExerciseResponse} returns this
 */
proto.healthApi.UpdateExerciseResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteExerciseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteExerciseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteExerciseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExerciseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    exerciseid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteExerciseRequest}
 */
proto.healthApi.DeleteExerciseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteExerciseRequest;
  return proto.healthApi.DeleteExerciseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteExerciseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteExerciseRequest}
 */
proto.healthApi.DeleteExerciseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExerciseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteExerciseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteExerciseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteExerciseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExerciseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExerciseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string exerciseId = 1;
 * @return {string}
 */
proto.healthApi.DeleteExerciseRequest.prototype.getExerciseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExerciseRequest} returns this
 */
proto.healthApi.DeleteExerciseRequest.prototype.setExerciseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteExerciseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteExerciseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteExerciseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExerciseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteExerciseResponse}
 */
proto.healthApi.DeleteExerciseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteExerciseResponse;
  return proto.healthApi.DeleteExerciseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteExerciseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteExerciseResponse}
 */
proto.healthApi.DeleteExerciseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteExerciseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteExerciseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteExerciseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExerciseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteExerciseResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteExerciseResponse} returns this
 */
proto.healthApi.DeleteExerciseResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteExerciseResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExerciseResponse} returns this
 */
proto.healthApi.DeleteExerciseResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteExerciseResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExerciseResponse} returns this
 */
proto.healthApi.DeleteExerciseResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateCigaretteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateCigaretteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateCigaretteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateCigaretteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    measure: jspb.Message.getFieldWithDefault(msg, 6, ""),
    when: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateCigaretteRequest}
 */
proto.healthApi.CreateCigaretteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateCigaretteRequest;
  return proto.healthApi.CreateCigaretteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateCigaretteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateCigaretteRequest}
 */
proto.healthApi.CreateCigaretteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateCigaretteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateCigaretteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateCigaretteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateCigaretteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.healthApi.CreateCigaretteRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteRequest} returns this
 */
proto.healthApi.CreateCigaretteRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.healthApi.CreateCigaretteRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteRequest} returns this
 */
proto.healthApi.CreateCigaretteRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.healthApi.CreateCigaretteRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteRequest} returns this
 */
proto.healthApi.CreateCigaretteRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.healthApi.CreateCigaretteRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.CreateCigaretteRequest} returns this
 */
proto.healthApi.CreateCigaretteRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string measure = 6;
 * @return {string}
 */
proto.healthApi.CreateCigaretteRequest.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteRequest} returns this
 */
proto.healthApi.CreateCigaretteRequest.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string when = 7;
 * @return {string}
 */
proto.healthApi.CreateCigaretteRequest.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteRequest} returns this
 */
proto.healthApi.CreateCigaretteRequest.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateCigaretteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateCigaretteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateCigaretteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateCigaretteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateCigaretteResponse}
 */
proto.healthApi.CreateCigaretteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateCigaretteResponse;
  return proto.healthApi.CreateCigaretteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateCigaretteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateCigaretteResponse}
 */
proto.healthApi.CreateCigaretteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateCigaretteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateCigaretteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateCigaretteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateCigaretteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateCigaretteResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateCigaretteResponse} returns this
 */
proto.healthApi.CreateCigaretteResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateCigaretteResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteResponse} returns this
 */
proto.healthApi.CreateCigaretteResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateCigaretteResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateCigaretteResponse} returns this
 */
proto.healthApi.CreateCigaretteResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateCigaretteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateCigaretteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateCigaretteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    smokerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    measure: jspb.Message.getFieldWithDefault(msg, 6, ""),
    when: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateCigaretteRequest}
 */
proto.healthApi.UpdateCigaretteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateCigaretteRequest;
  return proto.healthApi.UpdateCigaretteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateCigaretteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateCigaretteRequest}
 */
proto.healthApi.UpdateCigaretteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmokerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateCigaretteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateCigaretteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateCigaretteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmokerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWhen();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string smokerId = 1;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getSmokerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setSmokerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string appointmentId = 2;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string frequency = 4;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string measure = 6;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string when = 7;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteRequest.prototype.getWhen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteRequest} returns this
 */
proto.healthApi.UpdateCigaretteRequest.prototype.setWhen = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateCigaretteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateCigaretteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateCigaretteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateCigaretteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateCigaretteResponse}
 */
proto.healthApi.UpdateCigaretteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateCigaretteResponse;
  return proto.healthApi.UpdateCigaretteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateCigaretteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateCigaretteResponse}
 */
proto.healthApi.UpdateCigaretteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateCigaretteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateCigaretteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateCigaretteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateCigaretteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.UpdateCigaretteResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateCigaretteResponse} returns this
 */
proto.healthApi.UpdateCigaretteResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteResponse} returns this
 */
proto.healthApi.UpdateCigaretteResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.UpdateCigaretteResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateCigaretteResponse} returns this
 */
proto.healthApi.UpdateCigaretteResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteCigaretteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteCigaretteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteCigaretteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteCigaretteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    smokerid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteCigaretteRequest}
 */
proto.healthApi.DeleteCigaretteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteCigaretteRequest;
  return proto.healthApi.DeleteCigaretteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteCigaretteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteCigaretteRequest}
 */
proto.healthApi.DeleteCigaretteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmokerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteCigaretteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteCigaretteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteCigaretteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteCigaretteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmokerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string smokerId = 1;
 * @return {string}
 */
proto.healthApi.DeleteCigaretteRequest.prototype.getSmokerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteCigaretteRequest} returns this
 */
proto.healthApi.DeleteCigaretteRequest.prototype.setSmokerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteCigaretteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteCigaretteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteCigaretteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteCigaretteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteCigaretteResponse}
 */
proto.healthApi.DeleteCigaretteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteCigaretteResponse;
  return proto.healthApi.DeleteCigaretteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteCigaretteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteCigaretteResponse}
 */
proto.healthApi.DeleteCigaretteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteCigaretteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteCigaretteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteCigaretteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteCigaretteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteCigaretteResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteCigaretteResponse} returns this
 */
proto.healthApi.DeleteCigaretteResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteCigaretteResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteCigaretteResponse} returns this
 */
proto.healthApi.DeleteCigaretteResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteCigaretteResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteCigaretteResponse} returns this
 */
proto.healthApi.DeleteCigaretteResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ResultsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ResultsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ResultsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ResultsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examresultid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resultname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    res1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unit1: jspb.Message.getFieldWithDefault(msg, 6, ""),
    res1sec: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unit1sec: jspb.Message.getFieldWithDefault(msg, 8, ""),
    name2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    res2: jspb.Message.getFieldWithDefault(msg, 10, ""),
    unit2: jspb.Message.getFieldWithDefault(msg, 11, ""),
    res2sec: jspb.Message.getFieldWithDefault(msg, 12, ""),
    unit2sec: jspb.Message.getFieldWithDefault(msg, 13, ""),
    name3: jspb.Message.getFieldWithDefault(msg, 14, ""),
    res3: jspb.Message.getFieldWithDefault(msg, 15, ""),
    unit3: jspb.Message.getFieldWithDefault(msg, 16, ""),
    res3sec: jspb.Message.getFieldWithDefault(msg, 17, ""),
    unit3sec: jspb.Message.getFieldWithDefault(msg, 18, ""),
    name4: jspb.Message.getFieldWithDefault(msg, 19, ""),
    res4: jspb.Message.getFieldWithDefault(msg, 20, ""),
    unit4: jspb.Message.getFieldWithDefault(msg, 21, ""),
    res4sec: jspb.Message.getFieldWithDefault(msg, 22, ""),
    unit4sec: jspb.Message.getFieldWithDefault(msg, 23, ""),
    name5: jspb.Message.getFieldWithDefault(msg, 24, ""),
    res5: jspb.Message.getFieldWithDefault(msg, 25, ""),
    unit5: jspb.Message.getFieldWithDefault(msg, 26, ""),
    res5sec: jspb.Message.getFieldWithDefault(msg, 27, ""),
    unit5sec: jspb.Message.getFieldWithDefault(msg, 28, ""),
    name6: jspb.Message.getFieldWithDefault(msg, 29, ""),
    res6: jspb.Message.getFieldWithDefault(msg, 30, ""),
    unit6: jspb.Message.getFieldWithDefault(msg, 31, ""),
    res6sec: jspb.Message.getFieldWithDefault(msg, 32, ""),
    unit6sec: jspb.Message.getFieldWithDefault(msg, 33, ""),
    name7: jspb.Message.getFieldWithDefault(msg, 34, ""),
    res7: jspb.Message.getFieldWithDefault(msg, 35, ""),
    unit7: jspb.Message.getFieldWithDefault(msg, 36, ""),
    res7sec: jspb.Message.getFieldWithDefault(msg, 37, ""),
    unit7sec: jspb.Message.getFieldWithDefault(msg, 38, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 39, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 40, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 41, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 42, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 43, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ResultsProto}
 */
proto.healthApi.ResultsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ResultsProto;
  return proto.healthApi.ResultsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ResultsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ResultsProto}
 */
proto.healthApi.ResultsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamresultid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1sec(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1sec(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2sec(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2sec(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setName3(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3sec(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3sec(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setName4(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4sec(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4sec(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName5(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5sec(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5sec(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setName6(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6sec(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6sec(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setName7(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7sec(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7sec(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsertype(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ResultsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ResultsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ResultsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ResultsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamresultid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResultname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRes1();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnit1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRes1sec();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnit1sec();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getName2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRes2();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUnit2();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRes2sec();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUnit2sec();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getName3();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRes3();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUnit3();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRes3sec();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUnit3sec();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getName4();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRes4();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUnit4();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRes4sec();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getUnit4sec();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getName5();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getRes5();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getUnit5();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getRes5sec();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getUnit5sec();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getName6();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getRes6();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getUnit6();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getRes6sec();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getUnit6sec();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getName7();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getRes7();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getUnit7();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getRes7sec();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getUnit7sec();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getUsertype();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
};


/**
 * optional string resultId = 1;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getResultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setResultid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string examResultId = 2;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getExamresultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setExamresultid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resultName = 3;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getResultname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setResultname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name1 = 4;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string res1 = 5;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes1 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unit1 = 6;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string res1Sec = 7;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes1sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes1sec = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string unit1Sec = 8;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit1sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit1sec = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string name2 = 9;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string res2 = 10;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes2 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string unit2 = 11;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit2 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string res2Sec = 12;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes2sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes2sec = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string unit2Sec = 13;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit2sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit2sec = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string name3 = 14;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName3 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string res3 = 15;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes3 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string unit3 = 16;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit3 = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string res3Sec = 17;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes3sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes3sec = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string unit3Sec = 18;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit3sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit3sec = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string name4 = 19;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName4 = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string res4 = 20;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes4 = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string unit4 = 21;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit4 = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string res4Sec = 22;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes4sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes4sec = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string unit4Sec = 23;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit4sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit4sec = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string name5 = 24;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName5 = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string res5 = 25;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes5 = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string unit5 = 26;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit5 = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string res5Sec = 27;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes5sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes5sec = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string unit5Sec = 28;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit5sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit5sec = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string name6 = 29;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName6 = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string res6 = 30;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes6 = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string unit6 = 31;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit6 = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string res6Sec = 32;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes6sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes6sec = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string unit6Sec = 33;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit6sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit6sec = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string name7 = 34;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getName7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setName7 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string res7 = 35;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes7 = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string unit7 = 36;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit7 = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string res7Sec = 37;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getRes7sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setRes7sec = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string unit7Sec = 38;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUnit7sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUnit7sec = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string observation = 39;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string userType = 40;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUsertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string userId = 41;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string createdAt = 42;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string updatedAt = 43;
 * @return {string}
 */
proto.healthApi.ResultsProto.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultsProto} returns this
 */
proto.healthApi.ResultsProto.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SearchDrugAllergiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SearchDrugAllergiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SearchDrugAllergiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchDrugAllergiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SearchDrugAllergiesRequest}
 */
proto.healthApi.SearchDrugAllergiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SearchDrugAllergiesRequest;
  return proto.healthApi.SearchDrugAllergiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SearchDrugAllergiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SearchDrugAllergiesRequest}
 */
proto.healthApi.SearchDrugAllergiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SearchDrugAllergiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SearchDrugAllergiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SearchDrugAllergiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchDrugAllergiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.healthApi.SearchDrugAllergiesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchDrugAllergiesRequest} returns this
 */
proto.healthApi.SearchDrugAllergiesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.SearchDrugAllergiesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SearchDrugAllergiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SearchDrugAllergiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchDrugAllergiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    drugallergiesList: jspb.Message.toObjectList(msg.getDrugallergiesList(),
    proto.healthApi.TherapeuticClasses.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SearchDrugAllergiesResponse}
 */
proto.healthApi.SearchDrugAllergiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SearchDrugAllergiesResponse;
  return proto.healthApi.SearchDrugAllergiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SearchDrugAllergiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SearchDrugAllergiesResponse}
 */
proto.healthApi.SearchDrugAllergiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.TherapeuticClasses;
      reader.readMessage(value,proto.healthApi.TherapeuticClasses.deserializeBinaryFromReader);
      msg.addDrugallergies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SearchDrugAllergiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SearchDrugAllergiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchDrugAllergiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDrugallergiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.TherapeuticClasses.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.SearchDrugAllergiesResponse} returns this
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchDrugAllergiesResponse} returns this
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchDrugAllergiesResponse} returns this
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TherapeuticClasses drugAllergies = 4;
 * @return {!Array<!proto.healthApi.TherapeuticClasses>}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.getDrugallergiesList = function() {
  return /** @type{!Array<!proto.healthApi.TherapeuticClasses>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.TherapeuticClasses, 4));
};


/**
 * @param {!Array<!proto.healthApi.TherapeuticClasses>} value
 * @return {!proto.healthApi.SearchDrugAllergiesResponse} returns this
*/
proto.healthApi.SearchDrugAllergiesResponse.prototype.setDrugallergiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.TherapeuticClasses=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.TherapeuticClasses}
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.addDrugallergies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.TherapeuticClasses, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.SearchDrugAllergiesResponse} returns this
 */
proto.healthApi.SearchDrugAllergiesResponse.prototype.clearDrugallergiesList = function() {
  return this.setDrugallergiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.UpdateResultExamRequest.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateResultExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateResultExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateResultExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateResultExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examresultid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collectiondate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onsitecollection: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    material: jspb.Message.getFieldWithDefault(msg, 5, ""),
    method: jspb.Message.getFieldWithDefault(msg, 6, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    laboratory: jspb.Message.getFieldWithDefault(msg, 8, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 9, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    releasedate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    profname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    registry: jspb.Message.getFieldWithDefault(msg, 13, ""),
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.healthApi.UpdateResultProto.toObject, includeInstance),
    imageList: jspb.Message.toObjectList(msg.getImageList(),
    proto.healthApi.UpdateImageProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateResultExamRequest}
 */
proto.healthApi.UpdateResultExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateResultExamRequest;
  return proto.healthApi.UpdateResultExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateResultExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateResultExamRequest}
 */
proto.healthApi.UpdateResultExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamresultid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectiondate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnsitecollection(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLaboratory(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleasedate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistry(value);
      break;
    case 14:
      var value = new proto.healthApi.UpdateResultProto;
      reader.readMessage(value,proto.healthApi.UpdateResultProto.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 15:
      var value = new proto.healthApi.UpdateImageProto;
      reader.readMessage(value,proto.healthApi.UpdateImageProto.deserializeBinaryFromReader);
      msg.addImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateResultExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateResultExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateResultExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateResultExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamresultid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollectiondate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnsitecollection();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLaboratory();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReleasedate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProfname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRegistry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.healthApi.UpdateResultProto.serializeBinaryToWriter
    );
  }
  f = message.getImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.healthApi.UpdateImageProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string examResultId = 1;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getExamresultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setExamresultid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string examName = 2;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getExamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setExamname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string collectionDate = 3;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getCollectiondate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setCollectiondate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool onSiteCollection = 4;
 * @return {boolean}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getOnsitecollection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setOnsitecollection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string material = 5;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string method = 6;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string equipment = 7;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string laboratory = 8;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getLaboratory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setLaboratory = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string unit = 9;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string observation = 10;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string releaseDate = 11;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getReleasedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setReleasedate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string profName = 12;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getProfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setProfname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string registry = 13;
 * @return {string}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getRegistry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated UpdateResultProto result = 14;
 * @return {!Array<!proto.healthApi.UpdateResultProto>}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getResultList = function() {
  return /** @type{!Array<!proto.healthApi.UpdateResultProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.UpdateResultProto, 14));
};


/**
 * @param {!Array<!proto.healthApi.UpdateResultProto>} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
*/
proto.healthApi.UpdateResultExamRequest.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.healthApi.UpdateResultProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.UpdateResultProto}
 */
proto.healthApi.UpdateResultExamRequest.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.healthApi.UpdateResultProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.clearResultList = function() {
  return this.setResultList([]);
};


/**
 * repeated UpdateImageProto image = 15;
 * @return {!Array<!proto.healthApi.UpdateImageProto>}
 */
proto.healthApi.UpdateResultExamRequest.prototype.getImageList = function() {
  return /** @type{!Array<!proto.healthApi.UpdateImageProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.UpdateImageProto, 15));
};


/**
 * @param {!Array<!proto.healthApi.UpdateImageProto>} value
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
*/
proto.healthApi.UpdateResultExamRequest.prototype.setImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.healthApi.UpdateImageProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.UpdateImageProto}
 */
proto.healthApi.UpdateResultExamRequest.prototype.addImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.healthApi.UpdateImageProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.UpdateResultExamRequest} returns this
 */
proto.healthApi.UpdateResultExamRequest.prototype.clearImageList = function() {
  return this.setImageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateResultProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateResultProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateResultProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateResultProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examresultid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resultname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    res1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unit1: jspb.Message.getFieldWithDefault(msg, 6, ""),
    res1sec: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unit1sec: jspb.Message.getFieldWithDefault(msg, 8, ""),
    name2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    res2: jspb.Message.getFieldWithDefault(msg, 10, ""),
    unit2: jspb.Message.getFieldWithDefault(msg, 11, ""),
    res2sec: jspb.Message.getFieldWithDefault(msg, 12, ""),
    unit2sec: jspb.Message.getFieldWithDefault(msg, 13, ""),
    name3: jspb.Message.getFieldWithDefault(msg, 14, ""),
    res3: jspb.Message.getFieldWithDefault(msg, 15, ""),
    unit3: jspb.Message.getFieldWithDefault(msg, 16, ""),
    res3sec: jspb.Message.getFieldWithDefault(msg, 17, ""),
    unit3sec: jspb.Message.getFieldWithDefault(msg, 18, ""),
    name4: jspb.Message.getFieldWithDefault(msg, 19, ""),
    res4: jspb.Message.getFieldWithDefault(msg, 20, ""),
    unit4: jspb.Message.getFieldWithDefault(msg, 21, ""),
    res4sec: jspb.Message.getFieldWithDefault(msg, 22, ""),
    unit4sec: jspb.Message.getFieldWithDefault(msg, 23, ""),
    name5: jspb.Message.getFieldWithDefault(msg, 24, ""),
    res5: jspb.Message.getFieldWithDefault(msg, 25, ""),
    unit5: jspb.Message.getFieldWithDefault(msg, 26, ""),
    res5sec: jspb.Message.getFieldWithDefault(msg, 27, ""),
    unit5sec: jspb.Message.getFieldWithDefault(msg, 28, ""),
    name6: jspb.Message.getFieldWithDefault(msg, 29, ""),
    res6: jspb.Message.getFieldWithDefault(msg, 30, ""),
    unit6: jspb.Message.getFieldWithDefault(msg, 31, ""),
    res6sec: jspb.Message.getFieldWithDefault(msg, 32, ""),
    unit6sec: jspb.Message.getFieldWithDefault(msg, 33, ""),
    name7: jspb.Message.getFieldWithDefault(msg, 34, ""),
    res7: jspb.Message.getFieldWithDefault(msg, 35, ""),
    unit7: jspb.Message.getFieldWithDefault(msg, 36, ""),
    res7sec: jspb.Message.getFieldWithDefault(msg, 37, ""),
    unit7sec: jspb.Message.getFieldWithDefault(msg, 38, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 39, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateResultProto}
 */
proto.healthApi.UpdateResultProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateResultProto;
  return proto.healthApi.UpdateResultProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateResultProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateResultProto}
 */
proto.healthApi.UpdateResultProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamresultid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1sec(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1sec(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2sec(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2sec(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setName3(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3sec(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3sec(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setName4(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4sec(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4sec(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setName5(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5sec(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5sec(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setName6(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6sec(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6sec(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setName7(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7sec(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7sec(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateResultProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateResultProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateResultProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateResultProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamresultid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResultname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRes1();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnit1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRes1sec();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnit1sec();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getName2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRes2();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUnit2();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRes2sec();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUnit2sec();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getName3();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRes3();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUnit3();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRes3sec();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUnit3sec();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getName4();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRes4();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUnit4();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRes4sec();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getUnit4sec();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getName5();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getRes5();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getUnit5();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getRes5sec();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getUnit5sec();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getName6();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getRes6();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getUnit6();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getRes6sec();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getUnit6sec();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getName7();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getRes7();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getUnit7();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getRes7sec();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getUnit7sec();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
};


/**
 * optional string resultId = 1;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getResultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setResultid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string examResultId = 2;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getExamresultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setExamresultid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resultName = 3;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getResultname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setResultname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name1 = 4;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string res1 = 5;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes1 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unit1 = 6;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string res1Sec = 7;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes1sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes1sec = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string unit1Sec = 8;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit1sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit1sec = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string name2 = 9;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string res2 = 10;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes2 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string unit2 = 11;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit2 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string res2Sec = 12;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes2sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes2sec = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string unit2Sec = 13;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit2sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit2sec = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string name3 = 14;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName3 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string res3 = 15;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes3 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string unit3 = 16;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit3 = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string res3Sec = 17;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes3sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes3sec = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string unit3Sec = 18;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit3sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit3sec = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string name4 = 19;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName4 = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string res4 = 20;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes4 = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string unit4 = 21;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit4 = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string res4Sec = 22;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes4sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes4sec = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string unit4Sec = 23;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit4sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit4sec = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string name5 = 24;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName5 = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string res5 = 25;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes5 = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string unit5 = 26;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit5 = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string res5Sec = 27;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes5sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes5sec = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string unit5Sec = 28;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit5sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit5sec = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string name6 = 29;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName6 = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string res6 = 30;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes6 = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string unit6 = 31;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit6 = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string res6Sec = 32;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes6sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes6sec = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string unit6Sec = 33;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit6sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit6sec = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string name7 = 34;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getName7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setName7 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string res7 = 35;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes7 = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string unit7 = 36;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit7 = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string res7Sec = 37;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getRes7sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setRes7sec = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string unit7Sec = 38;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getUnit7sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setUnit7sec = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string observation = 39;
 * @return {string}
 */
proto.healthApi.UpdateResultProto.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultProto} returns this
 */
proto.healthApi.UpdateResultProto.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateImageProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateImageProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateImageProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateImageProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examresultid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    urlexam: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateImageProto}
 */
proto.healthApi.UpdateImageProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateImageProto;
  return proto.healthApi.UpdateImageProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateImageProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateImageProto}
 */
proto.healthApi.UpdateImageProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamresultid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlexam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateImageProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateImageProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateImageProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateImageProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamresultid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrlexam();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string imageId = 1;
 * @return {string}
 */
proto.healthApi.UpdateImageProto.prototype.getImageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateImageProto} returns this
 */
proto.healthApi.UpdateImageProto.prototype.setImageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string examResultId = 2;
 * @return {string}
 */
proto.healthApi.UpdateImageProto.prototype.getExamresultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateImageProto} returns this
 */
proto.healthApi.UpdateImageProto.prototype.setExamresultid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string urlExam = 3;
 * @return {string}
 */
proto.healthApi.UpdateImageProto.prototype.getUrlexam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateImageProto} returns this
 */
proto.healthApi.UpdateImageProto.prototype.setUrlexam = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.UpdateResultExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.UpdateResultExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.UpdateResultExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateResultExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.UpdateResultExamResponse}
 */
proto.healthApi.UpdateResultExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.UpdateResultExamResponse;
  return proto.healthApi.UpdateResultExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.UpdateResultExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.UpdateResultExamResponse}
 */
proto.healthApi.UpdateResultExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.UpdateResultExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.UpdateResultExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.UpdateResultExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.UpdateResultExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.UpdateResultExamResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.UpdateResultExamResponse} returns this
 */
proto.healthApi.UpdateResultExamResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.UpdateResultExamResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamResponse} returns this
 */
proto.healthApi.UpdateResultExamResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.UpdateResultExamResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.UpdateResultExamResponse} returns this
 */
proto.healthApi.UpdateResultExamResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.CreateRequestResultRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateRequestResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateRequestResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateRequestResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateRequestResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prescdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    urlexam: jspb.Message.getFieldWithDefault(msg, 5, ""),
    examList: jspb.Message.toObjectList(msg.getExamList(),
    proto.healthApi.ExamProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateRequestResultRequest}
 */
proto.healthApi.CreateRequestResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateRequestResultRequest;
  return proto.healthApi.CreateRequestResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateRequestResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateRequestResultRequest}
 */
proto.healthApi.CreateRequestResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrescdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlexam(value);
      break;
    case 6:
      var value = new proto.healthApi.ExamProto;
      reader.readMessage(value,proto.healthApi.ExamProto.deserializeBinaryFromReader);
      msg.addExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateRequestResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateRequestResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateRequestResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateRequestResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrescdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrlexam();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.healthApi.ExamProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointmentId = 1;
 * @return {string}
 */
proto.healthApi.CreateRequestResultRequest.prototype.getAppointmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
 */
proto.healthApi.CreateRequestResultRequest.prototype.setAppointmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prescDate = 2;
 * @return {string}
 */
proto.healthApi.CreateRequestResultRequest.prototype.getPrescdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
 */
proto.healthApi.CreateRequestResultRequest.prototype.setPrescdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profName = 3;
 * @return {string}
 */
proto.healthApi.CreateRequestResultRequest.prototype.getProfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
 */
proto.healthApi.CreateRequestResultRequest.prototype.setProfname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string orientation = 4;
 * @return {string}
 */
proto.healthApi.CreateRequestResultRequest.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
 */
proto.healthApi.CreateRequestResultRequest.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string urlExam = 5;
 * @return {string}
 */
proto.healthApi.CreateRequestResultRequest.prototype.getUrlexam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
 */
proto.healthApi.CreateRequestResultRequest.prototype.setUrlexam = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ExamProto exam = 6;
 * @return {!Array<!proto.healthApi.ExamProto>}
 */
proto.healthApi.CreateRequestResultRequest.prototype.getExamList = function() {
  return /** @type{!Array<!proto.healthApi.ExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ExamProto, 6));
};


/**
 * @param {!Array<!proto.healthApi.ExamProto>} value
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
*/
proto.healthApi.CreateRequestResultRequest.prototype.setExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.healthApi.ExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ExamProto}
 */
proto.healthApi.CreateRequestResultRequest.prototype.addExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.healthApi.ExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.CreateRequestResultRequest} returns this
 */
proto.healthApi.CreateRequestResultRequest.prototype.clearExamList = function() {
  return this.setExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ExamProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ExamProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ExamProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    codetuss: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descgroup: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descsubgroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    urlexam: jspb.Message.getFieldWithDefault(msg, 5, ""),
    numberpage: jspb.Message.getFieldWithDefault(msg, 6, 0),
    examresult: (f = msg.getExamresult()) && proto.healthApi.ExamResultProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ExamProto}
 */
proto.healthApi.ExamProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ExamProto;
  return proto.healthApi.ExamProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ExamProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ExamProto}
 */
proto.healthApi.ExamProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodetuss(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescgroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescsubgroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlexam(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberpage(value);
      break;
    case 7:
      var value = new proto.healthApi.ExamResultProto;
      reader.readMessage(value,proto.healthApi.ExamResultProto.deserializeBinaryFromReader);
      msg.setExamresult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ExamProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ExamProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ExamProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodetuss();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescgroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescsubgroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrlexam();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumberpage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getExamresult();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.healthApi.ExamResultProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string codeTuss = 1;
 * @return {string}
 */
proto.healthApi.ExamProto.prototype.getCodetuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.setCodetuss = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.healthApi.ExamProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string descGroup = 3;
 * @return {string}
 */
proto.healthApi.ExamProto.prototype.getDescgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.setDescgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string descSubgroup = 4;
 * @return {string}
 */
proto.healthApi.ExamProto.prototype.getDescsubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.setDescsubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string urlExam = 5;
 * @return {string}
 */
proto.healthApi.ExamProto.prototype.getUrlexam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.setUrlexam = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 numberPage = 6;
 * @return {number}
 */
proto.healthApi.ExamProto.prototype.getNumberpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.setNumberpage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ExamResultProto examResult = 7;
 * @return {?proto.healthApi.ExamResultProto}
 */
proto.healthApi.ExamProto.prototype.getExamresult = function() {
  return /** @type{?proto.healthApi.ExamResultProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ExamResultProto, 7));
};


/**
 * @param {?proto.healthApi.ExamResultProto|undefined} value
 * @return {!proto.healthApi.ExamProto} returns this
*/
proto.healthApi.ExamProto.prototype.setExamresult = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ExamProto} returns this
 */
proto.healthApi.ExamProto.prototype.clearExamresult = function() {
  return this.setExamresult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ExamProto.prototype.hasExamresult = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ExamResultProto.repeatedFields_ = [16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ExamResultProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ExamResultProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ExamResultProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamResultProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    collectiondate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    onsitecollection: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    material: jspb.Message.getFieldWithDefault(msg, 4, ""),
    method: jspb.Message.getFieldWithDefault(msg, 5, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    laboratory: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 8, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 9, ""),
    releasedate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    profname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    registry: jspb.Message.getFieldWithDefault(msg, 12, ""),
    authentication: jspb.Message.getFieldWithDefault(msg, 13, ""),
    examreport: jspb.Message.getFieldWithDefault(msg, 14, ""),
    urlexam: jspb.Message.getFieldWithDefault(msg, 15, ""),
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.healthApi.ResultProto.toObject, includeInstance),
    imageList: jspb.Message.toObjectList(msg.getImageList(),
    proto.healthApi.ImageProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ExamResultProto}
 */
proto.healthApi.ExamResultProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ExamResultProto;
  return proto.healthApi.ExamResultProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ExamResultProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ExamResultProto}
 */
proto.healthApi.ExamResultProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectiondate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnsitecollection(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLaboratory(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleasedate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfname(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistry(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthentication(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamreport(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlexam(value);
      break;
    case 16:
      var value = new proto.healthApi.ResultProto;
      reader.readMessage(value,proto.healthApi.ResultProto.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 17:
      var value = new proto.healthApi.ImageProto;
      reader.readMessage(value,proto.healthApi.ImageProto.deserializeBinaryFromReader);
      msg.addImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ExamResultProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ExamResultProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ExamResultProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamResultProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCollectiondate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOnsitecollection();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLaboratory();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReleasedate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProfname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRegistry();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAuthentication();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExamreport();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUrlexam();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.healthApi.ResultProto.serializeBinaryToWriter
    );
  }
  f = message.getImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.healthApi.ImageProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string examName = 1;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getExamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setExamname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string collectionDate = 2;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getCollectiondate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setCollectiondate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool onSiteCollection = 3;
 * @return {boolean}
 */
proto.healthApi.ExamResultProto.prototype.getOnsitecollection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setOnsitecollection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string material = 4;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string method = 5;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string equipment = 6;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string laboratory = 7;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getLaboratory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setLaboratory = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string unit = 8;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string observation = 9;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string releaseDate = 10;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getReleasedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setReleasedate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string profName = 11;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getProfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setProfname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string registry = 12;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getRegistry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string authentication = 13;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getAuthentication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setAuthentication = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string examReport = 14;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getExamreport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setExamreport = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string urlExam = 15;
 * @return {string}
 */
proto.healthApi.ExamResultProto.prototype.getUrlexam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.setUrlexam = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated ResultProto result = 16;
 * @return {!Array<!proto.healthApi.ResultProto>}
 */
proto.healthApi.ExamResultProto.prototype.getResultList = function() {
  return /** @type{!Array<!proto.healthApi.ResultProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ResultProto, 16));
};


/**
 * @param {!Array<!proto.healthApi.ResultProto>} value
 * @return {!proto.healthApi.ExamResultProto} returns this
*/
proto.healthApi.ExamResultProto.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.healthApi.ResultProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ResultProto}
 */
proto.healthApi.ExamResultProto.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.healthApi.ResultProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.clearResultList = function() {
  return this.setResultList([]);
};


/**
 * repeated ImageProto image = 17;
 * @return {!Array<!proto.healthApi.ImageProto>}
 */
proto.healthApi.ExamResultProto.prototype.getImageList = function() {
  return /** @type{!Array<!proto.healthApi.ImageProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ImageProto, 17));
};


/**
 * @param {!Array<!proto.healthApi.ImageProto>} value
 * @return {!proto.healthApi.ExamResultProto} returns this
*/
proto.healthApi.ExamResultProto.prototype.setImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.healthApi.ImageProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ImageProto}
 */
proto.healthApi.ExamResultProto.prototype.addImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.healthApi.ImageProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ExamResultProto} returns this
 */
proto.healthApi.ExamResultProto.prototype.clearImageList = function() {
  return this.setImageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ResultProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ResultProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ResultProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ResultProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    res1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unit1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    res1sec: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unit1sec: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name2: jspb.Message.getFieldWithDefault(msg, 7, ""),
    res2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    unit2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    res2sec: jspb.Message.getFieldWithDefault(msg, 10, ""),
    unit2sec: jspb.Message.getFieldWithDefault(msg, 11, ""),
    name3: jspb.Message.getFieldWithDefault(msg, 12, ""),
    res3: jspb.Message.getFieldWithDefault(msg, 13, ""),
    unit3: jspb.Message.getFieldWithDefault(msg, 14, ""),
    res3sec: jspb.Message.getFieldWithDefault(msg, 15, ""),
    unit3sec: jspb.Message.getFieldWithDefault(msg, 16, ""),
    name4: jspb.Message.getFieldWithDefault(msg, 17, ""),
    res4: jspb.Message.getFieldWithDefault(msg, 18, ""),
    unit4: jspb.Message.getFieldWithDefault(msg, 19, ""),
    res4sec: jspb.Message.getFieldWithDefault(msg, 20, ""),
    unit4sec: jspb.Message.getFieldWithDefault(msg, 21, ""),
    name5: jspb.Message.getFieldWithDefault(msg, 22, ""),
    res5: jspb.Message.getFieldWithDefault(msg, 23, ""),
    unit5: jspb.Message.getFieldWithDefault(msg, 24, ""),
    res5sec: jspb.Message.getFieldWithDefault(msg, 25, ""),
    unit5sec: jspb.Message.getFieldWithDefault(msg, 26, ""),
    name6: jspb.Message.getFieldWithDefault(msg, 27, ""),
    res6: jspb.Message.getFieldWithDefault(msg, 28, ""),
    unit6: jspb.Message.getFieldWithDefault(msg, 29, ""),
    res6sec: jspb.Message.getFieldWithDefault(msg, 30, ""),
    unit6sec: jspb.Message.getFieldWithDefault(msg, 31, ""),
    name7: jspb.Message.getFieldWithDefault(msg, 32, ""),
    res7: jspb.Message.getFieldWithDefault(msg, 33, ""),
    unit7: jspb.Message.getFieldWithDefault(msg, 34, ""),
    res7sec: jspb.Message.getFieldWithDefault(msg, 35, ""),
    unit7sec: jspb.Message.getFieldWithDefault(msg, 36, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 37, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ResultProto}
 */
proto.healthApi.ResultProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ResultProto;
  return proto.healthApi.ResultProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ResultProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ResultProto}
 */
proto.healthApi.ResultProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1sec(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1sec(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName2(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2sec(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2sec(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName3(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3sec(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3sec(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setName4(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4sec(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4sec(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setName5(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5sec(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5sec(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setName6(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6sec(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6sec(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setName7(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7sec(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7sec(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ResultProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ResultProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ResultProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ResultProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRes1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnit1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRes1sec();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnit1sec();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName2();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRes2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnit2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRes2sec();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUnit2sec();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getName3();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRes3();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUnit3();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRes3sec();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUnit3sec();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getName4();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRes4();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUnit4();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRes4sec();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUnit4sec();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getName5();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getRes5();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getUnit5();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getRes5sec();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getUnit5sec();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getName6();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getRes6();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getUnit6();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getRes6sec();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getUnit6sec();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getName7();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getRes7();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getUnit7();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getRes7sec();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getUnit7sec();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
};


/**
 * optional string resultName = 1;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getResultname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setResultname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name1 = 2;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string res1 = 3;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unit1 = 4;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string res1Sec = 5;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes1sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes1sec = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unit1Sec = 6;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit1sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit1sec = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name2 = 7;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName2 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string res2 = 8;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string unit2 = 9;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string res2Sec = 10;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes2sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes2sec = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string unit2Sec = 11;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit2sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit2sec = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string name3 = 12;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName3 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string res3 = 13;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes3 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string unit3 = 14;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit3 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string res3Sec = 15;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes3sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes3sec = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string unit3Sec = 16;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit3sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit3sec = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string name4 = 17;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName4 = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string res4 = 18;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes4 = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string unit4 = 19;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit4 = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string res4Sec = 20;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes4sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes4sec = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string unit4Sec = 21;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit4sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit4sec = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string name5 = 22;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName5 = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string res5 = 23;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes5 = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string unit5 = 24;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit5 = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string res5Sec = 25;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes5sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes5sec = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string unit5Sec = 26;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit5sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit5sec = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string name6 = 27;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName6 = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string res6 = 28;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes6 = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string unit6 = 29;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit6 = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string res6Sec = 30;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes6sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes6sec = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string unit6Sec = 31;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit6sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit6sec = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string name7 = 32;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getName7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setName7 = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string res7 = 33;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes7 = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string unit7 = 34;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit7 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string res7Sec = 35;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getRes7sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setRes7sec = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string unit7Sec = 36;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getUnit7sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setUnit7sec = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string observation = 37;
 * @return {string}
 */
proto.healthApi.ResultProto.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ResultProto} returns this
 */
proto.healthApi.ResultProto.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ImageProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ImageProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ImageProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ImageProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    urlexam: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ImageProto}
 */
proto.healthApi.ImageProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ImageProto;
  return proto.healthApi.ImageProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ImageProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ImageProto}
 */
proto.healthApi.ImageProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlexam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ImageProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ImageProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ImageProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ImageProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrlexam();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string imageId = 1;
 * @return {string}
 */
proto.healthApi.ImageProto.prototype.getImageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ImageProto} returns this
 */
proto.healthApi.ImageProto.prototype.setImageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string urlExam = 2;
 * @return {string}
 */
proto.healthApi.ImageProto.prototype.getUrlexam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ImageProto} returns this
 */
proto.healthApi.ImageProto.prototype.setUrlexam = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.CreateRequestResultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.CreateRequestResultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.CreateRequestResultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateRequestResultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.CreateRequestResultResponse}
 */
proto.healthApi.CreateRequestResultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.CreateRequestResultResponse;
  return proto.healthApi.CreateRequestResultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.CreateRequestResultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.CreateRequestResultResponse}
 */
proto.healthApi.CreateRequestResultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.CreateRequestResultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.CreateRequestResultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.CreateRequestResultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.CreateRequestResultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.CreateRequestResultResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.CreateRequestResultResponse} returns this
 */
proto.healthApi.CreateRequestResultResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.CreateRequestResultResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultResponse} returns this
 */
proto.healthApi.CreateRequestResultResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.CreateRequestResultResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.CreateRequestResultResponse} returns this
 */
proto.healthApi.CreateRequestResultResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListExamHistoryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListExamHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListExamHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListExamHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examList: jspb.Message.toObjectList(msg.getExamList(),
    proto.healthApi.ExamHistoryProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListExamHistoryResponse}
 */
proto.healthApi.ListExamHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListExamHistoryResponse;
  return proto.healthApi.ListExamHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListExamHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListExamHistoryResponse}
 */
proto.healthApi.ListExamHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.ExamHistoryProto;
      reader.readMessage(value,proto.healthApi.ExamHistoryProto.deserializeBinaryFromReader);
      msg.addExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListExamHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListExamHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListExamHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.ExamHistoryProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListExamHistoryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListExamHistoryResponse} returns this
 */
proto.healthApi.ListExamHistoryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListExamHistoryResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamHistoryResponse} returns this
 */
proto.healthApi.ListExamHistoryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListExamHistoryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamHistoryResponse} returns this
 */
proto.healthApi.ListExamHistoryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ExamHistoryProto exam = 4;
 * @return {!Array<!proto.healthApi.ExamHistoryProto>}
 */
proto.healthApi.ListExamHistoryResponse.prototype.getExamList = function() {
  return /** @type{!Array<!proto.healthApi.ExamHistoryProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ExamHistoryProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.ExamHistoryProto>} value
 * @return {!proto.healthApi.ListExamHistoryResponse} returns this
*/
proto.healthApi.ListExamHistoryResponse.prototype.setExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.ExamHistoryProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ExamHistoryProto}
 */
proto.healthApi.ListExamHistoryResponse.prototype.addExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.ExamHistoryProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListExamHistoryResponse} returns this
 */
proto.healthApi.ListExamHistoryResponse.prototype.clearExamList = function() {
  return this.setExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ExamHistoryProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ExamHistoryProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ExamHistoryProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamHistoryProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 9, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ExamHistoryProto}
 */
proto.healthApi.ExamHistoryProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ExamHistoryProto;
  return proto.healthApi.ExamHistoryProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ExamHistoryProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ExamHistoryProto}
 */
proto.healthApi.ExamHistoryProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ExamHistoryProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ExamHistoryProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ExamHistoryProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ExamHistoryProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code_tuss = 2;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_group = 4;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string desc_subgroup = 5;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_type = 8;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string createdAt = 9;
 * @return {string}
 */
proto.healthApi.ExamHistoryProto.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool active_edit = 10;
 * @return {boolean}
 */
proto.healthApi.ExamHistoryProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool active_delete = 11;
 * @return {boolean}
 */
proto.healthApi.ExamHistoryProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ExamHistoryProto} returns this
 */
proto.healthApi.ExamHistoryProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListExamRequest}
 */
proto.healthApi.ListExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListExamRequest;
  return proto.healthApi.ListExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListExamRequest}
 */
proto.healthApi.ListExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string examId = 1;
 * @return {string}
 */
proto.healthApi.ListExamRequest.prototype.getExamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamRequest} returns this
 */
proto.healthApi.ListExamRequest.prototype.setExamid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examRequest: (f = msg.getExamRequest()) && proto.healthApi.ListExamRequestProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListExamResponse}
 */
proto.healthApi.ListExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListExamResponse;
  return proto.healthApi.ListExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListExamResponse}
 */
proto.healthApi.ListExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.ListExamRequestProto;
      reader.readMessage(value,proto.healthApi.ListExamRequestProto.deserializeBinaryFromReader);
      msg.setExamRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.ListExamRequestProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListExamResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListExamResponse} returns this
 */
proto.healthApi.ListExamResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListExamResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResponse} returns this
 */
proto.healthApi.ListExamResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListExamResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResponse} returns this
 */
proto.healthApi.ListExamResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListExamRequestProto exam_request = 4;
 * @return {?proto.healthApi.ListExamRequestProto}
 */
proto.healthApi.ListExamResponse.prototype.getExamRequest = function() {
  return /** @type{?proto.healthApi.ListExamRequestProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ListExamRequestProto, 4));
};


/**
 * @param {?proto.healthApi.ListExamRequestProto|undefined} value
 * @return {!proto.healthApi.ListExamResponse} returns this
*/
proto.healthApi.ListExamResponse.prototype.setExamRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListExamResponse} returns this
 */
proto.healthApi.ListExamResponse.prototype.clearExamRequest = function() {
  return this.setExamRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListExamResponse.prototype.hasExamRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListExamRequestProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListExamRequestProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListExamRequestProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamRequestProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examRequestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prescDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exam: (f = msg.getExam()) && proto.healthApi.ListExamProto.toObject(includeInstance, f),
    information: (f = msg.getInformation()) && proto.healthApi.InformationProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListExamRequestProto}
 */
proto.healthApi.ListExamRequestProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListExamRequestProto;
  return proto.healthApi.ListExamRequestProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListExamRequestProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListExamRequestProto}
 */
proto.healthApi.ListExamRequestProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrescDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 5:
      var value = new proto.healthApi.ListExamProto;
      reader.readMessage(value,proto.healthApi.ListExamProto.deserializeBinaryFromReader);
      msg.setExam(value);
      break;
    case 6:
      var value = new proto.healthApi.InformationProto;
      reader.readMessage(value,proto.healthApi.InformationProto.deserializeBinaryFromReader);
      msg.setInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListExamRequestProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListExamRequestProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListExamRequestProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamRequestProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrescDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExam();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.healthApi.ListExamProto.serializeBinaryToWriter
    );
  }
  f = message.getInformation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.healthApi.InformationProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_request_id = 1;
 * @return {string}
 */
proto.healthApi.ListExamRequestProto.prototype.getExamRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamRequestProto} returns this
 */
proto.healthApi.ListExamRequestProto.prototype.setExamRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string presc_date = 2;
 * @return {string}
 */
proto.healthApi.ListExamRequestProto.prototype.getPrescDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamRequestProto} returns this
 */
proto.healthApi.ListExamRequestProto.prototype.setPrescDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string prof_name = 3;
 * @return {string}
 */
proto.healthApi.ListExamRequestProto.prototype.getProfName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamRequestProto} returns this
 */
proto.healthApi.ListExamRequestProto.prototype.setProfName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string orientation = 4;
 * @return {string}
 */
proto.healthApi.ListExamRequestProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamRequestProto} returns this
 */
proto.healthApi.ListExamRequestProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ListExamProto exam = 5;
 * @return {?proto.healthApi.ListExamProto}
 */
proto.healthApi.ListExamRequestProto.prototype.getExam = function() {
  return /** @type{?proto.healthApi.ListExamProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ListExamProto, 5));
};


/**
 * @param {?proto.healthApi.ListExamProto|undefined} value
 * @return {!proto.healthApi.ListExamRequestProto} returns this
*/
proto.healthApi.ListExamRequestProto.prototype.setExam = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListExamRequestProto} returns this
 */
proto.healthApi.ListExamRequestProto.prototype.clearExam = function() {
  return this.setExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListExamRequestProto.prototype.hasExam = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional InformationProto information = 6;
 * @return {?proto.healthApi.InformationProto}
 */
proto.healthApi.ListExamRequestProto.prototype.getInformation = function() {
  return /** @type{?proto.healthApi.InformationProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.InformationProto, 6));
};


/**
 * @param {?proto.healthApi.InformationProto|undefined} value
 * @return {!proto.healthApi.ListExamRequestProto} returns this
*/
proto.healthApi.ListExamRequestProto.prototype.setInformation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListExamRequestProto} returns this
 */
proto.healthApi.ListExamRequestProto.prototype.clearInformation = function() {
  return this.setInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListExamRequestProto.prototype.hasInformation = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.InformationProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.InformationProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.InformationProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.InformationProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activeEdit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    activeDelete: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.InformationProto}
 */
proto.healthApi.InformationProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.InformationProto;
  return proto.healthApi.InformationProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.InformationProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.InformationProto}
 */
proto.healthApi.InformationProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveEdit(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.InformationProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.InformationProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.InformationProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.InformationProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActiveEdit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getActiveDelete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.healthApi.InformationProto.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.InformationProto} returns this
 */
proto.healthApi.InformationProto.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_type = 2;
 * @return {string}
 */
proto.healthApi.InformationProto.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.InformationProto} returns this
 */
proto.healthApi.InformationProto.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active_edit = 3;
 * @return {boolean}
 */
proto.healthApi.InformationProto.prototype.getActiveEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.InformationProto} returns this
 */
proto.healthApi.InformationProto.prototype.setActiveEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool active_delete = 4;
 * @return {boolean}
 */
proto.healthApi.InformationProto.prototype.getActiveDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.InformationProto} returns this
 */
proto.healthApi.InformationProto.prototype.setActiveDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListExamProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListExamProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListExamProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    codeTuss: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descGroup: jspb.Message.getFieldWithDefault(msg, 4, ""),
    descSubgroup: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, ""),
    urlexam: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numberpage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    examResult: (f = msg.getExamResult()) && proto.healthApi.ListExamResultProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListExamProto}
 */
proto.healthApi.ListExamProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListExamProto;
  return proto.healthApi.ListExamProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListExamProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListExamProto}
 */
proto.healthApi.ListExamProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeTuss(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescSubgroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlexam(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberpage(value);
      break;
    case 10:
      var value = new proto.healthApi.ListExamResultProto;
      reader.readMessage(value,proto.healthApi.ListExamResultProto.deserializeBinaryFromReader);
      msg.setExamResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListExamProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListExamProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListExamProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeTuss();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescGroup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescSubgroup();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUrlexam();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumberpage();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getExamResult();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.healthApi.ListExamResultProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code_tuss = 2;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getCodeTuss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setCodeTuss = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string desc_group = 4;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getDescGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setDescGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string desc_subgroup = 5;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getDescSubgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setDescSubgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string urlExam = 8;
 * @return {string}
 */
proto.healthApi.ListExamProto.prototype.getUrlexam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setUrlexam = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 numberPage = 9;
 * @return {number}
 */
proto.healthApi.ListExamProto.prototype.getNumberpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.setNumberpage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ListExamResultProto exam_result = 10;
 * @return {?proto.healthApi.ListExamResultProto}
 */
proto.healthApi.ListExamProto.prototype.getExamResult = function() {
  return /** @type{?proto.healthApi.ListExamResultProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ListExamResultProto, 10));
};


/**
 * @param {?proto.healthApi.ListExamResultProto|undefined} value
 * @return {!proto.healthApi.ListExamProto} returns this
*/
proto.healthApi.ListExamProto.prototype.setExamResult = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListExamProto} returns this
 */
proto.healthApi.ListExamProto.prototype.clearExamResult = function() {
  return this.setExamResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListExamProto.prototype.hasExamResult = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListExamResultProto.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListExamResultProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListExamResultProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListExamResultProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamResultProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examResultId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collectionDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onSiteCollection: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    material: jspb.Message.getFieldWithDefault(msg, 5, ""),
    method: jspb.Message.getFieldWithDefault(msg, 6, ""),
    equipment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    laboratory: jspb.Message.getFieldWithDefault(msg, 8, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 9, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    releaseDate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    profName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    registry: jspb.Message.getFieldWithDefault(msg, 13, ""),
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.healthApi.ListResultsProto.toObject, includeInstance),
    imageList: jspb.Message.toObjectList(msg.getImageList(),
    proto.healthApi.ListImagesProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListExamResultProto}
 */
proto.healthApi.ListExamResultProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListExamResultProto;
  return proto.healthApi.ListExamResultProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListExamResultProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListExamResultProto}
 */
proto.healthApi.ListExamResultProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamResultId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectionDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnSiteCollection(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquipment(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLaboratory(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistry(value);
      break;
    case 14:
      var value = new proto.healthApi.ListResultsProto;
      reader.readMessage(value,proto.healthApi.ListResultsProto.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 15:
      var value = new proto.healthApi.ListImagesProto;
      reader.readMessage(value,proto.healthApi.ListImagesProto.deserializeBinaryFromReader);
      msg.addImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListExamResultProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListExamResultProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListExamResultProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListExamResultProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamResultId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollectionDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnSiteCollection();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEquipment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLaboratory();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReleaseDate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProfName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRegistry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.healthApi.ListResultsProto.serializeBinaryToWriter
    );
  }
  f = message.getImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.healthApi.ListImagesProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_result_Id = 1;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getExamResultId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setExamResultId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_name = 2;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getExamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setExamName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string collection_date = 3;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getCollectionDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setCollectionDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool on_site_collection = 4;
 * @return {boolean}
 */
proto.healthApi.ListExamResultProto.prototype.getOnSiteCollection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setOnSiteCollection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string material = 5;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string method = 6;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string equipment = 7;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getEquipment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setEquipment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string laboratory = 8;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getLaboratory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setLaboratory = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string unit = 9;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string observation = 10;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string release_date = 11;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getReleaseDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setReleaseDate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string prof_name = 12;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getProfName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setProfName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string registry = 13;
 * @return {string}
 */
proto.healthApi.ListExamResultProto.prototype.getRegistry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated ListResultsProto result = 14;
 * @return {!Array<!proto.healthApi.ListResultsProto>}
 */
proto.healthApi.ListExamResultProto.prototype.getResultList = function() {
  return /** @type{!Array<!proto.healthApi.ListResultsProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ListResultsProto, 14));
};


/**
 * @param {!Array<!proto.healthApi.ListResultsProto>} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
*/
proto.healthApi.ListExamResultProto.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.healthApi.ListResultsProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ListResultsProto}
 */
proto.healthApi.ListExamResultProto.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.healthApi.ListResultsProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.clearResultList = function() {
  return this.setResultList([]);
};


/**
 * repeated ListImagesProto image = 15;
 * @return {!Array<!proto.healthApi.ListImagesProto>}
 */
proto.healthApi.ListExamResultProto.prototype.getImageList = function() {
  return /** @type{!Array<!proto.healthApi.ListImagesProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ListImagesProto, 15));
};


/**
 * @param {!Array<!proto.healthApi.ListImagesProto>} value
 * @return {!proto.healthApi.ListExamResultProto} returns this
*/
proto.healthApi.ListExamResultProto.prototype.setImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.healthApi.ListImagesProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ListImagesProto}
 */
proto.healthApi.ListExamResultProto.prototype.addImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.healthApi.ListImagesProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListExamResultProto} returns this
 */
proto.healthApi.ListExamResultProto.prototype.clearImageList = function() {
  return this.setImageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListImagesProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListImagesProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListImagesProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListImagesProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    urlExam: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListImagesProto}
 */
proto.healthApi.ListImagesProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListImagesProto;
  return proto.healthApi.ListImagesProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListImagesProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListImagesProto}
 */
proto.healthApi.ListImagesProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListImagesProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListImagesProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListImagesProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListImagesProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrlExam();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string image_id = 1;
 * @return {string}
 */
proto.healthApi.ListImagesProto.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListImagesProto} returns this
 */
proto.healthApi.ListImagesProto.prototype.setImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url_exam = 2;
 * @return {string}
 */
proto.healthApi.ListImagesProto.prototype.getUrlExam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListImagesProto} returns this
 */
proto.healthApi.ListImagesProto.prototype.setUrlExam = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListResultsProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListResultsProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListResultsProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListResultsProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    res1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unit1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    res1Sec: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unit1Sec: jspb.Message.getFieldWithDefault(msg, 7, ""),
    name2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    res2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unit2: jspb.Message.getFieldWithDefault(msg, 10, ""),
    res2Sec: jspb.Message.getFieldWithDefault(msg, 11, ""),
    unit2Sec: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name3: jspb.Message.getFieldWithDefault(msg, 13, ""),
    res3: jspb.Message.getFieldWithDefault(msg, 14, ""),
    unit3: jspb.Message.getFieldWithDefault(msg, 15, ""),
    res3Sec: jspb.Message.getFieldWithDefault(msg, 16, ""),
    unit3Sec: jspb.Message.getFieldWithDefault(msg, 17, ""),
    name4: jspb.Message.getFieldWithDefault(msg, 18, ""),
    res4: jspb.Message.getFieldWithDefault(msg, 19, ""),
    unit4: jspb.Message.getFieldWithDefault(msg, 20, ""),
    res4Sec: jspb.Message.getFieldWithDefault(msg, 21, ""),
    unit4Sec: jspb.Message.getFieldWithDefault(msg, 22, ""),
    name5: jspb.Message.getFieldWithDefault(msg, 23, ""),
    res5: jspb.Message.getFieldWithDefault(msg, 24, ""),
    unit5: jspb.Message.getFieldWithDefault(msg, 25, ""),
    res5Sec: jspb.Message.getFieldWithDefault(msg, 26, ""),
    unit5Sec: jspb.Message.getFieldWithDefault(msg, 27, ""),
    name6: jspb.Message.getFieldWithDefault(msg, 28, ""),
    res6: jspb.Message.getFieldWithDefault(msg, 29, ""),
    unit6: jspb.Message.getFieldWithDefault(msg, 30, ""),
    res6Sec: jspb.Message.getFieldWithDefault(msg, 31, ""),
    unit6Sec: jspb.Message.getFieldWithDefault(msg, 32, ""),
    name7: jspb.Message.getFieldWithDefault(msg, 33, ""),
    res7: jspb.Message.getFieldWithDefault(msg, 34, ""),
    unit7: jspb.Message.getFieldWithDefault(msg, 35, ""),
    res7Sec: jspb.Message.getFieldWithDefault(msg, 36, ""),
    unit7Sec: jspb.Message.getFieldWithDefault(msg, 37, ""),
    observation: jspb.Message.getFieldWithDefault(msg, 38, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListResultsProto}
 */
proto.healthApi.ListResultsProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListResultsProto;
  return proto.healthApi.ListResultsProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListResultsProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListResultsProto}
 */
proto.healthApi.ListResultsProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes1Sec(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit1Sec(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes2Sec(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit2Sec(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName3(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes3Sec(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit3Sec(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setName4(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes4Sec(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit4Sec(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setName5(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes5Sec(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit5Sec(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setName6(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes6Sec(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit6Sec(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setName7(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setRes7Sec(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit7Sec(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setObservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListResultsProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListResultsProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListResultsProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListResultsProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRes1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnit1();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRes1Sec();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnit1Sec();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getName2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRes2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnit2();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRes2Sec();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUnit2Sec();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName3();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRes3();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUnit3();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRes3Sec();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUnit3Sec();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getName4();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getRes4();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getUnit4();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRes4Sec();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getUnit4Sec();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getName5();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getRes5();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getUnit5();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getRes5Sec();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getUnit5Sec();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getName6();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getRes6();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getUnit6();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getRes6Sec();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getUnit6Sec();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getName7();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getRes7();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getUnit7();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getRes7Sec();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getUnit7Sec();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getObservation();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
};


/**
 * optional string result_id = 1;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getResultId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setResultId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string result_name = 2;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getResultName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setResultName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name1 = 3;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string res1 = 4;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit1 = 5;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit1 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string res1_sec = 6;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes1Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes1Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string unit1_sec = 7;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit1Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit1Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string name2 = 8;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string res2 = 9;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string unit2 = 10;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit2 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string res2_sec = 11;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes2Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes2Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string unit2_sec = 12;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit2Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit2Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name3 = 13;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName3 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string res3 = 14;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes3 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string unit3 = 15;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit3 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string res3_sec = 16;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes3Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes3Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string unit3_sec = 17;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit3Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit3Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string name4 = 18;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName4 = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string res4 = 19;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes4 = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string unit4 = 20;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit4 = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string res4_sec = 21;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes4Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes4Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string unit4_sec = 22;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit4Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit4Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string name5 = 23;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName5 = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string res5 = 24;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes5 = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string unit5 = 25;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit5 = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string res5_sec = 26;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes5Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes5Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string unit5_sec = 27;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit5Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit5Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string name6 = 28;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName6 = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string res6 = 29;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes6 = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string unit6 = 30;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit6 = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string res6_sec = 31;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes6Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes6Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string unit6_sec = 32;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit6Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit6Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string name7 = 33;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getName7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setName7 = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string res7 = 34;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes7 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string unit7 = 35;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit7 = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string res7_sec = 36;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getRes7Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setRes7Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string unit7_sec = 37;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getUnit7Sec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setUnit7Sec = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string observation = 38;
 * @return {string}
 */
proto.healthApi.ListResultsProto.prototype.getObservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListResultsProto} returns this
 */
proto.healthApi.ListResultsProto.prototype.setObservation = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListRequisitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListRequisitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListRequisitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListRequisitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examrequestid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListRequisitionRequest}
 */
proto.healthApi.ListRequisitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListRequisitionRequest;
  return proto.healthApi.ListRequisitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListRequisitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListRequisitionRequest}
 */
proto.healthApi.ListRequisitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamrequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListRequisitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListRequisitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListRequisitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListRequisitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamrequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string examRequestId = 1;
 * @return {string}
 */
proto.healthApi.ListRequisitionRequest.prototype.getExamrequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionRequest} returns this
 */
proto.healthApi.ListRequisitionRequest.prototype.setExamrequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListRequisitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListRequisitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListRequisitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListRequisitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requisition: (f = msg.getRequisition()) && proto.healthApi.ListRequisitionProto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListRequisitionResponse}
 */
proto.healthApi.ListRequisitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListRequisitionResponse;
  return proto.healthApi.ListRequisitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListRequisitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListRequisitionResponse}
 */
proto.healthApi.ListRequisitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.ListRequisitionProto;
      reader.readMessage(value,proto.healthApi.ListRequisitionProto.deserializeBinaryFromReader);
      msg.setRequisition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListRequisitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListRequisitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListRequisitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListRequisitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequisition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.healthApi.ListRequisitionProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.ListRequisitionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.ListRequisitionResponse} returns this
 */
proto.healthApi.ListRequisitionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.ListRequisitionResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionResponse} returns this
 */
proto.healthApi.ListRequisitionResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.ListRequisitionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionResponse} returns this
 */
proto.healthApi.ListRequisitionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListRequisitionProto requisition = 4;
 * @return {?proto.healthApi.ListRequisitionProto}
 */
proto.healthApi.ListRequisitionResponse.prototype.getRequisition = function() {
  return /** @type{?proto.healthApi.ListRequisitionProto} */ (
    jspb.Message.getWrapperField(this, proto.healthApi.ListRequisitionProto, 4));
};


/**
 * @param {?proto.healthApi.ListRequisitionProto|undefined} value
 * @return {!proto.healthApi.ListRequisitionResponse} returns this
*/
proto.healthApi.ListRequisitionResponse.prototype.setRequisition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.healthApi.ListRequisitionResponse} returns this
 */
proto.healthApi.ListRequisitionResponse.prototype.clearRequisition = function() {
  return this.setRequisition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.healthApi.ListRequisitionResponse.prototype.hasRequisition = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.ListRequisitionProto.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.ListRequisitionProto.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.ListRequisitionProto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.ListRequisitionProto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListRequisitionProto.toObject = function(includeInstance, msg) {
  var f, obj = {
    examrequestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prescdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    urlExam: jspb.Message.getFieldWithDefault(msg, 5, ""),
    examsList: jspb.Message.toObjectList(msg.getExamsList(),
    proto.healthApi.ListExamProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.ListRequisitionProto}
 */
proto.healthApi.ListRequisitionProto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.ListRequisitionProto;
  return proto.healthApi.ListRequisitionProto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.ListRequisitionProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.ListRequisitionProto}
 */
proto.healthApi.ListRequisitionProto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamrequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrescdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrientation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlExam(value);
      break;
    case 6:
      var value = new proto.healthApi.ListExamProto;
      reader.readMessage(value,proto.healthApi.ListExamProto.deserializeBinaryFromReader);
      msg.addExams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.ListRequisitionProto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.ListRequisitionProto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.ListRequisitionProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.ListRequisitionProto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamrequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrescdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrientation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrlExam();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.healthApi.ListExamProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string examRequestId = 1;
 * @return {string}
 */
proto.healthApi.ListRequisitionProto.prototype.getExamrequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionProto} returns this
 */
proto.healthApi.ListRequisitionProto.prototype.setExamrequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prescDate = 2;
 * @return {string}
 */
proto.healthApi.ListRequisitionProto.prototype.getPrescdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionProto} returns this
 */
proto.healthApi.ListRequisitionProto.prototype.setPrescdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profName = 3;
 * @return {string}
 */
proto.healthApi.ListRequisitionProto.prototype.getProfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionProto} returns this
 */
proto.healthApi.ListRequisitionProto.prototype.setProfname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string orientation = 4;
 * @return {string}
 */
proto.healthApi.ListRequisitionProto.prototype.getOrientation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionProto} returns this
 */
proto.healthApi.ListRequisitionProto.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url_exam = 5;
 * @return {string}
 */
proto.healthApi.ListRequisitionProto.prototype.getUrlExam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.ListRequisitionProto} returns this
 */
proto.healthApi.ListRequisitionProto.prototype.setUrlExam = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ListExamProto exams = 6;
 * @return {!Array<!proto.healthApi.ListExamProto>}
 */
proto.healthApi.ListRequisitionProto.prototype.getExamsList = function() {
  return /** @type{!Array<!proto.healthApi.ListExamProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ListExamProto, 6));
};


/**
 * @param {!Array<!proto.healthApi.ListExamProto>} value
 * @return {!proto.healthApi.ListRequisitionProto} returns this
*/
proto.healthApi.ListRequisitionProto.prototype.setExamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.healthApi.ListExamProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ListExamProto}
 */
proto.healthApi.ListRequisitionProto.prototype.addExams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.healthApi.ListExamProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.ListRequisitionProto} returns this
 */
proto.healthApi.ListRequisitionProto.prototype.clearExamsList = function() {
  return this.setExamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteExamRequest}
 */
proto.healthApi.DeleteExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteExamRequest;
  return proto.healthApi.DeleteExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteExamRequest}
 */
proto.healthApi.DeleteExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string examId = 1;
 * @return {string}
 */
proto.healthApi.DeleteExamRequest.prototype.getExamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExamRequest} returns this
 */
proto.healthApi.DeleteExamRequest.prototype.setExamid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteExamResponse}
 */
proto.healthApi.DeleteExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteExamResponse;
  return proto.healthApi.DeleteExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteExamResponse}
 */
proto.healthApi.DeleteExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteExamResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteExamResponse} returns this
 */
proto.healthApi.DeleteExamResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteExamResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExamResponse} returns this
 */
proto.healthApi.DeleteExamResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteExamResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExamResponse} returns this
 */
proto.healthApi.DeleteExamResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteExamResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteExamResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteExamResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    examresultid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteExamResultRequest}
 */
proto.healthApi.DeleteExamResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteExamResultRequest;
  return proto.healthApi.DeleteExamResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteExamResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteExamResultRequest}
 */
proto.healthApi.DeleteExamResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamresultid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteExamResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteExamResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteExamResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamresultid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string examResultId = 1;
 * @return {string}
 */
proto.healthApi.DeleteExamResultRequest.prototype.getExamresultid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExamResultRequest} returns this
 */
proto.healthApi.DeleteExamResultRequest.prototype.setExamresultid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.DeleteExamResultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.DeleteExamResultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.DeleteExamResultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamResultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.DeleteExamResultResponse}
 */
proto.healthApi.DeleteExamResultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.DeleteExamResultResponse;
  return proto.healthApi.DeleteExamResultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.DeleteExamResultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.DeleteExamResultResponse}
 */
proto.healthApi.DeleteExamResultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.DeleteExamResultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.DeleteExamResultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.DeleteExamResultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.DeleteExamResultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.DeleteExamResultResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.DeleteExamResultResponse} returns this
 */
proto.healthApi.DeleteExamResultResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.DeleteExamResultResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExamResultResponse} returns this
 */
proto.healthApi.DeleteExamResultResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.DeleteExamResultResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.DeleteExamResultResponse} returns this
 */
proto.healthApi.DeleteExamResultResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SearchListExamHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SearchListExamHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SearchListExamHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListExamHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SearchListExamHistoryRequest}
 */
proto.healthApi.SearchListExamHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SearchListExamHistoryRequest;
  return proto.healthApi.SearchListExamHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SearchListExamHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SearchListExamHistoryRequest}
 */
proto.healthApi.SearchListExamHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SearchListExamHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SearchListExamHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SearchListExamHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListExamHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.healthApi.SearchListExamHistoryRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchListExamHistoryRequest} returns this
 */
proto.healthApi.SearchListExamHistoryRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.SearchListExamHistoryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SearchListExamHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SearchListExamHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListExamHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examList: jspb.Message.toObjectList(msg.getExamList(),
    proto.healthApi.ExamHistoryProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SearchListExamHistoryResponse}
 */
proto.healthApi.SearchListExamHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SearchListExamHistoryResponse;
  return proto.healthApi.SearchListExamHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SearchListExamHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SearchListExamHistoryResponse}
 */
proto.healthApi.SearchListExamHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.ExamHistoryProto;
      reader.readMessage(value,proto.healthApi.ExamHistoryProto.deserializeBinaryFromReader);
      msg.addExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SearchListExamHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SearchListExamHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListExamHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.ExamHistoryProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.SearchListExamHistoryResponse} returns this
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchListExamHistoryResponse} returns this
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchListExamHistoryResponse} returns this
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ExamHistoryProto exam = 4;
 * @return {!Array<!proto.healthApi.ExamHistoryProto>}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.getExamList = function() {
  return /** @type{!Array<!proto.healthApi.ExamHistoryProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.ExamHistoryProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.ExamHistoryProto>} value
 * @return {!proto.healthApi.SearchListExamHistoryResponse} returns this
*/
proto.healthApi.SearchListExamHistoryResponse.prototype.setExamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.ExamHistoryProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.ExamHistoryProto}
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.addExam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.ExamHistoryProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.SearchListExamHistoryResponse} returns this
 */
proto.healthApi.SearchListExamHistoryResponse.prototype.clearExamList = function() {
  return this.setExamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SearchListLifestylesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SearchListLifestylesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SearchListLifestylesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListLifestylesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SearchListLifestylesRequest}
 */
proto.healthApi.SearchListLifestylesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SearchListLifestylesRequest;
  return proto.healthApi.SearchListLifestylesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SearchListLifestylesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SearchListLifestylesRequest}
 */
proto.healthApi.SearchListLifestylesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SearchListLifestylesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SearchListLifestylesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SearchListLifestylesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListLifestylesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.healthApi.SearchListLifestylesRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchListLifestylesRequest} returns this
 */
proto.healthApi.SearchListLifestylesRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.healthApi.SearchListLifestylesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.healthApi.SearchListLifestylesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.healthApi.SearchListLifestylesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListLifestylesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lifestylesList: jspb.Message.toObjectList(msg.getLifestylesList(),
    proto.healthApi.LifestyleProto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.healthApi.SearchListLifestylesResponse}
 */
proto.healthApi.SearchListLifestylesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.healthApi.SearchListLifestylesResponse;
  return proto.healthApi.SearchListLifestylesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.healthApi.SearchListLifestylesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.healthApi.SearchListLifestylesResponse}
 */
proto.healthApi.SearchListLifestylesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new proto.healthApi.LifestyleProto;
      reader.readMessage(value,proto.healthApi.LifestyleProto.deserializeBinaryFromReader);
      msg.addLifestyles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.healthApi.SearchListLifestylesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.healthApi.SearchListLifestylesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.healthApi.SearchListLifestylesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLifestylesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.healthApi.LifestyleProto.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.healthApi.SearchListLifestylesResponse} returns this
 */
proto.healthApi.SearchListLifestylesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchListLifestylesResponse} returns this
 */
proto.healthApi.SearchListLifestylesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.healthApi.SearchListLifestylesResponse} returns this
 */
proto.healthApi.SearchListLifestylesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated LifestyleProto lifestyles = 4;
 * @return {!Array<!proto.healthApi.LifestyleProto>}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.getLifestylesList = function() {
  return /** @type{!Array<!proto.healthApi.LifestyleProto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.healthApi.LifestyleProto, 4));
};


/**
 * @param {!Array<!proto.healthApi.LifestyleProto>} value
 * @return {!proto.healthApi.SearchListLifestylesResponse} returns this
*/
proto.healthApi.SearchListLifestylesResponse.prototype.setLifestylesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.healthApi.LifestyleProto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.healthApi.LifestyleProto}
 */
proto.healthApi.SearchListLifestylesResponse.prototype.addLifestyles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.healthApi.LifestyleProto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.healthApi.SearchListLifestylesResponse} returns this
 */
proto.healthApi.SearchListLifestylesResponse.prototype.clearLifestylesList = function() {
  return this.setLifestylesList([]);
};


goog.object.extend(exports, proto.healthApi);
