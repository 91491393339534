import anvisaDrugPb from "../grpc/generated/anvisaDrug_pb.js";
import anvisaDrugService from "../services/anvisaDrugService"

// async function DrugSearchItems (obj) {
//     try {
//         const req = new anvisaDrugPb.DrugSearchItemsRequest();
//         req.setType(obj.type);
//         req.setWord(obj.word);
//         const res = await anvisaDrugService.drugSearchItems(req);

//         // console.log("DrugSearchItems", res)

//         return res;
//     } catch (error) {
//         console.log(error);
//         return error;
//     }
// }

async function ListLaboratories (obj) {
    try {
        const req = new anvisaDrugPb.ListLaboratoriesRequest();
        req.setProductName(obj.name);
        req.setActivePrinciple(obj.activePrinciple);
        req.setRegulatoryCategory(obj.regulatoryCategory);
        req.setTherapeuticClass(obj.therapeuticClass);
        req.setApresentation(obj.apresentation);
        const res = await anvisaDrugService.listLaboratories(req);

        // console.log("ListLaboratories", res)
        console.log('ListLaboratories', obj)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function ListAprasentations (obj) {
    try {
        const req = new anvisaDrugPb.ListAprasentationsRequest();
        req.setProductName(obj.name);
        req.setActivePrinciple(obj.activePrinciple);
        req.setRegulatoryCategory(obj.regulatoryCategory);
        req.setTherapeuticClass(obj.therapeuticClass);
        req.setLaboratory(obj.laboratory);
        const res = await anvisaDrugService.listApresentations(req);

        // console.log("ListAprasentations", res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    ListLaboratories,
    ListAprasentations,
}