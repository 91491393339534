<template>
    <div class="card-personalized">
        <QualityOfLife :list="list" v-if="name === 'Qualidade de vida'" />
        <Measurements :list="list" v-else-if="name === 'Medidas'" />
        <Default :list="list" v-else-if="listNotEmpty" />
        <NoRecords v-else-if="!listNotEmpty" />
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import QualityOfLife from "./QualityOfLife"
import Measurements from "./Measurements"
import Default from "./default"
import NoRecords from "./NoRecords"
// import A from "./A";

export default {
    props: ["name", "list"],
    components: {
        QualityOfLife,
        Measurements,
        Default,
        NoRecords,
    },
    setup(props, { emit }) {
        const listNotEmpty = computed(() => {
            return props.list.length > 0;
        });

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            listNotEmpty
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-personalized {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100% !important;
    min-height: 45px;
}

</style>