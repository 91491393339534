<template>
    <div class="health-record--search">
        <InputText 
            type="text" 
            :value="modelValue" 
            @change="(e) => changeValue(e.target.value)"
            placeholder="Pesquisar"
        />
        <img 
            src="../../../../assets/icons/health/search-icon.png" 
            alt=""
            v-tooltip.top="'Pesquisar'"
        >
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";

export default {
    props: ["modelValue"],
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        // const bool = ref(false);

        const changeValue = (val) => {
            emit("update:modelValue", val)
        }

        return {
            changeValue
        }
    },
};
</script>


<style lang="scss" scoped>
.health-record--search {
    position: relative;
    max-width: 266px;
    img{
        position: absolute;
        top: 10px;
        right: 14px;
        cursor: pointer;
    }
}

@media (max-width: 1409px) {
    input {
        display: flex;
        align-items: center;
        background: red;
        height: 38px !important;
        font-size: 14px !important;
    }

    img{
        top: 13px !important;
    }
}

@media (min-width: 1920px) {
    input {
        display: flex;
        align-items: center;
        background: red;
        height: 38px !important;
        font-size: 14px !important;
    }

    img{
        top: 12px !important;
    }
}

::v-deep .p-inputtext {
    width: 266px !important;
    height: 34px;
    background: #FAFAFA;
    border-radius: 100px;
    padding: 0 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4F4F4F;

    &::placeholder{
        color: #BDBDBD;
    }
}
</style>