import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c75cf58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "health-record--actions" }
const _hoisted_2 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowUpperRight = _resolveComponent("ArrowUpperRight")!
  const _component_TableMode = _resolveComponent("TableMode")!
  const _component_CardMode = _resolveComponent("CardMode")!
  const _component_ArrowUp = _resolveComponent("ArrowUp")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createVNode(_component_ArrowUpperRight, {
        width: "10",
        fill: "#828282"
      })
    ])), [
      [
        _directive_tooltip,
        'Abrir separado',
        void 0,
        { top: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.changeMode && $setup.changeMode(...args)))
    }, [
      ($props.modeCard)
        ? (_openBlock(), _createBlock(_component_TableMode, {
            key: 0,
            width: "13",
            fill: "#828282",
            class: "forceSize"
          }))
        : (_openBlock(), _createBlock(_component_CardMode, {
            key: 1,
            width: "13",
            fill: "#828282"
          }))
    ])), [
      [
        _directive_tooltip,
        {value: $props.modeCard ? 'Modo Tabela' : 'Modo Card'},
        void 0,
        { top: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "btn",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.changeExpanded && $setup.changeExpanded(...args)))
    }, [
      ($props.expanded)
        ? (_openBlock(), _createBlock(_component_ArrowUp, {
            key: 0,
            fill: "#828282"
          }))
        : (_openBlock(), _createBlock(_component_ArrowDown, {
            key: 1,
            fill: "#828282"
          }))
    ])), [
      [
        _directive_tooltip,
        {value: $props.expanded ? 'Minimizar' : 'Maximizar'},
        void 0,
        { top: true }
      ]
    ])
  ]))
}