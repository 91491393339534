<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M1.66797 16.3334C1.66797 17.7501 2.7513 18.8334 4.16797 18.8334H15.8346C17.2513 18.8334 18.3346 17.7501 18.3346 16.3334V9.66675H1.66797V16.3334ZM15.8346 3.83341H14.168V3.00008C14.168 2.50008 13.8346 2.16675 13.3346 2.16675C12.8346 2.16675 12.5013 2.50008 12.5013 3.00008V3.83341H7.5013V3.00008C7.5013 2.50008 7.16797 2.16675 6.66797 2.16675C6.16797 2.16675 5.83464 2.50008 5.83464 3.00008V3.83341H4.16797C2.7513 3.83341 1.66797 4.91675 1.66797 6.33342V8.00008H18.3346V6.33342C18.3346 4.91675 17.2513 3.83341 15.8346 3.83341Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>