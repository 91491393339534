import Minus from "@/assets/IconsComponent/Minus.vue"
import Square from "@/assets/IconsComponent/Square.vue"
import Medicine from "@/assets/IconsComponent/Medicine.vue"
import Medical from "@/assets/IconsComponent/Medical.vue"
import Stretcher from "@/assets/IconsComponent/Stretcher.vue"
import Compress from "@/assets/IconsComponent/Compress.vue"
import CoronaVirus from "@/assets/IconsComponent/CoronaVirus.vue"
import Syringe from "@/assets/IconsComponent/Syringe.vue"
import IncreaseBrightness from "@/assets/IconsComponent/IncreaseBrightness.vue"
import Building from "@/assets/IconsComponent/Building.vue"
import Ruler from "@/assets/IconsComponent/Ruler.vue"
import HeadSideCough from "@/assets/IconsComponent/HeadSideCough.vue"
// import Refresh from "@/assets/IconsComponent/Refresh.vue"


const list = [
    {name: "Alertas de saúde", icon: Minus, list: ["Colesterol elevado", "Vitamina D Baixa", "Dor de cabeça (Últimos 10 dias)"]},
    {name: "Diagnósticos", icon: Square, list: ["Anemia", "Ansiedade", "Doença de Crohn"]},
    {name: "Medicamentos em uso", icon: Medicine, list: [] },
    {name: "Tratamentos atuais", icon: Medical, list: ["Covid-19", "Ansiedade", "Doença de Crohn"]},
    {name: "Cirurgias", icon: Stretcher, list: ["Reparo do tendão patelar ", "Varicocelectomia", "Amigdalectomia"]},
    {name: "Lesões", icon: Compress, list: ["Pulmão", "Fígado", "Mão esquerda"]},
    {name: "Alergias", icon: CoronaVirus, list: ["Leite", "Pelo de felinos"]},
    {name: "Vacinas", icon: Syringe, list: ["1º Dose Covid-19", "2º Dose Covid-19", "Tríplice viral"]},
    {name: "Qualidade de vida", icon: IncreaseBrightness, list: []}, // diferente
    {name: "Internações", icon: Building, list: []},
    {name: "Medidas", icon: Ruler, list: []}, //diferente
    {name: "Doenças crônicas", icon: HeadSideCough, list: ["Diabetes tipo 1", "Doença de Crohn"]},
    {name: "Doenças congênitas", icon: HeadSideCough, list: []},
    {name: "Doenças autoimunes", icon: HeadSideCough, list: ["Hipotireoidismo de Hashimoto"]},
    // {name: "Ciclo menstrual", icon: MedidasIcon, list: []},
]

export { list }