import professionService from "@/modules/core/services/professionService"
import professionPb from "@/modules/core/grpc/generated/profession_pb.js";

interface SearchItems {
    word: string;
    gender: string;
}

const GetProfessions = async (word: string) => {
    try {
        const req = new professionPb.GetProfessionsRequest();
        req.setWord(word);

        const res = await professionService.getProfessions(req);
        
        return res
    } catch (error) {
        console.log(error);
        return error;
    }
}


export {
    GetProfessions,
}