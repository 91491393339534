
// import { onMounted, ref } from "vue";
import Filter from "./Filter.vue";
import Actions from "./Actions.vue"


export default {
    props: ["filter", "search", "modeCard", "expanded"],
    emits: ["changeFilter", "update:search", "changeMode", "changeExpanded"],
    components: {
        Filter,
        Actions,
    },    
    setup(props, { emit }) {
        const changeFilter = (filter: string) => {
            emit("changeFilter", filter)
        }

        const changeMode = () => emit("changeMode")
        const changeExpanded = () => emit("changeExpanded")

        return {
            changeFilter,
            changeMode,
            changeExpanded,
        }
    },
};
