<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.07129 0.5H0.821289C0.371289 0.5 0.0712891 0.8 0.0712891 1.25V6.5C0.0712891 6.95 0.371289 7.25 0.821289 7.25H6.07129C6.52129 7.25 6.82129 6.95 6.82129 6.5V1.25C6.82129 0.8 6.52129 0.5 6.07129 0.5ZM6.07129 8.75H0.821289C0.371289 8.75 0.0712891 9.05 0.0712891 9.5V14.75C0.0712891 15.2 0.371289 15.5 0.821289 15.5H6.07129C6.52129 15.5 6.82129 15.2 6.82129 14.75V9.5C6.82129 9.05 6.52129 8.75 6.07129 8.75ZM14.3213 0.5H9.07129C8.62129 0.5 8.32129 0.8 8.32129 1.25V6.5C8.32129 6.95 8.62129 7.25 9.07129 7.25H14.3213C14.7713 7.25 15.0713 6.95 15.0713 6.5V1.25C15.0713 0.8 14.7713 0.5 14.3213 0.5ZM14.3213 8.75H9.07129C8.62129 8.75 8.32129 9.05 8.32129 9.5V14.75C8.32129 15.2 8.62129 15.5 9.07129 15.5H14.3213C14.7713 15.5 15.0713 15.2 15.0713 14.75V9.5C15.0713 9.05 14.7713 8.75 14.3213 8.75Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>