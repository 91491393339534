
// import { onMounted, ref } from "vue";

export default {
    props: ["modelValue"],
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const changeValue = (val: string) => {
            emit("update:modelValue", val)
        }

        return {
            changeValue
        }
    },
};
