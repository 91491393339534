<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.3167 10.0164L12.8167 0.683093C12.3501 0.333093 11.7667 0.333093 11.3001 0.683093L0.800053 10.0164C0.333387 10.4831 0.21672 11.1831 0.683386 11.6498C1.15005 12.1164 1.85005 12.2331 2.31672 11.7664L2.66672 11.2998V22.4998C2.66672 23.1998 3.13339 23.6664 3.83339 23.6664H20.1667C20.8667 23.6664 21.3334 23.1998 21.3334 22.4998V11.2998L21.6834 11.6498C22.1501 12.1164 22.8501 11.9998 23.3167 11.5331C23.7834 11.1831 23.7834 10.3664 23.3167 10.0164ZM14.3334 15.4998H13.1667V16.6664C13.1667 17.3664 12.7001 17.8331 12.0001 17.8331C11.3001 17.8331 10.8334 17.3664 10.8334 16.6664V15.4998H9.66672C8.96672 15.4998 8.50005 15.0331 8.50005 14.3331C8.50005 13.6331 8.96672 13.1664 9.66672 13.1664H10.8334V11.9998C10.8334 11.2998 11.3001 10.8331 12.0001 10.8331C12.7001 10.8331 13.1667 11.2998 13.1667 11.9998V13.1664H14.3334C15.0334 13.1664 15.5001 13.6331 15.5001 14.3331C15.5001 15.0331 15.0334 15.4998 14.3334 15.4998Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>