import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4344a21c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filter = _resolveComponent("Filter")!
  const _component_Actions = _resolveComponent("Actions")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bar-fixed",
    style: _normalizeStyle({borderBottom: $props.expanded ? '1px solid #E0E0E0' : 'none'})
  }, [
    _createVNode(_component_Filter, {
      filter: $props.filter,
      onChangeFilter: $setup.changeFilter
    }, null, 8, ["filter", "onChangeFilter"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Actions, {
        modeCard: $props.modeCard,
        expanded: $props.expanded,
        onChangeMode: $setup.changeMode,
        onChangeExpanded: $setup.changeExpanded
      }, null, 8, ["modeCard", "expanded", "onChangeMode", "onChangeExpanded"])
    ])
  ], 4))
}