import shareHealthService from "@/modules/core/services/shareHealthService"
import shareHealthPb from "@/modules/core/grpc/generated/shareHealth_pb.js";

const DeleteToken = async () => {
    try {
        const req = new shareHealthPb.EmptyRequest();
        const res = await shareHealthService.deleteToken(req);
        const { success } = res

        if (success) {
            return res;
        }

        return { success: false };
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    DeleteToken
}