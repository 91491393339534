<template>
  <svg width="28" height="40" viewBox="0 0 214 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81 63.8537C81 27.2823 109.153 0 147.544 0C185.57 0 213.723 27.136 213.723 63.8537C213.723 100.645 185.57 127.707 147.544 127.707C109.153 127.781 81 100.425 81 63.8537ZM195.88 63.8537C195.88 36.2057 175.186 15.872 147.544 15.872C119.537 15.872 98.8426 36.2057 98.8426 63.8537C98.8426 91.5017 119.537 111.835 147.544 111.835C175.186 111.909 195.88 91.5017 195.88 63.8537Z"
      fill="#2D313D" />
    <path
      d="M88.8476 107.862C82.2663 111.08 74.6612 112.835 66.5443 112.835C38.5372 112.835 17.8426 92.5017 17.8426 64.8537C17.8426 37.2057 38.5372 16.872 66.5443 16.872C75.8313 16.872 84.387 19.2126 91.5533 23.3086L91.7726 23.016C95.2095 18.6274 99.3046 14.6777 103.838 11.3131C93.3814 4.73029 80.6575 1 66.5443 1C28.1534 1 0 28.2823 0 64.8537C0 101.425 28.1534 128.707 66.5443 128.707C79.8532 128.707 91.992 125.343 102.156 119.418C95.8677 118.321 90.822 113.786 88.8476 107.862Z"
      fill="#FF4500" />
    <path
      d="M120.73 26.9657L120.584 27.0389C115.977 30.184 112.101 34.0606 109.03 38.6686L108.299 39.7657C112.54 46.9337 114.88 55.4914 114.88 64.8537C114.88 72.5337 113.271 79.6286 110.346 85.9189C116.708 87.8937 121.535 93.4526 122.412 100.255C128.993 90.3074 132.723 78.2389 132.723 64.8537C132.723 50.3714 128.335 37.4251 120.73 26.9657Z"
      fill="#FF4500" />
  </svg>
</template>