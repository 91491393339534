<template>
  <svg width="180" height="40" viewBox="0 0 1028 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6055_209)">
      <path
        d="M514.1 99.2V174H490.7V101.9C490.7 76.4 478.5 63.9 457.3 63.9C433.9 63.9 418.5 78.9 418.5 107V174H395.1V101.9C395.1 76.4 382.9 63.9 361.7 63.9C338.3 63.9 322.9 78.9 322.9 107V174H299.5L299.7 42.6H321.8L321.9 63.5C331.2 50.3 347 42.9 366.5 42.9C386.3 42.9 402.8 50.7 411.4 66.9C421.2 52.2 439.2 42.9 461.2 42.9C492.7 42.9 514.1 61 514.1 99.2Z"
        fill="#2D313D" />
      <path
        d="M740.1 99.2V174H716.7V101.9C716.7 76.4 704.5 63.9 683.3 63.9C659.9 63.9 644.5 78.9 644.5 107V174H621.1V101.9C621.1 76.4 608.9 63.9 587.7 63.9C564.3 63.9 548.9 78.9 548.9 107V174H525.5L525.4 42.6H547.9L548 63.5C557.3 50.3 573.1 42.9 592.6 42.9C612.4 42.9 628.9 50.7 637.5 66.9C647.3 52.2 665.3 42.9 687.3 42.9C718.6 42.9 740.1 61 740.1 99.2Z"
        fill="#2D313D" />
      <path
        d="M877.3 116.3H772.4C775.3 138.7 793.6 153.6 819.2 153.6C834.3 153.6 847 148.6 856.5 138.2L873.3 149.1C861.6 162.6 839.9 173.8 818.5 173.8C776.8 173.8 749 146.6 749 108.6C749 70.8999 776.6 43.7 813.9 43.7C851.2 43.7 877.8 70.1 877.8 109.3C877.8 111.3 877.6 114.2 877.3 116.3ZM772.4 99.8H855.3C852.9 78.4 836.5 63.2999 813.8 63.2999C791.4 63.1999 774.9 78.1 772.4 99.8Z"
        fill="#2D313D" />
      <path
        d="M1006.7 1.09998V62.7C1006.3 62.3 1005.9 61.9 1005.5 61.5C992.8 49.2 975.2 42.5 956 42.5C936.6 42.5 918.9 49.3 906.1 61.6C893.3 73.9 886.3 90.9 886.3 109.4C886.3 127.9 893.3 144.9 906.1 157.2C918.9 169.5 936.6 176.3 956 176.3C975.2 176.3 992.8 169.5 1005.5 157.3C1005.9 156.9 1006.3 156.5 1006.7 156.1V175.3H1027.1V1.09998H1006.7ZM956 157.9C927.5 157.9 906.7 137.5 906.7 109.4C906.7 81.3 927.4 60.9 956 60.9C983.9 60.9 1004.9 81.8 1004.9 109.4C1004.9 137 983.9 157.9 956 157.9Z"
        fill="#2D313D" />
      <path
        d="M121.5 146.9C112.5 151.3 102.1 153.7 91 153.7C52.7 153.7 24.4 125.9 24.4 88.1C24.4 50.3 52.7 22.5 91 22.5C103.7 22.5 115.4 25.7 125.2 31.3L125.5 30.9C130.2 24.9 135.8 19.5 142 14.9C127.7 5.90005 110.3 0.800049 91 0.800049C38.5 0.800049 0 38.1 0 88.1C0 138.1 38.5 175.4 91 175.4C109.2 175.4 125.8 170.8 139.7 162.7C131.1 161.2 124.2 155 121.5 146.9Z"
        fill="#FF4500" />
      <path
        d="M165.1 36.3L164.9 36.4001C158.6 40.7001 153.3 46 149.1 52.3L148.1 53.8C153.9 63.6 157.1 75.3 157.1 88.1C157.1 98.6 154.9 108.3 150.9 116.9C159.6 119.6 166.2 127.2 167.4 136.5C176.4 122.9 181.5 106.4 181.5 88.1C181.5 68.3 175.5 50.6 165.1 36.3Z"
        fill="#FF4500" />
      <path
        d="M121.5 146.9C112.5 151.3 102.1 153.7 91 153.7C52.7 153.7 24.4 125.9 24.4 88.1C24.4 50.3 52.7 22.5 91 22.5C103.7 22.5 115.4 25.7 125.2 31.3L125.5 30.9C130.2 24.9 135.8 19.5 142 14.9C127.7 5.90005 110.3 0.800049 91 0.800049C38.5 0.800049 0 38.1 0 88.1C0 138.1 38.5 175.4 91 175.4C109.2 175.4 125.8 170.8 139.7 162.7C131.1 161.2 124.2 155 121.5 146.9Z"
        fill="#FF4500" />
      <path
        d="M165.1 36.3L164.9 36.4001C158.6 40.7001 153.3 46 149.1 52.3L148.1 53.8C153.9 63.6 157.1 75.3 157.1 88.1C157.1 98.6 154.9 108.3 150.9 116.9C159.6 119.6 166.2 127.2 167.4 136.5C176.4 122.9 181.5 106.4 181.5 88.1C181.5 68.3 175.5 50.6 165.1 36.3Z"
        fill="#FF4500" />
      <path
        d="M110.5 87.3C110.5 37.3 149 0 201.5 0C253.5 0 292 37.1 292 87.3C292 137.6 253.5 174.6 201.5 174.6C149 174.7 110.5 137.3 110.5 87.3ZM267.6 87.3C267.6 49.5 239.3 21.7 201.5 21.7C163.2 21.7 134.9 49.5 134.9 87.3C134.9 125.1 163.2 152.9 201.5 152.9C239.3 153 267.6 125.1 267.6 87.3Z"
        fill="#2D313D" />
    </g>
    <defs>
      <clipPath id="clip0_6055_209">
        <rect width="1027.2" height="176.3" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>