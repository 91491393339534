import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38232efc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "22",
  height: "21",
  viewBox: "0 0 22 21",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M5 15C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17C5.6 17 6 16.6 6 16C6 15.4 5.6 15 5 15ZM1 16C0.4 16 0 16.4 0 17C0 17.6 0.4 18 1 18C1.6 18 2 17.6 2 17C2 16.4 1.6 16 1 16ZM4 19C3.4 19 3 19.4 3 20C3 20.6 3.4 21 4 21C4.6 21 5 20.6 5 20C5 19.4 4.6 19 4 19ZM15.2 0.999987C11.2 0.899987 8 3.89999 7.9 7.89999C7.9 7.99999 7.9 7.99999 7.9 8.09999L6.1 11.9C5.9 12.4 6.1 13 6.6 13.2C6.7 13.3 6.9 13.3 7 13.3H7.9V15.1C7.9 16.2 8.8 17 9.8 17H10.7V18.8C10.7 19.4 11.1 19.8 11.7 19.8C12.3 19.8 19.7 19.8 20.1 19.8C20.2 19.8 20.3 19.8 20.4 19.8C20.9 19.6 21.2 19.1 21.1 18.6L20.2 15.6L22 8.79999C22 8.69999 22 8.59999 22 8.49999V8.29999C22 4.39999 19 1.19999 15.2 0.999987Z",
      fill: $setup.fill
    }, null, 8, _hoisted_2)
  ]))
}