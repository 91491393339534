<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8346 1.66675H4.16797C3.50493 1.66675 2.86904 1.93014 2.4002 2.39898C1.93136 2.86782 1.66797 3.50371 1.66797 4.16675V15.8334C1.66797 16.4965 1.93136 17.1323 2.4002 17.6012C2.86904 18.07 3.50493 18.3334 4.16797 18.3334H15.8346C16.4977 18.3334 17.1336 18.07 17.6024 17.6012C18.0712 17.1323 18.3346 16.4965 18.3346 15.8334V4.16675C18.3346 3.50371 18.0712 2.86782 17.6024 2.39898C17.1336 1.93014 16.4977 1.66675 15.8346 1.66675ZM8.33464 3.33341H11.668V5.94175L10.4596 5.10841C10.3236 5.01881 10.1642 4.97105 10.0013 4.97105C9.83838 4.97105 9.67903 5.01881 9.54297 5.10841L8.33464 5.94175V3.33341ZM16.668 15.8334C16.668 16.0544 16.5802 16.2664 16.4239 16.4227C16.2676 16.579 16.0556 16.6667 15.8346 16.6667H4.16797C3.94696 16.6667 3.73499 16.579 3.57871 16.4227C3.42243 16.2664 3.33464 16.0544 3.33464 15.8334V4.16675C3.33464 3.94573 3.42243 3.73377 3.57871 3.57749C3.73499 3.42121 3.94696 3.33341 4.16797 3.33341H6.66797V7.50008C6.66837 7.65084 6.70966 7.79866 6.78744 7.9278C6.86522 8.05694 6.97657 8.16256 7.10964 8.23342C7.23995 8.30032 7.38559 8.3317 7.53189 8.32439C7.67819 8.31707 7.81998 8.27132 7.94297 8.19175L10.0013 6.83342L12.043 8.19175C12.1683 8.27429 12.3136 8.32145 12.4635 8.32826C12.6134 8.33507 12.7624 8.30127 12.8947 8.23042C13.027 8.15958 13.1377 8.05433 13.2151 7.92578C13.2926 7.79723 13.3339 7.65015 13.3346 7.50008V3.33341H15.8346C16.0556 3.33341 16.2676 3.42121 16.4239 3.57749C16.5802 3.73377 16.668 3.94573 16.668 4.16675V15.8334Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>