<template>
    <Skeleton class="loader" />
</template>

<style lang="scss" scoped>
.loader {
    border-radius: 16px;
    width: 100%;
    height: 96px !important;
}
</style>