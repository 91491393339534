import { 
    ListDrugAllergies, 
    SearchDrugAllergies,
    ListDrugs,
    SearchListDrugs,
    ListLifestyles,
    SearchListLifestyles
} from "@/modules/core/actions/health.js"

const start = async (obj: any) => {
    const { name, type, appointmentid, search } = obj

    // console.log("start", obj)

    switch (name) {
        case "Alergia a medicamentos":
            let resultDrugAllergies: any;
            let methodName: string;
            let listName: string;

            if(type === 'start'){
                resultDrugAllergies = await ListDrugAllergies()
                methodName = "ListDrugAllergies"
            }else if(type === 'search'){
                if(search){
                    resultDrugAllergies = await SearchDrugAllergies(obj)
                    methodName = "SearchDrugAllergies"
                }else{
                    resultDrugAllergies = await ListDrugAllergies()
                    methodName = "ListDrugAllergies"
                }                
            }else if(type === 'reload'){
                if(search){
                    resultDrugAllergies = await SearchDrugAllergies(obj)
                    methodName = "SearchDrugAllergies"
                }else{
                    resultDrugAllergies = await ListDrugAllergies()
                    methodName = "ListDrugAllergies"
                }
            }

            console.log("aqui ->:", resultDrugAllergies)

            return { ...resultDrugAllergies, card_select: obj.name }
        break;
        case "Medicamentos em uso":
            let resultDrugs: any;
            
            if(type === 'start'){
                resultDrugs = await ListDrugs(appointmentid)
            }else if(type === 'reload'){
                if(search){
                    resultDrugs = await SearchListDrugs({appointmentid: appointmentid, word: search})
                }else{
                    resultDrugs = await ListDrugs(appointmentid)
                }
            }else if(type === 'search'){
                if(search){
                    resultDrugs = await SearchListDrugs({appointmentid: appointmentid, word: search})
                }else{
                    resultDrugs = await ListDrugs(appointmentid)
                }
            }

            return { ...resultDrugs, card_select: obj.name }
        break;
        case "Estilo de vida":
            let resultLifeStyle: any;

            if(type === 'start'){
                resultLifeStyle = await ListLifestyles(appointmentid)
            }else if(type === 'reload'){
                if(search){
                    resultLifeStyle = await SearchListLifestyles({appointmentid: appointmentid, word: search})
                }else{
                    resultLifeStyle = await ListLifestyles(appointmentid)
                }
            }else if(type === 'search'){
                if(search){
                    resultLifeStyle = await SearchListLifestyles({appointmentid: appointmentid, word: search})
                }else{
                    resultLifeStyle = await ListLifestyles(appointmentid)
                }
            }

            return { ...resultLifeStyle, card_select: obj.name }
        break;            
        default:
            console.error(name + ": listagem não encontrada.")
            return { card_select: obj.name }
    }
}

export default start