import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95260644"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-header"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 1,
  class: "form--list"
}
const _hoisted_4 = { class: "ask" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_Answers = _resolveComponent("Answers")!
  const _component_Write = _resolveComponent("Write")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString($props.label), 1),
          _withDirectives(_createVNode(_component_Close, {
            class: "close",
            onClick: $setup.close
          }, null, 8, ["onClick"]), [
            [
              _directive_tooltip,
              { value: 'Fechar' },
              void 0,
              { top: true }
            ]
          ])
        ]))
      : _createCommentVNode("", true),
    ($props.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h1", null, _toDisplayString(item.task), 1),
                _createVNode(_component_Answers)
              ]),
              (item.write)
                ? (_openBlock(), _createBlock(_component_Write, { key: 0 }))
                : _createCommentVNode("", true)
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}