/**
 * @fileoverview gRPC-Web generated client stub for drugApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: drug.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.drugApi = require('./drug_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.drugApi.DrugProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.drugApi.DrugProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.drugApi.PrescriptionDrugSearchItemsRequest,
 *   !proto.drugApi.PrescriptionDrugSearchItemsResponse>}
 */
const methodDescriptor_DrugProtoService_PrescriptionDrugSearchItems = new grpc.web.MethodDescriptor(
  '/drugApi.DrugProtoService/PrescriptionDrugSearchItems',
  grpc.web.MethodType.UNARY,
  proto.drugApi.PrescriptionDrugSearchItemsRequest,
  proto.drugApi.PrescriptionDrugSearchItemsResponse,
  /**
   * @param {!proto.drugApi.PrescriptionDrugSearchItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.drugApi.PrescriptionDrugSearchItemsResponse.deserializeBinary
);


/**
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.drugApi.PrescriptionDrugSearchItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.drugApi.PrescriptionDrugSearchItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.drugApi.DrugProtoServiceClient.prototype.prescriptionDrugSearchItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/drugApi.DrugProtoService/PrescriptionDrugSearchItems',
      request,
      metadata || {},
      methodDescriptor_DrugProtoService_PrescriptionDrugSearchItems,
      callback);
};


/**
 * @param {!proto.drugApi.PrescriptionDrugSearchItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.drugApi.PrescriptionDrugSearchItemsResponse>}
 *     Promise that resolves to the response
 */
proto.drugApi.DrugProtoServicePromiseClient.prototype.prescriptionDrugSearchItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/drugApi.DrugProtoService/PrescriptionDrugSearchItems',
      request,
      metadata || {},
      methodDescriptor_DrugProtoService_PrescriptionDrugSearchItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.drugApi.DosageSuggestionRequest,
 *   !proto.drugApi.DosageSuggestionResponse>}
 */
const methodDescriptor_DrugProtoService_DosageSuggestion = new grpc.web.MethodDescriptor(
  '/drugApi.DrugProtoService/DosageSuggestion',
  grpc.web.MethodType.UNARY,
  proto.drugApi.DosageSuggestionRequest,
  proto.drugApi.DosageSuggestionResponse,
  /**
   * @param {!proto.drugApi.DosageSuggestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.drugApi.DosageSuggestionResponse.deserializeBinary
);


/**
 * @param {!proto.drugApi.DosageSuggestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.drugApi.DosageSuggestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.drugApi.DosageSuggestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.drugApi.DrugProtoServiceClient.prototype.dosageSuggestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/drugApi.DrugProtoService/DosageSuggestion',
      request,
      metadata || {},
      methodDescriptor_DrugProtoService_DosageSuggestion,
      callback);
};


/**
 * @param {!proto.drugApi.DosageSuggestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.drugApi.DosageSuggestionResponse>}
 *     Promise that resolves to the response
 */
proto.drugApi.DrugProtoServicePromiseClient.prototype.dosageSuggestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/drugApi.DrugProtoService/DosageSuggestion',
      request,
      metadata || {},
      methodDescriptor_DrugProtoService_DosageSuggestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.drugApi.DrugSearchItemsRequest,
 *   !proto.drugApi.DrugSearchItemsResponse>}
 */
const methodDescriptor_DrugProtoService_DrugSearchItems = new grpc.web.MethodDescriptor(
  '/drugApi.DrugProtoService/DrugSearchItems',
  grpc.web.MethodType.UNARY,
  proto.drugApi.DrugSearchItemsRequest,
  proto.drugApi.DrugSearchItemsResponse,
  /**
   * @param {!proto.drugApi.DrugSearchItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.drugApi.DrugSearchItemsResponse.deserializeBinary
);


/**
 * @param {!proto.drugApi.DrugSearchItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.drugApi.DrugSearchItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.drugApi.DrugSearchItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.drugApi.DrugProtoServiceClient.prototype.drugSearchItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/drugApi.DrugProtoService/DrugSearchItems',
      request,
      metadata || {},
      methodDescriptor_DrugProtoService_DrugSearchItems,
      callback);
};


/**
 * @param {!proto.drugApi.DrugSearchItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.drugApi.DrugSearchItemsResponse>}
 *     Promise that resolves to the response
 */
proto.drugApi.DrugProtoServicePromiseClient.prototype.drugSearchItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/drugApi.DrugProtoService/DrugSearchItems',
      request,
      metadata || {},
      methodDescriptor_DrugProtoService_DrugSearchItems);
};


module.exports = proto.drugApi;

