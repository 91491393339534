<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5013 10.998C17.2803 10.998 17.0683 11.0858 16.912 11.2421C16.7558 11.3984 16.668 11.6104 16.668 11.8314V16.8314C16.668 17.0524 16.5802 17.2644 16.4239 17.4206C16.2676 17.5769 16.0556 17.6647 15.8346 17.6647H4.16797C3.94696 17.6647 3.73499 17.5769 3.57871 17.4206C3.42243 17.2644 3.33464 17.0524 3.33464 16.8314V5.16471C3.33464 4.9437 3.42243 4.73174 3.57871 4.57546C3.73499 4.41918 3.94696 4.33138 4.16797 4.33138H9.16797C9.38898 4.33138 9.60094 4.24358 9.75723 4.0873C9.9135 3.93102 10.0013 3.71906 10.0013 3.49805C10.0013 3.27703 9.9135 3.06507 9.75723 2.90879C9.60094 2.75251 9.38898 2.66471 9.16797 2.66471H4.16797C3.50493 2.66471 2.86904 2.92811 2.4002 3.39695C1.93136 3.86579 1.66797 4.50167 1.66797 5.16471V16.8314C1.66797 17.4944 1.93136 18.1303 2.4002 18.5991C2.86904 19.068 3.50493 19.3314 4.16797 19.3314H15.8346C16.4977 19.3314 17.1336 19.068 17.6024 18.5991C18.0712 18.1303 18.3346 17.4944 18.3346 16.8314V11.8314C18.3346 11.6104 18.2468 11.3984 18.0906 11.2421C17.9343 11.0858 17.7223 10.998 17.5013 10.998ZM5.0013 11.6314V15.1647C5.0013 15.3857 5.0891 15.5977 5.24538 15.754C5.40166 15.9103 5.61362 15.998 5.83464 15.998H9.36797C9.47764 15.9987 9.58636 15.9777 9.68789 15.9362C9.78942 15.8947 9.88177 15.8336 9.95964 15.7564L15.7263 9.98138L18.093 7.66471C18.1711 7.58724 18.2331 7.49508 18.2754 7.39353C18.3177 7.29198 18.3395 7.18306 18.3395 7.07305C18.3395 6.96304 18.3177 6.85412 18.2754 6.75257C18.2331 6.65102 18.1711 6.55885 18.093 6.48138L14.5596 2.90638C14.4822 2.82827 14.39 2.76628 14.2885 2.72397C14.1869 2.68166 14.078 2.65988 13.968 2.65988C13.858 2.65988 13.749 2.68166 13.6475 2.72397C13.5459 2.76628 13.4538 2.82827 13.3763 2.90638L11.0263 5.26471L5.24297 11.0397C5.16573 11.1176 5.10463 11.2099 5.06316 11.3115C5.02169 11.413 5.00067 11.5217 5.0013 11.6314ZM13.968 4.67305L16.3263 7.03138L15.143 8.21471L12.7846 5.85638L13.968 4.67305ZM6.66797 11.973L11.6096 7.03138L13.968 9.38971L9.0263 14.3314H6.66797V11.973Z" :fill="fill"/>
    </svg>

</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>