<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.4336 9.66652C22.9669 8.26652 21.5669 7.33319 20.1669 7.33319C18.1836 7.33319 16.6669 8.84986 16.6669 10.8332C16.6669 12.3499 17.6002 13.6332 19.0002 14.0999V16.0832C19.0002 18.9999 16.6669 21.3332 13.7502 21.3332C10.8336 21.3332 8.50022 18.9999 8.50022 16.0832V15.3832C11.8836 14.7999 14.3336 11.8832 14.3336 8.49986V1.49986C14.3336 0.799858 13.8669 0.333191 13.1669 0.333191H10.8336C10.1336 0.333191 9.66689 0.799858 9.66689 1.49986C9.66689 2.19986 10.1336 2.66652 10.8336 2.66652H12.0002V8.49986C12.0002 11.0665 9.90022 13.1665 7.33356 13.1665C4.76689 13.1665 2.66689 11.0665 2.66689 8.49986V2.66652H3.83356C4.53356 2.66652 5.00022 2.19986 5.00022 1.49986C5.00022 0.799858 4.53356 0.333191 3.83356 0.333191H1.50022C0.800224 0.333191 0.333557 0.799858 0.333557 1.49986V8.49986C0.333557 11.8832 2.78356 14.7999 6.16689 15.3832V16.0832C6.16689 20.2832 9.55022 23.6665 13.7502 23.6665C17.9502 23.6665 21.3336 20.2832 21.3336 16.0832V14.0999C23.2002 13.5165 24.1336 11.5332 23.4336 9.66652Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>