
import Calendar from "../../../assets/IconsComponent/Calendar.vue"
import Clock from "../../../assets/IconsComponent/Clock.vue"


export default {
    props: ["appointment", "isLoading"],
    emits: ["openDialogAdd"],
    components: { Calendar, Clock },
    setup(props, { emit }) {
        const openDialogAdd = () => {
            emit("openDialogAdd")
        }
        return {
            openDialogAdd
        }
    },
};
