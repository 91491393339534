<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.9974 1.66666C8.34922 1.66666 6.73806 2.1554 5.36765 3.07108C3.99724 3.98675 2.92913 5.28824 2.2984 6.81096C1.66767 8.33368 1.50265 10.0092 1.82419 11.6257C2.14573 13.2423 2.93941 14.7271 4.10484 15.8925C5.27028 17.058 6.75514 17.8517 8.37165 18.1732C9.98816 18.4947 11.6637 18.3297 13.1864 17.699C14.7091 17.0683 16.0106 16.0002 16.9263 14.6297C17.842 13.2593 18.3307 11.6482 18.3307 9.99999C18.3307 8.90564 18.1152 7.82201 17.6964 6.81096C17.2776 5.79991 16.6638 4.88125 15.89 4.10743C15.1161 3.33361 14.1975 2.71978 13.1864 2.30099C12.1754 1.8822 11.0917 1.66666 9.9974 1.66666ZM9.16407 16.6083C7.56252 16.3951 6.09292 15.6074 5.02853 14.3919C3.96413 13.1764 3.37739 11.6157 3.37739 9.99999C3.37739 8.38431 3.96413 6.8236 5.02853 5.60807C6.09292 4.39255 7.56252 3.60492 9.16407 3.39166V16.6083ZM10.8307 16.6083V3.39166C12.4323 3.60492 13.9019 4.39255 14.9663 5.60807C16.0307 6.8236 16.6174 8.38431 16.6174 9.99999C16.6174 11.6157 16.0307 13.1764 14.9663 14.3919C13.9019 15.6074 12.4323 16.3951 10.8307 16.6083Z" :fill="fill"/>
    </svg>

</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>