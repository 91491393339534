<template>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9206 0.62C12.8191 0.375651 12.625 0.181475 12.3806 0.0799999C12.2604 0.028759 12.1313 0.00157999 12.0006 0H2.00061C1.73539 0 1.48104 0.105357 1.2935 0.292893C1.10597 0.48043 1.00061 0.734784 1.00061 1C1.00061 1.26522 1.10597 1.51957 1.2935 1.70711C1.48104 1.89464 1.73539 2 2.00061 2H9.59061L1.29061 10.29C1.19688 10.383 1.12249 10.4936 1.07172 10.6154C1.02095 10.7373 0.994812 10.868 0.994812 11C0.994812 11.132 1.02095 11.2627 1.07172 11.3846C1.12249 11.5064 1.19688 11.617 1.29061 11.71C1.38357 11.8037 1.49417 11.8781 1.61603 11.9289C1.73789 11.9797 1.8686 12.0058 2.00061 12.0058C2.13262 12.0058 2.26333 11.9797 2.38519 11.9289C2.50705 11.8781 2.61765 11.8037 2.71061 11.71L11.0006 3.41V11C11.0006 11.2652 11.106 11.5196 11.2935 11.7071C11.481 11.8946 11.7354 12 12.0006 12C12.2658 12 12.5202 11.8946 12.7077 11.7071C12.8953 11.5196 13.0006 11.2652 13.0006 11V1C12.999 0.869323 12.9719 0.740222 12.9206 0.62Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>