// source: anvisaDrug.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.dataApi.Drug', null, global);
goog.exportSymbol('proto.dataApi.DrugSearchItemsRequest', null, global);
goog.exportSymbol('proto.dataApi.DrugSearchItemsResponse', null, global);
goog.exportSymbol('proto.dataApi.ListAprasentationsRequest', null, global);
goog.exportSymbol('proto.dataApi.ListAprasentationsResponse', null, global);
goog.exportSymbol('proto.dataApi.ListLaboratoriesRequest', null, global);
goog.exportSymbol('proto.dataApi.ListLaboratoriesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.DrugSearchItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataApi.DrugSearchItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.DrugSearchItemsRequest.displayName = 'proto.dataApi.DrugSearchItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.DrugSearchItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataApi.DrugSearchItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.dataApi.DrugSearchItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.DrugSearchItemsResponse.displayName = 'proto.dataApi.DrugSearchItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.Drug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataApi.Drug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.Drug.displayName = 'proto.dataApi.Drug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.ListLaboratoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataApi.ListLaboratoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.ListLaboratoriesRequest.displayName = 'proto.dataApi.ListLaboratoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.ListLaboratoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataApi.ListLaboratoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.dataApi.ListLaboratoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.ListLaboratoriesResponse.displayName = 'proto.dataApi.ListLaboratoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.ListAprasentationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataApi.ListAprasentationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.ListAprasentationsRequest.displayName = 'proto.dataApi.ListAprasentationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataApi.ListAprasentationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataApi.ListAprasentationsResponse.repeatedFields_, null);
};
goog.inherits(proto.dataApi.ListAprasentationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataApi.ListAprasentationsResponse.displayName = 'proto.dataApi.ListAprasentationsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.DrugSearchItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.DrugSearchItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.DrugSearchItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.DrugSearchItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    word: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.DrugSearchItemsRequest}
 */
proto.dataApi.DrugSearchItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.DrugSearchItemsRequest;
  return proto.dataApi.DrugSearchItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.DrugSearchItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.DrugSearchItemsRequest}
 */
proto.dataApi.DrugSearchItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.DrugSearchItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.DrugSearchItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.DrugSearchItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.DrugSearchItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.dataApi.DrugSearchItemsRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.DrugSearchItemsRequest} returns this
 */
proto.dataApi.DrugSearchItemsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string word = 2;
 * @return {string}
 */
proto.dataApi.DrugSearchItemsRequest.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.DrugSearchItemsRequest} returns this
 */
proto.dataApi.DrugSearchItemsRequest.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataApi.DrugSearchItemsResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.DrugSearchItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.DrugSearchItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.DrugSearchItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    drugsList: jspb.Message.toObjectList(msg.getDrugsList(),
    proto.dataApi.Drug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.DrugSearchItemsResponse}
 */
proto.dataApi.DrugSearchItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.DrugSearchItemsResponse;
  return proto.dataApi.DrugSearchItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.DrugSearchItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.DrugSearchItemsResponse}
 */
proto.dataApi.DrugSearchItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 5:
      var value = new proto.dataApi.Drug;
      reader.readMessage(value,proto.dataApi.Drug.deserializeBinaryFromReader);
      msg.addDrugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.DrugSearchItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.DrugSearchItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.DrugSearchItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDrugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.dataApi.Drug.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataApi.DrugSearchItemsResponse} returns this
 */
proto.dataApi.DrugSearchItemsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.DrugSearchItemsResponse} returns this
 */
proto.dataApi.DrugSearchItemsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.DrugSearchItemsResponse} returns this
 */
proto.dataApi.DrugSearchItemsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Drug drugs = 5;
 * @return {!Array<!proto.dataApi.Drug>}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.getDrugsList = function() {
  return /** @type{!Array<!proto.dataApi.Drug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataApi.Drug, 5));
};


/**
 * @param {!Array<!proto.dataApi.Drug>} value
 * @return {!proto.dataApi.DrugSearchItemsResponse} returns this
*/
proto.dataApi.DrugSearchItemsResponse.prototype.setDrugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dataApi.Drug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataApi.Drug}
 */
proto.dataApi.DrugSearchItemsResponse.prototype.addDrugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dataApi.Drug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataApi.DrugSearchItemsResponse} returns this
 */
proto.dataApi.DrugSearchItemsResponse.prototype.clearDrugsList = function() {
  return this.setDrugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.Drug.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.Drug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.Drug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.Drug.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    drugAllergy: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    principleAllergy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    allergy: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.Drug}
 */
proto.dataApi.Drug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.Drug;
  return proto.dataApi.Drug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.Drug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.Drug}
 */
proto.dataApi.Drug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrugAllergy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipleAllergy(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllergy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.Drug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.Drug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.Drug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.Drug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDrugAllergy();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPrincipleAllergy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAllergy();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dataApi.Drug.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string active_principle = 3;
 * @return {string}
 */
proto.dataApi.Drug.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string regulatory_category = 4;
 * @return {string}
 */
proto.dataApi.Drug.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string therapeutic_class = 5;
 * @return {string}
 */
proto.dataApi.Drug.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool drug_allergy = 6;
 * @return {boolean}
 */
proto.dataApi.Drug.prototype.getDrugAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setDrugAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string principle_allergy = 7;
 * @return {string}
 */
proto.dataApi.Drug.prototype.getPrincipleAllergy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setPrincipleAllergy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool allergy = 8;
 * @return {boolean}
 */
proto.dataApi.Drug.prototype.getAllergy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataApi.Drug} returns this
 */
proto.dataApi.Drug.prototype.setAllergy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.ListLaboratoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.ListLaboratoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListLaboratoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    apresentation: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.ListLaboratoriesRequest}
 */
proto.dataApi.ListLaboratoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.ListLaboratoriesRequest;
  return proto.dataApi.ListLaboratoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.ListLaboratoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.ListLaboratoriesRequest}
 */
proto.dataApi.ListLaboratoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setApresentation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.ListLaboratoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.ListLaboratoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListLaboratoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getApresentation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesRequest} returns this
 */
proto.dataApi.ListLaboratoriesRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string active_principle = 2;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesRequest} returns this
 */
proto.dataApi.ListLaboratoriesRequest.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string regulatory_category = 4;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesRequest} returns this
 */
proto.dataApi.ListLaboratoriesRequest.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string therapeutic_class = 5;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesRequest} returns this
 */
proto.dataApi.ListLaboratoriesRequest.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string apresentation = 6;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesRequest.prototype.getApresentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesRequest} returns this
 */
proto.dataApi.ListLaboratoriesRequest.prototype.setApresentation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataApi.ListLaboratoriesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.ListLaboratoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.ListLaboratoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.ListLaboratoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListLaboratoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    laboratoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.ListLaboratoriesResponse}
 */
proto.dataApi.ListLaboratoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.ListLaboratoriesResponse;
  return proto.dataApi.ListLaboratoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.ListLaboratoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.ListLaboratoriesResponse}
 */
proto.dataApi.ListLaboratoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLaboratories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.ListLaboratoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.ListLaboratoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.ListLaboratoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListLaboratoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLaboratoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dataApi.ListLaboratoriesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataApi.ListLaboratoriesResponse} returns this
 */
proto.dataApi.ListLaboratoriesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesResponse} returns this
 */
proto.dataApi.ListLaboratoriesResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.dataApi.ListLaboratoriesResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListLaboratoriesResponse} returns this
 */
proto.dataApi.ListLaboratoriesResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string laboratories = 4;
 * @return {!Array<string>}
 */
proto.dataApi.ListLaboratoriesResponse.prototype.getLaboratoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataApi.ListLaboratoriesResponse} returns this
 */
proto.dataApi.ListLaboratoriesResponse.prototype.setLaboratoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataApi.ListLaboratoriesResponse} returns this
 */
proto.dataApi.ListLaboratoriesResponse.prototype.addLaboratories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataApi.ListLaboratoriesResponse} returns this
 */
proto.dataApi.ListLaboratoriesResponse.prototype.clearLaboratoriesList = function() {
  return this.setLaboratoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.ListAprasentationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.ListAprasentationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.ListAprasentationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListAprasentationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activePrinciple: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regulatoryCategory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    therapeuticClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    laboratory: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.ListAprasentationsRequest}
 */
proto.dataApi.ListAprasentationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.ListAprasentationsRequest;
  return proto.dataApi.ListAprasentationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.ListAprasentationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.ListAprasentationsRequest}
 */
proto.dataApi.ListAprasentationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivePrinciple(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegulatoryCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTherapeuticClass(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLaboratory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.ListAprasentationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.ListAprasentationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.ListAprasentationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListAprasentationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivePrinciple();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegulatoryCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTherapeuticClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLaboratory();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.dataApi.ListAprasentationsRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsRequest} returns this
 */
proto.dataApi.ListAprasentationsRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string active_principle = 2;
 * @return {string}
 */
proto.dataApi.ListAprasentationsRequest.prototype.getActivePrinciple = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsRequest} returns this
 */
proto.dataApi.ListAprasentationsRequest.prototype.setActivePrinciple = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string regulatory_category = 4;
 * @return {string}
 */
proto.dataApi.ListAprasentationsRequest.prototype.getRegulatoryCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsRequest} returns this
 */
proto.dataApi.ListAprasentationsRequest.prototype.setRegulatoryCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string therapeutic_class = 5;
 * @return {string}
 */
proto.dataApi.ListAprasentationsRequest.prototype.getTherapeuticClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsRequest} returns this
 */
proto.dataApi.ListAprasentationsRequest.prototype.setTherapeuticClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string laboratory = 6;
 * @return {string}
 */
proto.dataApi.ListAprasentationsRequest.prototype.getLaboratory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsRequest} returns this
 */
proto.dataApi.ListAprasentationsRequest.prototype.setLaboratory = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataApi.ListAprasentationsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataApi.ListAprasentationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dataApi.ListAprasentationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataApi.ListAprasentationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListAprasentationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apresentationsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataApi.ListAprasentationsResponse}
 */
proto.dataApi.ListAprasentationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataApi.ListAprasentationsResponse;
  return proto.dataApi.ListAprasentationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataApi.ListAprasentationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataApi.ListAprasentationsResponse}
 */
proto.dataApi.ListAprasentationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addApresentations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataApi.ListAprasentationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataApi.ListAprasentationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataApi.ListAprasentationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataApi.ListAprasentationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApresentationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dataApi.ListAprasentationsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataApi.ListAprasentationsResponse} returns this
 */
proto.dataApi.ListAprasentationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.dataApi.ListAprasentationsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsResponse} returns this
 */
proto.dataApi.ListAprasentationsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.dataApi.ListAprasentationsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataApi.ListAprasentationsResponse} returns this
 */
proto.dataApi.ListAprasentationsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string apresentations = 4;
 * @return {!Array<string>}
 */
proto.dataApi.ListAprasentationsResponse.prototype.getApresentationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataApi.ListAprasentationsResponse} returns this
 */
proto.dataApi.ListAprasentationsResponse.prototype.setApresentationsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataApi.ListAprasentationsResponse} returns this
 */
proto.dataApi.ListAprasentationsResponse.prototype.addApresentations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataApi.ListAprasentationsResponse} returns this
 */
proto.dataApi.ListAprasentationsResponse.prototype.clearApresentationsList = function() {
  return this.setApresentationsList([]);
};


goog.object.extend(exports, proto.dataApi);
