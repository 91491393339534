<template>
    <div class="min-height-loader flex flex-column gap-8">
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="40px" border-radius="10px" />
        <Skeleton width="100%" height="68px" border-radius="10px" />
        <div class="flex align-items-center mt-10 gap-32">
            <Skeleton width="320px" height="78px" border-radius="10px" />
            <Skeleton width="140.69px" height="40px" border-radius="128.571px" />
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.min-height-loader{
    min-height: 406px;
}

</style>