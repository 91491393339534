import { ListPatientHealthRequest } from "../grpc/generated/patientProfile_pb.js";
import patientProfileService from "../services/patientProfileService"

async function ListPatientHealth (patient_id) {
    try {
        const req = new ListPatientHealthRequest();
        req.setPatientId(patient_id)
        const res = await patientProfileService.listPatientHealth(req);

        // console.log("ListPatientHealth", res, patient_id)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    ListPatientHealth,
}