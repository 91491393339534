<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5002 19.3499V4.64986C23.2002 4.29986 23.6669 3.48319 23.6669 2.66652C23.6669 1.38319 22.6169 0.333191 21.3336 0.333191C20.5169 0.333191 19.7002 0.799858 19.3502 1.49986H4.65022C4.30022 0.799858 3.48356 0.333191 2.66689 0.333191C1.38356 0.333191 0.333557 1.38319 0.333557 2.66652C0.333557 3.48319 0.800224 4.29986 1.50022 4.64986V19.3499C0.800224 19.6999 0.333557 20.5165 0.333557 21.3332C0.333557 22.6165 1.38356 23.6665 2.66689 23.6665C3.48356 23.6665 4.30022 23.1999 4.65022 22.4999H19.3502C19.7002 23.1999 20.5169 23.6665 21.3336 23.6665C22.6169 23.6665 23.6669 22.6165 23.6669 21.3332C23.6669 20.5165 23.2002 19.6999 22.5002 19.3499ZM20.1669 19.3499C19.8169 19.5832 19.5836 19.8165 19.3502 20.1665H4.65022C4.41689 19.8165 4.18356 19.5832 3.83356 19.3499V4.64986C4.18356 4.41652 4.41689 4.18319 4.65022 3.83319H19.3502C19.5836 4.18319 19.8169 4.41652 20.1669 4.64986V19.3499ZM16.6669 6.16652H7.33356C6.63356 6.16652 6.16689 6.63319 6.16689 7.33319V16.6665C6.16689 17.3665 6.63356 17.8332 7.33356 17.8332H16.6669C17.3669 17.8332 17.8336 17.3665 17.8336 16.6665V7.33319C17.8336 6.63319 17.3669 6.16652 16.6669 6.16652Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>