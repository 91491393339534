import { 
    DeleteDrugAllergy, 
    DeleteDrugInUse,
    DeleteExercise,
    DeleteDrink,
    DeleteCigarette,
} from "@/modules/core/actions/health.js"

const remove = async (obj: any) => {
    console.groupCollapsed('Obj Remove')
    console.table(obj)
    console.groupEnd()

    const { name, type } = obj

    switch (name) {
        case "Alergia a medicamentos":
            let resultDrugAllergy = await DeleteDrugAllergy({ drugallergyid: obj.drugAllergyId });
            // let listName: string;

            return { ...resultDrugAllergy, card_select: obj.name }
        break;
        case "Medicamentos em uso":
            let resultDrugInUse = await DeleteDrugInUse(obj.prescriptionDrugId);

            return {...resultDrugInUse, card_select: obj.name}
        break;
        case "Estilo de vida":
            if('exerciseId' in obj){
                const resDeleteExercise = await DeleteExercise(obj.exerciseId)
                return { ...resDeleteExercise, card_select: obj.name }
            }
            if('drinkId' in obj){
                const resDeleteDrink = await DeleteDrink(obj.drinkId)
                return { ...resDeleteDrink, card_select: obj.name }
            }
            if('smokerId' in obj){
                const resDeleteCigarette = await DeleteCigarette(obj.smokerId)
                return { ...resDeleteCigarette, card_select: obj.name }
            }
        break;
        default:
            console.error(name + " não entrou em nenhum no remove.")
    }
}

export default remove