import { defineStore } from 'pinia';
import router from '../router';

export const useHealth = defineStore('Health', {
    state: () => {
      return {
        dropdown: "",
        pathName: 'históricos',
        items: [
          { label: "históricos" },
          { label: "triagem" },
          { label: "anamnese" },
          { label: "exames" },
          { label: "prescrição" },
          { label: "diagnóstico" },
          { label: "encaminhar" },
          { label: "atestados" },
        ]
      }
    },
    getters: {
    },
    actions: {
      changeDropdown(value) {
        this.dropdown = value;
      },
      closeAll() {
        if(this.dropdown){
          this.dropdown = "";
        }
      },
      changeRoute(path) {
        
        // router.push(path);
        this.pathName = path
      },
      logout(path) {
        this.pathName = "/"
        router.push("/account/login");
      }
    }
});