<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 10H19.9C19.7 8.3 19 6.7 18 5.4L18.8 4.6C19.2 4.2 19.2 3.6 18.8 3.2C18.4 2.8 17.8 2.8 17.4 3.2L16.6 4C15.3 2.9 13.7 2.3 12 2.1V1C12 0.4 11.6 0 11 0C10.4 0 10 0.4 10 1V2.1C8.3 2.2 6.7 2.9 5.4 4L4.6 3.2C4.2 2.8 3.6 2.8 3.2 3.2C2.8 3.6 2.8 4.2 3.2 4.6L4 5.4C2.9 6.7 2.2 8.3 2.1 10H1C0.4 10 0 10.4 0 11C0 11.6 0.4 12 1 12H2.1C2.3 13.7 3 15.3 4 16.6L3.2 17.4C2.8 17.8 2.8 18.4 3.2 18.8C3.6 19.2 4.2 19.2 4.6 18.8L5.4 18C6.7 19.1 8.3 19.7 10 19.9V21C10 21.6 10.4 22 11 22C11.6 22 12 21.6 12 21V19.9C13.7 19.7 15.3 19 16.6 18L17.4 18.8C17.8 19.2 18.4 19.2 18.8 18.8C19.2 18.4 19.2 17.8 18.8 17.4L18 16.6C19.1 15.3 19.7 13.7 19.9 12H21C21.6 12 22 11.6 22 11C22 10.4 21.6 10 21 10ZM8 15C7.4 15 7 14.6 7 14C7 13.4 7.4 13 8 13C8.6 13 9 13.4 9 14C9 14.6 8.6 15 8 15ZM8.5 11C7.7 11 7 10.3 7 9.5C7 8.7 7.7 8 8.5 8C9.3 8 10 8.7 10 9.5C10 10.3 9.3 11 8.5 11ZM13.5 14C12.7 14 12 13.3 12 12.5C12 11.7 12.7 11 13.5 11C14.3 11 15 11.7 15 12.5C15 13.3 14.3 14 13.5 14ZM14 9C13.4 9 13 8.6 13 8C13 7.4 13.4 7 14 7C14.6 7 15 7.4 15 8C15 8.6 14.6 9 14 9Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>